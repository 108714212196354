import React, { useState } from 'react'
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Form,
  Button,
  Table,
  Space,
  Dropdown,
  message,
} from "antd";
import { useGetAllRolesQuery } from './adminApi';
import { ReusablePagination } from '../../component';

const RoleListTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    data: roleListData,
    isLoading,
    error,
  } = useGetAllRolesQuery();
  console.log("Role list data", roleListData);
  
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "",
      key: "option",
      align: "center",
      width: "15%",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EyeOutlined /> View
                    </span>
                  ),
                  // onClick: () =>
                  //   navigate(`/cd/adminstrator/role-permission/view-detail`, {
                  //     state: { role: record },
                  //   }),
                },
                {
                  key: "2",
                  label: (
                    <span>
                      <EditOutlined /> Edit
                    </span>
                  ),
                  // onClick: () =>
                  //   navigate(`/cd/adminstrator/role-permission/edit-role`, {
                  //     state: { role: record },
                  //   }),
                },
                {
                  key: "3",
                  label: (
                    <span>
                      <DeleteOutlined /> Delete
                    </span>
                  ),
                  // onClick: () => showDeleteModal(record),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = roleListData?.data?.map((role) => ({
    key: role._id,
    role: role.role_name,
  }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div style={{marginTop:'16px'}}>
      <Table loading={isLoading} columns={columns} dataSource={paginatedData} pagination={false}/>
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={roleListData?.paginator?.totalItems}
        onChange={handlePaginationChange}
      />
    </div>
  )
}

export default RoleListTable