import { baseApi } from "../../app/baseApi";

export const utilityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUtility: builder.query({
      query: () => `/Utility_Bill/list`,
      providesTags: ["UtilityList"],
    }),

    addNewUtility: builder.mutation({
      query: ({ utilityType, title, unit, rate, branch, rate_type, property_type }) => ({
        url: `/Utility_Bill/create`,
        method: "POST",
        body: {
          utilityType,
          title,
          unit,
          rate,
          rate_type,
          branch,
          property_type
        },
      }),
      invalidatesTags: ["UtilityList"],
    }),

    updateUtility: builder.mutation({
      query: ({ utilityId, updatedData }) => ({
        url: `/Utility_Bill/update/${utilityId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit utility failed:", error);
      },
      invalidatesTags: ["UtilityList"],
    }),

    deleteUtility: builder.mutation({
      query: ({ utilityId }) => ({
        url: `/Utility_Bill/delete/${utilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UtilityList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllUtilityQuery,
  useAddNewUtilityMutation,
  useDeleteUtilityMutation,
  useUpdateUtilityMutation,
} = utilityApi;
