import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table, Tag } from 'antd';
import React, { useState } from 'react'
import { ReusablePagination } from '../../component';

const MaintenanceListTable = ({onView, onChange, onDelete}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Property Code",
      dataIndex: "propertyCode",
      key: "propertyCode",
    },
    {
      title: "Type of Issue",
      dataIndex: "typeOfIssue",
      key: "typeOfIssue",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags?.map((tag) => {
            let color;
            switch (tag) {
              case "Processing":
                color = "#FF9F43";
                break;
              case "Finished":
                color = "#7367F0";
                break;
              case "Pending":
                color = "red";
                break;
              default:
                color = "red";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: "View Details",
                  onClick: (record) => onView(record),
                },
                {
                  key: "2",
                  label: "Change Status",
                  onClick: (record) => onChange(record),
                },
                {
                  key: "3",
                  label: "Delete",
                  onClick: (record) => onDelete(record),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      date: "11/11/2022",
      tenantName: "Tenant Name",
      propertyCode: "#C001",
      typeOfIssue: "Issue #001",
      tags: ["Processing"],
    },
    {
      key: "2",
      date: "11/11/2022",
      tenantName: "Tenant Name",
      propertyCode: "#C002",
      typeOfIssue: "Issue #001",
      tags: ["Finished"],
    },
    {
      key: "3",
      date: "11/11/2022",
      tenantName: "Tenant Name",
      propertyCode: "#C003",
      typeOfIssue: "Issue #001",
      tags: ["Pending"],
    },
  ];
  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <Table dataSource={paginatedData} columns={columns} pagination={false} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total='3' // Use total items here
        onChange={handlePaginationChange}
      />
    </div>
  )
}

export default MaintenanceListTable