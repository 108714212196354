import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetShopByZoneIdQuery,
  useGetZoneByBuildingIdQuery,
} from "../../features/property/propertyApi";
import { useGetAllRoomSettingQuery } from "../../features/setting/roomSettingApi";

const { Option } = Select;

const PropertyInformation = ({ properties, setProperties, setFormState }) => {
  const [form] = Form.useForm();
  const branch = properties?.[0]?.branch || ""; // Access the first property
  const building = properties?.[0]?.building || "";
  const floor = properties?.[0]?.floor || "";
  const zone = properties?.[0]?.zone || "";

  // State Control
  const [selectedBuilding, setSelectedBuilding] = useState(building);
  const [selectedFloor, setSelectedFloor] = useState(floor);
  const [selectedBranch, setSelectedBranch] = useState(branch);
  const [selectedZone, setSelectedZone] = useState(zone);
  const [branchName, setBranchName] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [floorName, setFloorName] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [formStateData, setFormStateData] = useState({
    branch: null,
    branchName: "",
    building: null,
    buildingName: "",
    floor: null,
    floorName: "",
    zone: null,
    zoneName: "", // Add zone name
    shop: null,
    shopName: "", // Add shop name
    area: "",
    price: "",
    room_type: null,
    room_type_name: "", // Room type name
  });

  // API fetching
  const { data: branchList, isLoading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetBuildingsByBranchIdQuery(selectedBranch, { skip: !selectedBranch });
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });
  const { data: zonesData, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      { skip: !selectedFloor }
    );
  const { data: shopData, isLoading: isLoadingShop } = useGetShopByZoneIdQuery(
    { zoneId: selectedZone },
    { skip: !selectedZone }
  );

  const { data: roomTypeData, isLoading: isLoadingRoomType } =
    useGetAllRoomSettingQuery();

  // Add selected state data
  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    setSelectedFloor(""); // Clear floor selection when building changes
    form.setFieldsValue({ floor: undefined, zone: undefined });
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
    form.setFieldsValue({ zone: undefined });
  };

  const handleZoneChange = (value) => {
    setSelectedZone(value);
    form.setFieldsValue({ shop: undefined }); // Reset shop field
  };

  const handleShopChange = (shopId) => {
    const selectedShop = shopData?.data?.find((shop) => shop._id === shopId);

    if (selectedShop) {
      // Find the room type name
      const roomTypeName =
        roomTypeData?.data?.buildings?.find(
          (roomType) => roomType._id === selectedShop.room_type
        )?.room_type || "N/A"; // Default to "N/A" if not found

      // Update the form fields
      form.setFieldsValue({
        room_type: selectedShop.room_type,
        area: selectedShop.total_area,
        price: selectedShop.price,
      });

      // Update the form state
      setFormState((prevState) => ({
        ...prevState,
        shop: shopId,
        shopName: selectedShop.name || "N/A",
        room_type: selectedShop.room_type,
        room_type_name: roomTypeName, // Set the correct room type name
        area: selectedShop.total_area,
        price: selectedShop.price,
      }));
    } else {
      // Reset shop-related fields if no shop is selected
      form.setFieldsValue({
        room_type: undefined,
        area: undefined,
        price: undefined,
      });

      // Reset the form state for shop-related fields
      setFormState((prevState) => ({
        ...prevState,
        shop: null,
        shopName: "",
        room_type: null,
        room_type_name: "",
        area: "",
        price: "",
      }));
    }
  };

  // Set branch, building, floor, zone, shop names
  useEffect(() => {
    if (selectedBranch) {
      const branch = branchList?.data?.find((b) => b._id === selectedBranch);
      setBranchName(branch?.name || "");
    }
  }, [selectedBranch, branchList]);

  useEffect(() => {
    if (selectedBuilding) {
      const building = buildingListData?.data?.find(
        (b) => b._id === selectedBuilding
      );
      setBuildingName(building?.name || "");
    }
  }, [selectedBuilding, buildingListData]);

  useEffect(() => {
    if (selectedFloor) {
      const floor = floorListData?.data?.find((f) => f._id === selectedFloor);
      setFloorName(floor?.name || "");
    }
  }, [selectedFloor, floorListData]);
  useEffect(() => {
    if (selectedZone) {
      const zone = zonesData?.data?.zone?.find((z) => z._id === selectedZone);
      setZoneName(zone?.name || "");
    }
  }, [selectedZone, zonesData]);

  // Add Properties function
  const handleAddProperty = () => {
    form.validateFields().then((values) => {
      // Find shop name
      const selectedShop = shopData?.data?.find(
        (shop) => shop._id === values.shop
      );
      const selectedShopName = selectedShop?.name || "N/A";

      // Find room type name
      const selectedRoomType = roomTypeData?.data?.buildings?.find(
        (roomType) => roomType._id === values.room_type
      );

      const newProperty = {
        branch: values.branch,
        branchName,
        building: values.building,
        buildingName,
        floor: values.floor,
        floorName,
        zone: values.zone,
        zoneName, // Add zone name
        shop: values.shop || "N/A",
        shopName: selectedShopName, // Add shop name
        area: values.area || "N/A",
        price: values.price || 0,
        room_type: values.room_type || "N/A", // Room type ID
        room_type_name: selectedRoomType?.room_type || "N/A", // Room type name
      };
      if (properties?.length === 0) {
        setProperties([newProperty]);
      } else {
        setProperties((prevProperties) => [...prevProperties, newProperty]);
      }
  
      setFormState({});
      form.resetFields();
    });
  };

  // Add delete function and edit function in add properties card
  const handleDeleteProperty = (index) => {
    const updatedProperties = properties.filter((_, i) => i !== index);
    setProperties(updatedProperties);
  };

  const handleEditProperty = (property) => {
    form.setFieldsValue({
      branch: property.branch,
      building: property.building,
      floor: property.floor,
      zone: property.zone,
      shop: property.shop, // Set the shop ID
      room_type: property.room_type,
      area: property.area,
      price: property.price,
    });
    setFormState({
      branch: property.branch,
      building: property.building,
      floor: property.floor,
      zone: property.zone,
      shop: property.shop, // Set the shop ID
      room_type: property.room_type,
      area: property.area,
      price: property.price,
    });
    handleDeleteProperty(properties.indexOf(property)); // Remove the property being edited
  };
  const handleValuesChange = (changedValues, allValues) => {
    setFormState((prevState) => ({
      ...prevState,
      ...allValues,
      branchName:
        branchList?.data?.find((b) => b._id === allValues.branch)?.name || "",
      buildingName:
        buildingListData?.data?.find((b) => b._id === allValues.building)
          ?.name || "",
      floorName:
        floorListData?.data?.find((f) => f._id === allValues.floor)?.name || "",
      zoneName:
        zonesData?.data?.zone?.find((z) => z._id === allValues.zone)?.name ||
        "",
      shopName:
        shopData?.data?.find((shop) => shop._id === allValues.shop)?.name || "",
      room_type_name:
        roomTypeData?.data?.buildings?.find(
          (roomType) => roomType._id === allValues.room_type
        )?.room_type || "",
    }));
  };

  return (
    <div>
      <Card style={{ marginTop: "16px" }} title="Property Information">
        <Form layout="vertical" form={form} onValuesChange={handleValuesChange}>
          <div className="add-branch-form">
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Project / Branch"
              name="branch">
              <Select
                style={{ height: 44 }}
                placeholder="Select Project / Branch"
                onChange={(value) => setSelectedBranch(value)}
                loading={isLoadingBranch} // Adds a spinner
                disabled={isLoadingBranch} // Disable the Select while loading
              >
                {isLoadingBranch ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  branchList?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Building"
              name="building">
              <Select
                placeholder="Building"
                style={{ height: 44 }}
                rules={[{ required: true, message: "Please select this" }]}
                onChange={handleBuildingChange}
                loading={isLoadingBuildings}>
                {isLoadingBuildings ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  buildingListData?.data?.map((building) => (
                    <Option key={building._id} value={building._id}>
                      {building.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Floor"
              name="floor">
              <Select
                placeholder="Floor"
                style={{ height: 44 }}
                onChange={handleFloorChange}
                loading={isLoadingFloors}
                rules={[{ required: true, message: "Please select this" }]}
                disabled={!selectedBuilding}>
                {isLoadingFloors ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  floorListData?.data?.map((floor) => (
                    <Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Zone"
              name="zone">
              <Select
                placeholder="Zone"
                style={{ height: 44 }}
                loading={isLoadingZones}
                rules={[{ required: true, message: "Please select this" }]}
                onChange={handleZoneChange}
                disabled={!selectedBuilding || !selectedFloor}>
                {isLoadingZones ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  zonesData?.data?.zone?.map((zone) => (
                    <Option key={zone._id} value={zone._id}>
                      {zone.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Shop Name"
              name="shop">
              <Select
                placeholder="Shop"
                style={{ height: 44 }}
                readOnly
                loading={isLoadingShop}
                onChange={handleShopChange}
                disabled={!selectedBuilding || !selectedFloor || !selectedZone}>
                {isLoadingShop ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  shopData?.data?.map((shop) => (
                    <Option key={shop._id} value={shop._id}>
                      {shop.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Room Type"
              name="room_type">
              <Select
                placeholder="Room Type"
                style={{ height: 44 }}
                readOnly
                loading={isLoadingRoomType}>
                {roomTypeData?.data?.buildings?.map((roomType) => (
                  <Option key={roomType._id} value={roomType._id}>
                    {roomType.room_type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Total Area (sq ft)"
              name="area">
              <Input style={{ height: 44 }} placeholder="Area" readOnly />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              label="Price (MMK)"
              name="price">
              <Input style={{ height: 44 }} placeholder="Price" />
            </Form.Item>
          </div>
          <div className="add-property-div">
            <Button className="add-property" onClick={handleAddProperty}>
              <PlusOutlined /> Add Property
            </Button>
          </div>
        </Form>
      </Card>

      {/* Display added properties */}
      {properties?.length > 0 && (
        <div className="property-data-card" style={{ marginTop: "16px" }}>
          {properties.map((property, index) => (
            <Card key={index} style={{ marginBottom: "10px" }}>
              <Form
                key={`property_form_${index}`}
                initialValues={{
                  branch: property.branchName,
                  building: property.buildingName,
                  floor: property.floorName,
                  zone: property.zoneName,
                  shop: property.shop, // Display shop name
                  room_type: property.room_type, // Display room type
                  area: property.area,
                  price: property.price,
                }}
                layout="vertical">
                <div className="add-branch-form">
                  <Form.Item
                    className="add-branch-input"
                    label="Project / Branch"
                    name="branch">
                    <Select
                      disabled
                      value={property.branch}
                      style={{ height: 44 }}
                      onChange={(value) => setSelectedBranch(value)}
                      placeholder="Select Project / Branch"
                      loading={isLoadingBranch}>
                      {isLoadingBranch ? (
                        <Option key="loading" disabled>
                          Loading...
                        </Option>
                      ) : (
                        branchList?.data?.map((branch) => (
                          <Option key={branch._id} value={branch._id}>
                            {branch?.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Building"
                    name="building">
                    <Select
                      disabled
                      value={property.building}
                      placeholder="Building"
                      style={{ height: 44 }}
                      onChange={handleBuildingChange}
                      loading={isLoadingBuildings}>
                      {isLoadingBuildings ? (
                        <Option key="loading" disabled>
                          Loading...
                        </Option>
                      ) : (
                        buildingListData?.data?.map((building) => (
                          <Option key={building._id} value={building._id}>
                            {building.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Floor"
                    name="floor">
                    <Select
                      disabled
                      value={property.floor}
                      placeholder="Floor"
                      style={{ height: 44 }}
                      onChange={handleFloorChange}
                      loading={isLoadingFloors}>
                      {isLoadingFloors ? (
                        <Option key="loading" disabled>
                          Loading...
                        </Option>
                      ) : (
                        floorListData?.data?.map((floor) => (
                          <Option key={floor._id} value={floor._id}>
                            {floor.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Zone"
                    name="zone">
                    <Select
                      disabled
                      value={property.zone}
                      placeholder="Zone"
                      style={{ height: 44 }}
                      loading={isLoadingZones}>
                      {isLoadingZones ? (
                        <Option key="loading" disabled>
                          Loading...
                        </Option>
                      ) : (
                        zonesData?.data?.zone?.map((zone) => (
                          <Option key={zone._id} value={zone._id}>
                            {zone.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Shop Name"
                    name="shop">
                    <Select
                      disabled
                      value={property?.shopName} // Ensure this matches the expected shop ID
                      placeholder="Shop"
                      style={{ height: 44 }}
                      readOnly
                      loading={isLoadingShop}>
                      {isLoadingShop ? (
                        <Option key="loading" disabled>
                          Loading...
                        </Option>
                      ) : (
                        shopData?.data?.map((shop) => (
                          <Option key={shop._id} value={shop._id}>
                            {shop?.name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Room Type"
                    name="room_type">
                    <Select
                      disabled
                      placeholder="Room Type"
                      style={{ height: 44 }}
                      readOnly
                      loading={isLoadingRoomType}>
                      {roomTypeData?.data?.buildings?.map((roomType) => (
                        <Option key={roomType._id} value={roomType._id}>
                          {roomType.room_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Total Area (sq ft)"
                    name="area">
                    <Input
                      readOnly
                      value={property.area}
                      style={{ height: 44 }}
                      placeholder="Area"
                    />
                  </Form.Item>
                  <Form.Item
                    className="add-branch-input"
                    label="Price (MMK)"
                    name="price">
                    <Input
                      readOnly
                      value={property.price}
                      style={{ height: 44 }}
                      placeholder="Price"
                    />
                  </Form.Item>
                </div>
              </Form>
              <div className="add-property-div">
                <Button onClick={() => handleEditProperty(property)}>
                  <EditOutlined />
                </Button>
                <Button onClick={() => handleDeleteProperty(index)}>
                  <DeleteOutlined />
                </Button>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default PropertyInformation;
