import { Form, Input, Button, Select, Space, Card, Modal, message } from "antd";
import React from "react";
import { useAddNewContractMutation } from "../contract/contractApi";

const AddNewContract = ({ visible, handleOk, handleCancel }) => {
  const [form] = Form.useForm();

  // Initialize the mutation hook
  const [addNewContract, { isLoading }] = useAddNewContractMutation();

  // Handle form submission
  const onFinish = async (values) => {
    try {
      const { contractType, contractNumber, aboutToExpire } = values;
      await addNewContract({
        contract_type: contractType ? contractType : "day",
        contract_duration: contractNumber,
        about_to_expire: aboutToExpire,
      }).unwrap();
      message.success("Contract added successfully!");
      form.resetFields(); // Clear the form
      handleOk(); // Close the modal
    } catch (error) {
      console.error("Failed to add contract:", error);
      message.error("Failed to add contract.");
    }
  };

  return (
    <Modal
      width={880}
      className="bu-add-modal"
      open={visible}
      onCancel={handleCancel}
      closeIcon={null}
      footer={null}>
      <Card title="Add New Type of Contract">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish} // Attach form submit handler
        >
          <Form.Item label="Contract Duration">
            <Space.Compact style={{ width: "100%" }}>
              <Form.Item
                noStyle
                name="contractNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input the contract number!",
                  },
                ]}>
                <Input type="number" min={1} style={{ height: 44, width: "80%" }} />
              </Form.Item>
              <div className="select-rate-type">
                <Form.Item
                  name="contractType"
                  noStyle
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please choose the contract type!",
                  //   },
                  // ]}
                  >
                  <Select defaultValue="day" style={{ width: 110, height: 44 }}>
                    <Select.Option value="day">Day(s)</Select.Option>
                    <Select.Option value="month">Month(s)</Select.Option>
                    <Select.Option value="year">Year(s)</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Space.Compact>
          </Form.Item>
          <Form.Item
            label="About to Expire"
            name="aboutToExpire"
          >
            <Input type="number" min={1} style={{ height: 44 }} />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" className="btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isLoading} // Show loading state on submit button
            >
              Create
            </Button>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default AddNewContract;
