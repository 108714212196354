import React, { useState } from "react";
import { FilterCard, ReportingHeader } from "../../component/index";
import { Button, DatePicker, Select } from "antd";
import { ReportingSubTenantTable, ReportingTenantTable } from "../../features";

const { RangePicker } = DatePicker;
const TenantReport = () => {
  const [activeButton, setActiveButton] = useState("tenant");

  return (
    <div>
      <ReportingHeader title="Tenant Report" />
      <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
        <Button
          className={`btn ${activeButton === "tenant" ? "filter" : ""}`}
          onClick={() => setActiveButton("tenant")}>
          Tenant
        </Button>
        <Button
          className={`btn ${activeButton === "subTenant" ? "filter" : ""}`}
          onClick={() => setActiveButton("subTenant")}>
          Sub Tenant
        </Button>
      </div>
      <FilterCard>
        <RangePicker style={{ height: 38, width: 233 }} />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="All Status"
        />
      </FilterCard>
      {activeButton === "tenant" && <ReportingTenantTable />}
      {activeButton === "subTenant" && <ReportingSubTenantTable />}
    </div>
  );
};

export default TenantReport;
