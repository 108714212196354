import { InboxOutlined } from "@ant-design/icons";
import { Card, Input, Form, Upload } from "antd";
import React, { useEffect, useState } from "react";

const { Dragger } = Upload;

const ViewOwnContract = ({ contract_info }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  console.log("contract info", contract_info);

  useEffect(() => {
    if (contract_info) {
      form.setFieldsValue({
        contract_name: contract_info.contract_name,
      });

      // Prepopulate the file list with the existing contract file
      if (contract_info.contract_file) {
        setFileList([
          {
            uid: "-1", // Unique ID for the file
            name: contract_info.contract_file.split("/").pop(), // Extract the file name from the path
            status: "done", // File upload status
            url: contract_info.contract_file, // File URL
          },
        ]);
      }
    }
  }, [contract_info, form]);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <Card title="Contract Information" style={{ marginTop: "16px" }}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="contract_name"
          label="Contract Name"
          rules={[{ required: true, message: "Please enter a contract name" }]}
        >
          <Input readOnly style={{ height: 44 }} />
        </Form.Item>
        <Form.Item name="contract_file" label="Contract File">
          <Dragger
            beforeUpload={() => false}
            multiple={false}
            maxCount={1}
            listType="text"
            accept=".pdf, .jpg, .jpeg, .png"
            fileList={fileList} // Prepopulate the file list
            onChange={handleFileChange}
            style={{ width: 1060, height: 44 }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single upload.</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ViewOwnContract;
