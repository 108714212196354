import React, { useState } from "react";
import { message } from "antd";
import { EditTenantCategory, TenantCategoryTable, AddTenantCategory } from "../../features";
import { DeleteModal, PageHeader, ReusablePagination, SearchFilter } from "../../component";
import { useSelector } from "react-redux";
import {
  useDeleteTenantCategoryMutation,
  useGetAllTenantCategoryQuery,
} from "../../features/tenant/tenantCategoryApi";

const TenantCategory = () => {
  // Api
  const token = useSelector((state) => state.authSlice.token2);
  const { data: tenantCategoryList, isLoading } =
    useGetAllTenantCategoryQuery(token);
  const [deleteTenantCategory] = useDeleteTenantCategoryMutation(token);

  // useState for managing modals and form data
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // Add state for Add Modal

  // Delete Modal
  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteTenantCategory({ categoryId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Tenant Category is successfully deleted.");
      } catch (err) {
        console.error("Failed to delete contract:", err);
        message.error("Failed to delete this contract type");
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  // Edit Modal
  const showEditModal = (record) => {
    setSelectedRecord(record);
    setIsEditModalOpen(true);
  };

  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
    setSelectedRecord(null); // Clear selected record on cancel
  };

  // Add Modal
  const showAddModal = () => {
    setIsAddModalOpen(true);
  };

  const handleAddOk = () => {
    setIsAddModalOpen(false); // Close the add modal after successful submission
  };

  const handleAddCancel = () => {
    setIsAddModalOpen(false); // Close the add modal without submitting
  };

  // Table Data
  const filteredData = tenantCategoryList?.data
    ?.filter((category) =>
      category.tenant_category_name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.map((category, index) => ({
      key: category._id,
      no: index + 1,
      tenantCategory: category.tenant_category_name,
    }));

  // Handle pagination changes
  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // Calculate data for the current page
  const paginatedData = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <PageHeader title="Tenant Categories" addBtn="Add Tenant Category" onAdd={showAddModal} />
      <SearchFilter searchPlaceHolder="Search..." onSearch={setSearchQuery} />

      <TenantCategoryTable
        loading={isLoading}
        data={paginatedData}
        onEdit={showEditModal}
        onDelete={showDeleteModal}
      />

      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={tenantCategoryList?.paginator?.totalItems}
        onChange={handlePaginationChange}
      />

      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <EditTenantCategory
        isEditModalOpen={isEditModalOpen}
        selectedRecord={selectedRecord}
        handleEditOk={handleEditOk}
        handleEditCancel={handleEditCancel}
      />

      <AddTenantCategory
        isAddModalOpen={isAddModalOpen} // Reuse modal state for Add
        handleOk={handleAddOk}
        handleCancel={handleAddCancel}
      />
    </div>
  );
};

export default TenantCategory;
