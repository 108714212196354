import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  DatePicker,
  Modal,
  Dropdown,
  Space,
  Table,
  Tag,
  Input,
  message,
  Card,
} from "antd";
import PaymentIcon from "../../assets/icons/payment.svg";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import {
  useGetAllInvoicesListQuery,
  useUpdateInvoiceMutation,
} from "../../features/invoice/invoiceApi";
import moment from "moment";
import { DeleteOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
import {
  DeleteModal,
  FilterCard,
  InvoiceCard,
  PageHeader,
  ReusablePagination,
  SearchFilter,
} from "../../component";

const { Option } = Select;
const { RangePicker } = DatePicker;

const InvoiceList = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Manage current page state
  const [pageSize, setPageSize] = useState(10);
  const { id } = useParams();
  const [form] = Form.useForm(); // Create a form instance
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [invoiceTypeFilter, setInvoiceTypeFilter] = useState(null);
  const [transactionTypeFilter, setTransactionTypeFilter] = useState(null);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // API call to fetch invoice list
  const {
    data: invoiceList,
    isLoading,
    error,
  } = useGetAllInvoicesListQuery({
    page: currentPage,
    limit: pageSize,
    status: invoiceStatus,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const [updateInvoice] = useUpdateInvoiceMutation();

  const handleExpandRow = (expanded, record) => {
    const keys = [...expandedRowKeys];
    const index = keys.indexOf(record.id);

    if (expanded && index === -1) {
      keys.push(record.id);
    } else if (!expanded && index !== -1) {
      keys.splice(index, 1);
    }

    setExpandedRowKeys(keys);
  };
  const handleUpdate = async (values) => {
    // Remove commas from amount and remaining_amount
    const sanitizedAmount = values.amount
      ? values.amount.replace(/,/g, "")
      : currentRecord.amount.replace(/,/g, "");
    const sanitizedRemainingAmount = values.remaining_amount
      ? values.remaining_amount.replace(/,/g, "")
      : currentRecord.remaining_amount.replace(/,/g, "");

    // Set remaining_amount and payment_amount equal and status to 2 if paymentType is 0
    const isFullPayment = currentRecord.paymentType === 0;
    const paymentAmount = isFullPayment
      ? sanitizedRemainingAmount // Set payment_amount to remaining_amount if paymentType is 0
      : values.payment_amount || 0;
    const remainingAmount = isFullPayment
      ? sanitizedRemainingAmount // Set remaining_amount to the same value as payment_amount if paymentType is 0
      : sanitizedRemainingAmount;
    const status = isFullPayment ? 2 : values.status || currentRecord.status; // Automatically set status to Paid (2) if paymentType is 0

    const payload = {
      status: status,
      amount: sanitizedAmount,
      remaining_amount: remainingAmount,
      transcation_type: values.transcationType || currentRecord.transcationType,
      payment_type: values.paymentType || currentRecord.paymentType,
      payment_amount: paymentAmount,
    };

    if (payload.payment_amount === 0) {
      message.warning(`Payment amount cannot be 0.`);
      return;
    }

    if (payload.payment_type === 2) {
      if (payload.remaining_amount !== payload.payment_amount) {
        message.warning(
          `Must choose status Paid and Fully Payment. Remaining amount ${payload.remaining_amount} and payment amount ${payload.payment_amount}`
        );
        return;
      } else if (payload.status === 1 || payload.status === 3) {
        message.warning(`Must choose status Paid.`);
        return;
      }
    } else if (payload.remaining_amount < payload.payment_amount) {
      message.warning(
        `Payment amount must be less than or equal to remaining amount.`
      );
      return;
    } else if (
      payload.remaining_amount === payload.payment_amount &&
      payload.status !== 2
    ) {
      message.warning(`Status must be Paid.`);
      return;
    }

    try {
      await updateInvoice({
        params: payload,
        id: currentRecord.id,
      });
      message.success("Updated successfully.");
      navigate(`/${id}/invoice/list`);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Update failed:", error);
      message.error("Unable to update invoice.");
    }
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    setIsEditModalOpen(true);
  };

  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const resetFilters = () => {
    setSearchQuery("");
    setStatusFilter(""); // Reset status filter to empty string ("All")
  };

  console.log("invoice list", invoiceList);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1, // Adjust index based on current page
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_code",
      key: "invoice_code",
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
    },
    {
      title: "Room / Shop Name",
      dataIndex: "outlet_name",
      key: "outlet_name",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color;
        let text;
        switch (status) {
          case 2:
            color = "rgba(40, 199, 111, 1)";
            text = "Paid";
            break;
          case 1:
            color = "#FF9F43";
            text = "Unpaid";
            break;
          case 3:
            color = "red";
            text = "Due";
            break;
          default:
            color = "red";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },

    {
      title: "Transcation Type",
      dataIndex: "transcationType",
      key: "transcationType",
      render: (transcationType) => {
        let transactionLabel;
        switch (transcationType) {
          case 1:
            transactionLabel = "KBZ Pay";
            break;
          case 2:
            transactionLabel = "Cash";
            break;
          case 3:
            transactionLabel = "AYA Pay";
            break;
          default:
            transactionLabel = "-";
        }
        return <p>{transactionLabel}</p>;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType) => {
        let paymentLabel;
        let color;
        switch (paymentType) {
          case 1:
            paymentLabel = "Partially";
            color = "orange";
            break;
          case 0:
            paymentLabel = "Fully";
            color = "green";
            break;
          default:
            paymentLabel = "-";
            color = "gray";
        }
        return <Tag color={color}>{paymentLabel}</Tag>;
      },
    },
    {
      title: "Amount (MMK)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining_amount",
      key: "remaining_amount",
    },
    {
      title: "",
      key: "operation",
      align: "center",

      render: (text, record) => {
        const isPaid = record.status === 2; // Check if status is Paid
        return (
          <Space size="middle">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <>
                        <EyeOutlined /> &nbsp;View
                      </>
                    ),
                    onClick: () =>
                      navigate(
                        `/${id}/invoice/list/invoice-details/${record.id}`
                      ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}>
                        <img
                          style={{
                            width: 18,
                            height: 18,
                            padding: 0,
                            margin: 0,
                            filter: isPaid
                              ? "brightness(0) saturate(0) opacity(0.5)"
                              : "none",
                          }}
                          src={PaymentIcon}
                          alt="payment-logo"
                        />{" "}
                        &nbsp; Payment
                      </div>
                    ),
                    onClick: () => !isPaid && showEditModal(record),
                    disabled: isPaid,
                  },
                  {
                    key: "3",
                    label: (
                      <>
                        <DeleteOutlined /> &nbsp;Delete
                      </>
                    ),
                    onClick: () => !isPaid && showDeleteModal(),
                    disabled: isPaid,
                  },
                ],
              }}
              placement="bottomRight">
              <Button style={{ border: "1px solid darkblue" }}>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const expandedColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Payment Amount (MMK)",
      dataIndex: "payment_amount",
      key: "payment_amount",
    },
  ];

  useEffect(() => {
    if (invoiceList?.data) {
      const filteredData = invoiceList.data
        .filter((invoice) => {
          const matchesSearch = invoice.invoice_code
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          const matchesInvoiceType = invoiceTypeFilter
            ? invoice.invoice_type === invoiceTypeFilter
            : true;
          const matchesTransactionType = transactionTypeFilter
            ? invoice.transaction_type === transactionTypeFilter
            : true;
          const matchesPaymentType = paymentTypeFilter
            ? invoice.payment_type === paymentTypeFilter
            : true;

          return (
            matchesSearch &&
            matchesInvoiceType &&
            matchesTransactionType &&
            matchesPaymentType
          );
        })
        .map((invoice, index) => ({
          key: invoice?._id,
          no: index + 1,
          date: moment(invoice?.createdAt).format("DD/MM/YYYY"),
          invoice_code: invoice?.invoice_code,
          invoiceType: invoice?.invoice_type,
          tenant_name: invoice?.tenant?.tenant_name,
          outlet_name: invoice?.shop,
          status: invoice?.status,
          transactionType: invoice?.transaction_type,
          paymentType: invoice?.payment_type,
          amount: formatPrice(invoice?.grand_total),
          remaining_amount: formatPrice(invoice?.remaining_amount),
        }));
      setFilteredInvoices(filteredData);
    }
  }, [
    invoiceList,
    searchTerm,
    invoiceStatus,
    invoiceTypeFilter,
    transactionTypeFilter,
    paymentTypeFilter,
  ]);

  const handleSearch = (value) => setSearchTerm(value);

  const handleResetFilters = () => {
    form.resetFields();
    setSearchTerm("");
    setInvoiceStatus(null);
    setInvoiceTypeFilter(null);
    setTransactionTypeFilter(null);
    setPaymentTypeFilter(null);
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <>
      <PageHeader
        title="Invoice List"
        totalName="Invoice"
        totalItems={invoiceList?.data?.invoices}
      />
      <div style={{ marginTop: "16px" }}>
        <SearchFilter
          onSearch={handleSearch}
          searchPlaceHolder="Search by Invoice No., Tenant Name, Outlet Name"
        />
      </div>
      <Form form={form}>
        <FilterCard onFilter={form.submit} onClear={handleResetFilters}>
          <RangePicker style={{ height: 38, width: 233 }} />
          <DatePicker
            placeholder="Year"
            picker="year"
            style={{
              width: 140,
              height: 38,
              borderRadius: "25px",
            }}></DatePicker>
          <DatePicker
            placeholder="Month"
            picker="month"
            style={{
              width: 140,
              height: 38,
              borderRadius: "25px",
            }}></DatePicker>
          <Form.Item name="status">
            <Select
              placeholder="Invoice Status"
              // value={statusFilter}
              style={{
                width: 140,
                height: 38,
              }}
              onChange={setInvoiceStatus} // Update status filter state
            >
              <Option value="1">Unpaid</Option>
              <Option value="2">Paid</Option>
              <Option value="3">Due</Option>
            </Select>
          </Form.Item>
          <Form.Item name="invoice_type">
            <Select
              placeholder="Invoice Type"
              onChange={setInvoiceTypeFilter}
              style={{
                width: 140,
                height: 38,
              }}>
              <Option value="">Monthly</Option>
              <Option value="Active">Yearly</Option>
              <Option value="Suspend">Maintenance</Option>
            </Select>
          </Form.Item>
          <Form.Item name="transcation_type">
            <Select
              placeholder="Transcation Type"
              onChange={setTransactionTypeFilter}
              style={{
                width: 140,
                height: 38,
              }}>
              <Option value="">KBZ QR</Option>
              <Option value="Active">Cash</Option>
            </Select>
          </Form.Item>
          <Form.Item name="payment_type">
            <Select
              placeholder="Payment Type"
              onChange={setPaymentTypeFilter}
              style={{
                width: 140,
                height: 38,
              }}>
              <Option value="">Fully</Option>
              <Option value="Active">Partially</Option>
            </Select>
          </Form.Item>
        </FilterCard>
      </Form>

      <InvoiceCard />

      <Table
        columns={columns}
        dataSource={filteredInvoices}
        loading={isLoading}
        pagination={false}
        scroll={{
          x: 1300,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={expandedColumns}
              dataSource={record.payment_list.map((payment, index) => ({
                key: index,
                payment_amount: payment.payment_amount,
                date: moment(payment.date).format("DD/MM/YYYY"),
              }))}
              pagination={false}
              rowKey={(record) => record.key}
            />
          ),
          rowExpandable: (record) =>
            record.paymentType === 1 &&
            record.payment_list &&
            record.payment_list.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpandRow,
        }}
        rowKey={(record) => record.id}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        onChange={handlePaginationChange}
        total={invoiceList?.paginator?.totalItems}
      />
      <Modal
        className="bu-add-modal"
        open={isEditModalOpen}
        onCancel={handleEditCancel}
        footer={null}
        width={880}>
        <Card
          title={
            currentRecord?.paymentType === 1
              ? "Partially Payment"
              : "Fully Payment"
          }>
          <Form layout="vertical" form={form} onFinish={handleUpdate}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}>
              <Form.Item style={{ width: 400 }} label="Amount" name="amount">
                <Input
                  defaultValue={currentRecord?.amount}
                  readOnly
                  style={{
                    height: 44,
                  }}></Input>
              </Form.Item>
              <Form.Item label="Payment Type" name="paymentType">
                <Select
                  defaultValue={currentRecord?.paymentType}
                  style={{
                    width: 400,
                    height: 44,
                  }}
                  options={[
                    {
                      value: 1,
                      label: "Partially",
                    },
                    {
                      value: 0,
                      label: "Fully",
                    },
                  ]}></Select>
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="text"
                className="btn"
                key="back"
                onClick={handleEditCancel}>
                Cancel
              </Button>
              <Button
                className="btn filter"
                key="submit"
                type="primary"
                onClick={() => form.submit()}>
                PAY
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default InvoiceList;
