import { InboxOutlined } from "@ant-design/icons";
import { Card, Input, Form, Upload, Button, message } from "antd";
import React, { useState } from "react";

const { Dragger } = Upload;

const ViewContractInfoCard = ({ form, setContractInformation }) => {
  const [contractFilePath, setContractFilePath] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  // Simulate backend upload
  const uploadFileToServer = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      // Simulate API call (Replace with actual backend API logic)
      const mockUploadPath = `/contracts/${file.name}`;
      console.log("File Uploaded Successfully to Path:", mockUploadPath);

      // Return the uploaded file path
      return mockUploadPath;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw new Error("File upload failed.");
    }
  };

  const handleFileChange = async ({ file, fileList }) => {
    console.log("File List:", fileList);
  
    // Check if the file list is empty
    if (fileList.length === 0) {
      console.log("No file selected or file removed.");
      setContractFilePath(null);
      setContractInformation({ ...form.getFieldsValue(), contract_file: "" });
      return;
    }
  
    // Access originFileObj from the first file in the fileList
    const selectedFile = fileList[0]?.originFileObj;
  
    if (selectedFile) {
      console.log("Selected File:", selectedFile);
      console.log("File Name:", selectedFile.name);
      console.log("File Size:", selectedFile.size);
  
      // Check if the file is empty
      if (selectedFile.size === 0) {
        console.error("The file is empty. Please select a valid file.");
        message.error("The file is empty. Please select a valid file.");
        return;
      }
  
      try {
        // Simulate file upload path
        const filePath = `/contracts/${selectedFile.name}`;
        console.log("File Path for Upload:", filePath);
  
        setContractFilePath(filePath);
        setContractInformation({
          ...form.getFieldsValue(),
          contract_file: filePath,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("Selected file is undefined.");
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    setContractInformation({
      ...allValues,
      contract_file: contractFilePath || "",
    });
  };

  return (
    <Card title="Contract Information" style={{ marginTop: "16px" }}>
      <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
        <Form.Item
          name="contract_name"
          label="Contract Name"
          rules={[{ required: true, message: "Please enter a contract name" }]}>
          <Input style={{ height: 44 }} placeholder="Enter contract name" />
        </Form.Item>
        <Form.Item name="contract_file" label="Contract File">
          <Dragger
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            multiple={false}
            maxCount={1}
            accept=".pdf,.jpg,.jpeg,.png">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Supports single upload. Accepted formats: .pdf, .jpg, .jpeg, .png</p>
          </Dragger>
        </Form.Item>
        {uploadedFile && (
          <Form.Item>
            <Button
              type="primary"
              onClick={() => message.success("Contract Information saved successfully!")}
              style={{ width: "100%", height: 44 }}>
              Save Contract
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default ViewContractInfoCard;
