import { Breadcrumb, Button, Card, Form, Input, Select, message } from "antd";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../tenant/tenantApi";
import { useAddNewBranchMutation } from "../../features/property/propertyApi"; // Import the mutation hook

const { Option } = Select;

const AddNewBranch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState(null);
  const [addNewBranch] = useAddNewBranchMutation(); // Initialize the mutation hook

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  const handleSubmit = async (values) => {
    try {
      await addNewBranch(values).unwrap(); // Call the mutation with form values
      message.success("Branch created successfully!"); // Show success message
      navigate(`/${id}/property/add-project`);
    } catch (error) {
      message.error("Failed to create branch. Please try again."); // Show error message
      console.error("Error creating branch:", error); // Log error for debugging
    }
  };

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/property/add-project`}>Branches</Link>,
          },
          {
            title: "Add New Branch",
          },
        ]}
      />
      <Card title="Create New Branch">
        <Form layout="vertical" onFinish={handleSubmit}>
          <div className="add-branch-form">
            <Form.Item
              label="Branch / Project"
              name="name"
              rules={[{ required: true }]}
              className="add-branch-input">
              <Input placeholder="Branch Name" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Contact Person"
              name="contact_person"
              rules={[{ required: true }]}
              className="add-branch-input">
              <Input placeholder="Name" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              className="add-branch-input"
              rules={[{ type: "email", required: true }]} // Add validation rule
            >
              <Input placeholder="Email" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              className="add-branch-input"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  pattern: /^[0-9]{7,15}$/, // Adjust for your requirements (e.g., length, allowed characters)
                  message:
                    "Phone number must be 7-15 digits long and contain only numbers!",
                },
              ]}>
              <Input
                placeholder="Phone Number"
                style={{ height: 44 }}
                maxLength={15} // Optional: Restrict max input length
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault(); // Prevent non-numeric input
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              label="City"
              name="city"
              className="add-branch-input">
              <Select
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={(value) => setSelectedCity(value)}
                loading={isCityListLoading}>
                {cityList?.data?.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city?.city_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="township"
              label="Township"
              rules={[{ required: true }]}
              className="add-branch-input">
              <Select
                style={{ height: 44 }}
                placeholder="Select a township"
                loading={isTownshipListLoading}>
                {townshipList?.data?.map((township) => (
                  <Option key={township._id} value={township._id}>
                    {township?.township_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item rules={[{ required: true }]} label="Address" name="address" style={{ marginTop: 16 }}>
            <Input style={{ height: 44 }} />
          </Form.Item>
          <div className="add-branch-btn-gp">
            <Button
              onClick={() => navigate(`/${id}/property/add-project`)}
              type="text"
              className="btn">
              Cancel
            </Button>
            <Button
              type="text"
              htmlType="submit"
              // loading={isAddingTenant}
              // disabled={isAddingTenant}
              className="btn filter">
              Create
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddNewBranch;
