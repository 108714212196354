import { Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  useGetAllExchangeRateQuery,
} from "./invoiceApi";
import dayjs from "dayjs";
import { ReusablePagination } from "../../component";

const ExchangeRateTable = ({
  selectedDateRange,
  selectedMonth,
  selectedYear,
}) => {
  const { data: exchangeRateList, isLoading } = useGetAllExchangeRateQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState();
  
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "USD",
      dataIndex: "usd",
      key: "usd",
    },
    {
      title: "MMK",
      dataIndex: "mmk",
      key: "mmk",
    },
  ];

  const formatDate = (dateString) =>
    dayjs(dateString).format("MMM D, YYYY HH:mm:ss");

  useEffect(() => {
    if (exchangeRateList) {
      const filtered = exchangeRateList.data.filter((exchangeRate) => {
        const date = dayjs(exchangeRate.date);

        const matchesDateRange =
          selectedDateRange && selectedDateRange.length === 2
            ? date.isAfter(dayjs(selectedDateRange[0]).subtract(1, "day")) &&
              date.isBefore(dayjs(selectedDateRange[1]).add(1, "day"))
            : true;

        const matchesMonth = selectedMonth
          ? date.month() === dayjs(selectedMonth).month() &&
            date.year() === dayjs(selectedMonth).year()
          : true;

        const matchesYear = selectedYear
          ? date.year() === dayjs(selectedYear).year()
          : true;

        return matchesDateRange && matchesMonth && matchesYear;
      });

      setFilteredData(filtered);
    }
  }, [selectedDateRange, selectedMonth, selectedYear, exchangeRateList]);

  const dataSource = filteredData?.map((exchangeRate, index) => ({
    key: exchangeRate._id,
    date: formatDate(exchangeRate?.date),
    usd: `$ ${exchangeRate?.usd}`,
    mmk: `${exchangeRate?.mmk} MMK`,
  }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={paginatedData}
        pagination={false}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={exchangeRateList?.data?.length}
        onChange={handlePaginationChange}
      />
    </div>
  );
};

export default ExchangeRateTable;
