import { InboxOutlined } from "@ant-design/icons";
import {
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  ConfigProvider,
  message,
} from "antd";
import React, { useState } from "react";
import { useGetAllContractSettingQuery } from "../../features/contract/contractApi";
import dayjs from "dayjs";

const { Dragger } = Upload;
const { Option } = Select;

const ContractInformationCard = ({ form, setContractInformation, contract_info }) => {
  const { data: contractSettingList, isLoading: isLoadingContractSetting } =
    useGetAllContractSettingQuery();
  const [contractFilePath, setContractFilePath] = useState(null);

  const handleFileChange = async ({ file, fileList }) => {
    console.log("File List:", fileList);

    if (fileList.length === 0) {
      console.log("No file selected or file removed.");
      setContractFilePath(null);
      setContractInformation({
        ...form.getFieldsValue(),
        contract_file: "",
      });
      return;
    }

    const selectedFile = fileList[0]?.originFileObj;

    if (selectedFile) {
      console.log("Selected File:", selectedFile);
      console.log("File Name:", selectedFile.name);
      console.log("File Size:", selectedFile.size);

      if (selectedFile.size === 0) {
        console.error("The file is empty. Please select a valid file.");
        message.error("The file is empty. Please select a valid file.");
        return;
      }

      try {
        // Simulate file upload path
        const filePath = `/contracts/${selectedFile.name}`;
        console.log("File Path for Upload:", filePath);

        setContractFilePath(filePath);
        setContractInformation({
          ...form.getFieldsValue(),
          contract_file: filePath,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    const { start_date, contract_duration } = allValues;

    if (changedValues.contract_duration !== undefined) {
      form.setFieldsValue({ start_date: null, end_date: null });
      return;
    }

    if (start_date && contract_duration) {
      const selectedDuration = contractSettingList?.data?.find(
        (contract) => contract._id === contract_duration
      );

      if (selectedDuration) {
        const duration = parseInt(selectedDuration.contract_duration, 10);
        const durationType = selectedDuration.contract_type;

        let endDate;

        if (durationType === "day") {
          endDate = dayjs(start_date).add(duration - 1, "day");
        } else if (durationType === "month") {
          endDate = dayjs(start_date).add(duration, "month");
        }

        form.setFieldsValue({ end_date: endDate });
      }
    }

    setContractInformation({
      ...allValues,
      contract_file: contractFilePath || "",
    });
  };

  return (
    <div>
      <Card title="Contract Information" style={{ marginTop: "15px" }}>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
            <div className="contract-form">
              <Form.Item
                className="contract-label-group"
                name="contract_name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the contract name!",
                  },
                ]}>
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="contract-label-group"
                name="contract_duration"
                label="Contract Duration"
                rules={[
                  {
                    required: true,
                    message: "Please select the contract duration!",
                  },
                ]}>
                <Select
                  style={{ height: 44 }}
                  placeholder="Select contract type"
                  loading={isLoadingContractSetting}>
                  {contractSettingList?.data?.map((contract) => (
                    <Option key={contract._id} value={contract._id}>
                      {contract.contract_duration}{" "}
                      {contract.contract_type === "day"
                        ? "Day(s)"
                        : contract.contract_type === "month"
                        ? "Month(s)"
                        : "Year(s)"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  { required: true, message: "Please select the start date!" },
                ]}>
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Start Date"
                />
              </Form.Item>

              <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  { required: true, message: "Please select the end date!" },
                ]}>
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="End Date"
                  disabled
                />
              </Form.Item>
            </div>

            <Form.Item
              name="contract_file"
              label="Contract"
              rules={[
                { required: true, message: "Please upload the contract file!" },
              ]}>
              <Dragger
                onChange={handleFileChange}
                beforeUpload={() => false}
                multiple={false}
                maxCount={1}
                accept=".pdf, .jpg, .jpeg, .png"
                style={{ width: 1060, padding: "20px" }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Supports single upload.</p>
              </Dragger>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </Card>
    </div>
  );
};

export default ContractInformationCard;
