import { Card, Modal, Button } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AddContractModal = ({ visible, onClose }) => {
  const [selectedContractType, setSelectedContractType] = useState("new"); // Default selection
  const navigate = useNavigate(); // Initialize navigate function
  const { id } = useParams();

  const handleButtonClick = (type) => {
    setSelectedContractType(type); // Update the selected contract type
  };

  const handleContinue = () => {
    // Redirect based on the selected contract type
    const path =
      selectedContractType === "new"
        ? `/${id}/contract/list/new-contract`
        : `/${id}/contract/list/existing-contract`;

    navigate(path); // Navigate to the desired path
    onClose(); // Close the modal
  };

  return (
    <Modal
      className="bu-add-modal"
      width={880}
      open={visible}
      footer={null}
      onCancel={onClose}
      closeIcon={false}>
      <Card title="Choose Contract Type">
        <div className="choose-contract-btn-gp">
          <Button
            className={`choose-contract-btn ${
              selectedContractType === "new" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("new")}>
            New Contract
          </Button>
          <Button
            className={`choose-contract-btn ${
              selectedContractType === "existing" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("existing")}>
            Existing Contract
          </Button>
        </div>
        <div className="add-branch-btn-gp">
          <Button className="btn" type="text" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn filter" onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default AddContractModal;
