import { Card, ConfigProvider } from "antd";
import React from "react";
import { useGetReportQuery } from "../../features/invoice/invoiceApi";

const InvoiceCard = () => {
  const { data: getReport } = useGetReportQuery();

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            padding: 15,
            paddingLG: 0,
          },
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}>
          {[
            {
              label: "Outstanding",
              count: getReport?.data?.outstanding?.count,
              total: getReport?.data?.outstanding?.totalAmount,
              bgColor: "rgba(255, 179, 38, 0.2)",
              textColor: "rgba(255, 179, 38, 1)",
            },
            {
              label: "Paid",
              count: getReport?.data?.paid?.count,
              total: getReport?.data?.paid?.totalAmount,
              bgColor: "rgba(53, 222, 120, 0.2)",
              textColor: "rgba(27, 206, 99, 1)",
            },
            {
              label: "Unpaid",
              count: getReport?.data?.unpaid?.count,
              total: getReport?.data?.unpaid?.totalAmount,
              bgColor: "rgba(0, 87, 255, 0.2)",
              textColor: "rgba(0, 87, 255, 1)",
            },
            {
              label: "Due",
              count: getReport?.data?.due?.count,
              total: getReport?.data?.due?.totalAmount,
              bgColor: "rgba(240, 30, 44, 0.2)",
              textColor: "rgba(240, 30, 44, 1)",
            },
          ].map((item, index) => (
            <Card
              key={index}
              style={{
                width: 275,
                padding: "15px",
                height: 100, // Set consistent card height
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Ensure content spacing
              }}>
              <div className="count-detail" style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="count-box"
                  style={{
                    width: 50,
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    background: item.bgColor,
                    color: item.textColor,
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}>
                  <p>{item.count || 0}</p>
                </div>
                <div className="count-info" style={{ marginLeft: "15px" }}>
                  <p
                    style={{
                      color: item.textColor,
                      fontSize: "14px",
                      marginBottom: "5px",
                    }}>
                    {item.label}
                  </p>
                  <h3 style={{ fontSize: "16px", margin: 0 }}>
                    {formatPrice(item.total || 0)} MMK
                  </h3>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default InvoiceCard;
