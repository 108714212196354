import React from "react";
import { PageHeader } from "../../component";
import { useParams } from "react-router-dom";
import { InvoiceSettingTable } from "../../features";

const InvoiceSetting = () => {
  const { id } = useParams();

  return (
    <div>
      <PageHeader
        title="Invoice Setting"
        addBtn="Add Invoice Setting"
        linkTo={`/${id}/setting/invoice/add-invoice-setting`}
      />
      <InvoiceSettingTable/>
    </div>
  );
};

export default InvoiceSetting;
