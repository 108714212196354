import { baseApi } from "../../app/baseApi";

export const tenantCategoryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenantCategory: builder.query({
      query: () => `/Tenant/category/list`,
      providesTags: ["TenantCategoryList"],
    }),

    getTenantCategoryById: builder.query({
      query: (categoryId) => `/Tenant/tenant-category/edit/${categoryId}`,
      providesTags: ["TenantCategoryList"],
    }),

    addTenantCategory: builder.mutation({
      query: ({ tenant_category_name }) => ({
        url: `/Tenant/category/create`,
        method: "POST",
        body: {
          tenant_category_name,
        },
      }),
      invalidatesTags: ["TenantCategoryList"],
    }),

    updateTenantCategory: builder.mutation({
      query: ({ categoryId, updatedData }) => ({
        url: `/Tenant/category/update/${categoryId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit contract failed:", error);
      },
      invalidatesTags: ["TenantCategoryList"],
    }),

    deleteTenantCategory: builder.mutation({
      query: ({ categoryId }) => ({
        url: `/Tenant/category/delete/${categoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TenantCategoryList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTenantCategoryQuery,
  useAddTenantCategoryMutation,
  useUpdateTenantCategoryMutation,
  useGetTenantCategoryByIdQuery,
  useDeleteTenantCategoryMutation
} = tenantCategoryApi;
