import {
  Form,
  Input,
  Button,
  message,
  ConfigProvider,
  Modal,
  Divider,
  Card,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useAddTenantCategoryMutation } from "./tenantCategoryApi";

const AddTenantCategory = ({
  isAddModalOpen, // This controls modal visibility
  handleOk, // Function to close modal after successful submission
  handleCancel, // Function to cancel and close modal
}) => {
  const token = useSelector((state) => state.authSlice.token2);

  // API mutation for adding a new tenant category
  const [addNewTenantCategory, { isLoading, error }] =
    useAddTenantCategoryMutation(token);

  const [form] = Form.useForm(); // Form instance

  // Handle form submission
  const handleSubmit = async (values) => {
    const payload = {
      tenant_category_name: values.tenant_category_name,
    };
    try {
      await addNewTenantCategory(payload).unwrap(); // API call
      form.resetFields(); // Reset form fields after successful submission
      message.success("Tenant Category added successfully.");
      handleOk(); // Close modal after successful submission
    } catch (error) {
      const errorMessage =
        error?.data?.errors?.[0] || "Failed to add tenant category";
      message.error(errorMessage);
    }
  };

  // Handle modal cancel action and reset form
  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields
    handleCancel(); // Close modal
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#f8f7f7",
        },
      }}>
      <Modal
        width={880}
        closeIcon={false}
        className="bu-add-modal"
        open={isAddModalOpen} // Control modal visibility
        onCancel={handleFormCancel} // Use cancel function to close modal
        footer={null} // Remove default footer with OK and Cancel buttons
      >
        <Card title="Add New Tenant Category">
          <Form
            form={form} // Bind the form instance to the Form component
            layout="vertical"
            onFinish={handleSubmit} // Trigger form submission on successful validation
          >
            <Form.Item label="New Tenant Category" name="tenant_category_name">
              <Input placeholder="Tenant Category" style={{ height: 44 }} />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="text" className="btn" onClick={handleFormCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit" // Use submit to trigger form submission
                className="btn filter"
                loading={isLoading} // Show loading state during submission
              >
                Create
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </ConfigProvider>
  );
};

export default AddTenantCategory;
