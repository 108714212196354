import { Form, Modal, Select } from "antd";
import React, { useState } from "react";
import "./styles.css";
import ZoneList from "../../features/property/ZoneList";
import { AddNewZone } from "../../features";
import { FilterCard, PageHeader, SearchFilter } from "../../component";
import {
  useGetAllBranchesQuery,
  useGetAllBuildingQuery,
  useGetAllFloorListQuery,
  useGetAllZoneQuery,
} from "../../features/property/propertyApi";
import { useGetAllRoomSettingQuery } from "../../features/setting/roomSettingApi";

const { Option } = Select;
const AddZone = () => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);

  const { data: branchListData, loading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, loading: isLoadingBuildings } =
    useGetAllBuildingQuery();
  const { data: floorListData, loading: isLoadingFloors } =
    useGetAllFloorListQuery();
  const { data: zoneListData, loading: isLoadingZones } = useGetAllZoneQuery();
  const { data: roomTypeData, loading: isLoadingRoomType } =
  useGetAllRoomSettingQuery();

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value); // Update selected branch
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
  };

  const handleZoneChange = (value) => {
    setSelectedZone(value);
  };

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    setSelectedBranch(formValues.branch);
    setSelectedBuilding(formValues.building);
    setSelectedFloor(formValues.floor);
    setSelectedZone(formValues.zone);
  };

  const handleClearFilters = () => {
    form.resetFields();
    setSearchTerm("");
    setSelectedBranch(null);
    setSelectedBuilding(null);
    setSelectedFloor(null);
    setSelectedZone(null);
  };

  return (
    <>
      <PageHeader title="Zone" addBtn="Add New Zone" onAdd={showModal} />
      <SearchFilter searchPlaceHolder="Search..." onSearch={handleSearch} />
      <Form form={form}>
        <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
          <Form.Item name="branch">
            <Select
              placeholder="Branch"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleBranchChange}>
              {isLoadingBranch ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                branchListData?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
          <Form.Item name="building">
            <Select
              placeholder="Building"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleBuildingChange}>
              {isLoadingBuildings ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                buildingListData?.data?.buildings?.map((building) => (
                  <Option key={building?._id} value={building?._id}>
                    {building?.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
          <Form.Item name="floor">
            <Select
              placeholder="Floor"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleFloorChange}>
              {isLoadingFloors ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                floorListData?.data?.floors?.map((floor) => (
                  <Option key={floor?._id} value={floor?._id}>
                    {floor?.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
          <Form.Item name="zone">
            <Select
              placeholder="Zone"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleZoneChange}>
              {isLoadingZones ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                zoneListData?.data?.zones?.map((zone) => (
                  <Option key={zone?._id} value={zone?._id}>
                    {zone?.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
        </FilterCard>
      </Form>
      <Form>
        <Form.Item>
          <ZoneList
            selectedBranch={selectedBranch}
            searchTerm={searchTerm}
            selectedFloor={selectedFloor}
            selectedBuildingFromFilter={selectedBuilding}
            selectedZone={selectedZone}
          />
        </Form.Item>
      </Form>
      <Modal
        width={880}
        className="bu-add-modal"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <AddNewZone handleOk={handleOk} handleCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default AddZone;
