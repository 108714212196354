import React, { useState } from 'react'
import { PageHeader } from '../../component'
import { AddMaintenanceNoti, MaintenanceTable } from '../../features';

const MaintenanceNoti = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handleAddNoti = () => {
    setIsAddModalVisible(true); // Open the modal
  };

  const handleCancelNoti = () => {
    setIsAddModalVisible(false); // Close the modal
  };
  return (
    <div>
      <PageHeader title="Maintenance Notification" addBtn="Add Notification" onAdd={handleAddNoti} />
      <AddMaintenanceNoti visible={isAddModalVisible} onClose={handleCancelNoti} />
      <MaintenanceTable />
    </div>
  )
}

export default MaintenanceNoti