import React, { useState } from "react";
import { FilterCard, ReportingHeader } from "../../component";
import { Button, Select } from "antd";
import { InstallmentInvoice, PenaltyInvoice, ReportingMaintenanceInvoice, ReportingMonthlyInvoice } from "../../features";

const InvoiceReport = () => {
  const [activeButton, setActiveButton] = useState("monthly");

  return (
    <div>
      <ReportingHeader title="Invoice Report" />
      <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
        <Button
          className={`btn ${activeButton === "monthly" ? "filter" : ""}`}
          onClick={() => setActiveButton("monthly")}
        >
          Monthly
        </Button>
        <Button
          className={`btn ${activeButton === "installment" ? "filter" : ""}`}
          onClick={() => setActiveButton("installment")}
        >
          Installment
        </Button>
        <Button
          className={`btn ${activeButton === "maintenance" ? "filter" : ""}`}
          onClick={() => setActiveButton("maintenance")}
        >
          Maintenance
        </Button>
        <Button
          className={`btn ${activeButton === "penalty" ? "filter" : ""}`}
          onClick={() => setActiveButton("penalty")}
        >
          Penalty
        </Button>
      </div>

      {/* Conditionally render FilterCard, excluding it for 'penalty' */}
      {activeButton !== "penalty" && (
        <FilterCard>
          <Select
            style={{
              width: 150,
              height: 38,
            }}
            placeholder="All Status"
          />
          <Select
            style={{
              width: 150,
              height: 38,
            }}
            placeholder="Transcation Type"
          />
          
          {/* Conditionally render Payment Type for buttons other than 'maintenance' */}
          {activeButton !== "maintenance" && (
            <Select
              style={{
                width: 150,
                height: 38,
              }}
              placeholder="Payment Type"
            />
          )}
        </FilterCard>
      )}
      {activeButton === "monthly" && <ReportingMonthlyInvoice />}
      {activeButton === "installment" && <InstallmentInvoice />}
      {activeButton === "maintenance" && <ReportingMaintenanceInvoice />}
      {activeButton === "penalty" && <PenaltyInvoice />}
    </div>
  );
};

export default InvoiceReport;
