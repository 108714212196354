import { DownOutlined, EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Space, Table } from 'antd'
import React from 'react'

const ReminderList = () => {
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Tenant Type",
      dataIndex: "tenantType",
      key: "tenantType",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Invoice Payment",
      dataIndex: "invoicePayment",
      key: "invoicePayment",
    },
    {
      title: "Advanced Payment",
      dataIndex: "advancedPayment",
      key: "advancedPayment",
    },
    {
      title: (
        <div className="setting-icon">
          <SettingOutlined />
        </div>
      ),
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EyeOutlined /> View
                    </span>
                  ),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: 1,
      contractName: 'Condo Sale Contract',
      tenantName: 'Tenant 1',
      startDate: 'May 16, 2024',
      endDate: '-',
      tenantType: 'Owner',
      paymentType: 'Installment',
      invoicePayment: 'Half-Yearly',
      advancedPayment: '-',
    },
    {
      key: 2,
      contractName: 'Condo Sale Contract',
      tenantName: 'Tenant 2',
      startDate: 'April 4, 2024',
      endDate: 'April 4, 2025',
      tenantType: 'Lease',
      paymentType: 'Fully',
      invoicePayment: 'Quarterly',
      advancedPayment: '50,000',
    },
  ];
  return (
      <div className="dashboard-card">
      <Card style={{marginBottom:'20px'}} title="Reminder List">
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </Card>
    </div>
  )
}

export default ReminderList