import React, { useEffect } from 'react';
import { Button, Card, Form, Input, Modal } from "antd";

const { TextArea } = Input;
const EditMaintenanceNoti = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        event: record.event,
        sampleMessage: record.sampleMessage,
      });
    }
  }, [record, form]);

  return (
    <div>
      <Modal
        width={880}
        open={visible}
        onCancel={onClose}
        className="bu-add-modal"
        footer={null}>
        <Card title="Edit Maintenance Notification">
          <Form form={form} layout="vertical">
            <Form.Item label="Event" name="event">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Template Sample Message" name="sampleMessage">
              <TextArea rows={4} />
            </Form.Item>
            <div className="add-property-div">
              <Button onClick={onClose} type="cancel" className="btn">
                Cancel
              </Button>
              <Button className="filter btn">UPDATE</Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
}

export default EditMaintenanceNoti;
