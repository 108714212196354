import {
  Form,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Button,
  ConfigProvider,
  Card,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  useGetAllBuildingQuery,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
  useGetAllBranchesQuery,
} from "../../features/property/propertyApi";
import { useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { DeleteModal, ReusablePagination } from "../../component";

const { Option } = Select;
const BuildingList = ({ searchTerm, selectedBranch, selectedBuilding }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: buildingListData,
    isLoading,
    error,
  } = useGetAllBuildingQuery(token);

  const [updateBuilding, { isLoading: isUpdateLoading, error: isUpdateError }] =
    useUpdateBuildingMutation();
  const [deleteBuilding, { isLoading: isDeleteLoading, error: isDeleteError }] =
    useDeleteBuildingMutation();

  const {
    data: BranchListData,
    isLoading: branchLoading,
  } = useGetAllBranchesQuery();

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Project / Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const [filteredBuildings, setFilteredBuildings] = useState([]);

  useEffect(() => {
    if (buildingListData) {
      const filteredData = buildingListData?.data?.buildings.filter((building) => {
        const matchesSearch = building.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesBranch = selectedBranch
          ? building.branch?._id === selectedBranch
          : true; // Match branch if selected, otherwise show all
        const matchesBuilding = selectedBuilding ? building?._id === selectedBuilding : true;
        return matchesSearch && matchesBranch && matchesBuilding;
      });
      setFilteredBuildings(filteredData);
    }
  }, [searchTerm, selectedBranch, selectedBuilding, buildingListData]);
  

  const tableData = filteredBuildings.map((building, index) => ({
    key: building._id,
    no: index + 1,
    branch: building?.branch?.name,
    branchId: building?.branch?._id,
    building: building?.name,
  }));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [form] = Form.useForm();

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteBuilding({ buildingId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Building is deleted successfully.");
      } catch (isDeleteError) {
        const errorMessage =
          isDeleteError?.data?.errors?.[0] || "Failed to delete building";
        message.error(errorMessage);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({ branch: record.branchId, building: record.building }); // Pre-fill the form
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        buildingId: currentRecord.key,
        updatedData: {
          branch: values.branch,
          name: values.building, // Get value from form
        },
      };
      await updateBuilding(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Building is edited successfully.");
    } catch (isUpdateError) {
      const errorMessage =
        isUpdateError?.data?.errors?.[0] || "Failed to edit building";
      message.error(errorMessage);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const totalItems = buildingListData?.data?.totalBuildings;

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <Table
        style={{ marginTop: "16px" }}
        loading={isLoading}
        columns={columns}
        dataSource={paginatedData}
        pagination={false}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePaginationChange}
      />

      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Modal
        width={880}
        className="bu-add-modal"
        onCancel={handleEditCancel}
        open={isEditModalOpen}
        footer={null}
        closeIcon={false}>
        <Card title="Edit Building">
          <Form form={form} layout="vertical">
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#f8f7f7",
                },
              }}>
              <Form.Item label="Project / Branch" name="branch">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select Project / Branch"
                  loading={branchLoading}>
                  {BranchListData?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Building" name="building">
                <Input style={{ height: 44 }} />
              </Form.Item>
            </ConfigProvider>
          </Form>
          <div className="add-branch-btn-gp">
            <Button onClick={handleEditCancel} type="text" className="btn">
              Cancel
            </Button>
            <Button
              onClick={handleEditOk}
              type="text"
              htmlType="submit"
              className="btn filter">
              Update
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default BuildingList;
