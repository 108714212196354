import React, { useState } from "react";
import { Form, Input, Modal, DatePicker, Select } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DeleteModal,
  FilterCard,
  PageHeader,
  SearchFilter,
} from "../../component";
import { MaintenanceListTable } from "../../features";

const { Option } = Select;
const { RangePicker } = DatePicker;

const MaintenanceList = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isViewDetailModalOpen, setIsViewDetailModalOpen] = useState(false);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showChangeStatusModal = (record) => {
    setCurrentRecord(record);
    setIsChangeStatusModalOpen(true);
  };

  const handleChangeOk = () => {
    setIsChangeStatusModalOpen(false);
  };

  const handleChangeCancel = () => {
    setIsChangeStatusModalOpen(false);
  };

  const showViewDetailModal = (record) => {
    setCurrentRecord(record);
    setIsViewDetailModalOpen(true);
  };

  const handleViewDetailOk = () => {
    setIsViewDetailModalOpen(false);
  };

  const handleViewDetailCancel = () => {
    setIsViewDetailModalOpen(false);
  };

  return (
    <>
      <PageHeader
        title="Maintenance List"
        totalName="Maintenance"
        totalItems="3"
      />
      <SearchFilter searchPlaceHolder="Search by Tenant Name, Outlet Name" />
      <FilterCard>
        <RangePicker style={{ height: 38, width: 233 }} />
        <Select
          placeholder="Type of Issue"
          // value={statusFilter}
          style={{
            width: 150,
            height: 38,
          }}
          // onChange={(value) => setStatusFilter(value)} // Update status filter state
        >
          <Option value="">Own</Option>
          <Option value="Active">Lease</Option>
        </Select>
        <Select
          placeholder="Status"
          style={{
            width: 150,
            height: 38,
          }}>
          <Option value="">All</Option>
          <Option value="Active">Active</Option>
          <Option value="Suspend">Suspend</Option>
        </Select>
      </FilterCard>

      {/* Table Card */}
      <MaintenanceListTable
        onView={showViewDetailModal}
        onChange={showChangeStatusModal}
        onDelete={showDeleteModal}
      />

      {/* Delete Modal */}
      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
        // confirmLoading={isDeleting}
      />

      {/* Change Status Modal */}
      <Modal
        title="Maintenance Details"
        open={isChangeStatusModalOpen}
        okText="Confirm"
        onOk={handleChangeOk}
        onCancel={handleChangeCancel}>
        <Form layout="vertical">
          <Form.Item label="Status">
            <Select
              defaultValue={currentRecord?.tags}
              style={{ height: 44 }}
              options={[
                { value: "processing", label: "Processing" },
                { value: "finished", label: "Finished" },
                { value: "pending", label: "Pending" },
              ]}></Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* View Detail Modal */}
      <Modal
        title="Maintenance Details"
        open={isViewDetailModalOpen}
        footer={null}
        onCancel={handleViewDetailCancel}
        width={780}>
        <Form layout="vertical">
          <Form.Item label="Date">
            <Input
              defaultValue={currentRecord?.date}
              style={{ height: 44 }}></Input>
          </Form.Item>

          <div className="view-detail-modal">
            <Form.Item label="Tenant Name">
              <Input
                defaultValue={currentRecord?.tenantName}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Outlet Name">
              <Input
                defaultValue={currentRecord?.outletName}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="view-detail-modal">
            <Form.Item label="Type of Issue">
              <Input
                defaultValue={currentRecord?.typeOfIssue}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Status">
              <Input
                defaultValue={currentRecord?.tags}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <Form.Item label="Note">
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MaintenanceList;
