import { baseApi } from "../../app/baseApi";

export const roomSettingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoomSetting: builder.query({
      query: () => `/Setting/room/list`,
      providesTags: ["RoomSettingList"],
    }),

    addNewRoomType: builder.mutation({
      query: ({ room_type }) => ({
        url: `/Setting/room/create`,
        method: "POST",
        body: {
          room_type,
        },
      }),
      invalidatesTags: ["RoomSettingList"],
    }),

    updateRoomType: builder.mutation({
      query: ({ roomTypeId, updatedData }) => ({
        url: `/Setting/room/update/${roomTypeId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit room type failed:", error);
      },
      invalidatesTags: ["RoomSettingList"],
    }),

    deleteRoomType: builder.mutation({
      query: ({ roomTypeId }) => ({
        url: `/Setting/room/delete/${roomTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RoomSettingList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllRoomSettingQuery,
  useAddNewRoomTypeMutation,
  useUpdateRoomTypeMutation,
  useDeleteRoomTypeMutation,
} = roomSettingApi;
