import { SettingOutlined } from "@ant-design/icons";
import { Table, Button, Space, Checkbox } from "antd";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import EditNotiTemplate from "./EditNotiTemplate";

const SettingNotiList = () => {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setEditModalVisible(true);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setSelectedRecord(null);
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      width: 200,
    },
    {
      title: "Option",
      dataIndex: "option",
      key: "option",
      width: 250,
      render: (options) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox readOnly checked={options.includes("SMS")}>
              SMS
            </Checkbox>
            <Checkbox
              readOnly
              checked={options.includes("Mobile App")}
              style={{ marginLeft: 8 }}>
              MOBILE APP
            </Checkbox>
          </div>
        </>
      ),
    },
    {
      title: "Template Sample Message",
      dataIndex: "sampleMessage",
      key: "sampleMessage",
    },
    {
      title: (
        <div className="setting-icon">
          <SettingOutlined />
        </div>
      ),
      key: "operation",
      align: "center",
      width: 150,
      render: (record) => (
        <Space size="middle">
          <Button onClick={() => handleEditClick(record)} type="text">
            <FaEdit
              style={{ color: "#0057FF", width: "18px", height: "18px" }}
            />
          </Button>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      event: "Maintenance Confirm",
      option: ["SMS", "Mobile App"],
      sampleMessage:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      key: "2",
      event: "Maintenance Reject",
      option: ["SMS", "Mobile App"],
      sampleMessage:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      key: "3",
      event: "Welcome Message",
      option: ["Mobile App"],
      sampleMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    },
  ];

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <Table columns={columns} dataSource={paginatedData} pagination={false} />
      <EditNotiTemplate
        record={selectedRecord}
        visible={isEditModalVisible}
        onClose={handleCloseEditModal}
      />
    </div>
  );
};

export default SettingNotiList;
