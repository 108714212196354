import React, { useState } from 'react'
import { PageHeader } from '../../component'
import { AddNotiTemplate, SettingNotiList } from '../../features';

const Notification = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handleAddNoti = () => {
    setIsAddModalVisible(true); // Open the modal
  };

  const handleCancelNoti = () => {
    setIsAddModalVisible(false); // Close the modal
  };
  return (
    <div>
      <PageHeader title="Notification Setting" addBtn="Add Notification" onAdd={handleAddNoti} />
      <AddNotiTemplate visible={isAddModalVisible} onClose={handleCancelNoti} />
      <SettingNotiList />
    </div>
  )
}

export default Notification