import { Table, Dropdown, Space, Button } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllInvoicesListQuery } from "../../features/invoice/invoiceApi";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { DeleteModal, ReusablePagination } from "../../component";

const InvoicePreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const { data, isLoading } = useGetAllInvoicesListQuery({
    page: currentPage,
    limit: pageSize,
    status: 0,
  });

  console.log("data", data);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Collect unique utility titles
  const uniqueUtilityTitles = Array.from(
    new Set(
      (data?.data || []).flatMap((record) =>
        record.utilities?.map((utility) => utility.title) || []
      )
    )
  );

  // Dynamically generate utility columns based on titles
  const utilityColumns = uniqueUtilityTitles.map((title) => ({
    title,
    key: title,
    render: (_, record) => {
      const utilityData = record.utilities?.filter((u) => u.title === title);
      if (utilityData && utilityData.length > 0) {
        return utilityData
          .map((u) => formatPrice(`${u.amount} ${u.rate_type}`))
          .join(", "); // Combine amounts if multiple entries exist
      }
      return "N/A";
    },
  }));

  // Combine static columns with dynamic utility columns
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 25,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoice_type",
      key: "invoice_type",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
      render: (_, record) => record?.tenant?.tenant_name,
    },
    {
      title: "Room/ Shop Name",
      dataIndex: "shop_name",
      key: "shop_name",
      render: (_, record) => record?.shop,
    },
    ...utilityColumns, // Add dynamically generated utility columns
    {
      title: "",
      key: "operation",
      align: "center",
      fixed: "right",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                      <EditOutlined /> Edit
                    </>
                  ),
                  onClick: () =>
                    navigate(`/${id}/invoice/preview/edit/${record._id}`),
                },
                {
                  key: "2",
                  label: (
                    <>
                      <DeleteOutlined /> Delete
                    </>
                  ),
                  onClick: () => showModal(),
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button style={{ border: "1px solid darkblue" }}>
              Action
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <>
      <h3 className="page-title">Preview Invoice</h3>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data?.data || []}
        pagination={false}
        rowKey="_id"
        scroll={{
          x: "max-content",
        }}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        onChange={handlePaginationChange}
        total={data?.paginator?.totalItems}
      />
      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default InvoicePreview;
