import { Button } from "antd";
import React from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa6";

const ReportingHeader = ({ title }) => {
  return (
    <div className="reporting-header">
      <div>
        <h3 className="report-page-title">{title}</h3>
      </div>
      <div className="excel-pdf-download-btn">
        <Button type="text">
          <RiFileExcel2Fill style={{ width: "20px", height: "20px" }} />
        </Button>
        <Button type="text">
          <FaFilePdf style={{ width: "20px", height: "20px" }} />
        </Button>
      </div>
    </div>
  );
};

export default ReportingHeader;
