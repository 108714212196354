import React from 'react'
import { Button, Card, Form, Input, Modal } from "antd";

const { TextArea } = Input;
const AddMaintenanceNoti = ({ visible, onClose }) => {
  return (
    <div>
    <Modal
      width={880}
      open={visible}
      onCancel={onClose}
      className="bu-add-modal"
      footer={null}>
      <Card title="Add Maintenance Notification">
        <Form layout="vertical">
          <Form.Item label="Event" name="event">
            <Input style={{ height: 44 }} />
          </Form.Item>
          <Form.Item label="Template Sample Message" name="sampleMessage">
            <TextArea rows={4} />
          </Form.Item>
          <div className="add-property-div">
            <Button onClick={onClose} type="cancel" className="btn">
              Cancel
            </Button>
            <Button className="filter btn">CREATE</Button>
          </div>
        </Form>
      </Card>
    </Modal>
  </div>
  )
}

export default AddMaintenanceNoti