import { InboxOutlined } from "@ant-design/icons";
import {
  Card,
  Form,
  Input,
  Upload,
  ConfigProvider,
} from "antd";
import React from "react";
import dayjs from "dayjs";

const { Dragger } = Upload;

const ViewLeaseContract = ({ contract_info }) => {
const [form] = Form.useForm();
const initialValues = {
  contract_name: contract_info?.contract_name,
  start_date: contract_info?.start_date
  ? dayjs(contract_info.start_date).format("YYYY-MM-DD")
  : null,
end_date: contract_info?.end_date
  ? dayjs(contract_info.end_date).format("YYYY-MM-DD")
  : null,
  contract_duration: `${contract_info?.contract_duration?.contract_duration} ${contract_info?.contract_duration?.contract_type === "month" ? "Month (s)" : "Day (s)"}`
}
  return (
    <div>
      <Card title="Contract Information" style={{ marginTop: "15px" }}>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Form initialValues={initialValues} form={form} layout="vertical">
            <div className="contract-form">
              <Form.Item
                className="contract-label-group"
                name="contract_name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the contract name!",
                  },
                ]}>
                <Input readOnly style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="contract-label-group"
                name="contract_duration"
                label="Contract Duration"
                rules={[
                  {
                    required: true,
                    message: "Please select the contract duration!",
                  },
                ]}>
                <Input style={{ height: 44 }} readOnly />
              </Form.Item>

              <Form.Item
               className="contract-label-group"
                name="start_date"
                label="Start Date"
                rules={[
                  { required: true, message: "Please select the start date!" },
                ]}>
               <Input style={{ height: 44 }} readOnly />
              </Form.Item>

              <Form.Item
                name="end_date"
                className="contract-label-group"
                label="End Date"
                rules={[
                  { required: true, message: "Please select the end date!" },
                ]}>
               <Input readOnly style={{ height: 44 }} />
              </Form.Item>
            </div>

            <Form.Item
              name="contract_file"
              label="Contract"
              rules={[
                { required: true, message: "Please upload the contract file!" },
              ]}>
              <Dragger
                multiple={false}
                maxCount={1}
                listType="text"
                accept=".pdf, .jpg, .jpeg, .png"
                style={{ width: 1060, padding: "20px" }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Support for a single upload.</p>
              </Dragger>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </Card>
    </div>
  );
};

export default ViewLeaseContract;
