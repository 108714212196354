import React, { useState, useEffect } from "react";
import {
  PageHeader,
  ReusablePagination,
  SearchFilter,
} from "../../component/index";
import { useSelector } from "react-redux";
import { useGetAllBranchesQuery } from "../../features/property/propertyApi";
import { BranchesList } from "../../features";
import { useParams } from "react-router-dom";

const ProjectList = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const { id } = useParams();
  
  const { data: branchListData, isLoading, error } = useGetAllBranchesQuery(token);

  const [filteredBranches, setFilteredBranches] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page on new search
  };

  useEffect(() => {
    if (branchListData) {
      // Filtering logic based on search term
      const filteredData = branchListData.data.filter((branch) =>
        branch.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBranches(filteredData);
    }
  }, [searchTerm, branchListData]);

  // Handle loading state
  if (isLoading) return <div>Loading...</div>; // Show loading state
  if (error) {
    console.error("Error loading branches:", error);
    return <div>Error loading branch data. Please try again later.</div>;
  }

  // Paginate filtered data
  const paginatedData = filteredBranches?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Calculate total items from filtered data
  const totalItems = filteredBranches?.length;

  // Map paginated data to table format
  const tableData = paginatedData?.map((branch, index) => ({
    key: branch?._id,
    no: (currentPage - 1) * pageSize + index + 1, // Adjust to reflect the correct numbering
    branch: branch?.name,
    contact_person: branch?.contact_person,
    email: branch?.email,
    phone_number: branch?.phone_number,
    city: branch?.city?.city_name,
    city_id: branch?.city._id,
    township: branch?.township?.township_name,
    township_id: branch?.township?._id,
    address: branch?.address
  }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <div>
      <PageHeader
        title="Branches"
        totalItems={totalItems}
        totalName="Branch"
        addBtn="Add New Branch"
        linkTo={`/${id}/property/add-new-branch`}
      />
      <SearchFilter onSearch={handleSearch} searchPlaceHolder="Search Branch" />
      <BranchesList loading={isLoading} tableData={tableData} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePaginationChange}
      />
    </div>
  );
};

export default ProjectList;
