import { Card, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";

const { Option } = Select;

const ViewLeasePaymentCard = ({ total, advanced, type }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (total || advanced) {
      form.setFieldsValue({
        totalAmount: total,
        advancedPayment: advanced,
        type: type,
      });
    }
  }, [total, advanced, form]);
  const currency = type || "MMK";
  return (
    <div className="payment-card">
      <Form layout="vertical" form={form}>
        <Card
          style={{ marginTop: "16px" }}
          title="Payment Type"
          extra={
            <Form.Item
              name="type"
              noStyle
              rules={[{ required: true, message: "Please select a type" }]}>
              <Select disabled placeholder="MMK">
                <Option value="MMK">MMK</Option>
                <Option value="USD">USD</Option>
              </Select>
            </Form.Item>
          }>
          <div className="add-branch-form">
            <Form.Item
              className="add-branch-input"
              name="totalAmount"
              rules={[{ required: true }]}
              label={`Total Amount (${currency.toUpperCase()})`}>
              <Input
              readOnly
                placeholder={`Enter total amount (${currency.toUpperCase()})`}
                style={{ height: 44 }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              name="advancedPayment"
              label={`Advanced Payment (${currency.toUpperCase()})`}>
              <Input
              readOnly
                placeholder={`Enter advanced payment (${currency.toUpperCase()})`}
                style={{ height: 44 }}
              />
            </Form.Item>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default ViewLeasePaymentCard;
