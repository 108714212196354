import { Breadcrumb } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { AddContractPolicyCard } from '../../features';

const ContractPolicy = () => {
  const { id } = useParams();
  return (
    <div>
       <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/contract/list`}>Contract List</Link>,
          },
          { title: "Contract Policy" },
        ]}
      />
      <AddContractPolicyCard />
    </div>
  )
}

export default ContractPolicy