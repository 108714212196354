import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, ConfigProvider, Form, Input, Select, Space } from "antd";
import React, { useState } from "react";

const { Option } = Select;

const PmfCard = ({ propertyList = [], setPmf }) => {
  const [form1] = Form.useForm();
  const [pmfDataList, setPmfDataList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const handlePropertyChange = (propertyId, form) => {
    const selectedProperty = propertyList.find(
      (property) => property.shop === propertyId
    );

    if (selectedProperty) {
      form.setFieldsValue({
        totalArea: selectedProperty?.area || "",
        selectProperty: selectedProperty?.shop, // Use the property ID
      });
    } else {
      form.resetFields();
    }
  };

  const handleSave = (formInstance) => {
    formInstance
      .validateFields()
      .then((values) => {
        const pmfData = {
          ...values,
          shop: values.selectProperty,
          rateType: values.rateType || "usd",
        };
  
        let updatedPmfDataList = [...pmfDataList];
  
        if (editingIndex !== null) {
          // Replace the item at the editing index
          updatedPmfDataList[editingIndex] = pmfData;
        } else {
          // Add new data if not editing
          updatedPmfDataList.push(pmfData);
        }
  
        setPmfDataList(updatedPmfDataList);
        setPmf(updatedPmfDataList); // Update parent state
        setEditingIndex(null); // Reset editing index
        formInstance.resetFields();
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };  

  const handleDeleteProperty = (index) => {
    const updatedPmfDataList = pmfDataList.filter((_, i) => i !== index);
    setPmfDataList(updatedPmfDataList);
    setPmf(updatedPmfDataList); // Update parent state
  };
  

  const handleEditProperty = (property, index) => {
    form1.setFieldsValue(property); // Populate the form with the selected data
    setEditingIndex(index); // Set the index of the item being edited
    handleDeleteProperty(pmfDataList.indexOf(property));
  };
  
  console.log("pmfDataList:", pmfDataList);

  return (
    <div className="add-discount-card">
      <Card
        title="Property Management Facility (PMF)"
        style={{ marginTop: "15px" }}>
        <Form form={form1} layout="vertical">
          <div className="add-branch-form">
            <Form.Item
              name="selectProperty"
              className="add-branch-input"
              label="Select Property"
              rules={[{ required: true, message: "Please select a property" }]}>
              <Select
                style={{ height: 44 }}
                onChange={(value) => handlePropertyChange(value, form1)}
                placeholder="Select Property">
                {propertyList?.map((property) => (
                  <Option key={property.shop} value={property.shop}>
                    {property.shopName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="totalArea"
              className="add-branch-input"
              label="Total Area (sq ft)"
              rules={[{ required: true, message: "Total area is required" }]}>
              <Input style={{ height: 44 }} placeholder="Total Area" readOnly />
            </Form.Item>

            <Form.Item className="add-branch-input" label="PMF Fee (USD)">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  name="rateValue"
                  noStyle
                  rules={[
                    { required: true, message: "PMF value is required" },
                  ]}>
                  <Input
                    type="number"
                    min={1}
                    style={{ height: 44 }}
                    placeholder="PMF Value"
                  />
                </Form.Item>
                <ConfigProvider
                  theme={{
                    token: {
                      colorTextPlaceholder: "#fff",
                    },
                  }}>
                  <Form.Item name="rateType" noStyle>
                    <Select
                      defaultValue="usd"
                      style={{ width: "110px", height: 44 }}
                      placeholder="PMF Type">
                      <Option value="usd">USD</Option>
                    </Select>
                  </Form.Item>
                </ConfigProvider>
              </Space.Compact>
            </Form.Item>
          </div>
          <div className="add-branch-btn-gp">
            <Button className="btn filter" onClick={() => handleSave(form1)}>
              Save
            </Button>
          </div>
        </Form>
      </Card>

      {/* Render saved PMF data */}
      {pmfDataList.map((pmfData, index) => (
        <div className="pmf-second-card" key={index}>
          <Card>
            <Form layout="vertical" initialValues={pmfData}>
              <div className="add-branch-form">
                <Form.Item
                  name="selectProperty"
                  className="add-branch-input"
                  label="Select Property">
                  <Select
                    disabled
                    style={{ height: 44 }}
                    placeholder="Select Property">
                    {propertyList?.map((property) => (
                      <Option key={property.shop} value={property.shop}>
                        {property.shopName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="totalArea"
                  className="add-branch-input"
                  label="Total Area (sq ft)">
                  <Input style={{ height: 44 }} readOnly />
                </Form.Item>

                <Form.Item className="add-branch-input" label="PMF Fee (USD)">
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item name="rateValue" noStyle>
                      <Input style={{ height: 44 }} readOnly />
                    </Form.Item>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorTextPlaceholder: "#fff",
                        },
                      }}>
                      <Form.Item name="rateType" noStyle>
                        <Select
                          style={{ width: "110px", height: 44 }}
                          placeholder="USD"
                          disabled>
                          <Option value="usd">USD</Option>
                        </Select>
                      </Form.Item>
                    </ConfigProvider>
                  </Space.Compact>
                </Form.Item>
              </div>
              <div className="add-property-div">
                <Button
                  className="property-btn"
                  onClick={() => handleEditProperty(pmfData, index)}>
                  <EditOutlined />
                </Button>
                <Button
                  className="property-btn"
                  onClick={() => handleDeleteProperty(index)}>
                  <DeleteOutlined />
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default PmfCard;
