import { Card, Modal, Form, DatePicker, Button, message } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { useUpdatePreTerminateMutation } from "./contractApi"; // Import the mutation hook

const PreTerminateModal = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  const [updatePreTerminate, { isLoading }] = useUpdatePreTerminateMutation(); // Hook for API integration

  const handleFormSubmit = async (values) => {
    const { pre_terminate_date } = values;

    // Prepare the request body
    const updatedData = {
      start_date: moment(record.start_date, "MMM DD, YYYY").format("YYYY-MM-DD"),
      end_date: moment(record.end_date, "MMM DD, YYYY").format("YYYY-MM-DD"),
      terminate_date: moment(pre_terminate_date).format("YYYY-MM-DD"),
    };

    try {
      await updatePreTerminate({
        contractId: record.key, // Use the record's `key` as the contract ID
        updatedData,
      }).unwrap();
      message.success("Pre-Terminate date added successfully!");
      onClose(); // Close the modal
      form.resetFields(); // Reset the form
    } catch (error) {
      message.error("Failed to add Pre-Terminate date. Please try again.");
    }
  };

  return (
    <div>
      <Modal
        className="bu-add-modal"
        width={880}
        open={visible}
        footer={null}
        onCancel={onClose}
        closeIcon={false}
      >
        <Card title="Pre-Terminate">
          <p className="pre-terminate-title">ACCORDING TO CONTRACT</p>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              start_date: record?.start_date ? moment(record.start_date, "MMM DD, YYYY") : null,
              end_date: record?.end_date ? moment(record.end_date, "MMM DD, YYYY") : null,
            }}
            onFinish={handleFormSubmit} // Handle form submission
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item label="Start Date" name="start_date">
                <DatePicker
                  style={{
                    width: 400,
                    height: 44,
                    backgroundColor: "#F7F8F8",
                  }}
                  disabled // Make it read-only
                />
              </Form.Item>
              <Form.Item label="End Date" name="end_date">
                <DatePicker
                  style={{
                    width: 400,
                    height: 44,
                    backgroundColor: "#F7F8F8",
                  }}
                  disabled // Make it read-only
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Pre-Terminate Date"
              name="pre_terminate_date"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker
                style={{
                  width: "100%",
                  height: 44,
                  backgroundColor: "#F7F8F8",
                }}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="text" className="btn" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className="btn filter"
                type="primary"
                htmlType="submit"
                loading={isLoading} // Show loading spinner while the request is processing
              >
                CONFIRM
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default PreTerminateModal;
