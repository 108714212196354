import { baseApi } from "../../app/baseApi";

export const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdminList: builder.query({
      query: () => `/Administrator/list`,
      providesTags: ["AdminList"],
    }),

    getAdminById: builder.query({
      query:({adminId}) => `/Administrator/edit/${adminId}`,
      providesTags: ["Admin"],
    }),

    getAllRoles: builder.query({
      query: () => `/main-Administrator/roles/list`,
      providesTags: ["RoleList"],
    }),

    getAllRolesByCAdmin: builder.query({
      query: () => `/Administrator/roles/admin/list`,
      providesTags: ["RoleList"],
    }),

    getPermissionById: builder.query({
      query: ({ roleId }) =>
        `/Administrator/roles/get-permission-role/edit/${roleId}`,
      providesTags: ["PermissionList"],
    }),

    addNewRole: builder.mutation({
      query: ({ name }) => ({
        url: `/Administrator/roles/create`,
        method: "POST",
        body: {
          name,
        },
      }),
      invalidatesTags: ["RoleList"],
    }),

    addNewRoleByCAdmin: builder.mutation({
      query: ({ name }) => ({
        url: `/Administrator/roles/admin/create`,
        method: "POST",
        body: {
          name,
        },
      }),
      invalidatesTags: ["RoleList"],
    }),

    addNewAdmin: builder.mutation({
      query: (params) => {
        const formData = new FormData();
    
        // Append each field directly to the FormData object
        formData.append("name", params.name);
        formData.append("email", params.email);
        formData.append("phone_number", params.phone_number);
        formData.append("password", params.password);
        formData.append("role", params.role);
    
        // Append the profile image/file as binary data
        if (params.profile) {
          formData.append("profile", params.profile); // Assuming `params.profile` is a File object
        }
    
        return {
          url: `/Administrator/create`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          body: formData,
        };
      },
      invalidatesTags: ["AdminList"],
    }),

    addPermission: builder.mutation({
      query: ({adminId, updatedData}) => ({
        url: `/Administrator/roles/permission-role/create/${adminId}`,
        method: "POST",
        body: updatedData, 
      }),
      invalidatesTags: ["PermissionList", "RoleList"]
    }),
    
    updateAdminData: builder.mutation({
      query: ({ adminId, updatedData }) => ({
        url: `/Administrator/update/${adminId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit admin failed:", error);
      },
      invalidatesTags: ["PermissionList", "RoleList", "AdminList"],
    }),

    updateAdminPassword: builder.mutation({
      query: ({adminId, updatedData}) => ({
        url: `/Administrator/change-password/${adminId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Change Password failed:", error);
      },
      invalidatesTags:["AdminList"]
    }),

    deleteAdmin: builder.mutation({
      query: ({ adminId }) => ({
        url: `/Administrator/delete/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AdminList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllAdminListQuery,
  useGetAllRolesQuery,
  useGetPermissionByIdQuery,
  useAddNewRoleMutation,
  useGetAdminByIdQuery,
  useAddNewAdminMutation,
  useAddPermissionMutation,
  useUpdateAdminDataMutation,
  useDeleteAdminMutation,
  useUpdateAdminPasswordMutation,
  useGetAllRolesByCAdminQuery,
  useAddNewRoleByCAdminMutation
} = adminApi;
