import React from "react";
import { Modal, Row, Col, Tag, Card } from "antd";

const formatPrice = (price) => {
  return new Intl.NumberFormat("en-US").format(price) + " MMK";
};

const ViewProperty = ({ isViewModalOpen, selectedShop, hanleViewCancel }) => {
  
  return (
    <Modal
      className="bu-add-modal"
      width={730}
      centered
      open={isViewModalOpen}
      onCancel={hanleViewCancel}
      footer={null}>
      <Card title="View Details">
        <div className="view-property-details">
        {selectedShop && (
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <p>
                <strong>Property Type</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={8}>
              <p>{selectedShop.property_type}</p>
            </Col>
            <Col span={4}>
              <p>
                <strong>Building</strong>
              </p>
            </Col>

            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={6}>
              <p>{selectedShop.building}</p>
            </Col>

            {/* Shop Name and Floor */}
            <Col span={4}>
              <p>
                <strong>Room / Shop Name</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={8}>
              <p>{selectedShop.shopName}</p>
            </Col>
            <Col span={4}>
              <p>
                <strong>Floor</strong>
              </p>
            </Col>

            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={6}>
              <p>{selectedShop.floor}</p>
            </Col>

            {/* Shop Code and Zone */}
            <Col span={4}>
              <p>
                <strong>Total Area</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={8}>
              <p>{selectedShop.area} sq ft</p>
            </Col>
            <Col span={4}>
              <p>
                <strong>Zone</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={6}>
              <p>{selectedShop.zone}</p>
            </Col>

            <Col span={4}>
              <p>
                <strong>Room Type</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={8}>
              <p>{selectedShop.roomType}</p>
            </Col>
            {/* Total Area and Status */}
            <Col span={4}>
              <p>
                <strong>Price</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={6}>
              <p>{formatPrice(selectedShop.price)}</p>
            </Col>
            <Col span={4}>
              <p>
                <strong>Status</strong>
              </p>
            </Col>

            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={8}>
              <p>
                <Tag
                  color={selectedShop.status === "Available" ? "green" : "red"}>
                  {selectedShop.status}
                </Tag>
              </p>
            </Col>

            {/* Price */}
          </Row>
        )}
        </div>
      </Card>
    </Modal>
  );
};

export default ViewProperty;
