import { Form, Modal, Select } from "antd";
import React, { useState } from "react";
import { AddNewBuildingForm, BuildingList } from "../../features";
import { FilterCard, PageHeader, SearchFilter } from "../../component";
import {
  useGetAllBranchesQuery,
  useGetAllBuildingQuery,
} from "../../features/property/propertyApi";

const { Option } = Select;
const AddBuilding = () => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  const { data: branchListData, loading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, loading: isLoadingBuildings } =
    useGetAllBuildingQuery();
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value); // Update selected branch
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
  };

  const handleFilter = () => {
    // Get current form values
    const formValues = form.getFieldsValue();
    setSelectedBranch(formValues.branch); // Set the selected branch from form
    setSelectedBuilding(formValues.building); // Set the selected building from form
  };

  const handleClearFilters = () => {
    form.resetFields(); // Reset all fields in the form
    setSearchTerm(""); // Clear the search term
    setSelectedBranch(null); // Reset branch selection
    setSelectedBuilding(null);
  };

  return (
    <>
      <PageHeader
        title="Building"
        addBtn="Add New Building"
        onAdd={showModal}
      />
      <SearchFilter searchPlaceHolder="Search..." onSearch={handleSearch} />
      <Form form={form}>
        <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
          <Form.Item name="branch">
            <Select
              placeholder="Branch"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleBranchChange}>
              {isLoadingBranch ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                branchListData?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
          <Form.Item name="building">
            <Select
              placeholder="Building"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleBuildingChange}>
              {isLoadingBuildings ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                buildingListData?.data?.buildings?.map((building) => (
                  <Option key={building?._id} value={building?._id}>
                    {building?.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
        </FilterCard>
      </Form>
      <Form>
        <Form.Item>
          <BuildingList
            selectedBranch={selectedBranch}
            searchTerm={searchTerm}
            selectedBuilding={selectedBuilding}
          />
        </Form.Item>
      </Form>

      {/* Modal for Adding New Building */}
      <Modal
        width={880}
        className="bu-add-modal"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <AddNewBuildingForm handleOk={handleOk} handleCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default AddBuilding;
