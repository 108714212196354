import React, { useState } from "react";
import { DatePicker, Form, message, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteTenantMutation,
  useGetAllTenantQuery,
} from "../../features/tenant/tenantApi";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  ReusablePagination,
  DeleteModal,
  PageHeader,
  SearchFilter,
  FilterCard,
} from "../../component";
import { TenantTable } from "../../features";

const { RangePicker } = DatePicker;
const TenantList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: tenantListData,
    isLoading,
    error,
  } = useGetAllTenantQuery(token);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [tenantTypeFilter, setTenantTypeFilter] = useState("");

  const resetFilters = () => {
    setStatusFilter("");
    setTenantTypeFilter("");
  };

  const applyFilters = () => {
    console.log("apply filter");
  };

  const [deleteTenant] = useDeleteTenantMutation();

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteTenant({ tenantId: currentRecord?.key }).unwrap();
        setIsModalOpen(false);
        message.success("Tenant is deleted successfully.");
      } catch (err) {
        const errorMessage =
          error?.data?.errors?.[0] || "Failed to delete tenant";
        message.error(errorMessage);
      }
    }
  };
  // Filtering the tenant data
  const filteredData = tenantListData?.data
    ?.filter((tenant) =>
      tenant.tenant_name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.map((tenant, index) => ({
      key: tenant._id,
      no: index + 1,
      tenantName: tenant.tenant_name,
      email: tenant.email,
      phNum: tenant.phone_number,
      creDate: moment(tenant.createdAt).format("DD/MM/YYYY"),
      status: tenant.status === 1 ? "Active" : "Suspend",
    }));
  // Handle pagination changes
  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  // Calculate data for the current page
  const paginatedData = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const totalItems = tenantListData?.paginator?.totalItems;

  return (
    <>
      <PageHeader
        title="Tenant List"
        totalItems={totalItems}
        totalName="Tenants"
      />

      <SearchFilter
        searchPlaceHolder="Search by Tenant Name"
        onSearch={setSearchQuery}
      />

      <FilterCard onClear={resetFilters} onFilter={applyFilters}>
      <RangePicker style={{ height: 38, width: 233 }} />
        <Select
          placeholder="Status"
          // value={tenantTypeFilter}
          style={{
            width: 150,
            height: 38,
          }}
          // onChange={(value) => setTenantTypeFilter(value)}
        >
          {/* <Option value="">Own</Option>
          <Option value="Lease">Lease</Option> */}
        </Select>
      </FilterCard>

      <Form>
        <Form.Item>
          <TenantTable
            loading={isLoading}
            data={paginatedData} // Use paginated data
            onView={(record) =>
              navigate(`tenant-details`, { state: { tenantId: record.key } })
            }
            onDelete={showModal}
          />
          <ReusablePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePaginationChange} // Pass the handler to ReusablePagination
          />
        </Form.Item>
      </Form>

      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default TenantList;
