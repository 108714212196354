import { baseApi } from "../../app/baseApi";

export const invoiceSettingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoiceSetting: builder.query({
      query: () => `/Setting/invoice-settings/list`,
      providesTags: ["InvoiceSettingList"],
    }),

    addInvoiceSetting: builder.mutation({
      query: ({ bodyData }) => ({
        url: `/Setting/invoice-settings/create`,
        method: "POST",
        body: bodyData,
      }),
      onError: (error) => {
        console.error("Add invoice setting failed:", error);
      },
      invalidatesTags: ["InvoiceSettingList"],
    }),

    updateInvoiceSetting: builder.mutation({
      query: ({ invoiceId, updatedData }) => ({
        url: `/Setting/invoice-settings/update/${invoiceId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit invoice setting is failed:", error);
      },
      invalidatesTags: ["InvoiceSettingList"],
    }),

    deleteInvoiceSetting: builder.mutation({
      query: ({ invoiceId }) => ({
        url: `/Setting/invoice-settings/delete/${invoiceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["InvoiceSettingList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllInvoiceSettingQuery,
  useAddInvoiceSettingMutation,
  useDeleteInvoiceSettingMutation,
  useUpdateInvoiceSettingMutation,
} = invoiceSettingApi;
