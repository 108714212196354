import { DownOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { ReusablePagination } from "../../component";
import { RiFileEditLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useGetAllContractListsQuery } from "./contractApi";
import { LuFileX2 } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import PreTerminateModal from "./PreTerminateModal";

const ContractListTable = ({
  setTotalItems,
  selectedPayment,
  selectedTenant,
  selectedDateRange,
  searchTerm,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterContract, setFilterContract] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formatPrice = (price) => {
    if (price === 0) return "0"; // Explicitly handle 0
    return price
      ? `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : "";
  };
  const token = useSelector((state) => state.authSlice.token2);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: contractList, isLoading: isLoadingFloors } =
    useGetAllContractListsQuery(token);

  const handlePreTerminate = (record) => {
    setSelectedRecord(record); // Store the selected record
    setIsModalVisible(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Close the modal
    setSelectedRecord(null); // Clear the selected record
  };
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Contract Name",
      dataIndex: "contract_name",
      key: "contract_name",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Tenant Type",
      dataIndex: "tenant_type",
      key: "tenant_type",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: "Advanced Payment",
      dataIndex: "advanced_payment",
      key: "advanced_payment",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => {
        // Dynamically add action button for "lease" tenant type
        const dynamicItems = [
          {
            key: "1",
            label: (
              <span>
                <EyeOutlined /> View Details
              </span>
            ),
            onClick: () => {
              navigate(`/${id}/contract/list/view-contract`, {
                state: { contract: record },
              }); // Pass the record data
            },
          },
          {
            key: "2",
            label: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "3px",
                }}>
                <RiFileEditLine /> Contract Policy
              </div>
            ),
            onClick: () => {
              navigate(`/${id}/contract/list/add-policy`, {
                state: { contract: record },
              }); // Pass the record data
            },
          },
        ];

        // Add extra action button if tenant_type is "lease"
        if (record.tenant_type === "Lease") {
          dynamicItems.push({
            key: "3",
            label: (
              <span>
                <LuFileX2 /> Pre-Terminate
              </span>
            ),
            onClick: () => handlePreTerminate(record),
          });
        }

        return (
          <Space size="middle">
            <Dropdown
              menu={{
                items: dynamicItems.map((item) => ({
                  ...item,
                  onClick: item.onClick, // Pass the record to onClick handler
                })),
              }}
              placement="bottomRight">
              <Button style={{ border: "1px solid darkblue" }}>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  useEffect(() => {
    if (contractList) {
      const filteredData = contractList?.data?.filter((contract) => {
        const matchesSearch = contract?.contract_information?.contract_name
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase());
        const matchesPayment = selectedPayment
          ? contract?.payment_type?.payment_type === selectedPayment
          : true;
        const matchesTenant = selectedTenant
          ? contract?.property_type === selectedTenant
          : true;
        const matchesDateRange = selectedDateRange
          ? new Date(contract?.contract_information?.start_date) >=
              new Date(selectedDateRange[0]) &&
            new Date(contract?.contract_information?.end_date) <=
              new Date(selectedDateRange[1])
          : true;
        return (
          matchesSearch && matchesPayment && matchesTenant && matchesDateRange
        );
      });
      setFilterContract(filteredData);
    }
  }, [
    searchTerm,
    selectedPayment,
    selectedTenant,
    selectedDateRange,
    contractList,
  ]);

  const tableData = filterContract?.map((contract) => ({
    key: contract?._id,
    contract_name: contract?.contract_information?.contract_name,
    tenant_name: contract?.tenant?.tenant_name,
    phone_number: contract?.tenant?.phone_number,
    start_date: formatDate(contract?.contract_information?.start_date),
    end_date: formatDate(contract?.contract_information?.end_date),
    tenant_type: contract?.property_type === "own" ? "Owner" : "Lease",
    payment_type: contract?.payment_type?.payment_type,
    advanced_payment: formatPrice(contract?.payment_type?.advanced_payment),
  }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalItems = contractList?.paginator?.totalItems;

  // Update parent component with totalItems whenever it changes
  useEffect(() => {
    setTotalItems(totalItems || 0);
  }, [totalItems, setTotalItems]);

  return (
    <div>
      <Table dataSource={paginatedData} columns={columns} pagination={false} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePaginationChange}
      />
      <PreTerminateModal
        record={selectedRecord}
        visible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default ContractListTable;
