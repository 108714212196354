import { Table, Tag } from "antd";
import React from "react";

const MaintenanceInvoice = () => {
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNum",
      key: "invoiceNum",
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Outlet Name",
      dataIndex: "outletName",
      key: "outletName",
    },
    {
      title: "Invoice Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Paid":
            color = "green";
            break;
          case "Unpaid":
            color = "blue";
            break;
          case "Due":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transcationType",
      key: "transcationType",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Remaining Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
    },
  ];

  const data = [
    {
      key: "1",
      date: "1/8/2024",
      invoiceNum: "Invoice #001",
      invoiceType: "Rental",
      tenantName: "Tenant",
      outletName: "outlet 1",
      transcationType: "Cash",
      paymentType: "Partially",
      amount: "250,000 MMK",
      remainingAmount: "0 MMK",
      status: "Paid",
    },
    {
      key: "2",
      date: "2/8/2024",
      invoiceNum: "Invoice #002",
      invoiceType: "Monthly",
      tenantName: "Tenant 1",
      outletName: "outlet 2",
      transcationType: "Cash",
      paymentType: "Fully",
      amount: "250,000 MMK",
      remainingAmount: "0 MMK",
      status: "Unpaid",
    },
  ];
  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default MaintenanceInvoice;
