import { Table, Tag } from "antd";
import React, { useState } from "react";
import { ReusablePagination } from "../../../component";

const SubTenantTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNum",
      key: "phoneNum",
    },
    {
      title: "Created Date",
      dataIndex: "creDate",
      key: "creDate",
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Active":
            color = "green";
            break;
          case "Suspend":
            color = "red";
            break;
          default:
            color = "red";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];
  const dataSource = [
    {
      key: "1",
      tenantName: "Sub Tenant 1",
      email: "subtenant@gmail.com",
      phoneNum: "09987654321",
      creDate: "May 16, 2023",
      status: "Active",
    },
    {
      key: "2",
      tenantName: "Sub Tenant 2",
      email: "subtenant2@gmail.com",
      phoneNum: "09987654321",
      creDate: "May 16, 2023",
      status: "Suspend",
    },
  ];

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div>
      <Table columns={columns} dataSource={paginatedData} pagination={false} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total="20"
        onChange={handlePaginationChange}
      />
    </div>
  );
};

export default SubTenantTable;
