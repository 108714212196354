import React from "react";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { LuFileEdit, LuBell } from "react-icons/lu";
import { FiUsers, FiLayers, FiDollarSign, FiTool } from "react-icons/fi";
import { BiReceipt } from "react-icons/bi";
import { TfiAnnouncement } from "react-icons/tfi";
import { BsPersonGear } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";

const getItem = (label, key, icon, path, children) => ({
  key,
  icon,
  children,
  label: path ? <Link to={path}>{label}</Link> : label,
});

const MenuItems = ({ permissions, selectedBuId }) => {
  const checkPermission = (permissions, moduleName, action) => {
    const module = permissions.find(
      (perm) =>
        perm.module.module_name.toLowerCase() === moduleName.toLowerCase()
    );
    return module ? module[action] : false;
  };

  return [
    (checkPermission(permissions, "Dashboard", "read") ||
      checkPermission(permissions, "Dashboard", "create") ||
      checkPermission(permissions, "Dashboard", "write") ||
      checkPermission(permissions, "Dashboard", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Dashboard</span>,
        "1",
        <RxDashboard className="icon-style" />,
        `/${selectedBuId}`
      ),

    (checkPermission(permissions, "Contract_Manage", "read") ||
      checkPermission(permissions, "Contract_Manage", "write") ||
      checkPermission(permissions, "Contract_Manage", "create") ||
      checkPermission(permissions, "Contract_Manage", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Contract Manage
        </span>,
        "sub1",
        <LuFileEdit className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Contract_Manage", "read") ||
            checkPermission(permissions, "Contract_Manage", "write") ||
            checkPermission(permissions, "Contract_Manage", "create") ||
            checkPermission(permissions, "Contract_Manage", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Contract List
              </span>,
              "2",
              null,
              "contract/list"
            ),
          (checkPermission(permissions, "Contract_Manage", "read") ||
            checkPermission(permissions, "Contract_Manage", "write") ||
            checkPermission(permissions, "Contract_Manage", "create") ||
            checkPermission(permissions, "Contract_Manage", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Contract Setting
              </span>,
              "3",
              null,
              "contract/setting"
            ),
        ]
      ),

    (checkPermission(permissions, "Tenant", "read") ||
      checkPermission(permissions, "Tenant", "write") ||
      checkPermission(permissions, "Tenant", "create") ||
      checkPermission(permissions, "Tenant", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Tenant</span>,
        "sub2",
        <FiUsers className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Tenant", "read") ||
            checkPermission(permissions, "Tenant", "write") ||
            checkPermission(permissions, "Tenant", "create") ||
            checkPermission(permissions, "Tenant", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Tenant List
              </span>,
              "4",
              null,
              "tenant/list"
            ),
          (checkPermission(permissions, "Tenant", "read") ||
            checkPermission(permissions, "Tenant", "write") ||
            checkPermission(permissions, "Tenant", "create") ||
            checkPermission(permissions, "Tenant", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Sub Tenant List
              </span>,
              "5",
              null,
              "tenant/sub-list"
            ),

          (checkPermission(permissions, "Tenant", "read") ||
            checkPermission(permissions, "Tenant", "write") ||
            checkPermission(permissions, "Tenant", "create") ||
            checkPermission(permissions, "Tenant", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Tenant Category
              </span>,
              "6",
              null,
              "tenant/tenant-category"
            ),
        ]
      ),

    (checkPermission(permissions, "Property", "read") ||
      checkPermission(permissions, "Property", "write") ||
      checkPermission(permissions, "Property", "create") ||
      checkPermission(permissions, "Property", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Property</span>,
        "sub3",
        <FiLayers className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Property", "read") ||
            checkPermission(permissions, "Property", "write") ||
            checkPermission(permissions, "Property", "create") ||
            checkPermission(permissions, "Property", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Property List
              </span>,
              "7",
              null,
              "property/list"
            ),

          (checkPermission(permissions, "Property", "read") ||
            checkPermission(permissions, "Property", "write") ||
            checkPermission(permissions, "Property", "create") ||
            checkPermission(permissions, "Property", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Add Project / Branch
              </span>,
              "8",
              null,
              "property/add-project"
            ),

          (checkPermission(permissions, "Property", "read") ||
            checkPermission(permissions, "Property", "write") ||
            checkPermission(permissions, "Property", "create") ||
            checkPermission(permissions, "Property", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Add Building
              </span>,
              "9",
              null,
              "property/add-building"
            ),

          (checkPermission(permissions, "Property", "read") ||
            checkPermission(permissions, "Property", "write") ||
            checkPermission(permissions, "Property", "create") ||
            checkPermission(permissions, "Property", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Add Floor
              </span>,
              "10",
              null,
              "property/add-floor"
            ),

          (checkPermission(permissions, "Property", "read") ||
            checkPermission(permissions, "Property", "write") ||
            checkPermission(permissions, "Property", "create") ||
            checkPermission(permissions, "Property", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Add Zone / View
              </span>,
              "11",
              null,
              "property/add-zone"
            ),

          (checkPermission(permissions, "Property", "read") ||
            checkPermission(permissions, "Property", "write") ||
            checkPermission(permissions, "Property", "create") ||
            checkPermission(permissions, "Property", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Add Room / Shop
              </span>,
              "12",
              null,
              "property/add-shop"
            ),
        ]
      ),

    (checkPermission(permissions, "Utility_Bill", "read") ||
      checkPermission(permissions, "Utility_Bill", "write") ||
      checkPermission(permissions, "Utility_Bill", "create") ||
      checkPermission(permissions, "Utility_Bill", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Utility Bill</span>,
        "sub4",
        <FiDollarSign className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Utility_Bill", "read") ||
            checkPermission(permissions, "Utility_Bill", "write") ||
            checkPermission(permissions, "Utility_Bill", "create") ||
            checkPermission(permissions, "Utility_Bill", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Utility List
              </span>,
              "13",
              null,
              "utility/list"
            ),
        ]
      ),

    (checkPermission(permissions, "Invoice", "read") ||
      checkPermission(permissions, "Invoice", "write") ||
      checkPermission(permissions, "Invoice", "create") ||
      checkPermission(permissions, "Invoice", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Invoice</span>,
        "sub5",
        <BiReceipt className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Invoice", "read") ||
            checkPermission(permissions, "Invoice", "write") ||
            checkPermission(permissions, "Invoice", "create") ||
            checkPermission(permissions, "Invoice", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Invoice List
              </span>,
              "14",
              null,
              "invoice/list"
            ),

            (checkPermission(permissions, "Invoice", "read") ||
            checkPermission(permissions, "Invoice", "write") ||
            checkPermission(permissions, "Invoice", "create") ||
            checkPermission(permissions, "Invoice", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Custom Invoice
              </span>,
              "38",
              null,
              "invoice/custom-invoice"
            ),

          (checkPermission(permissions, "Invoice", "read") ||
            checkPermission(permissions, "Invoice", "write") ||
            checkPermission(permissions, "Invoice", "create") ||
            checkPermission(permissions, "Invoice", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Exchange Rate
              </span>,
              "15",
              null,
              "invoice/exchange-rate"
            ),

          (checkPermission(permissions, "Invoice", "read") ||
            checkPermission(permissions, "Invoice", "write") ||
            checkPermission(permissions, "Invoice", "create") ||
            checkPermission(permissions, "Invoice", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Preview Invoice
              </span>,
              "16",
              null,
              "invoice/preview"
            ),

          (checkPermission(permissions, "Invoice", "read") ||
            checkPermission(permissions, "Invoice", "write") ||
            checkPermission(permissions, "Invoice", "create") ||
            checkPermission(permissions, "Invoice", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Bulk Upload
              </span>,
              "17",
              null,
              "invoice/bulk-upload"
            ),
        ]
      ),

    (checkPermission(permissions, "Maintenance", "read") ||
      checkPermission(permissions, "Maintenance", "write") ||
      checkPermission(permissions, "Maintenance", "create") ||
      checkPermission(permissions, "Maintenance", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Maintenance</span>,
        "sub6",
        <FiTool className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Maintenance", "read") ||
            checkPermission(permissions, "Maintenance", "write") ||
            checkPermission(permissions, "Maintenance", "create") ||
            checkPermission(permissions, "Maintenance", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Maintenance List
              </span>,
              "18",
              null,
              "maintenance/list"
            ),

          (checkPermission(permissions, "Maintenance", "read") ||
            checkPermission(permissions, "Maintenance", "write") ||
            checkPermission(permissions, "Maintenance", "create") ||
            checkPermission(permissions, "Maintenance", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Types of Issue
              </span>,
              "19",
              null,
              "maintenance/types-of-issue"
            ),
        ]
      ),

    (checkPermission(permissions, "Announcement", "read") ||
      checkPermission(permissions, "Announcement", "write") ||
      checkPermission(permissions, "Announcement", "create") ||
      checkPermission(permissions, "Announcement", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Announcement</span>,
        "sub7",
        <TfiAnnouncement className="icon-style" />,
        null,
        (checkPermission(permissions, "Announcement", "read") ||
          checkPermission(permissions, "Announcement", "write") ||
          checkPermission(permissions, "Announcement", "create") ||
          checkPermission(permissions, "Announcement", "delete")) && [
          getItem(
            <span style={{ fontSize: "16px", fontWeight: 400 }}>
              Announcement List
            </span>,
            "20",
            null,
            "announcement/list"
          ),
        ]
      ),

    (checkPermission(permissions, "Notification", "read") ||
      checkPermission(permissions, "Notification", "write") ||
      checkPermission(permissions, "Notification", "create") ||
      checkPermission(permissions, "Notification", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Notification</span>,
        "sub8",
        <LuBell className="icon-style" />,
        null,
        (checkPermission(permissions, "Notification", "read") ||
          checkPermission(permissions, "Notification", "write") ||
          checkPermission(permissions, "Notification", "create") ||
          checkPermission(permissions, "Notification", "delete")) && [
          getItem(
            <span style={{ fontSize: "16px", fontWeight: 400 }}>
              Notification Lists
            </span>,
            "21",
            null,
            "notification/list"
          ),
        ]
      ),

    (checkPermission(permissions, "Administrator", "read") ||
      checkPermission(permissions, "Administrator", "write") ||
      checkPermission(permissions, "Administrator", "create") ||
      checkPermission(permissions, "Administrator", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Adminstrator</span>,
        "sub9",
        <BsPersonGear className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Administrator", "read") ||
            checkPermission(permissions, "Administrator", "write") ||
            checkPermission(permissions, "Administrator", "create") ||
            checkPermission(permissions, "Administrator", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Administrator List
              </span>,
              "22",
              null,
              "adminstrator/list"
            ),

          (checkPermission(permissions, "Administrator", "read") ||
            checkPermission(permissions, "Administrator", "write") ||
            checkPermission(permissions, "Administrator", "create") ||
            checkPermission(permissions, "Administrator", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Role & Permission
              </span>,
              "23",
              null,
              "adminstrator/role-permission"
            ),
        ]
      ),

      (checkPermission(permissions, "Reporting", "read") ||
      checkPermission(permissions, "Reporting", "write") ||
      checkPermission(permissions, "Reporting", "create") ||
      checkPermission(permissions, "Reporting", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Reporting
        </span>,
        "sub11",
        <LuFileEdit className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Tenant Report
              </span>,
              "31",
              null,
              "reporting/tenant"
            ),
          (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Property Report
              </span>,
              "32",
              null,
              "reporting/property"
            ),
            (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Invoice Report
              </span>,
              "33",
              null,
              "reporting/invoice"
            ),
            (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Property Maintenance Report
              </span>,
              "34",
              null,
              "reporting/property-maintenance"
            ),
            (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Contract Report
              </span>,
              "35",
              null,
              "reporting/contract"
            ),
            (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                User Logs
              </span>,
              "36",
              null,
              "reporting/user-logs"
            ),
            (checkPermission(permissions, "Reporting", "read") ||
            checkPermission(permissions, "Reporting", "write") ||
            checkPermission(permissions, "Reporting", "create") ||
            checkPermission(permissions, "Reporting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Audit Logs
              </span>,
              "37",
              null,
              "reporting/audit-logs"
            ),
        ]
      ),

    (checkPermission(permissions, "Setting", "read") ||
      checkPermission(permissions, "Setting", "write") ||
      checkPermission(permissions, "Setting", "create") ||
      checkPermission(permissions, "Setting", "delete")) &&
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Setting</span>,
        "sub10",
        <IoSettingsOutline className="icon-style" />,
        null,
        [
          (checkPermission(permissions, "Setting", "read") ||
            checkPermission(permissions, "Setting", "write") ||
            checkPermission(permissions, "Setting", "create") ||
            checkPermission(permissions, "Setting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Add Room Type
              </span>,
              "27",
              null,
              "setting/add-room-type"
            ),

          (checkPermission(permissions, "Setting", "read") ||
            checkPermission(permissions, "Setting", "write") ||
            checkPermission(permissions, "Setting", "create") ||
            checkPermission(permissions, "Setting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Invoice Setting
              </span>,
              "24",
              null,
              "setting/invoice"
            ),
          (checkPermission(permissions, "Setting", "read") ||
            checkPermission(permissions, "Setting", "write") ||
            checkPermission(permissions, "Setting", "create") ||
            checkPermission(permissions, "Setting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Advertisement
              </span>,
              "29",
              null,
              "setting/advertisement"
            ),
          (checkPermission(permissions, "Setting", "read") ||
            checkPermission(permissions, "Setting", "write") ||
            checkPermission(permissions, "Setting", "create") ||
            checkPermission(permissions, "Setting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Maintenance Notification
              </span>,
              "30",
              null,
              "setting/maintenance-noti"
            ),
          (checkPermission(permissions, "Setting", "read") ||
            checkPermission(permissions, "Setting", "write") ||
            checkPermission(permissions, "Setting", "create") ||
            checkPermission(permissions, "Setting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Notification Setting
              </span>,
              "25",
              null,
              "setting/notification"
            ),

          (checkPermission(permissions, "Setting", "read") ||
            checkPermission(permissions, "Setting", "write") ||
            checkPermission(permissions, "Setting", "create") ||
            checkPermission(permissions, "Setting", "delete")) &&
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Terms and Conditions
              </span>,
              "26",
              null,
              "setting/terms"
            ),
        ]
      ),
  ].filter(Boolean);
};

export default MenuItems;
