import { Form, Input, Checkbox, Button, message, Card } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginAccountMutation } from "../../features/auth/authApi";
import { setCanNavigateToCentralized } from "../../features/navigation/navigationSlice";
import useTokenExpiration from "../../hooks/useTokenExpired";
import Cookies from "js-cookie";

const SuperAdminSignIn = () => {
  useTokenExpiration();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginAccount] = useLoginAccountMutation();

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);
      const { remember, ...loginValues } = values;

      // Send login request
      const { data, error } = await loginAccount(loginValues).unwrap();
      console.log("Data", data);
      

      // Successful login handling
      if (data?.token) {
        const tokenDuration = new Date(data?.exp).getTime() - Date.now();
        const dayInMilliseconds = 24 * 60 * 60 * 1000;
        const tokenExpiredTime = tokenDuration / dayInMilliseconds;   
        const branches = data?.user?.role?.branch || [];       
        // Set login credentials in Redux
        dispatch(
          setCredentials({
            email: data.user.email,
            accessToken: data.token,
            name: data.user.name,
            branches,
          })
        );

        // Save token to cookies
        Cookies.set("token", data?.token, { expires: tokenExpiredTime });

        // Allow navigation to centralized dashboard
        dispatch(setCanNavigateToCentralized(true));
        navigate("/");
      } else {
        // Handle login error
        message.error(error?.data?.message || error?.error || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      message.error(
        error?.data?.message || "An error occurred during login. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="sign-in-form">
      <Card>
        <Form
          className="sign-in"
          layout="vertical"
          name="sign-in"
          style={{ width: "450px" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h2 className="login-header">Tenant Management System</h2>
          <p className="login-message">Please log in to your account.</p>

          <Form.Item
            className="sign-in-email"
            label="Email Address"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            hasFeedback
          >
            <Input style={{ height: 40 }} />
          </Form.Item>

          <Form.Item
            className="sign-in-pwd"
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
          >
            <Input.Password style={{ height: 40 }} />
          </Form.Item>

          <div className="forgot-pwd-remember">
            <Form.Item className="remember-me" style={{width:'200px', height:'auto'}} name="remember" valuePropName="checked">
              <Checkbox className="remember-me">Remember me</Checkbox>
            </Form.Item>

            <Link to="/sign-in/forgot-pwd" className="forgot-pwd">
              Forgot Password
            </Link>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="sign-in-submit-btn"
              loading={isSubmitting}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SuperAdminSignIn;
