import { Button, Card, DatePicker, Form, Input, Modal, message } from "antd";
import React from "react";
import { BiTransferAlt } from "react-icons/bi";
import { useAddExchangeRateMutation } from "./invoiceApi";

const AddExchangeRate = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [addNewExchangeRate, { isLoading }] =
    useAddExchangeRateMutation();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields
      const { date, usd, mmk } = values;

      await addNewExchangeRate({
        date: date.toISOString(), // Ensure date is formatted properly
        usd: parseFloat(usd), // Convert to a number
        mmk: parseFloat(mmk), // Convert to a number
      }).unwrap();

      message.success("Exchange rate added successfully!");
      form.resetFields(); // Reset the form
      onClose(); // Close the modal
    } catch (error) {
      message.error(error?.data?.message || "Failed to add exchange rate.");
    }
  };

  return (
    <div>
      <Modal
        width={880}
        className="bu-add-modal"
        open={visible}
        footer={null}
        onCancel={onClose}
        closeIcon={false}>
        <Card title="Exchange Rate">
          <Form form={form} layout="vertical">
            <Form.Item rules={[{ required: true }]} label="Date" name="date">
              <DatePicker
                style={{
                  width: "100%",
                  height: 44,
                }}
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                rules={[{ required: true }]}
                style={{ width: "377px" }}
                label="USD"
                name="usd">
                <Input type="number" min={1} style={{ height: 44 }} />
              </Form.Item>
              <div style={{ marginTop: "40px" }}>
                <BiTransferAlt style={{ width: "24px", height: "24px" }} />
              </div>
              <Form.Item
                rules={[{ required: true }]}
                style={{ width: "377px" }}
                label="MMK"
                name="mmk">
                <Input type="number" min={1} style={{ height: 44 }} />
              </Form.Item>
            </div>
            <div className="add-branch-btn-gp">
              <Button onClick={onClose} type="text" className="btn">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                loading={isLoading}
                className="btn filter">
                CREATE
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default AddExchangeRate;
