import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  message,
} from "antd";
import React from "react";
import { IoWarning } from "react-icons/io5";
import { useAddInvoiceSettingMutation } from "./invoiceSettingApi";
import { useNavigate, useParams } from "react-router-dom";

const AddInvoiceSettingCard = () => {
  const [form] = Form.useForm();
  const [addInvoiceSetting, { isLoading }] = useAddInvoiceSettingMutation();
  const navigate = useNavigate();
  const { id } = useParams();
  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      const bodyData = {
        ...values,
        invoice_number_padding_left: values.invoice_number_padding_left || 0,
        invoice_number_prefix: values.invoice_number_prefix || "",
        invoice_number_suffix: values.invoice_number_suffix || "",
        invoice_validity: values.invoice_validity || 0,
        due_validity: values.due_validity || 0,
        remainder_days: values.remainder_days || 0,
        penalty_fee: values.penalty_fee || 0,
        penalty_type: values.penalty_type || "flat-mmk",
      };

      // Call API
      await addInvoiceSetting({ bodyData }).unwrap();
      message.success("Invoice setting added successfully!");
      form.resetFields(); // Reset form
      navigate(`/${id}/setting/invoice`)
    } catch (error) {
      message.error("Failed to add invoice setting. Please try again.");
    }
  };

  const handleCancel = () => {
    form.resetFields();
    navigate(`/${id}/setting/invoice`)
  }
  return (
    <div>
      <Card title="Add Invoice Setting">
        <Form form={form} layout="vertical">
          <div className="add-branch-form">
            <Form.Item
              name="invoice_type"
              label="Invoice Type"
              className="add-branch-input"
              rules={[{ required: true, message: "Invoice Type is required" }]}>
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_number_padding_left"
              label="Invoice Number Padding Left Zeros (Optional)">
              <Input type="number" min={1} style={{ width: 340, height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_number_prefix"
              className="add-branch-input"
              label="Invoice Number Prefix (Optional)">
              <Input style={{ height: 44, width: 340 }} />
            </Form.Item>
            <Form.Item
              name="invoice_number_suffix"
              className="add-branch-input"
              label="Invoice Number Suffix (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_validity"
              style={{ width: 340 }}
              label="Invoice Validity (days) (Optional)">
              <Input type="number" min={1} style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
             style={{ width: 340 }}
              name="due_validity"
              label="Due Validity (days) (Optional)">
              <Input type="number" min={1} style={{ width: 340, height: 44 }} />
            </Form.Item>
            <Form.Item  style={{ width: 340 }} name="remainder_days" label="Reminder (days) (Optional)">
              <Input type="number" min={1} style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              className="add-branch-input"
              label="Late Fee (Optional)"
              rules={[{ required: true, message: "Late Fee is required" }]}>
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item name="penalty_fee" noStyle>
                  <Input type="number" style={{ height: 44, width: "258px" }} />
                </Form.Item>
                <div className="select-rate-type">
                  <Form.Item name="penalty_type" noStyle>
                    <Select
                      defaultValue="flat-mmk"
                      style={{ width: 130, height: 44 }}>
                      <Select.Option value="flat-mmk">Flat (MMK)</Select.Option>
                      <Select.Option value="flat-usd">Flat (USD)</Select.Option>
                      <Select.Option value="percent">Percent (%)</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Space.Compact>
            </Form.Item>
          </div>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "16px",
          }}>
          <p
            style={{
              margin: "0",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontWeight: 600,
            }}>
            <IoWarning
              style={{ width: "24px", height: "24px", color: "#FFB326" }}
            />
            Reminder(days) : Remind to admin before invoice validity.
          </p>
          <div>
            <Button
              type="text"
              className="btn"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="btn filter"
              type="primary"
              loading={isLoading}
              onClick={handleCreate}>
              CREATE
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddInvoiceSettingCard;
