import { Form, message, Select } from "antd";
import React, { useState } from "react";
import "./styles.css";
import {
  useDeleteUtilityMutation,
  useUpdateUtilityMutation,
} from "../../features/utility/utilityApi";
import { DeleteModal, FilterCard, PageHeader } from "../../component/index";
import { AddNewUtility, EditUtilityModal, UtilityTable } from "../../features";
import { useGetAllBranchesQuery } from "../../features/property/propertyApi";

const { Option } = Select;

const UtilityList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // For delete modal
  const [currentRecord, setCurrentRecord] = useState(null); // Record to be deleted
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // For add modal
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [form] = Form.useForm();
  const [deleteUtility, { isLoading: isDeleting }] = useDeleteUtilityMutation();
  const [updateUtility] = useUpdateUtilityMutation();
  const { data: branchListData, loading: isLoadingBranch } =
    useGetAllBranchesQuery();

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleAddCancel = () => {
    setIsModalVisible(false);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value); // Update selected branch
  };

  const handleFilter = () => {
    // Get current form values
    const formValues = form.getFieldsValue();
    setSelectedBranch(formValues.branch); // Set the selected branch from form
  };

  const handleClearFilters = () => {
    form.resetFields(); // Reset all fields in the form
    setSelectedBranch(null); // Reset branch selection
  };

  // Handle delete modal confirmation
  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteUtility({ utilityId: currentRecord.key }).unwrap();
        setIsModalOpen(false);
        message.success("Utility deleted successfully!");
      } catch (err) {
        console.error("Failed to delete this utility:", err);
        message.error("Failed to delete this utility.");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Show delete modal with the selected record
  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const showEditModal = (record) => {
    form.setFieldsValue({
      utilityType: record.utilityType,
      title: record.title,
      unit: record.unit,
      branch: Array.isArray(record.branchId)
        ? record.branchId
        : record.branchId.split(", "), // Split the string into an array of IDs
      rateValue: record.rateValue,
      rateType: record.rateType,
      description: record.description,
      branchId: record.branchId,
    });
    setCurrentRecord(record);
    setIsViewMode(false);
    setIsEditModalOpen(true);
  };

  const showViewModal = (record) => {
    form.setFieldsValue({
      utilityType: record.utilityType,
      title: record.title,
      unit: record.unit,
      branch: record.branch.split(", "), // Split the string into an array of IDs
      rateValue: record.rateValue,
      rateType: record.rateType,
      description: record.description,
    });
    setCurrentRecord(record);
    setIsViewMode(true);
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        utilityId: currentRecord.key,
        updatedData: {
          utilityType: values.utilityType,
          title: values.title,
          unit: parseInt(values.unit, 10), // Ensure `unit` is a number
          rate: parseFloat(values.rateValue),
          rate_type: values.rateType,
          property_type: values.propertyType,
          description: values.description, // Add description field
          branch: values.branch.map((id) => ({ _id: id })), // Convert branch IDs to array of objects
        },
      };
      await updateUtility(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Utility updated successfully!");
    } catch (err) {
      console.error("Failed to update this utility:", err);
      message.error("Failed to update this utility.");
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleUtilityTypeChange = (value) => {
    if (value === "meter") {
      form.setFieldsValue({ unit: "1 Unit" });
    } else if (value === "area") {
      form.setFieldsValue({ unit: "1 sq ft" });
    } else {
      form.setFieldsValue({ unit: "-" });
    }
  };

  return (
    <>
      <PageHeader
        title="Utility List"
        addBtn="Add Type of Utility"
        onAdd={showAddModal}
      />
      <Form form={form}>
        <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
          <Form.Item name="branch">
            <Select
              placeholder="Branch"
              style={{
                width: 150,
                height: 38,
              }}
              onChange={handleBranchChange}>
              {isLoadingBranch ? (
                <Option key="loading" disabled>
                  Loading...
                </Option>
              ) : (
                branchListData?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
        </FilterCard>
      </Form>
      <UtilityTable
        selectedBranch={selectedBranch}
        onEdit={showEditModal}
        onView={showViewModal}
        onDelete={showDeleteModal} // Pass the delete function
      />

      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
        confirmLoading={isDeleting}
      />

      <EditUtilityModal
        isEditModalOpen={isEditModalOpen}
        handleEditOk={handleEditOk}
        handleEditCancel={handleEditCancel}
        isViewMode={isViewMode}
        form={form}
        currentRecord={currentRecord}
        handleUtilityTypeChange={handleUtilityTypeChange}
      />

      <AddNewUtility
        visible={isModalVisible}
        handleOk={() => setIsModalVisible(false)} // Passes the visibility state
        onCancel={handleAddCancel} // Handles modal cancel
      />
    </>
  );
};

export default UtilityList;
