import React, { useEffect, useState } from "react";
import {
  Form,
  Table,
  Button,
  message,
  Space,
  Dropdown,
  ConfigProvider,
} from "antd";
import { useSelector } from "react-redux";
import {
  useGetAllFloorListQuery,
  useGetAllBuildingQuery,
  useDeleteFloorMutation,
  useUpdateFloorMutation,
  useDeleteManageFloorMutation,
} from "../propertyApi";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { TbFolderCog } from "react-icons/tb";
import EditFloorModal from "./EditFloor";
import { DeleteModal, ReusablePagination } from "../../../component";
import ManageModal from "./ManageModal";
import EditManageModal from "./EditManageModal";

const FloorList = ({ searchTerm, selectedBranch, selectedBuilding, selectedFloor }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetAllFloorListQuery(token);
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery(token);

  const [deleteFloor] = useDeleteFloorMutation(token);
  const [deleteManageFloor] = useDeleteManageFloorMutation();
  const [updateFloor, { loading: isUpdateLoading }] =
    useUpdateFloorMutation(token);

  const [form] = Form.useForm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChildEditModalOpen, setIsChildEditModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isDeletingPropertyType, setIsDeletingPropertyType] = useState(false);

  const formatPrice = (price) => {
    if (price === 0) return "0"; // Explicitly handle 0
    return price
      ? `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : "";
  };  

  const items = [
    {
      key: "1",
      label: (
        <div>
          <TbFolderCog style={{ width: 18, height: 18 }} /> &nbsp;Manage
        </div>
      ),
      onClick: (record) => showManageModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> &nbsp;Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> &nbsp;Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record, false),
    },
  ];

  const childItems = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> &nbsp;Edit
        </span>
      ),
      onClick: (record) => showChildEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> &nbsp;Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record, true),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record) => (record.children ? text : null), // Show only for parent rows
    },
    {
      title: "Project / Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
      onCell: (record) => {
        if (record.children) {
          return {
            onClick: () => handleExpandRow(record.key),
            style: { cursor: "pointer" },
          };
        }
        return {};
      },
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Number of Shops",
      dataIndex: "numberOfShops",
      key: "numberOfShops",
    },
    {
      title: "Property Type",
      dataIndex: "property_type",
      key: "property_type",
    },
    {
      title: "Unit (sq ft)",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Price (per sq ft)",
      dataIndex: "price_per_sq",
      key: "price_per_sq",
      render: (price) => `${formatPrice(price)}`,
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          {record.children ? (
            <Dropdown
              menu={{
                items: items.map((item) => ({
                  ...item,
                  onClick: () => item.onClick(record),
                })),
              }}
              placement="bottomRight">
              <Button style={{ border: "1px solid darkblue" }}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Dropdown
              menu={{
                items: childItems.map((item) => ({
                  ...item,
                  onClick: () => item.onClick(record),
                })),
              }}
              placement="bottomRight">
              <Button style={{ border: "1px solid darkblue" }}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filteredFloor, setFilteredFloor] = useState([]);

  useEffect(() => {
    if (floorListData) {
      const filteredData = floorListData?.data?.floors.filter((floor) => {
        const matchesSearch = floor.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesBranch = selectedBranch
          ? floor.branch?._id === selectedBranch
          : true; // Match branch if selected, otherwise show all
        const matchesBuilding = selectedBuilding ? floor?.building?._id === selectedBuilding : true;
        const matchesFloor = selectedFloor ? floor?._id === selectedFloor : true;
        return matchesSearch && matchesBranch && matchesBuilding && matchesFloor;
      });
      setFilteredFloor(filteredData);
    }
  }, [searchTerm, selectedBranch, selectedBuilding, floorListData, selectedFloor]);

  const tableData = filteredFloor?.map((floor, index) => ({
    key: floor._id,
    no: (index + 1).toString().padStart(2, "0"),
    branch: floor.branch?.name,
    branchId: floor.branch?._id,
    building: floor.building?.name,
    buildingId: floor.building?._id,
    floor: floor.name,
    numberOfShops: floor.number_of_shop,
    children: floor.propertyTypes.map((type, index) => ({
      key: type._id,
      floorId: floor._id,
      property_type: type.property_type,
      unit: type.unit,
      price_per_sq:
        type?.price_per_sq !== undefined && type?.price_per_sq !== null
          ? formatPrice(type?.price_per_sq)
          : "N/A", // Show N/A if price_per_sq is undefined or null
    })),
  }));  

  const handleExpandRow = (key) => {
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };

  // Delete Modal functions
  const showDeleteModal = (record, isPropertyType) => {
    setCurrentRecord(record);
    setIsDeletingPropertyType(isPropertyType); // Set whether it's a property type
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        if (isDeletingPropertyType) {
          // Deleting a property type
          await deleteManageFloor({
            propertyTypeId: currentRecord.key,
          }).unwrap();
          message.success("Property type deleted successfully.");
        } else {
          // Deleting a floor
          await deleteFloor({ floorId: currentRecord.key }).unwrap();
          message.success("Floor deleted successfully.");
        }
        setIsDeleteModalOpen(false);
      } catch (err) {
        message.error("Failed to delete item.");
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  // Edit Modal functions
  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      building: record.building,
      floorName: record.floor,
      numberOfShops: record.numberOfShops,
      area: record.area,
      pricePerSq: formatPrice(record.price),
    });
    setIsEditModalOpen(true);
  };

  // Manage Modal functions
  const showManageModal = (record) => {
    setCurrentRecord(record);
    setIsManageModalOpen(true);
  };

  const showChildEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      propertyType: record.propertyType,
      price: record.price_per_sq,
    });
    setIsChildEditModalOpen(true);
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  console.log("floor list", floorListData);

  return (
    <>
      <ConfigProvider theme={{ token: { colorBgContainer: "#f8f7f7" } }}>
        <Table
          columns={columns}
          dataSource={paginatedData}
          loading={isLoadingFloors}
          pagination={false}
          expandable={{
            expandedRowKeys,
            onExpand: (expanded, record) => handleExpandRow(record.key),
          }}
          rowKey={(record) => record.key}
        />
        <ReusablePagination
          currentPage={currentPage}
          pageSize={pageSize}
          total={floorListData?.data?.totalFloors}
          onChange={handlePaginationChange}
        />
        <EditManageModal
          isOpen={isChildEditModalOpen}
          onClose={() => setIsChildEditModalOpen(false)}
          onOk={() => setIsChildEditModalOpen(false)}
          currentRecord={currentRecord}
          form={form}
        />
        <ManageModal
          isOpen={isManageModalOpen}
          onClose={() => setIsManageModalOpen(false)}
          onOk={() => setIsManageModalOpen(false)}
          currentRecord={currentRecord}
          form={form}
        />
        <DeleteModal
          visible={isDeleteModalOpen}
          onConfirm={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
        <EditFloorModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          currentRecord={currentRecord}
          form={form}
          onUpdate={updateFloor}
          buildingListData={buildingListData}
          isLoadingBuildings={isLoadingBuildings}
          isUpdateLoading={isUpdateLoading}
        />
      </ConfigProvider>
    </>
  );
};

export default FloorList;
