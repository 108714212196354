import React, { useState } from "react";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space, Table, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteModal, ReusablePagination } from "../../component";
import {
  useGetAllInvoiceSettingQuery,
  useDeleteInvoiceSettingMutation,
} from "./invoiceSettingApi";
import EditInvoiceSettingCard from "./EditInvoiceSettingCard";

const InvoiceSettingTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null); // Track editing record
  const { data: invoiceSettingList, isLoading } =
    useGetAllInvoiceSettingQuery();
  const [deleteInvoiceSetting, { isLoading: isDeleting }] =
    useDeleteInvoiceSettingMutation();

  // Handle delete modal open
  const handleDeleteClick = (record) => {
    setEditingRecord(record);
    setIsModalOpen(true);
  };

  // Handle cancel delete
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditingRecord(null);
  };

  // Handle confirm deletion
  const handleOk = async () => {
    try {
      await deleteInvoiceSetting({ invoiceId: editingRecord.key }).unwrap();
      message.success("Invoice setting deleted successfully!");
      setIsModalOpen(false);
      setEditingRecord(null);
    } catch (error) {
      message.error("Failed to delete invoice setting. Please try again.");
    }
  };

  // Handle edit click
  const handleEditClick = (record) => {
    setEditingRecord(record); // Set the record for editing
    navigate(`/${id}/setting/invoice/edit-invoice-setting`, {
      state: { record }, // Pass the record for editing
    });
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: (record) =>
        navigate(`/${id}/setting/invoice/view-invoice-setting`, {
          state: { record },
        }),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: handleEditClick,
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => handleDeleteClick(record),
    },
  ];

  const columns = [
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
    },
    {
      title: "Invoice Number Prefix",
      dataIndex: "prefix",
      key: "prefix",
    },
    {
      title: "Invoice Number Suffix",
      dataIndex: "suffix",
      key: "suffix",
    },
    {
      title: "Invoice Validity (days)",
      dataIndex: "validity",
      key: "validity",
    },
    {
      title: (
        <div className="setting-icon">
          <SettingOutlined />
        </div>
      ),
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = invoiceSettingList?.data?.map((invoice) => ({
    key: invoice._id,
    invoiceType: invoice.invoice_type,
    prefix: invoice.invoice_number_prefix,
    suffix: invoice.invoice_number_suffix,
    validity: invoice.invoice_validity,
    paddingLeft: invoice.invoice_number_padding_left,
    reminder: invoice.remainder_days,
    penalty_fee: invoice.penalty_fee,
    penalty_type: invoice.penalty_type,
    due_validity: invoice.due_validity,
  }));

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
        />
        <ReusablePagination
          currentPage={currentPage}
          pageSize={pageSize}
          total={invoiceSettingList?.data?.length}
          onChange={handlePaginationChange}
        />
        <DeleteModal
          visible={isModalOpen}
          onConfirm={handleOk}
          onCancel={handleCancel}
          confirmLoading={isDeleting}
        />
      </>
    </div>
  );
};

export default InvoiceSettingTable;
