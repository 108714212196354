import React from "react";
import { Button, Card, Form, Input, Modal, Select } from "antd";

const { Option } = Select;
const { TextArea } = Input;
const AddNotiTemplate = ({ visible, onClose }) => {
  return (
    <div>
      <Modal
        width={880}
        open={visible}
        onCancel={onClose}
        className="bu-add-modal"
        footer={null}>
        <Card title="Add Notification Template">
          <Form layout="vertical">
            <Form.Item label="Event" name="event">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Option" name="option">
              <Select style={{ height: 44 }}>
                <Option value="1">MOBILE APP</Option>
                <Option value="2">SMS</Option>
                <Option value="3">Both</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Template Sample Message" name="sampleMessage">
              <TextArea rows={4} />
            </Form.Item>
            <div className="add-property-div">
              <Button onClick={onClose} type="cancel" className="btn">
                Cancel
              </Button>
              <Button className="filter btn">CREATE</Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default AddNotiTemplate;
