import { Form, Input, Button, message, Card, Select } from "antd";
import React from "react";
import {
  useAddNewBuildingMutation,
  useGetAllBranchesQuery,
} from "./propertyApi";

const { Option } = Select;
const AddNewBuildingForm = ({ handleOk, handleCancel }) => {
  const {
    data: BranchListData,
    isLoading: branchLoading,
    error: branchError,
  } = useGetAllBranchesQuery();

  const [addNewBuilding, { isLoading, error }] = useAddNewBuildingMutation();
  const [form] = Form.useForm(); // Get the form instance

  const handleSubmit = async (values) => {
    const payload = {
      branch: values.branch,
      name: values.building,
    };
    try {
      await addNewBuilding(payload).unwrap();
      form.resetFields(); // Clear the input fields after successful submission
      message.success("Building is added successfully.");
      handleOk();
    } catch (error) {
      const errorMessage = error?.data?.message || "Failed to add building";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    handleCancel(); // Call parent cancel handler
  };

  return (
    <Card title="Add New Building">
      <Form
        form={form} // Assign the form instance to the Form component
        layout="vertical"
        onFinish={handleSubmit}>
        <Form.Item rules={[{ required: true }]} label="Project / Branch" name="branch">
          <Select style={{ height: 44 }} placeholder="Select a branch">
            {branchLoading ? (
              <Option key="loading" disabled>
                Loading...
              </Option>
            ) : (
              BranchListData?.data?.map((branch) => (
                <Option key={branch._id} value={branch._id}>
                  {branch.name}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="New Building" name="building">
          <Input
            style={{ height: 44, background: "#f8f7f7" }}
            placeholder="Enter building name"
          />
        </Form.Item>
        <div className="add-branch-btn-gp">
          <Button onClick={handleFormCancel} type="text" className="btn">
            Cancel
          </Button>
          <Button
            loading={isLoading}
            type="text"
            htmlType="submit"
            className="btn filter">
            Create
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default AddNewBuildingForm;
