import { Breadcrumb } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ViewContract } from '../../features';

const ViewContractDetails = () => {
  const { id } = useParams();
  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/contract/list`}>Contract List</Link>,
          },
          { title: "Contract Details" },
        ]}
      />
      <ViewContract />
    </div>
  )
}

export default ViewContractDetails