import React from 'react'
import { DigitalPayment, InvoiceReport, MaintenanceRequest, MonthlyInvoice, NumberOfTenant, ReminderList, TenantGrowth } from '../features'

const Dashboard = () => {
  return (
    <div>
      <NumberOfTenant/>
      <MonthlyInvoice/>
      <DigitalPayment/>
      <TenantGrowth/>
      <ReminderList />
      <InvoiceReport/>
      <MaintenanceRequest/>
    </div>
  )
}

export default Dashboard