import { Button, Card, Form, Input } from "antd";
import React from "react";

const SmsSetting = () => {
  return (
    <div>
      <Card title="SMS Setting">
        <Form layout="vertical">
          <div className="email-setting-form">
            <Form.Item
              label="Client ID"
              name="client_id"
              className="email-setting-input">
              <Input placeholder="Client ID" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Secret Key"
              name="secret_key"
              className="email-setting-input">
              <Input placeholder="Secret Key" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Sender ID"
              name="sender_id"
              className="email-setting-input">
              <Input placeholder="Sender ID" style={{ height: 44 }} />
            </Form.Item>
          </div>
          <div className="email-setting-btn-gp">
            <Button type="text" className="btn">
              Cancel
            </Button>
            <Button className="btn filter">Save</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default SmsSetting;
