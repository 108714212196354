import React from "react";
import { Modal, Form, Input, Select, Button, message, Card } from "antd";
import { useUpdateManageFloorMutation } from "../propertyApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const EditManageModal = ({ isOpen, onClose, onOk, currentRecord }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const [form] = Form.useForm();
  const [updateManageFloor, { isLoading: isLoadingManage }] =
    useUpdateManageFloorMutation(token);
console.log(currentRecord);

  const handleSubmit = async (values) => {
    const data = {
      floorId: currentRecord?.key,
      updatedData: {
        floor: currentRecord?.floorId,
        property_type: values.propertyType,
        price_per_sq: values.price,
        unit: values.unit || 1, // Ensure unit is correct
      },
    };

    try {
      const response = await updateManageFloor(data).unwrap();
      if (response?.errorCode === 500 || response?.success) {
        form.resetFields();
        message.success("Property Type updated successfully.");
        onOk(); // Close modal on successful update
      } else {
        throw new Error("Unexpected error: Update failed. Please try again.");
      }
    } catch (error) {
      const errorMessage =
        error?.data?.errors?.[0] ||
        error?.data?.message ||
        "Failed to update floor due to a server error.";
      console.error("Error updating floor:", error); // Log the full error for troubleshooting
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={880}
      onCancel={handleFormCancel}
      closeIcon={false}
      className="bu-add-modal"
      open={isOpen}
      footer={null}>
      <Card title="Edit Property Type">
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            propertyType: currentRecord?.property_type,
            price: currentRecord?.price_per_sq
              ? currentRecord?.price_per_sq.replace(/,/g, "") // Remove commas
              : "", // Default if no value exists
            unit: 1,
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
            }}>
            <Form.Item
              label="Property Type"
              name="propertyType"
              rules={[
                { required: true, message: "Please select a property type" },
              ]}
              style={{ width: 400 }}>
              <Select
                disabled
                style={{ height: 44 }}
                placeholder="Select property type">
                <Option value="Rent">Rent</Option>
                <Option value="Sell">Sell</Option>
                <Option value="FOC">Free Of Charge (FOC)</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Unit (sq ft)" name="unit" style={{ width: 400 }}>
              <Input style={{ height: 44 }} readOnly value="1" />
            </Form.Item>

            <Form.Item
              label="Price (per sq ft)"
              name="price"
              rules={[{ required: true, message: "Please enter a price" }]}
              style={{ width: 400 }}>
              <Input style={{ height: 44 }} placeholder="Price" />
            </Form.Item>
          </div>

          <div className="add-branch-btn-gp">
            <Button type="text" className="btn" onClick={handleFormCancel}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="btn filter"
              loading={isLoadingManage}>
              Update
            </Button>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditManageModal;
