import React from "react";
import { Table, Dropdown, Space, Button } from "antd";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const TenantCategoryTable = ({ data, onEdit, onDelete, loading }) => {
  
  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: onEdit,
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: onDelete,
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width:'5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tenant Category",
      dataIndex: "tenantCategory",
      key: "tenantCategory",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: "15px" }}
      loading={loading}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default TenantCategoryTable;
