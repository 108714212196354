import { Card, ConfigProvider, Form, Input, Select, Space } from "antd";
import React, { useEffect } from "react";

const { Option } = Select;

const AddDiscountCard = ({ discount, setDiscountData, totalAmount, currency }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (discount) {
      form.setFieldsValue({
        totalAmount: discount.total_amount,
        rateValue: discount.discount_price,
        rateType: discount.discount_type,
        grandTotalAmount: discount.grand_total_amount,
      });
    } else {
      form.setFieldsValue({ totalAmount }); // Set only on first mount
    }
  }, [discount, totalAmount, form]); // Ensure dependencies don't cause infinite updates
  
  const handleValueChange = () => {
    const totalAmount = form.getFieldValue("totalAmount") || 0;
    const rateValue = form.getFieldValue("rateValue");
    const rateType = form.getFieldValue("rateType");

    let discountPrice;

    if (rateType === "percent") {
      discountPrice = (totalAmount * rateValue) / 100;
    } else {
      discountPrice = rateValue;
    }

    const grandTotalAmount = totalAmount - discountPrice;

    form.setFieldsValue({ grandTotalAmount });

    setDiscountData({
      total_amount: totalAmount,
      discount_price: rateValue,
      discount_type: rateType,
      grand_total_amount: grandTotalAmount,
    });
  };

  return (
    <div className="add-discount-card">
      <Card title={`Add Discount (${currency?.toUpperCase()})`} style={{ marginTop: "15px" }}>
        <Form form={form} layout="vertical" onValuesChange={handleValueChange}>
          <div className="add-branch-form">
            <Form.Item
              name="totalAmount"
              className="add-branch-input"
              label={`Total Amount (${currency?.toUpperCase()})`}
            >
              <Input style={{ height: 44 }} placeholder="Enter total amount" readOnly />
            </Form.Item>

            <Form.Item className="add-branch-input" label="Rate">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  name="rateValue"
                  noStyle
                  rules={[{ required: true, message: "Rate value is required" }]}
                >
                  <Input type="number" min={1} style={{ height: 44 }} placeholder="Rate Value" />
                </Form.Item>
                <ConfigProvider
                  theme={{
                    token: {
                      colorTextPlaceholder: "#fff",
                    },
                  }}
                >
                  <Form.Item
                    name="rateType"
                    noStyle
                    rules={[{ required: true, message: "Rate type is required" }]}
                  >
                    <Select style={{ width: "110px", height: 44 }} placeholder="Rate Type">
                      <Option value="flat">Flat</Option>
                      <Option value="percent">Percent</Option>
                    </Select>
                  </Form.Item>
                </ConfigProvider>
              </Space.Compact>
            </Form.Item>

            <Form.Item
              name="grandTotalAmount"
              className="add-branch-input"
              label={`Grand Total (${currency?.toUpperCase()})`}
            >
              <Input style={{ height: 44 }} placeholder="Grand Total Amount" readOnly />
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default AddDiscountCard;
