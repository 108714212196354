import React, { useState } from "react";
import { FilterCard, PageHeader, SearchFilter } from "../../component";
import { DatePicker, Select, Form } from "antd";
import { AddContractModal, ContractListTable } from "../../features";

const { RangePicker } = DatePicker;
const { Option } = Select;
const ContractList = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAddContract = () => {
    setIsModalVisible(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Close the modal
  };

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    setSelectedPayment(formValues.paymentType);
    setSelectedTenant(formValues.tenantType);
    setSelectedDateRange(formValues.dateRange); // Save the date range
  };

  const handleClearFilters = () => {
    form.resetFields(); // Resets all form fields
    setSelectedPayment(null); // Clears selected payment filter
    setSelectedDateRange(null); // Clears selected date range
    setSelectedTenant(null); // Clears selected tenant filter
  };

  const handleDateRange = (value) => {
    setSelectedDateRange(value); // Update the state with the selected date range
  };

  const handlePaymentTypeChange = (value) => {
    setSelectedPayment(value);
  };

  const handleTenantTypeChange = (value) => {
    setSelectedTenant(value);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  return (
    <div>
      <PageHeader
        title="Contract List"
        totalName="Contract"
        totalItems={totalItems}
        addBtn="Add Contract"
        onAdd={handleAddContract} // Set the function to open modal
      />
      <SearchFilter
        onSearch={handleSearch}
        searchPlaceHolder="Search by Contract"
      />
      <div className="filter-card">
        <Form form={form}>
          <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
            <Form.Item name="dateRange">
              <RangePicker
                onChange={handleDateRange}
                style={{ height: 38, width: 233, borderRadius: "20px" }}
              />
            </Form.Item>

            <Form.Item name="tenantType">
              <Select
                placeholder="Tenant Type"
                style={{ width: 150, height: 38 }}
                onChange={handleTenantTypeChange}>
                <Option value="own">Owner</Option>
                <Option value="lease">Lease</Option>
              </Select>
            </Form.Item>
            <Form.Item name="paymentType">
              <Select
                placeholder="Payment Type"
                style={{ width: 150, height: 38 }}
                onChange={handlePaymentTypeChange}>
                <Option value="Fully">Fully</Option>
                <Option value="Installment">Installment</Option>
              </Select>
            </Form.Item>
          </FilterCard>
        </Form>
      </div>
      <ContractListTable
        searchTerm={searchTerm}
        selectedPayment={selectedPayment}
        selectedTenant={selectedTenant}
        selectedDateRange={selectedDateRange}
        setTotalItems={setTotalItems}
      />

      {/* Render the AddContractModal and control its visibility */}
      <AddContractModal visible={isModalVisible} onClose={handleModalClose} />
    </div>
  );
};

export default ContractList;
