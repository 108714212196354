import {
  Form,
  Table,
  Dropdown,
  Button,
  message,
  Space,
  ConfigProvider,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllZoneQuery,
  useGetAllBuildingQuery,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
  useGetFloorsByBuildingIdQuery,
} from "./propertyApi";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { DeleteModal, ReusablePagination } from "../../component";
import EditZoneModal from "./EditZoneModal";

const ZoneList = ({
  searchTerm,
  selectedBranch,
  selectedBuildingFromFilter,
  selectedFloor,
  selectedZone,
}) => {
  const token = useSelector((state) => state.authSlice.token2);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: zoneListData, isLoading: isLoadingZones } =
    useGetAllZoneQuery(token);
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery(token);

  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });

  const [deleteZone] = useDeleteZoneMutation();
  const [updateZone] = useUpdateZoneMutation();

  const [form] = Form.useForm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Project / Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [filteredZone, setFilteredZone] = useState([]);
  
  useEffect(() => {
    if (zoneListData) {
      const filteredData = zoneListData?.data?.zones?.filter((zone) => {
        const matchesSearch = zone.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesBranch = selectedBranch
          ? zone.branch?._id === selectedBranch
          : true;
        const matchesBuilding = selectedBuildingFromFilter
          ? zone?.building?._id === selectedBuildingFromFilter
          : true;
        const matchesFloor = selectedFloor
          ? zone?.floor?._id === selectedFloor
          : true;
        const matchesZone = selectedZone
          ? zone?._id === selectedZone
          : true;
        return (
          matchesSearch &&
          matchesBranch &&
          matchesBuilding &&
          matchesFloor &&
          matchesZone
        );
      });
      setFilteredZone(filteredData);
    }
  }, [
    searchTerm,
    zoneListData,
    selectedBranch,
    selectedBuildingFromFilter,
    selectedFloor,
    selectedZone,
  ]);

  const tableData = filteredZone?.map((zone, index) => ({
    key: zone._id,
    no: index + 1,
    branchId: zone?.branch?._id,
    branch: zone?.branch?.name,
    zone: zone?.name,
    building: zone?.building?.name,
    buildingId: zone?.building?._id,
    floor: zone?.floor?.name,
    floorId: zone?.floor?._id,
  }));

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteZone({ zoneId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Zone deleted successfully.");
      } catch (error) {
        const errorMessage =
          error?.data?.errors?.[0] || "Failed to delete zone";
        message.error(errorMessage);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      building: record?.buildingId,
      floor: record?.floorId,
      zone: record?.zone,
    });
    setSelectedBuilding(record?.buildingId);
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const updatedData = form.getFieldsValue(); // Get updated values from the form
      const payload = {
        zoneId: currentRecord.key,
        updatedData: {
          name: updatedData.zone,
          building: updatedData.building,
          floor: updatedData.floor,
          branch: updatedData.branch,
        },
      };
      console.log("payload", payload);
      await updateZone(payload).unwrap();
      setIsEditModalOpen(false);
      message.success("Zone updated successfully.");
    } catch (error) {
      const errorMessage = error?.data?.errors?.[0] || "Failed to update zone";
      message.error(errorMessage);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
        <Table
          columns={columns}
          dataSource={paginatedData}
          loading={isLoadingZones}
          pagination={false}
        />
        <ReusablePagination
          currentPage={currentPage}
          pageSize={pageSize}
          total={zoneListData?.data?.totalZones}
          onChange={handlePaginationChange}
        />
        <DeleteModal
          visible={isDeleteModalOpen}
          onConfirm={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
        <EditZoneModal
          currentRecord={currentRecord}
          visible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
          form={form}
          buildingListData={buildingListData}
          floorListData={floorListData}
          isLoadingBuildings={isLoadingBuildings}
          isLoadingFloors={isLoadingFloors}
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}
        />
      </ConfigProvider>
    </>
  );
};

export default ZoneList;
