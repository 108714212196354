import {
  Button,
  Table,
  Dropdown,
  Space,
  Select,
  Input,
  Tag,
  message,
} from "antd";
import React, { useState } from "react";
import AdminProfile from "../../../component/centralized/AdminProfile";
import "../../property/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDeleteAdminMutation } from "../../../features/adminstrator/adminApi";
import { DownOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useGetAllCentralizedAdminListQuery } from "../../../features/centralized/admin/centralizedAdminApi";
import {
  DeleteModal,
  FilterCard,
  PageHeader,
  ReusablePagination,
  SearchFilter,
} from "../../../component";

const { Search } = Input;

const AdminstratorList = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const token = useSelector((state) => state.authSlice.token);
  const { data: adminListData, isLoading: isLoadingAdmins } =
    useGetAllCentralizedAdminListQuery(token);

  const [deleteAdmin, { isLoading: isDeleteLoading }] =
    useDeleteAdminMutation();

  const { id } = useParams();

  const handleViewDetails = (admin) => {
    setSelectedAdmin(admin); // Set the selected shop to show details
    setIsViewModalOpen(true); // Open the modal when a shop is selected
  };

  const handleViewCancel = () => {
    setIsViewModalOpen(false);
  };

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteAdmin({ adminId: currentRecord?.key }).unwrap();
        setIsModalOpen(false);
        message.success("Admin is deleted successfully.");
      } catch (err) {
        console.error("Failed to delete this admin", err);
        message.error("Failed to delete this admin");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: (record) => handleViewDetails(record),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) =>
        navigate(`/cd/adminstrator/list/edit-admin`, {
          state: { adminData: record },
        }),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ph No.",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Roles",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={status === "1" ? "green" : "blue"}>
          {status === "1" ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "operation",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = adminListData?.data?.map((admin, index) => ({
    key: admin._id,
    no: index + 1,
    name: admin?.name,
    email: admin?.email,
    phone_number: admin?.phone_number,
    role: admin?.user_role_type,
    status: admin?.status,
  }));

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const totalItems = adminListData?.paginator?.totalItems;

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <PageHeader
        title="Admin List"
        addBtn="Add New Admin"
        linkTo="/cd/adminstrator/list/add-new-admin"
        totalName="Admin"
        totalItems={totalItems}
      />
      <SearchFilter searchPlaceHolder="Search..." />
      <FilterCard>
        <Select
          placeholder="Business Unit"
          style={{
            width: 150,
            height: 38,
          }}></Select>
        <Select
          placeholder="Role"
          style={{
            width: 150,
            height: 38,
          }}></Select>
        <Select
          placeholder="Status"
          style={{
            width: 150,
            height: 38,
          }}></Select>
      </FilterCard>
      <Table
        pagination={false}
        columns={columns}
        dataSource={paginatedData}
        onChange={onChange}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePaginationChange}
      />

      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
        confirmLoading={isDeleteLoading}
      />

      <AdminProfile
        visible={isViewModalOpen}
        onCancel={handleViewCancel}
        selectedAdmin={selectedAdmin}
      />
    </>
  );
};

export default AdminstratorList;
