import React from "react";
import { Table, Space, Button, Dropdown, Tag } from "antd";
import { EyeOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import LayerLogo from "../../../assets/icons/layers.svg";
import { useNavigate, useParams } from "react-router-dom";

const SubTenantTable = ({ data, isLoading, showModal }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: (record) =>
        navigate(`/${id}/tenant/sub-list/sub-tenant-details`, {
          state: { subTenantId: record.key },
        }),
    },
    {
      key: "2",
      label: (
        <>
          <img src={LayerLogo} alt="Layer Logo" style={{ marginTop: "2px" }} />
          &nbsp;Add Property
        </>
      ),
      onClick: (record) =>
        navigate(`/${id}/tenant/sub-list/add-sub-property`, {
          state: { subTenantId: record.key },
        }),
    },
    {
      key: "4",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => {
        showModal(record);
      },
    },
  ];

  const tableColumns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width:'5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Sub Tenant Name",
      dataIndex: "subTenantName",
      key: "subTenantName",
      width:'15%',
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width:'15%',
    },
    {
      title: "Phone Number",
      dataIndex: "phNum",
      key: "phNum",
      width:'15%',
    },
    {
      title: "Created Date",
      dataIndex: "creDate",
      key: "creDate",
      width:'15%',
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width:'15%',
      render: (status) => (
        <Tag color={status === "Active" ? "green" : "orange"}>{status}</Tag>
      ),
    },
    {
      title: '',
      key: "operation",
      align: "center",
      width:'10%',
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={tableColumns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default SubTenantTable;
