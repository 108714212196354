import { Card, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoWarning } from "react-icons/io5";
import { useGetAllInvoiceSettingQuery } from "../../../features/setting/invoiceSettingApi";

const { Option } = Select;

const ViewInvoicePaymentSetting = ({ invoicePayment }) => {
  const [form] = Form.useForm();
  const [selectedSetting, setSelectedSetting] = useState();

  console.log("invoice payment", invoicePayment);

  const { data: invoiceSettingList, isLoading: isInvoiceSettingLoading } =
    useGetAllInvoiceSettingQuery();

  // Set initial values when `invoicePayment` is available
  useEffect(() => {
    if (invoicePayment) {
      form.setFieldsValue({
        invoicePayment: invoicePayment.invoice_payment, // Match `invoice_payment` field
      });
    }
  }, [invoicePayment, form]);

  return (
    <div>
      <Card title="Invoice Payment Setting" style={{ marginTop: "15px" }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            invoicePayment: invoicePayment?.invoice_payment || undefined, // Default to the provided value
          }}
        >
          <div className="add-branch-form">
            <Form.Item
              name="paymentMethod"
              className="add-branch-input"
              label="Choose Payment Method"
            >
              <Select
                style={{ height: 44, width: "100%" }}
                placeholder="Choose Payment Method"
                onChange={(value) => {
                  setSelectedSetting(value);
                  form.setFieldValue("paymentMethod", value);
                }}
              >
                {isInvoiceSettingLoading ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  invoiceSettingList?.data?.map((setting) => (
                    <Option key={setting._id} value={setting._id}>
                      {setting?.invoice_type}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="invoicePayment"
              className="add-branch-input"
              label="Invoice Payment"
            >
              <Select
              disabled
                style={{ height: 44, width: "100%" }}
                placeholder="Select Invoice Payment Frequency"
              >
                <Option value={1}>Monthly</Option>
                <Option value={2}>Quarterly (3 Months)</Option>
                <Option value={3}>Half-Yearly (6 Months)</Option>
                <Option value={4}>Yearly</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
        <p
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            fontWeight: 600,
            marginTop: "16px",
          }}
        >
          <IoWarning
            style={{ width: "24px", height: "24px", color: "#FFB326" }}
          />
          If you choose MMK, you must pay the rental fee in Myanmar Kyat. If you
          choose USD, you must pay in dollars. But for the utility fee, you must
          pay in MMK.
        </p>
      </Card>
    </div>
  );
};

export default ViewInvoicePaymentSetting;
