import { Card, Modal } from "antd";
import React, { useState } from "react";
import UtilityType from "../../pages/utility/UtilityType";

const AddNewUtility = ({ visible, onCancel }) => {
  const [isModalVisible, setIsModalVisible] = useState(false); // For add modal
  const handleAddOk = () => {
    setIsModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <Modal
        className="bu-add-modal"
        width={880}
        open={visible}
        onOk={onCancel}
        closeIcon={null}
        onCancel={handleAddCancel}
        footer={null}>
        <Card title="Add Type of Utility">
          <UtilityType handleOk={handleAddOk} handleCancel={onCancel} />
        </Card>
      </Modal>
    </div>
  );
};

export default AddNewUtility;
