import React, { useState } from 'react'
import { PageHeader } from '../../component';
import { AddNewContractDurationModal, ContractSettingList } from '../../features';

const ContractSetting = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <PageHeader title="Contract Setting" addBtn="Add Contract" onAdd={showModal} />
      <ContractSettingList />
      <AddNewContractDurationModal visible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  );
};

export default ContractSetting;
