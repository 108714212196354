import {
  Form,
  Input,
  Button,
  Table,
  Modal,
  Select,
  Space,
  Dropdown,
  Card,
  message,
} from "antd";
import React, { useState } from "react";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { DeleteModal, ReusablePagination } from "../../component";
import {
  useGetAllContractSettingQuery,
  useUpdateContractMutation,
  useDeleteContractMutation, // Import delete mutation
} from "./contractApi";

const ContractSettingList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: contractSettingList, isLoading: isLoadingContract } =
    useGetAllContractSettingQuery({
      page: currentPage, // Use current page
      limit: pageSize, // Use page size
    });

  const [updateContract, { isLoading: isContractLoading }] =
    useUpdateContractMutation();
  const [deleteContract, { isLoading: isDeleting }] =
    useDeleteContractMutation(); // Initialize delete mutation

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "Contract Duration",
      dataIndex: "contractDuration",
      key: "contractDuration",
    },
    {
      title: "About to Expire (days)",
      dataIndex: "aboutToExpire",
      key: "aboutToExpire",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = contractSettingList?.data?.map((contract) => ({
    key: contract._id,
    contractNumber: contract.contract_duration,
    contractType: contract.contract_type,
    contractDuration: `${contract.contract_duration} ${
      contract.contract_type === "day"
        ? "Day(s)"
        : contract.contract_type === "month"
        ? "Month(s)"
        : "Year(s)"
    }`,
    aboutToExpire: `${contract.about_to_expire} Days`,
  }));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [form] = Form.useForm();

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    try {
      await deleteContract({ contractId: currentRecord.key }).unwrap();
      message.success("Contract deleted successfully.");
      setIsDeleteModalOpen(false); // Close the modal
    } catch (err) {
      console.error("Failed to delete contract:", err);
      message.error("Failed to delete contract.");
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      contractType: record.contractType,
      contractNumber: record.contractNumber,
      aboutToExpire: record.aboutToExpire.replace(" Days", ""),
    });
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        contractId: currentRecord.key,
        updatedData: {
          contract_type: values.contractType,
          contract_duration: values.contractNumber,
          about_to_expire: values.aboutToExpire,
        },
      };
      await updateContract(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Contract is updated successfully.");
    } catch (err) {
      console.error("Failed to update contract:", err);
      message.error("Failed to update contract.");
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  return (
    <>
      <Table
        style={{ marginTop: "16px" }}
        columns={columns}
        dataSource={tableData} // Use data directly from the API
        loading={isLoadingContract}
        pagination={false} // Disable built-in pagination
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        onChange={handlePaginationChange}
        total={contractSettingList?.paginator?.totalItems}
      />
      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
        confirmLoading={isDeleting} // Show loading state during delete
      />
      <Modal
        width={880}
        className="bu-add-modal"
        onCancel={handleEditCancel}
        open={isEditModalOpen}
        closeIcon={false}
        footer={null}>
        <Card title="Edit Type of Contract">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ contractType: "day" }}>
            <Form.Item label="Contract Duration">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  noStyle
                  name="contractNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input the contract number!",
                    },
                  ]}>
                  <Input
                    type="number"
                    min={1}
                    style={{ height: 44, width: "80%" }}
                  />
                </Form.Item>
                <div className="select-rate-type">
                  <Form.Item
                    name="contractType"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Please choose the contract type!",
                      },
                    ]}>
                    <Select style={{ width: 110, height: 44 }}>
                      <Select.Option value="day">Day(s)</Select.Option>
                      <Select.Option value="month">Month(s)</Select.Option>
                      <Select.Option value="year">Year(s)</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Space.Compact>
            </Form.Item>
            <Form.Item label="About to Expire (days)" name="aboutToExpire">
              <Input type="number" min={1} style={{ height: 44 }} />
            </Form.Item>
          </Form>
          <div className="add-branch-btn-gp">
            <Button onClick={handleEditCancel} type="text" className="btn">
              Cancel
            </Button>
            <Button
              onClick={handleEditOk}
              className="btn filter"
              loading={isContractLoading}>
              Update
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default ContractSettingList;
