export const theme = {
  components: {
    Input: {
      colorBgContainer: "#F7F8F8", // Specific token for Input background
    },
    Select: {
      colorBgContainer: "#F7F8F8",
    },
  },
};
