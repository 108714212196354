import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Select,
  Table,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useGetAllBranchesQuery } from "../property/propertyApi";
const { Option } = Select;
const AddNewRole = () => {
  const {
    data: BranchList,
    isLoading: isBranchLoading,
    error: isBranchError,
  } = useGetAllBranchesQuery();

  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "40%",
    },
    {
      title: "CREATE",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "READ",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "WRITE",
      dataIndex: "write",
      key: "write",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
  ];

  const tableData = [
    {
      key: "1",
      module: "Dashboard",
    },
    {
      key: "2",
      module: "Contract Manage",
    },
    {
      key: "3",
      module: "Tenant",
    },
    {
      key: "4",
      module: "Property",
    },
    {
      key: "5",
      module: "Utility",
    },
    {
      key: "6",
      module: "Invoice",
    },
    {
      key: "7",
      module: "Maintenance",
    },
    {
      key: "8",
      module: "Announcement",
    },
    {
      key: "9",
      module: "Administrator",
    },
    {
      key: "10",
      module: "Reporting",
    },
  ];
  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: (
              <Link to="/cd/adminstrator/role-permission">
                Role & Permissions
              </Link>
            ),
          },
          {
            title: "Add New Role",
          },
        ]}></Breadcrumb>
      <Card style={{ marginBottom: "16px" }} title="Create Role">
        <Form className="email-setting-form" layout="vertical">
          <Form.Item style={{ width: 520 }} label="New Role">
            <Input placeholder="Role" style={{ height: 44 }} />
          </Form.Item>
          <Form.Item label="Branch" name="branch">
            <Select
              loading={isBranchLoading}
              mode="multiple"
              style={{ width: 520 }}
              placeholder="Select Business Unit"
              className="admin-bu-select">
              {isBranchError && (
                <Option disabled>Error loading Business Unit</Option>
              )}
              {!isBranchLoading && BranchList?.data?.length > 0 ? (
                BranchList?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch.name}
                  </Option>
                ))
              ) : (
                <Option disabled>Business Unit is not available.</Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      <Card className="permission-card">
        <p className="permission-header">Group of Permissions</p>
        <div className="permission-table">
          <Table
            pagination={false}
            dataSource={tableData}
            columns={columns}></Table>
        </div>
        <div className="permission-btn-group">
          <Button type="text" className="btn">
            Cancel
          </Button>
          <Button className="btn filter">Create</Button>
        </div>
      </Card>
    </div>
  );
};

export default AddNewRole;
