import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialToken = Cookies.get("token") ? Cookies.get("token") : null;
const initialToken2 = Cookies.get("token2") ? Cookies.get("token2") : null;
const initialSelectedBu = Cookies.get("selectedBu")
  ? Cookies.get("selectedBu")
  : null; // Initialize from cookies

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    email: null,
    token: initialToken,
    token2: initialToken2,
    name: null,
    businessUnit: [],
    selectedBu: initialSelectedBu, // Set initial state from cookies
    permissions: [],
  },
  reducers: {
    setCredentials: (state, action) => {
      const { email, accessToken, name, selectedBu, businessUnit } =
        action.payload;
      state.email = email;
      state.token = accessToken;
      state.name = name;
      state.selectedBu = selectedBu;
      state.businessUnit = businessUnit;
      Cookies.set("token", accessToken, { expires: 1 }); // Save token in cookies
      Cookies.set("selectedBu", selectedBu, { expires: 1 }); // Save selectedBu in cookies
    },

    logoutAccount: (state) => {
      state.email = null;
      state.token = null;
      state.token2 = null;
      state.name = null;
      state.businessUnit = [];
      state.selectedBu = null;
      Cookies.remove("token");
      Cookies.remove("token2");
      Cookies.remove("selectedBu"); // Remove selectedBu on logout
    },

    setToken2: (state, action) => {
      const token2 = action.payload;
      state.token2 = token2;
      Cookies.set("token2", token2, { expires: 1 }); // Save token2 in cookies
    },

    setselectedBu: (state, action) => {
      state.selectedBu = action.payload;
      Cookies.set("selectedBu", action.payload, { expires: 1 }); // Save selectedBu in cookies
    },

    tokenExpired: (state) => {
      state.email = null;
      state.token = null;
      state.token2 = null;
      state.name = null;
      state.businessUnit = [];
      state.selectedBu = null;
      Cookies.remove("token");
      Cookies.remove("token2");
      Cookies.remove("selectedBu"); // Remove selectedBu on token expiration
    },
  },
});

export const {
  setCredentials,
  logoutAccount,
  setToken2,
  setselectedBu,
  tokenExpired,
} = authSlice.actions;
export default authSlice.reducer;
