import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Upload,
  Select,
  message,
  Breadcrumb,
} from "antd";
import "../../property/styles.css"; // Ensure this path is correct.
import { isJPGorPNG } from "../../../utils";
import ImgCrop from "antd-img-crop";
import { useGetAllBUListQuery } from "../../../features/centralized/businessUnit/buApi";
import GenerateIcon from "../../../assets/icons/refresh.svg";
import { useAddNewCentralizedAdminMutation, useGetAllCentralizedRoleQuery } from "../../../features/centralized/centralizedAdminApi";

const { Option } = Select;

const AddNewAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [addNewAdmin, { isLoading }] = useAddNewCentralizedAdminMutation(); // Initialize the mutation

  const [imageObj, setImageObj] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [form] = Form.useForm();

  const {
    data: roleDataList,
    isLoading: isRoleLoading,
    error,
  } = useGetAllCentralizedRoleQuery();

  const {
    data: BUList,
    isLoading: isBUListLoading,
    error: isBUListError,
  } = useGetAllBUListQuery();

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setImgUrl(reader.result);
      },
      false
    );
    if (imageObj) {
      reader.readAsDataURL(imageObj);
    }
  }, [imageObj]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          preview: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const handleShowPreview = () => {
    setShowPreviewModal(true);
  };

  const handleFormFinish = async (values) => {
    try {
      // Include the uploaded profile image and selected role ID in the form values
      const payload = {
        name: values.name,
        email: values.email,
        phone_number: values.phone_number,
        password: values.password,
        role: values.role, // Role ID
        profile: imageObj,
        status: values.status,
      };
      const response = await addNewAdmin(payload).unwrap();
      console.log("response", response);

      message.success("Admin created successfully!");
      navigate(`/cd/adminstrator/list`);
    } catch (error) {
      message.error("Failed to create admin. Please try again.");
      console.error("Error creating admin:", error);
    }
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword }); // Update form field with new password
  };

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: "Admins",
          },
          {
            title: <Link to="/cd/adminstrator/list">Admin List</Link>,
          },
          {
            title: "Add New Admin",
          },
        ]}></Breadcrumb>
      <Card
        style={{ width: 880, margin: "auto" }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: "8px" }}>Add New Admin</span>
          </div>
        }>
        <Form form={form} layout="vertical" onFinish={handleFormFinish}>
          <Form.Item
            rules={[
              { required: true, message: "Please upload tenant profile" },
            ]}>
            <ImgCrop rotationSlider>
              <Upload
                onPreview={handleShowPreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                listType="picture-circle"
                accept=".png,.jpg,image/png,image/jpeg"
                multiple={false}
                maxCount={1}
                fileList={fileList}>
                {"+ Upload"}
              </Upload>
            </ImgCrop>
          </Form.Item>
          <div className="add-new-admin-form">
            <Form.Item label="Business Unit" name="bu_id">
              <Select
                loading={isBUListLoading}
                mode="multiple"
                style={{ width: 400 }}
                placeholder="Select Business Unit"
                className="admin-bu-select">
                {isBUListError && (
                  <Option disabled>Error loading Business Unit</Option>
                )}
                {!isBUListLoading && BUList?.data?.length > 0 ? (
                  BUList?.data?.map((bu) => (
                    <Option key={bu._id} value={bu._id}>
                      {bu.bu_name}
                    </Option>
                  ))
                ) : (
                  <Option disabled>Business Unit is not available.</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Role" name="role" className="add-new-admin-input">
              <Select
                loading={isRoleLoading}
                placeholder="Select a role"
                style={{ height: 44 }}>
                {error && <Option disabled>Error loading roles</Option>}
                {!isRoleLoading && roleDataList?.data?.length > 0 ? (
                  roleDataList.data.map((role) => (
                    <Option key={role._id} value={role._id}>
                      {role.role_name}
                    </Option>
                  ))
                ) : (
                  <Option disabled>No roles available</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item className="add-new-admin-input" label="Name" name="name">
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              className="add-new-admin-input"
              rules={[
                {
                  type: "email",
                },
              ]}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phone_number"
              className="add-new-admin-input">
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Status"
              name="status"
              className="add-new-admin-input">
              <Select placeholder="Status" style={{ height: 44 }}>
                <Option value="1">Active</Option>
                <Option value="2">Inactive</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Generate Password"
              name="password"
              style={{ width: 400 }}
              className="tenant-label-group">
              <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                <Form.Item name="password" style={{ flex: 1, margin: 0 }}>
                  <Input.Password
                    style={{ height: 44 }}
                    placeholder="Set Password"
                  />
                </Form.Item>
                <Button
                  style={{ width: 44, height: 44, backgroundColor: "#F7F8F8" }}
                  onClick={handleGeneratePassword}>
                  <img
                    style={{ width: 20, height: 20 }}
                    src={GenerateIcon}
                    alt="Generate"
                  />
                </Button>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}>
            <Button
              type="text"
              onClick={() => navigate(`/cd/adminstrator/list`)}
              className="btn">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddNewAdmin;
