import { Modal, Row, Col, Tag, Avatar, Card } from "antd";
import React from "react";

const AdminProfile = ({ visible, onCancel, selectedAdmin }) => (
  <Modal
    visible={visible}
    onCancel={onCancel}
    footer={null}
    width={730}
    centered
    className="bu-add-modal admin-profile"
  >
    <Card bordered={false}>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "20px", width: '130px' }}>
        <Avatar
          size={80}
          src={selectedAdmin?.profile || "https://via.placeholder.com/80"}
          style={{ border: "4px solid white", marginTop: '-65px', alignSelf: 'center', zIndex: 999 }}
        />
        <Tag style={{ fontSize: "14px", marginTop: "10px", color: 'blue', width: '130px', height: '37px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {selectedAdmin?.role || "Super Admin"}
        </Tag>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={8}><strong>Business Unit</strong></Col>
        <Col span={1}>:</Col>
        <Col span={15}>
          <p style={{ margin: 0 }}>Condo Management</p>
          <p style={{ margin: 0 }}>Shopping Mall Management</p>
        </Col>

        <Col span={8}><strong>Name</strong></Col>
        <Col span={1}>:</Col>
        <Col span={15}><p style={{ margin: 0 }}>{selectedAdmin?.name}</p></Col>

        <Col span={8}><strong>Email Address</strong></Col>
        <Col span={1}>:</Col>
        <Col span={15}><p style={{ margin: 0 }}>{selectedAdmin?.email}</p></Col>

        <Col span={8}><strong>Phone Number</strong></Col>
        <Col span={1}>:</Col>
        <Col span={15}><p style={{ margin: 0 }}>{selectedAdmin?.phone_number}</p></Col>

        <Col span={8}><strong>Status</strong></Col>
        <Col span={1}>:</Col>
        <Col span={15}>
          <Tag color={selectedAdmin?.status === "1" ? "green" : "red"}>
            {selectedAdmin?.status === "1" ? "Active" : "Inactive"}
          </Tag>
        </Col>
      </Row>
    </Card>
  </Modal>
);

export default AdminProfile;
