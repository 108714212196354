import React, { useState, useEffect, useMemo } from "react";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import {
  Layout,
  Menu,
  Avatar,
  Button,
  Dropdown,
  Modal,
  message,
  Divider,
  ConfigProvider,
} from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import "./main.css";
import { logoutAccount, setToken2 } from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useGetBUDataQuery } from "../../features/auth/authApi";
import Logo from "../../assets/Logo -01 1.png";
import ExternalLinkIcon from "../../assets/externalLink.svg";
import MenuItems from "./MenuItem";

const { Header, Content, Sider } = Layout;

const siderStyle = {
  font: "16px",
  overflow: "auto",
  minHeight: "100vh",
  position: "fixed",
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarColor: "unset",
  transition: "width 0.2s",
};

const App = () => {
  const userName = useSelector((state) => state.authSlice.name);
  const selectedBuId = useSelector((state) => state.authSlice.selectedBu);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState("1");
  const [openKeys, setOpenKeys] = useState([]);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const { data: buData } = useGetBUDataQuery(selectedBuId, {
    skip: !selectedBuId,
  });

  const permissions =
    buData?.data?.user?.role_permissions?.[0]?.permission || [];

  const items = useMemo(() => {
    if (permissions.length > 0) {
      return MenuItems({ permissions, selectedBuId });
    }
    return [];
  }, [permissions, selectedBuId]);

  useEffect(() => {
    const storedOpenKeys = localStorage.getItem("openKeys");
    if (storedOpenKeys) {
      setOpenKeys(JSON.parse(storedOpenKeys));
    }
  }, []);

  useEffect(() => {
    if (buData?.data?.token) {
      dispatch(setToken2(buData.data.token));
    }
  }, [buData, dispatch]);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    const updatedOpenKeys = latestOpenKey ? [latestOpenKey] : [];
    setOpenKeys(updatedOpenKeys);
    localStorage.setItem("openKeys", JSON.stringify(updatedOpenKeys));
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const profileItems = [
    { label: <a>Edit Profile</a>, key: "edit" },
    {
      label: <a onClick={() => setLogoutModalVisible(true)}>Log out</a>,
      key: "logout",
    },
  ];

  const handleLogout = () => {
    dispatch(logoutAccount());
    navigate("/login");
    message.success("You have successfully logged out.");
  };

  const handleCancelLogout = () => {
    setLogoutModalVisible(false);
  };

  const handleNavigateToMainDashboard = () => {
    navigate("/");
  };

  const LogoutModalForm = ({ visible, handleOk, handleCancel }) => (
    <Modal
      title="Are you sure you want to logout?"
      open={visible}
      okButtonProps={{ type: "danger" }}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
    />
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "box-shadow: 0px 3px 10px 0px #00000033;",
            headerPadding: 0,
            siderBg: "linear-gradient(128.88deg, #03045E 1.86%, #0057FF 100%)",
          },
          Menu: {
            itemSelectedBg: "#FFFFFF",
            itemBg: "transparent",
            itemSelectedColor: "#03045E",
            itemColor: collapsed ? "black" : "white",
            subMenuItemBg: "#e6f4ff",
            itemBorderRadius: "4px",
          },
        },
      }}
    >
      <Layout hasSider>
        <Sider
          style={siderStyle}
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          width={280}
          collapsedWidth={80}
        >
          <div
            style={{
              height: 100,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "auto" }}>
              {!collapsed && <img style={{ width: 120, height: 52 }} src={Logo} alt="Logo" />}
            </div>
            <Button
              style={{
                border: "1px solid white",
                width: "15px",
                height: "50px",
                borderTopLeftRadius: "50px",
                borderBottomLeftRadius: "50px",
                background: "#FFFFFF",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
              }}
              onClick={toggleCollapsed}
            >
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </Button>
          </div>
          <Divider style={{ backgroundColor: "white", margin: 0, marginBottom: 20 }} />
          <Menu
            selectedKeys={[selectedKey]}
            openKeys={openKeys}
            onClick={handleMenuClick}
            onOpenChange={handleOpenChange}
            mode="inline"
            items={items}
          />
        </Sider>

        <Layout style={{ marginInlineStart: collapsed ? 80 : 280 }}>
          <Header
            style={{
              position: "fixed",
              top: 0,
              left: collapsed ? 80 : 280,
              right: 0,
              height: 70,
              backgroundColor: "#f0f2f5",
              zIndex: 1000,
              padding: "10px 24px",
            }}
          >
            <div className="header">
              <div className="setting">
                <Button style={{ width: "147px", height: "39px" }} onClick={handleNavigateToMainDashboard}>
                  Main Dashboard
                  <img src={ExternalLinkIcon} alt="External Link Icon" style={{ width: "18px", height: "18px" }} />
                </Button>
              </div>
              <div className="user-setting">
                <Dropdown menu={{ items: profileItems }} trigger={["click"]} placement="bottomRight" arrow>
                  <a onClick={(e) => e.preventDefault()}>
                    <div className="user">
                      <Avatar size="small" icon={<UserOutlined />} />
                      <p className="user-profile" style={{ color: "black" }}>
                        {userName}
                      </p>
                    </div>
                  </a>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content className="content">
            <Outlet />
          </Content>
        </Layout>
        <LogoutModalForm
          visible={logoutModalVisible}
          handleOk={handleLogout}
          handleCancel={handleCancelLogout}
        />
      </Layout>
    </ConfigProvider>
  );
};

export default App;
