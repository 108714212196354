import { Breadcrumb, Button, Card, Form, Input } from "antd";
import React from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

const ViewBranch = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const branchData = location.state?.branch; // Retrieve the passed branch data
  
  const initialValues = {
    name: branchData?.branch || '',
    contact_person: branchData?.contact_person || '',
    email: branchData?.email || '',
    phone_number: branchData?.phone_number || '',
    city: branchData?.city || '',
    township: branchData?.township || '',
    address: branchData?.address || '' // Assuming address is also part of branchData
  };

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/property/add-project`}>Branches</Link>,
          },
          {
            title: "View Branch",
          },
        ]}
      />
      <Card title="View Branch">
        <Form layout="vertical" initialValues={initialValues}>
          <div className="add-branch-form">
            <Form.Item
              label="Branch / Project"
              name="name"
              className="add-branch-input"
            >
              <Input 
                placeholder="Branch Name" 
                style={{ height: 44 }} 
                readOnly
              />
            </Form.Item>
            <Form.Item
              label="Contact Person"
              name="contact_person"
              className="add-branch-input"
            >
              <Input 
                placeholder="Name" 
                style={{ height: 44 }} 
                readOnly
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              className="add-branch-input"
            >
              <Input 
                placeholder="Email" 
                style={{ height: 44 }} 
                readOnly
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              className="add-branch-input"
            >
              <Input 
                placeholder="Phone Number" 
                style={{ height: 44 }} 
                readOnly
              />
            </Form.Item>
            <Form.Item label="City" name="city" className="add-branch-input">
              <Input 
                placeholder="Select city name" 
                style={{ height: 44 }} 
                readOnly
              />
            </Form.Item>

            <Form.Item
              name="township"
              label="Township"
              className="add-branch-input"
            >
              <Input 
                placeholder="Select a township" 
                style={{ height: 44 }} 
                readOnly
              />
            </Form.Item>
          </div>
          <div className="add-branch-btn-gp">
            <Button onClick={() => navigate(`/${id}/property/add-project`)} className="btn">
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ViewBranch;
