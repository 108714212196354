import {
  Form,
  Input,
  Button,
  Select,
  message,
  ConfigProvider,
  Modal,
  Card,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  useAddNewFloorMutation,
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
} from "../propertyApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddNewFloor = ({ isModalVisible, handleOk, handleCancel }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: BranchListData,
    isLoading: branchLoading,
    error: branchError,
  } = useGetAllBranchesQuery();

  const [selectedBranch, setSelectedBranch] = useState(null);

  // Fetch buildings by branch ID when selectedBranch changes
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetBuildingsByBranchIdQuery(selectedBranch, {
      skip: !selectedBranch, // Skip the query if no branch is selected
    });

  const [addNewFloor, { isLoading }] = useAddNewFloorMutation(token);
  const [form] = Form.useForm();

  // Log building data for debugging
  useEffect(() => {
    if (buildingListData) {
      console.log("Building list data:", buildingListData);
    }
  }, [buildingListData]);

  const handleSubmit = async (values) => {
    const payload = {
      name: values.floorName,
      number_of_shop: values.numberOfShops,
      building: values.building,
      branch: values.branch,
    };
    try {
      await addNewFloor(payload).unwrap();
      form.resetFields(); // Clear form after successful submission
      message.success("Floor is added successfully.");
      handleOk();
    } catch (isAddingError) {
      const errorMessage =
        isAddingError?.data?.errors?.[0] || "Failed to add floor";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields on cancel
    handleCancel(); // Trigger the parent component's cancel handler
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
            colorTextPlaceholder: "#00062599",
          },
        }}
      >
        <Modal
          className="bu-add-modal"
          width={880}
          open={isModalVisible}
          onCancel={handleFormCancel}
          footer={null}
        >
          <Card title="Add New Floor">
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Form.Item
                  label="Project / Branch"
                  name="branch"
                  rules={[
                    {
                      required: true,
                      message: "Please select a branch",
                    },
                  ]}
                >
                  <Select
                    style={{ height: 44, width: 400 }}
                    placeholder="Select Project / Branch"
                    onChange={(value) => {
                      setSelectedBranch(value);
                      form.setFieldsValue({ building: null }); // Clear building when branch changes
                    }}
                    loading={branchLoading}
                  >
                    {BranchListData?.data?.map((branch) => (
                      <Option key={branch._id} value={branch._id}>
                        {branch?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Building"
                  name="building"
                  rules={[
                    {
                      required: true,
                      message: "Please select a building",
                    },
                  ]}
                >
                  <Select
                    style={{ height: 44, width: 400 }}
                    placeholder="Select a building"
                    loading={isLoadingBuildings}
                    disabled={!selectedBranch} // Disable if no branch is selected
                  >
                    {buildingListData?.data?.map((building) => (
                      <Option key={building._id} value={building._id}>
                        {building.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: 400 }}
                  label="Floor Name"
                  name="floorName"
                  rules={[
                    {
                      required: true,
                      message: "Please input the floor name",
                    },
                  ]}
                >
                  <Input style={{ height: 44 }} placeholder="Floor Name" />
                </Form.Item>
                <Form.Item
                  style={{ width: 400 }}
                  label="Number of Shops"
                  name="numberOfShops"
                  rules={[
                    {
                      required: true,
                      message: "Please input the number of shops",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    style={{ height: 44 }}
                    placeholder="Number of Shops"
                    min={1}
                  />
                </Form.Item>
              </div>
              <div className="add-branch-btn-gp">
                <Button onClick={handleFormCancel} type="text" className="btn">
                  Cancel
                </Button>
                <Button
                  type="text"
                  htmlType="submit"
                  className="btn filter"
                  loading={isLoading} // Show loading state on submit
                >
                  Create
                </Button>
              </div>
            </Form>
          </Card>
        </Modal>
      </ConfigProvider>
    </div>
  );
};

export default AddNewFloor;
