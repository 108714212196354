import { Breadcrumb } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { AddInvoiceSettingCard } from '../../features';

const AddInvoiceSetting = () => {
  const { id } = useParams();
  return (
    <div>
       <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/setting/invoice`}>Invoice Setting</Link>,
          },
          {
            title: "Add Invoice Setting",
          },
        ]}
      />
      <AddInvoiceSettingCard />
    </div>
  )
}

export default AddInvoiceSetting