import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Form, Select, Input, Button, Breadcrumb } from "antd";
import "./styles.css";

const EditDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { shop } = location.state || {}; // Retrieve the shop data from state

  if (!shop) {
    return <div>No data available</div>; // Handle the case where no data is available
  }

  const statusColor = shop.status === "Available" ? "green" : "red";
  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list`)}>
          Property List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Property</Breadcrumb.Item>
      </Breadcrumb>
      <Form layout="vertical">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "8px" }}>Edit Property</span>
            </div>
          }>
          <div className="view-detail-form">
            <Form.Item label="Property Type" className="input-group">
              <Select style={{ height: 44 }} />
            </Form.Item>

            <Form.Item label="Building" className="input-group">
              <Select value={shop.building} style={{ height: 44 }} />
            </Form.Item>

            <Form.Item label="Floor" className="input-group">
              <Select value={shop.floor} style={{ height: 44 }} />
            </Form.Item>

            <Form.Item label="Zone / Role" className="input-group">
              <Select value={shop.zone} style={{ height: 44 }} />
            </Form.Item>

            <Form.Item label="Shop Name" className="input-group">
            <Select value={shop.shopName} style={{ height: 44 }} />
          </Form.Item>

            <Form.Item label="Shop Code" className="input-group">
              <Input value={shop.code} style={{ height: 44 }} />
            </Form.Item>

            <Form.Item label="Total Area (sq ft)" className="input-group">
              <Input value={shop.area} style={{ height: 44 }} />
            </Form.Item>

            <Form.Item label="Price (MMK)" className="input-group">
              <Input value={shop.price} style={{ height: 44 }} />
            </Form.Item>
          </div>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}>
            <Link to={`/${id}/property/list`}>
              <Button type="text" className="btn">Cancel</Button>
            </Link>
            <Button className="btn update">Update</Button>
          </Form.Item>
        </Card>
      </Form>
    </>
  );
};

export default EditDetail;
