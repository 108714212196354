import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Select,
  Table,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useGetAllBUListQuery } from "../../../features/centralized/businessUnit/buApi";

const { Option } = Select;
const EditRole = () => {
  const {
    data: BUList,
    isLoading: isBUListLoading,
    error: isBUListError,
  } = useGetAllBUListQuery();
  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "40%",
    },
    {
      title: "CREATE",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "READ",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "WRITE",
      dataIndex: "write",
      key: "write",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
  ];

  const tableData = [
    {
      key: "1",
      module: "Dashboard",
    },
    {
      key: "2",
      module: "Contract Manage",
    },
    {
      key: "3",
      module: "Tenant",
    },
    {
      key: "4",
      module: "Property",
    },
    {
      key: "5",
      module: "Utility",
    },
    {
      key: "6",
      module: "Invoice",
    },
    {
      key: "7",
      module: "Maintenance",
    },
    {
      key: "8",
      module: "Announcement",
    },
    {
      key: "9",
      module: "Administrator",
    },
    {
      key: "10",
      module: "Reporting",
    },
  ];
  return (
    <div>
    <Breadcrumb
      style={{ marginBottom: "10px", cursor: "pointer" }}
      items={[
        {
          title: (
            <Link to="/cd/adminstrator/role-permission">
              Role & Permissions
            </Link>
          ),
        },
        {
          title: "Add New Role",
        },
      ]}></Breadcrumb>
    <Card style={{ marginBottom: "16px" }} title="Create Role">
      <Form className="email-setting-form" layout="vertical">
        <Form.Item style={{ width: 520 }} label="New Role">
          <Input placeholder="Role" style={{ height: 44 }} />
        </Form.Item>
        <Form.Item label="Business Unit" name="bu_id">
          <Select
            loading={isBUListLoading}
            mode="multiple"
            style={{ width: 520 }}
            placeholder="Select Business Unit"
            className="admin-bu-select">
            {isBUListError && (
              <Option disabled>Error loading Business Unit</Option>
            )}
            {!isBUListLoading && BUList?.data?.length > 0 ? (
              BUList?.data?.map((bu) => (
                <Option key={bu._id} value={bu._id}>
                  {bu.bu_name}
                </Option>
              ))
            ) : (
              <Option disabled>Business Unit is not available.</Option>
            )}
          </Select>
        </Form.Item>
      </Form>
    </Card>
    <Card className="permission-card">
      <p className="permission-header">Group of Permissions</p>
      <div className="permission-table">
        <Table
          pagination={false}
          dataSource={tableData}
          columns={columns}></Table>
      </div>
      <div className="permission-btn-group">
        <Button type="text" className="btn">
          Cancel
        </Button>
        <Button className="btn filter">Update</Button>
      </div>
    </Card>
  </div>
  )
}

export default EditRole