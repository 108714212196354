import { Card, Modal, Row, Col, Tag } from "antd";
import React from "react";

const ViewShop = ({ visible, onCancel, selectedShop }) => {
  
  return (
    <div>
      <Modal
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={730}
        centered
        className="bu-add-modal">
        <Card title="View Details">
          <div className="view-property-details">
            {selectedShop && (
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <p>
                    <strong>Property Type</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.propertyType}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Room Type</strong>
                  </p>
                </Col>

                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.roomType}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Project / Branch</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.branch}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Room / Shop Name</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.name}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Building</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.building}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Total Area</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={4}>
                  <p>{selectedShop.area} sq ft</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Floor</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.floor}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Price</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.price} MMK</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Zone / View</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>{selectedShop.zone}</p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Status</strong>
                  </p>
                </Col>
                <Col span={1}>
                  <p>:</p>
                </Col>
                <Col span={5}>
                  <p>
                    <Tag
                      color={
                        selectedShop.status === "Available" ? "green" : "red"
                      }>
                      {selectedShop.status}
                    </Tag>
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default ViewShop;
