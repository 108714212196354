import { DeleteOutlined, DownOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Table, message } from "antd";
import React, { useState } from "react";
import { DeleteModal } from "../../component";
import { useDeleteBranchMutation } from "./propertyApi";
import { useNavigate, useParams } from "react-router-dom";

const BranchesList = ({ tableData, loading }) => {
  const [deleteBranch, { isLoading: isDeleting }] = useDeleteBranchMutation();
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteBranch({ branchId: currentRecord.key }).unwrap();
        message.success("Branch is deleted successfully.");
        setIsModalOpen(false);
      } catch (err) {
        const errorMessage = err?.data?.errors?.[0] || "Failed to delete branch";
        message.error(errorMessage);
      }
    }
  };

  const onDelete = (record) => {
    showModal(record);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: (record) => {
        navigate(`/${id}/property/view-branch/${record.key}`, { state: { branch: record } }); // Pass the record data
      },
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => {
        navigate(`/${id}/property/edit-branch/${record.key}`, { state: { branch: record } }); // Pass the record data
      },
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => onDelete(record), // Implement the actual action
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      key: "contact_person",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Township",
      dataIndex: "township",
      key: "township",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={loading} // Show loading spinner if loading is true
        style={{ marginTop: "16px" }}
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
        confirmLoading={isDeleting}
      />
    </div>
  );
};

export default BranchesList;
