import { Table } from 'antd'
import React from 'react'

const Installments = () => {
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Outlet Name",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Down Payment Amount (MMK)",
      dataIndex: "downPaymentAmount",
      key: "downPaymentAmount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ]
  return (
    <div>
      <Table columns={columns} />
    </div>
  )
}

export default Installments