import { baseApi } from "../../app/baseApi";

export const centralizedAdminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdminList: builder.query({
      query: () => `/Administrator/list`,
      providesTags: ["CAdminList"],
    }),

    getCAdminById: builder.query({
      query:({adminId}) => `/main-Administrator/admin/edit/${adminId}`,
      providesTags: ["CAdminList"],
    }),

    getAllCentralizedRole: builder.query({
      query: () => `/main-Administrator/roles/list`,
      providesTags: ["CentralizedRoleList"],
    }),

    // getPermissionById: builder.query({
    //   query: ({ roleId }) =>
    //     `/Administrator/roles/get-permission-role/edit/${roleId}`,
    //   providesTags: ["PermissionList"],
    // }),

    // addNewRole: builder.mutation({
    //   query: ({ name }) => ({
    //     url: `/Administrator/roles/create`,
    //     method: "POST",
    //     body: {
    //       name,
    //     },
    //   }),
    //   invalidatesTags: ["RoleList"],
    // }),

    addNewCentralizedAdmin: builder.mutation({
      query: (params) => {
        const formData = new FormData();
    
        // Append each field directly to the FormData object
        formData.append("name", params.name);
        formData.append("email", params.email);
        formData.append("phone_number", params.phone_number);
        formData.append("password", params.password);
        formData.append("adminRole", params.role);
        formData.append("status", params.status);
    
        // Append the profile image/file as binary data
        if (params.profile) {
          formData.append("profile", params.profile); // Assuming `params.profile` is a File object
        }
    
        return {
          url: `/main-Administrator/admin/create`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          body: formData,
        };
      },
      invalidatesTags: ["CAdminList"],
    }),

    // addPermission: builder.mutation({
    //   query: ({adminId, updatedData}) => ({
    //     url: `/Administrator/roles/permission-role/create/${adminId}`,
    //     method: "POST",
    //     body: updatedData, 
    //   }),
    //   invalidatesTags: ["PermissionList", "RoleList"]
    // }),
    
    updateCAdminData: builder.mutation({
      query: ({ adminId, updatedData }) => ({
        url: `/main-Administrator/admin/update/${adminId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit admin failed:", error);
      },
      invalidatesTags: ["CAdminList"],
    }),

    // updateAdminPassword: builder.mutation({
    //   query: ({adminId, updatedData}) => ({
    //     url: `/Administrator/change-password/${adminId}`,
    //     method: "PATCH",
    //     body: updatedData,
    //   }),
    //   onError: (error) => {
    //     console.error("Change Password failed:", error);
    //   },
    //   invalidatesTags:["AdminList"]
    // }),

    // deleteAdmin: builder.mutation({
    //   query: ({ adminId }) => ({
    //     url: `/Administrator/delete/${adminId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["AdminList"],
    // }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllAdminListQuery,
  useGetAllCentralizedRoleQuery,
  useAddNewCentralizedAdminMutation,
  useGetCAdminByIdQuery,
  useUpdateCAdminDataMutation
} = centralizedAdminApi;
