import {
  Table,
  Space,
  Dropdown,
  Button,
  message,
  Modal,
  Card,
  Form,
  Input,
} from "antd";
import React, { useState } from "react";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDeleteBUMutation, useGetAllBUListQuery, useUpdateBUMutation } from "./buApi";
import { DeleteModal, ReusablePagination } from "../../../component";

const BUTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [form] = Form.useForm();
  const token = useSelector((state) => state.authSlice.token);
  const { data: buList, isLoading } = useGetAllBUListQuery(token);
  const [deleteBU, { isLoading: isDeleting }] = useDeleteBUMutation();
  const [updateBU, { isLoading: isUpdating }] = useUpdateBUMutation();

  const buData = buList?.data?.map((bu) => ({
    key: bu._id,
    buName: bu.bu_name,
  }));

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteBU({ buId: currentRecord.key }).unwrap();
        message.success("Business Unit deleted successfully.");
        setIsModalOpen(false);
      } catch (err) {
        const errorMessage = err?.data?.errors?.[0] || "Failed to delete Business Unit";
        message.error(errorMessage);
      }
    }
  };

  const onDelete = (record) => {
    showModal(record);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({ bu_name: record.buName }); // Set form values for editing
    setIsEditModalOpen(true);
  };

  const handleEditCancel = () => {
    form.resetFields(); // Clear form fields
    setIsEditModalOpen(false);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = { bu_name: values.bu_name };

      await updateBU({ buId: currentRecord.key, updatedData }).unwrap();
      message.success("Business Unit updated successfully.");
      setIsEditModalOpen(false);
      form.resetFields();
    } catch (err) {
      const errorMessage = err?.data?.errors?.[0] || "Failed to update Business Unit";
      message.error(errorMessage);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: showEditModal,
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: onDelete,
    },
  ];

  const columns = [
    {
      title: "Business Unit",
      dataIndex: "buName",
      key: "buName",
    },
    {
      title: "",
      key: "operation",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight"
          >
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = buData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <Table pagination={false} dataSource={paginatedData} columns={columns} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={buList?.paginator?.totalItems}
        onChange={handlePaginationChange}
      />

      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
        confirmLoading={isDeleting}
      />

      <Modal
        className="bu-add-modal"
        width={880}
        open={isEditModalOpen}
        footer={null}
        onCancel={handleEditCancel}
        closeIcon={false}
      >
        <Card title="Edit Business Unit">
          <Form form={form} layout="vertical" onFinish={handleEditOk}>
            <Form.Item
              label="Business Unit"
              name="bu_name"
              rules={[{ required: true, message: "Please enter Business Unit name" }]}
            >
              <Input placeholder="Business Unit" style={{ height: "44px" }} />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleEditCancel} type="text" className="btn">
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isUpdating}
                className="btn filter"
              >
                Update
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default BUTable;
