import { Button, Card, Form, Input, Modal, message } from "antd";
import React from "react";
import { useAddNewRoomTypeMutation } from "./roomSettingApi"; // Import the mutation hook

const AddNewRoomType = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [addNewRoomType, { isLoading, error }] = useAddNewRoomTypeMutation(); 

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      await addNewRoomType({ room_type: values.roomType }).unwrap();
      message.success("Room type created successfully!");
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(
        `Failed to create new room type: ${error?.data?.message || "Unknown error"}`
      );
    }
  };

  return (
    <div>
      <Modal
        width={880}
        open={visible}
        onCancel={onClose}
        className="bu-add-modal"
        footer={null}>
        <Card title="Add Room Type">
          <Form form={form} layout="vertical">
            <Form.Item
              label="Room Type"
              name="roomType"
              rules={[{ required: true, message: "Please enter a room type" }]}>
              <Input style={{ height: 44 }} />
            </Form.Item>
            <div className="add-property-div">
              <Button onClick={onClose} type="cancel" className="btn">
                Cancel
              </Button>
              <Button
                onClick={handleCreate}
                className="filter btn"
                loading={isLoading}
                type="primary">
                CREATE
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default AddNewRoomType;
