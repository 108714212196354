import React, { useState } from "react";
import {
  Invoices,
  Maintenance,
  Profile,
  Property,
  Reminder,
  ResetPassword,
  PropertyDetail,
  MaintenanceDetail,
  RemainderDetail,
  AddNewReminder,
} from "../index";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Tabs, Breadcrumb } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TbReportMoney } from "react-icons/tb";
import PropertyEdit from "./tabs/PropertyEdit";
import Installments from "./tabs/Installments";
// import EditProperty from "./tabs/EditProperty";

const TenantDetails = () => {
  const [tab, setTab] = useState("1");
  const [propertyView, setPropertyView] = useState("list");
  const [propertyType, setPropertyType] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const location = useLocation();
  const tenantId = location.state?.tenantId;
  const { id } = useParams();

  const [maintenanceView, setMaintenanceView] = useState("list");

  const [remainderView, setRemainderView] = useState("list");

  const navigate = useNavigate();

  const handleViewDetail = ({ propertyId, type }) => {
    setPropertyId(propertyId);
    setPropertyType(type);
    setPropertyView("detail");
  };

  const handleEditDetail = ({propertyId, type }) => {
    setPropertyId(propertyId);
    setPropertyView("edit");
  }

  const handleMaintenanceViewDetail = () => {
    setMaintenanceView("detail");
  };

  const handleRemainderViewDetail = () => {
    setRemainderView("detail");
  };

  const items = [
    {
      key: "1",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PermIdentityOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Profile</p>
        </div>
      ),
      children: <Profile tenantId={tenantId} />,
    },
    {
      key: "2",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LayersOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Property</p>
        </div>
      ),
      children:
      propertyView === "list" ? (
        <Property tenantId={tenantId} onEditDetail={handleEditDetail} onViewDetail={handleViewDetail} />
      ) : propertyView === "edit" ? (
        <PropertyEdit 
          propertyId={propertyId} 
          onBack={() => setPropertyView("list")} 
        />
      ) : (
        <PropertyDetail
          propertyId={propertyId}
          type={propertyType}
          onBack={() => setPropertyView("list")}
        />
      ),
    },  
    {
      key: "3",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TbReportMoney style={{ width:'22px', height:'22px', margin: "auto" }} />
          &nbsp;<p>Installment</p>
        </div>
      ),
      children: <Installments />,
    },  
    {
      key: "4",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AttachMoneyOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Invoices</p>
        </div>
      ),
      children: <Invoices />,
    },
    {
      key: "5",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <BuildOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Maintenance</p>
        </div>
      ),
      children:
        maintenanceView === "list" ? (
          <Maintenance onViewDetail={handleMaintenanceViewDetail} />
        ) : (
          <MaintenanceDetail onBack={() => setMaintenanceView("list")} />
        ),
    },
    {
      key: "6",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NotificationsOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Reminder</p>
        </div>
      ),
      children:
        remainderView === "list" ? (
          <Reminder
            onViewDetail={handleRemainderViewDetail}
            onBack={() => setRemainderView("list")}
          />
        ) : (
          <RemainderDetail onBack={() => setRemainderView("list")} />
        ),
    },
    {
      key: "7",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LockOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Reset Password</p>
        </div>
      ),
      children: <ResetPassword tenantId={tenantId}/>,
    },
  ];

  return (
    <>
    <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
    <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/list`)}>
      Tenant List
    </Breadcrumb.Item>
    <Breadcrumb.Item>Tenant Details</Breadcrumb.Item>
  </Breadcrumb>
      <Tabs
        onChange={(key) => {
          setTab(key);
          setPropertyView("list");
        }}
        activeKey={tab}
        items={items}
      />
    </>
  );
};

export default TenantDetails;
