import { Card, Form, Input, Select, Space, Button, message } from "antd";
import React from "react";
import { IoWarning } from "react-icons/io5";
import { useUpdateInvoiceSettingMutation } from "./invoiceSettingApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditInvoiceSettingCard = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { record } = location.state || {};

  const [updateInvoiceSetting, { isLoading }] =
    useUpdateInvoiceSettingMutation();

  const handleUpdate = async () => {
    try {
      // Validate form and get updated values
      const updatedData = await form.validateFields();

      // Call the API with the updated data
      await updateInvoiceSetting({
        invoiceId: record.key,
        updatedData,
      }).unwrap();
      message.success("Invoice setting updated successfully!");
      navigate(`/${id}/setting/invoice`);
    } catch (error) {
      message.error("Failed to update invoice setting. Please try again.");
    }
  };
 
  const handleCancel = () => {
    navigate(`/${id}/setting/invoice`);
  }
  return (
    <div>
      <Card title="Edit Invoice Setting">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            invoice_type: record?.invoiceType,
            invoice_number_padding_left: record?.paddingLeft,
            invoice_number_prefix: record?.prefix,
            invoice_number_suffix: record?.suffix,
            invoice_validity: record?.validity,
            due_validity: record?.due_validity,
            remainder_days: record?.reminder,
            penalty_fee: record?.penalty_fee,
            penalty_type: record?.penalty_type,
          }}>
          <div className="add-branch-form">
            <Form.Item
              name="invoice_type"
              className="add-branch-input"
              label="Invoice Type"
              rules={[{ required: true, message: "Invoice Type is required" }]}>
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_number_padding_left"
              className="add-branch-input"
              label="Invoice Number Padding Left Zeros (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_number_prefix"
              className="add-branch-input"
              label="Invoice Number Prefix (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_number_suffix"
              className="add-branch-input"
              label="Invoice Number Suffix (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="invoice_validity"
              className="add-branch-input"
              label="Invoice Validity (days) (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="due_validity"
              className="add-branch-input"
              label="Due Validity (days) (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="remainder_days"
              className="add-branch-input"
              label="Reminder (days) (Optional)">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item className="add-branch-input" label="Late Fee (Optional)">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item name="penalty_fee" noStyle>
                  <Input style={{ height: 44, width: "258px" }} />
                </Form.Item>
                <div className="select-rate-type">
                  <Form.Item name="penalty_type" noStyle>
                    <Select style={{ width: 130, height: 44 }}>
                      <Select.Option value="flat-mmk">Flat (MMK)</Select.Option>
                      <Select.Option value="flat-usd">Flat (USD)</Select.Option>
                      <Select.Option value="percent">Percent (%)</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Space.Compact>
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "16px",
            }}>
            <p
              style={{
                margin: "0",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                fontWeight: 600,
              }}>
              <IoWarning
                style={{ width: "24px", height: "24px", color: "#FFB326" }}
              />
              Reminder(days): Remind to admin before invoice validity.
            </p>
            <div>
              <Button type="text" className="btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="btn filter"
                type="primary"
                loading={isLoading}
                onClick={handleUpdate}>
                Update
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default EditInvoiceSettingCard;
