import { Card, ConfigProvider, Form, Input, Radio, Select, Space } from "antd";
import React, { useEffect, useState } from "react";

const { Option } = Select;

const OwnerPaymentCard = ({ total, advanced, onPaymentChange }) => {
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState("MMK"); // State to track selected currency
  const [paymentType, setPaymentType] = useState("Fully"); // State to track payment type

  useEffect(() => {
    if (total || advanced) {
      form.setFieldsValue({
        totalAmount: total,
        advancedPayment: advanced,
      });
    }
  }, [total, advanced, form]);

  const handleValuesChange = (changedValues, allValues) => {
    const { totalAmount, rateValue, rateType, paymentDuration } = allValues;

    let remainingAmount = totalAmount || 0;
    if (rateType === "flat") {
      remainingAmount -= rateValue || 0;
    } else if (rateType === "percent") {
      remainingAmount -= ((rateValue || 0) / 100) * (totalAmount || 0);
    }

    let monthlyPayment = paymentDuration
      ? remainingAmount / paymentDuration
      : 0;

    form.setFieldsValue({
      remainingAmount: Math.max(remainingAmount, 0).toFixed(2),
      monthlyPayment: Math.max(monthlyPayment, 0).toFixed(2),
    });

    const updatedValues = {
      ...allValues,
      remainingAmount,
      monthlyPayment,
      currency,
      paymentType,
    };

    if (onPaymentChange) {
      onPaymentChange(updatedValues); // Notify parent of changes
    }
  };

  const handleCurrencyChange = (value) => {
    setCurrency(value); // Update currency when changed
    const updatedValues = { ...form.getFieldsValue(), currency: value };
    if (onPaymentChange) {
      onPaymentChange(updatedValues); // Notify parent of currency change
    }
  };

  const handlePaymentTypeChange = (e) => {
    const value = e.target.value;
    setPaymentType(value); // Update payment type
    if (onPaymentChange) {
      const updatedValues = { ...form.getFieldsValue(), paymentType: value };
      onPaymentChange(updatedValues); // Notify parent of payment type change
    }
  };

  return (
    <div className="payment-card">
      <Form layout="vertical" form={form} onValuesChange={handleValuesChange}>
        <Card
          style={{ marginTop: "16px" }}
          title="Payment Type"
          extra={
            <div style={{ display: "flex" }}>
              <Form.Item name="payment_type">
                <Radio.Group
                  onChange={handlePaymentTypeChange}
                  value={paymentType}>
                  <Radio value="Fully">Fully</Radio>
                  <Radio value="Installment">Installment</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="type">
                <Select placeholder="MMK" onChange={handleCurrencyChange}>
                  <Option value="MMK">MMK</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </div>
          }>
          {paymentType === "Fully" ? (
            <div className="add-branch-form">
              <Form.Item
                className="add-branch-input"
                name="totalAmount"
                label={`Total Amount (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  placeholder={`Enter total amount (${currency.toUpperCase()})`}
                  style={{ height: 44 }}
                />
              </Form.Item>
            </div>
          ) : (
            <div className="add-branch-form">
              <Form.Item
                className="add-branch-input"
                name="totalAmount"
                label={`Total Amount (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  placeholder={`Enter total amount (${currency.toUpperCase()})`}
                  style={{ height: 44 }}
                />
              </Form.Item>
              <Form.Item className="add-branch-input" label="Down Payment">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="rateValue"
                    noStyle
                    rules={[
                      { required: true, message: "Rate value is required" },
                    ]}>
                    <Input
                      type="number"
                      style={{ height: 44 }}
                      placeholder="Number"
                    />
                  </Form.Item>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorTextPlaceholder: "#fff",
                      },
                    }}>
                    <Form.Item
                      name="rateType"
                      noStyle
                      rules={[
                        { required: true, message: "Rate type is required" },
                      ]}>
                      <Select
                        style={{ width: "110px", height: 44 }}
                        placeholder="Rate Type">
                        <Option value="flat">Flat</Option>
                        <Option value="percent">Percent</Option>
                      </Select>
                    </Form.Item>
                  </ConfigProvider>
                </Space.Compact>
              </Form.Item>
              <Form.Item
                className="add-branch-input"
                name="remainingAmount"
                label={`Remaining Amount (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  readOnly
                  placeholder={`Remaining amount (${currency.toUpperCase()})`}
                  style={{ height: 44 }}
                />
              </Form.Item>
              <Form.Item
                className="add-branch-input"
                name="paymentDuration"
                label="Payment Duration (months)">
                <Input
                  type="number"
                  placeholder="No. of months"
                  style={{ height: 44 }}
                />
              </Form.Item>
              <Form.Item
                className="add-branch-input"
                name="monthlyPayment"
                label={`Monthly Payment (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  readOnly
                  placeholder="Monthly payment"
                  style={{ height: 44 }}
                />
              </Form.Item>
            </div>
          )}
        </Card>
      </Form>
    </div>
  );
};

export default OwnerPaymentCard;
