import { DownOutlined, EyeOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Space, Table, Tag } from "antd";
import React from "react";

const MaintenanceRequest = () => {
  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Outlet Name",
      dataIndex: "outletName",
      key: "outletName",
    },
    {
      title: "Type of Issue",
      dataIndex: "typeOfIssue",
      key: "typeOfIssue",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags?.map((tag) => {
            let color;
            switch (tag) {
              case "Processing":
                color = "#FF9F43";
                break;
              case "Finished":
                color = "#7367F0";
                break;
              case "Pending":
                color = "red";
                break;
              default:
                color = "red";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: (
        <div className="setting-icon">
          <SettingOutlined />
        </div>
      ),
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EyeOutlined /> View
                    </span>
                  ),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: 1,
      date: "8/9/2024",
      tenantName: "Name",
      outletName: "Outlet 1",
      typeOfIssue: "Issue #1",
      tags: ["Processing"],
    },
    {
      key: 2,
      date: "10/9/2024",
      tenantName: "Name",
      outletName: "Outlet 2",
      typeOfIssue: "Issue #2",
      tags: ["Pending"],
    },
    {
      key: 3,
      date: "10/9/2024",
      tenantName: "Name",
      outletName: "Outlet 3",
      typeOfIssue: "Issue #3",
      tags: ["Finished"],
    },
  ];

  return (
    <div className="dashboard-card">
      <Card title="Maintenance Request">
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </Card>
    </div>
  );
};

export default MaintenanceRequest;
