import React, { useEffect, useState } from "react";
import { AddNewTenantInfoCard, PropertyInformation } from "../../component";
import { Breadcrumb, Button, Form, message } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const NewContract = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [formState, setFormState] = useState([]);
  // Initialize state from location or defaults
  const [contractData, setContractData] = useState({
    properties: location.state?.properties || [],
    tenantType: location.state?.tenantType,
    newTenantInfo: {}, // Tenant info starts empty
    billingInfo: {},
  });
  console.log("formState", formState);
  
  
  useEffect(() => {
    if (location.state) {
      setContractData((prev) => ({
        ...prev,
        ...location.state,
      }));
    }
  }, [location.state]);

  console.log("properties from new contract", contractData?.properties);
  
  
  // Validation to check if tenant info is filled
  const isTenantInfoValid = () => {
    const { tenant_name, phoneNumber, email, city, township, address } =
      contractData.newTenantInfo;
    return (
      tenant_name &&
      phoneNumber &&
      email &&
      city &&
      township &&
      address
    );
  };
  const handleNextPage = () => {
    form
      .validateFields()
      .then(() => {
        // Ensure formState contains data
        const isFormStateValid = Object.keys(formState).length > 0;
        console.log("formState before append:", formState);
  
        if (isFormStateValid) {
          // Update properties
          const updatedProperties = [...contractData.properties, formState];
          console.log("updatedProperties:", updatedProperties);
  
          // Update contractData state
          setContractData((prev) => ({
            ...prev,
            properties: updatedProperties,
          }));
        }
  
        // Navigate with updated properties
        navigate(`/${id}/contract/list/add-new-contract`, {
          state: {
            ...contractData,
            properties: isFormStateValid
              ? [...contractData.properties, formState]
              : contractData.properties,
          },
        });
      })
      .catch((errorInfo) => {
        message.error("Please fill out all required fields correctly before proceeding.");
      });
  };
  
  const handleCancel = () => {
    navigate(`/${id}/contract/list`)
  }

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          { title: <Link to={`/${id}/contract/list`}>Contract List</Link> },
          { title: "Add New Property Information" },
        ]}
      />
      <AddNewTenantInfoCard
      form={form}
        tenantInfo={contractData.newTenantInfo}
        tenantType={contractData.tenantType}
        setTenantType = {(tenantType) => setContractData((prev) => ({...prev, tenantType}))}
        setTenantInfo={(newTenantInfo) =>
          setContractData((prev) => ({ ...prev, newTenantInfo }))
        }
        billingInfo={contractData.billingInfo}
        setBillingInfo={(billingInfo) =>
          setContractData((prev) => ({ ...prev, billingInfo }))
        }
      />
      <PropertyInformation
        properties={contractData.properties}
        setFormState={setFormState}
        initialProperties={location.state?.properties || []}
        setProperties={(properties) =>
          setContractData((prev) => ({ ...prev, properties }))
        }
      />
      <div className="add-branch-btn-gp">
        <Button onClick={handleCancel} className="btn" type="text">
          Cancel
        </Button>
        <Button
          onClick={handleNextPage}
          className="btn filter"
          disabled={!isTenantInfoValid()} // Disable button if tenant info is incomplete
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default NewContract;
