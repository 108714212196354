import { baseApi } from "../../../app/baseApi";

export const buApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBUList: builder.query({
      query: () => `/business-unit`,
      providesTags: ["BUList"],
    }),

    addNewBU: builder.mutation({
      query: ({ bu_name }) => ({
        url: `/business-unit/create`,
        method: "POST",
        body: {
          bu_name
        },
      }),
      invalidatesTags: ["BUList"],
    }),

    updateBU: builder.mutation({
      query: ({ buId, updatedData }) => ({
        url: `/business-unit/update/${buId}`,
        method: "PATCH",
        body: updatedData,
      }),
      invalidatesTags: ["BUList"],
    }),

    deleteBU: builder.mutation({
      query: ({ buId }) => ({
        url: `/business-unit/delete/${buId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BUList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBUListQuery,
  useAddNewBUMutation,
  useDeleteBUMutation,
  useUpdateBUMutation
} = buApi;
