import { Button, Table, Dropdown, Space, Form, Select, Tag } from "antd";
import React, { useState, useEffect } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import {
  useGetAllBranchesQuery,
  useGetAllBuildingQuery,
  useGetAllFloorListQuery,
  useGetAllShopsQuery,
  useGetAllZoneQuery,
} from "../../features/property/propertyApi";
import { EyeOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {
  DeleteModal,
  FilterCard,
  PageHeader,
  ReusablePagination,
  SearchFilter,
} from "../../component/index";
import ViewProperty from "./ViewProperty";
import { useGetAllRoomSettingQuery } from "../../features/setting/roomSettingApi";

const { Option } = Select;

const PropertyList = () => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterShop, setFilterShop] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);

  const token = useSelector((state) => state.authSlice.token2);
  const { data: shopListData, isLoading: isLoadingShops } =
    useGetAllShopsQuery(token);
  const { data: branchListData, loading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, loading: isLoadingBuildings } =
    useGetAllBuildingQuery();
  const { data: floorListData, loading: isLoadingFloors } =
    useGetAllFloorListQuery();
  const { data: zoneListData, loading: isLoadingZones } = useGetAllZoneQuery();
  const { data: roomTypeData, loading: isLoadingRoomType } =
    useGetAllRoomSettingQuery();
    
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleViewDetails = (shop) => {
    setSelectedShop(shop); // Set the selected shop to show details
    setIsViewModalOpen(true); // Open the modal when a shop is selected
  };

  const hanleViewCancel = () => {
    setIsViewModalOpen(false);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value); // Update selected branch
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
  };

  const handleZoneChange = (value) => {
    setSelectedZone(value);
  };

  const handleRoomTypeChange = (value) => {
    setSelectedRoomType(value);
  };

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    setSelectedBranch(formValues.branch);
    setSelectedBuilding(formValues.building);
    setSelectedFloor(formValues.floor);
    setSelectedZone(formValues.zone);
    setSelectedPropertyType(formValues.propertyType);
    setSelectedStatus(formValues.status);
    setSelectedRoomType(formValues.roomType);
  };

  const handleClearFilters = () => {
    form.resetFields();
    setSearchTerm("");
    setSelectedBranch(null);
    setSelectedBuilding(null);
    setSelectedFloor(null);
    setSelectedZone(null);
    setSelectedPropertyType(null);
    setSelectedStatus(null);
    setSelectedRoomType(null);
  };

  useEffect(() => {
    if (shopListData) {
      const filteredData = shopListData?.data?.filter((shop) => {
        const matchesSearch = shop.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesBranch = selectedBranch
          ? shop.branch?._id === selectedBranch
          : true;
        const matchesBuilding = selectedBuilding
          ? shop?.building?._id === selectedBuilding
          : true;
        const matchesFloor = selectedFloor
          ? shop?.floor?._id === selectedFloor
          : true;
        const matchesZone = selectedZone
          ? shop?.zone?._id === selectedZone
          : true;
        const matchesPropertyType = selectedPropertyType
          ? shop?.property_type === selectedPropertyType
          : true;
        const matchesStatus =
          selectedStatus !== null ? shop?.status === selectedStatus : true;
        const matchesRoomType = selectedRoomType
          ? shop?.room_type?._id === selectedRoomType
          : true;
        return (
          matchesSearch &&
          matchesBranch &&
          matchesBuilding &&
          matchesFloor &&
          matchesZone &&
          matchesPropertyType &&
          matchesStatus &&
          matchesRoomType
        );
      });
      setFilterShop(filteredData);
    }
  }, [
    searchTerm,
    shopListData,
    selectedBranch,
    selectedBuilding,
    selectedFloor,
    selectedZone,
    selectedPropertyType,
    selectedStatus,
    selectedRoomType,
  ]);
  const propertyTypeMap = {
    1: "Rent",
    2: "Sell",
    3: "Free Of Charge (FOC)",
  };

  const tableData = filterShop?.map((shop, index) => ({
    key: shop?._id,
    no: index + 1,
    branch: shop?.branch?.name,
    branchId: shop?.branch?._id,
    shop: shop?.name,
    property_type: propertyTypeMap[shop?.property_type] || "Unknown",
    building: shop?.building?.name,
    floor: shop?.floor?.name,
    shopName: shop?.name,
    code: shop?.code,
    area: shop?.total_area,
    price: shop?.price,
    zone: shop?.zone?.name,
    roomType: shop?.room_type?.room_type,
    status: shop?.status === 1 ? "Available" : "Unavailable",
  }));

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> &nbsp; View
        </span>
      ),
      onClick: (shop) => handleViewDetails(shop),
    },
    // {
    //   key: "2",
    //   label: (
    //     <span>
    //       <EditOutlined /> &nbsp; Edit
    //     </span>
    //   ),
    //   onClick: (shop) =>
    //     navigate(`edit-details/${shop.key}`, { state: { shop } }),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       <img src={TransferIcon} alt="transfer icon" /> &nbsp; Transfer Owner
    //     </div>
    //   ),
    //   onClick: () => navigate("transfer-owner"),
    // },
    // {
    //   key: "4",
    //   label: (
    //     <span>
    //       <img src={OwnerHistoryIcon} alt="owner-history" /> &nbsp; Owner
    //       History
    //     </span>
    //   ),
    //   onClick: () => navigate("owner-history"),
    // },
    {
      key: "5",
      label: (
        <span>
          <DeleteOutlined /> &nbsp; Delete
        </span>
      ),
      onClick: () => showModal(),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Room / Shop Name",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Project / Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Room Type",
      dataIndex: "roomType",
      key: "roomType",
    },
    {
      title: "Total Area (sq ft)",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Property Type",
      dataIndex: "property_type",
      key: "property_type",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={status === "Available" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record (shop data) to the onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <PageHeader title="Property List" />
      <div style={{ marginTop: "15px" }}>
        <SearchFilter
          onSearch={handleSearch}
          searchPlaceHolder="Search by Property Name"
        />
      </div>
      <div className="filter-card">
        <Form form={form}>
          <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
            <Form.Item name="branch">
              <Select
                placeholder="Branch"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleBranchChange}>
                {isLoadingBranch ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  branchListData?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="building">
              <Select
                placeholder="Building"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleBuildingChange}>
                {isLoadingBuildings ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  buildingListData?.data?.buildings?.map((building) => (
                    <Option key={building?._id} value={building?._id}>
                      {building?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="floor">
              <Select
                placeholder="Floor"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleFloorChange}>
                {isLoadingFloors ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  floorListData?.data?.floors?.map((floor) => (
                    <Option key={floor?._id} value={floor?._id}>
                      {floor?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="zone">
              <Select
                placeholder="Zone"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleZoneChange}>
                {isLoadingZones ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  zoneListData?.data?.zones?.map((zone) => (
                    <Option key={zone?._id} value={zone?._id}>
                      {zone?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="propertyType">
              <Select
                placeholder="Property Type"
                style={{ width: 150, height: 38 }}
                onChange={setSelectedPropertyType}>
                <Option value={1}>Rent</Option>
                <Option value={2}>Sell</Option>
                <Option value={3}>FOC</Option>
              </Select>
            </Form.Item>
            <Form.Item name="roomType">
              <Select
                placeholder="Room Type"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleRoomTypeChange}>
                {isLoadingRoomType ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  roomTypeData?.data?.buildings?.map((roomType) => (
                    <Option key={roomType?._id} value={roomType?._id}>
                      {roomType?.room_type}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="status">
              <Select
                placeholder="Status"
                style={{ width: 150, height: 38 }}
                onChange={setSelectedStatus}>
                <Option value={1}>Available</Option>
                <Option value={2}>Unavailable</Option>
              </Select>
            </Form.Item>
          </FilterCard>
        </Form>
      </div>
      <Form>
        <Form.Item>
          <Table
            loading={isLoadingShops}
            columns={columns}
            dataSource={paginatedData}
            onChange={onChange}
            pagination={false}
          />
        </Form.Item>
      </Form>
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={shopListData?.data?.length}
        onChange={handlePaginationChange}
      />
      <ViewProperty
        isViewModalOpen={isViewModalOpen}
        selectedShop={selectedShop}
        hanleViewCancel={hanleViewCancel}
      />

      {/* Delete Modal */}
      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default PropertyList;
