import React, { useState } from "react";
import { PageHeader } from "../../component";
import { AddNewRoomType, RoomTypeTable } from "../../features";

const AddRoomType = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [totalItems, setTotalItems] = useState(0); // State to hold total items

  const handleAddRoomType = () => {
    setIsAddModalVisible(true); // Open the modal
  };

  const handleAddRoomTypeClose = () => {
    setIsAddModalVisible(false); // Close the modal
  };

  // Callback function to set total items
  const handleTotalItems = (total) => {
    setTotalItems(total);
  };

  return (
    <div>
      <PageHeader
        title="Room Types"
        addBtn="Add Room Type"
        totalName="Room Types"
        totalItems={totalItems} // Pass totalItems to PageHeader
        onAdd={handleAddRoomType}
      />
      <AddNewRoomType visible={isAddModalVisible} onClose={handleAddRoomTypeClose} />
      <RoomTypeTable onTotalItems={handleTotalItems} />
    </div>
  );
};

export default AddRoomType;
