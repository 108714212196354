import {
  ConfigProvider,
  Form,
  Input,
  Select,
  Button,
  message,
  Space,
} from "antd";
import React from "react";
import "./styles.css";
import { useAddNewUtilityMutation } from "../../features/utility/utilityApi";
import { useGetAllBranchesQuery } from "../../features/property/propertyApi";

const { Option } = Select;

const UtilityType = ({ handleOk, handleCancel }) => {
  const [form] = Form.useForm();

  const [addNewUtility, { isLoading, error }] = useAddNewUtilityMutation();

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      const newUtility = {
        utilityType: values?.utilityType,
        title: values.title,
        unit: parseInt(values.unit, 10),
        rate: parseFloat(values.rateValue),
        rate_type: values.rateType,
        property_type: values.propertyType,
        branch: values.branch.map((id) => ({ _id: id })),
      };

      await addNewUtility(newUtility).unwrap();
      message.success("Utility created successfully!");
      form.resetFields(); // Reset form
      handleCancel(); // Close the modal after creation
    } catch (err) {
      console.error("Failed to create utility:", err);
      message.error(err?.data?.message);
    }
  };

  const handleUtilityTypeChange = (value) => {
    if (value === "meter") {
      form.setFieldsValue({ unit: 1 }); // Set numeric value for Meter
    } else if (value === "area") {
      form.setFieldsValue({ unit: 1 }); // Set numeric value for Area
    } else {
      form.setFieldsValue({ unit: 0 }); // Default to 0 for Other
    }
  };

  const handleCancelClick = () => {
    form.resetFields(); // Reset form when cancel is clicked
    handleCancel(); // Close the modal
  };

  const { data: branchList, isLoading: isLoadingBranch } =
    useGetAllBranchesQuery();

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
        <Form layout="vertical" form={form}>
          <div className="card-group">
            <Form.Item
              rules={[{ required: true }]}
              style={{ width: 400 }}
              name="branch"
              label="Branch">
              <Select
                mode="multiple"
                style={{ height: 44 }}
                placeholder="Select Branch">
                {branchList?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name="propertyType"
              label="Property Type"
              style={{ width: 400 }}>
              <Select style={{ height: 44 }} placeholder="Select Property Type">
                <Option value="1">Rent</Option>
                <Option value="2">Sell</Option>
                <Option value="3">Free of Charge (FOC)</Option>
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              name="utilityType"
              label="Utility Type"
              style={{ width: 400 }}>
              <Select
                style={{ height: 44 }}
                placeholder="Select Utility Type"
                onChange={handleUtilityTypeChange}>
                <Option value="meter">Meter</Option>
                <Option value="area">Area</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Title is required" }]}
              style={{ width: 400 }}
              className="card-item">
              <Input
                placeholder="Title"
                style={{ height: 44 }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\s+/g, "_"); // Replace spaces with underscores
                  form.setFieldsValue({ title: value }); // Update the form value
                }}
              />
            </Form.Item>

            <Form.Item
              name="unit"
              rules={[{ required: true }]}
              label="Unit"
              style={{ width: 400 }}
              className="card-item">
              <Input
                type="number"
                min={1}
                placeholder="Unit"
                style={{ height: 44 }}
              />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              style={{ width: 400 }}
              label="Rate">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item name="rateValue" noStyle>
                  <Input
                    type="number"
                    min={1}
                    style={{ width: "80%", height: 44 }}
                    placeholder="Rate Value"
                  />
                </Form.Item>
                <div className="select-rate-type">
                  <Form.Item
                    name="rateType"
                    noStyle
                    rules={[
                      { required: true, message: "Please select a rate type!" },
                    ]}>
                    <Select
                      style={{ width: 110, height: 44 }}
                      placeholder="Rate Type">
                      <Option value="%">Percent</Option>
                      <Option value="MMK">Flat</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Space.Compact>
            </Form.Item>
          </div>
          <div className="add-new-btn-div">
            <Button type="text" className="btn" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              className="filter btn"
              onClick={handleCreate}
              loading={isLoading}>
              Create
            </Button>
          </div>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default UtilityType;
