import { baseApi } from "../../app/baseApi";

export const contractApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllContractSetting: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `/Contract_Manage/contract-setting/list?page=${page}&limit=${limit}`,
      providesTags: ["ContractSettingList"],
    }),

    getAllContractLists: builder.query({
      query: () => `/Contract_Manage/contract/list`,
      providesTags: ["ContractList"],
    }),

    getContractById: builder.query({
      query: ({ contractId }) =>
        `/Contract_Manage/contract/view-detail/${contractId}`,
      providesTags: ["ContractList"],
    }),

    addNewContractTenant: builder.mutation({
      query: (payload) => ({
        url: "/Contract_Manage/contract/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Contract"],
    }),

    addNewExistingContract: builder.mutation({
      query: ({
        property_type,
        tenant,
        billing_contract_person,
        property_information,
        payment_type,
        discount,
        contract_policy,
        contract_information,
        invoice_payment,
        pmf,
      }) => ({
        url: `/Contract_Manage/contract/exiting/create`,
        method: "POST",
        body: {
          property_type,
          tenant,
          billing_contract_person,
          property_information,
          payment_type,
          discount,
          contract_policy,
          contract_information,
          invoice_payment,
          pmf,
        },
      }),
      invalidatesTags: ["ContractList"],
    }),

    addNewContract: builder.mutation({
      query: ({ contract_type, contract_duration, about_to_expire }) => ({
        url: `/Contract_Manage/contract-setting/create`,
        method: "POST",
        body: {
          contract_type,
          contract_duration,
          about_to_expire,
        },
      }),
      invalidatesTags: ["ContractSettingList"],
    }),

    updateContract: builder.mutation({
      query: ({ contractId, updatedData }) => ({
        url: `/Contract_Manage/contract-setting/update/${contractId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit contract failed:", error);
      },
      invalidatesTags: ["ContractSettingList"],
    }),

    updateContractPolicy: builder.mutation({
      query: ({ contractId, updatedData }) => ({
        url: `/Contract_Manage/contract/add-contract-policy/${contractId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit contract policy failed:", error);
      },
      invalidatesTags: ["ContractList"],
    }),

    updatePreTerminate: builder.mutation({
      query: ({contractId, updatedData}) => ({
        url: `/Contract_Manage/contract/add-terminate-stage/${contractId}`,
        method:"PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Add Pre-Terminate Date Failed:", error);
      },
      invalidatesTags: ["ContractList"],
    }),

    deleteContract: builder.mutation({
      query: ({ contractId }) => ({
        url: `/Contract_Manage/contract-setting/delete/${contractId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ContractSettingList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllContractSettingQuery,
  useUpdateContractMutation,
  useDeleteContractMutation,
  useAddNewContractMutation,
  useGetAllContractListsQuery,
  useGetContractByIdQuery,
  useUpdateContractPolicyMutation,
  useAddNewExistingContractMutation,
  useAddNewContractTenantMutation,
  useUpdatePreTerminateMutation
} = contractApi;
