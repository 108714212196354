import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import nrcData from "./nrc.json"; // Importing the JSON file

const NrcInput = () => {
  const [townshipOptions, setTownshipOptions] = useState([]);

  const handleRegionChange = (regionCode) => {
    // Filter townships based on the selected region code
    const filteredTownships = nrcData.data.filter(
      (item) => item.nrc_code === regionCode
    );
    setTownshipOptions(filteredTownships);
  };

  return (
    <div>
      <Form.Item label="NRC" className="tenant-label-group" name="nrc">
        <Input.Group compact style={{ display: "flex" }}>
          {/* Region Selector */}
          <Form.Item name={["nrc", "region"]} noStyle>
            <Select
              placeholder="1"
              style={{ width: "70px", height: 44 }}
              onChange={handleRegionChange} // Trigger on region change
            >
              {/* Dynamically create region dropdown */}
              {[...new Set(nrcData.data.map((item) => item.nrc_code))].map(
                (regionCode) => (
                  <Select.Option key={regionCode} value={regionCode}>
                    {regionCode}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>

          {/* Township Code Selector */}
          <Form.Item name={["nrc", "township"]} noStyle>
            <Select placeholder="BaMaNa" style={{ width: "150px", height: 44 }}>
              {townshipOptions.map((item) => (
                <Select.Option key={item.id} value={item.name_en}>
                  {item.name_en}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Citizenship Type */}
          <Form.Item name={["nrc", "type"]} noStyle>
            <Select placeholder="N" style={{ width: "70px", height: 44 }}>
              <Select.Option value="N">N</Select.Option>
              <Select.Option value="P">P</Select.Option>
            </Select>
          </Form.Item>

          {/* NRC Number */}
          <Form.Item
            name={["nrc", "number"]}
            noStyle
            rules={[
              {
                required: true,
                message: "This field is required",
              },
              {
                len: 6,
                message: "The input must be exactly 6 digits",
              },
            ]}>
            <Input
              type="number"
              placeholder="123456"
              style={{ width: "150px", height: 44 }}
              onKeyDown={(e) => {
                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "." // Disallow invalid keys like decimals
                ) {
                  e.preventDefault();
                }
              }}
              onInput={(e) => {
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6); // Enforce max length
                }
              }}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </div>
  );
};

export default NrcInput;
