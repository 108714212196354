import { InboxOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";

const { Dragger } = Upload;

const Advertisement = () => {
  const [bannerList, setBannerList] = useState([]); // State to store saved banners
  const [tempBanner, setTempBanner] = useState(null); // Temporary state for uploaded file
  const [form] = useForm();

  const handleFileChange = ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[0];
      convertToBase64(file.originFileObj).then((base64) => {
        const newBanner = { banner: file.name, link: "", base64 };
        setTempBanner(newBanner); // Store in tempBanner until saved
      });
    } else {
      setTempBanner(null); // Clear if no files are selected
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleSave = () => {
    if (bannerList.length >= 3 && !tempBanner.editing) {
      message.warning("You can only add up to 3 banners.");
      return;
    }

    form.validateFields().then((values) => {
      if (tempBanner && tempBanner.base64) {
        const newBanner = {
          ...tempBanner,
          link: values.link, // Add link from the form
        };

        if (tempBanner.editing) {
          // Update existing banner
          const updatedBanners = bannerList.map((banner, index) =>
            index === tempBanner.index ? newBanner : banner
          );
          setBannerList(updatedBanners);
        } else {
          // Add new banner
          setBannerList([...bannerList, newBanner]);
        }

        setTempBanner(null); // Clear tempBanner
        form.resetFields(); // Clear the form
      }
    });
  };

  const handleDelete = (index) => {
    const updatedBanners = bannerList.filter((_, i) => i !== index);
    setBannerList(updatedBanners); // Update banner list without the deleted item
  };

  const handleEdit = (index) => {
    const bannerToEdit = bannerList[index];
    setTempBanner({ ...bannerToEdit, editing: true, index }); // Set the banner as tempBanner with editing flag
    form.setFieldsValue({ link: bannerToEdit.link }); // Populate the form with the banner's link
  };

  return (
    <div>
      <Card
        title="Add Advertisement"
        extra={`Limited Banner : ${bannerList.length}/3`}>
        <Form form={form} layout="vertical">
          <Form.Item label="Upload Banner" name="banner">
            <Dragger
              onChange={handleFileChange}
              beforeUpload={() => false} // Prevent automatic upload
              multiple={false}
              maxCount={1}
              listType="text"
              accept=".pdf, .jpg, .jpeg, .png"
              fileList={tempBanner ? [{ uid: "1", name: tempBanner.banner, status: "done" }] : []} // Display only tempBanner
              style={{ width: 1060, height: 44 }}
              disabled={bannerList.length >= 3 && !tempBanner?.editing} // Disable if limit reached
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Support for a single upload.</p>
            </Dragger>
          </Form.Item>
          <Form.Item
            label="Link"
            name="link"
            rules={[{ required: true, message: "Please enter a link" }]}>
            <Input
              style={{ height: 44 }}
              placeholder="Link"
              disabled={bannerList.length >= 3 && !tempBanner?.editing}
            />
          </Form.Item>
          <div className="add-property-div">
            <Button type="text" className="btn" onClick={() => setTempBanner(null)}>
              Cancel
            </Button>
            <Button
              className="btn filter"
              onClick={handleSave}
              disabled={bannerList.length >= 3 && !tempBanner?.editing}>
              {tempBanner?.editing ? "UPDATE" : "SAVE"}
            </Button>
          </div>
        </Form>
      </Card>

      {/* Conditionally display saved banners card */}
      {bannerList.length > 0 && (
        <Card title="Banner" style={{ marginTop: "20px" }}>
          <Form layout="vertical">
            {bannerList.map((banner, index) => (
              <div
                key={index}
                style={{ display: 'flex', columnGap: '16px', alignItems: 'center', marginBottom: '10px' }}
              >
                <Form.Item label="Banner" style={{ width: '395px' }}>
                  <Input
                    value={banner.banner}
                    readOnly
                    style={{ height: "44px" }}
                  />
                </Form.Item>
                <Form.Item label="Link" style={{ width: '395px' }}>
                  <Input
                    value={banner.link}
                    readOnly
                    style={{ height: "44px" }}
                  />
                </Form.Item>
                <div>
                  <Button className="btn" onClick={() => handleDelete(index)}>
                    Delete
                  </Button>
                  <Button
                    className="btn filter"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleEdit(index)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ))}
          </Form>
        </Card>
      )}
    </div>
  );
};

export default Advertisement;
