import { baseApi } from "../../app/baseApi";
export const propertyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBranches: builder.query({
      query: () => `/Property/branchs/list`,
      providesTags: ["BranchList"],
    }),

    getAllBuilding: builder.query({
      query: () => `/Property/buildings/list`,
      providesTags: ["BuildingList"],
    }),

    getAllFloorList: builder.query({
      query: () => `/Property/floors/list`,
      providesTags: ["FloorList"],
    }),

    getAllZone: builder.query({
      query: () => `/Property/zones/list`,
      providesTags: ["ZoneList"],
    }),

    getAllShops: builder.query({
      query: () => `/Property/shops/list`,
      providesTags: ["ShopList"],
    }),

    AddNewBranch: builder.mutation({
      query: ({
        name,
        contact_person,
        email,
        phone_number,
        city,
        township,
        address,
      }) => ({
        url: `/Property/branchs/create`,
        method: "POST",
        body: {
          name,
          contact_person,
          email,
          phone_number,
          city,
          township,
          address,
        },
      }),
      invalidatesTags: ["BranchList"],
    }),

    AddNewBuilding: builder.mutation({
      query: ({ branch, name }) => ({
        url: `/Property/buildings/create`,
        method: "POST",
        body: {
          branch,
          name,
        },
      }),
      invalidatesTags: ["BuildingList"],
    }),

    AddNewFloor: builder.mutation({
      query: ({ name, number_of_shop, building, branch }) => ({
        url: `/Property/floors/create`,
        method: "POST",
        body: {
          name,
          number_of_shop,
          building,
          branch,
        },
      }),
      invalidatesTags: ["FloorList"],
    }),

    AddManageFloor: builder.mutation({
      query: ({ property_type, price_per_sq, floor, unit }) => ({
        url: `/Property/floors/property-type/create`,
        method: "POST",
        body: {
          property_type,
          price_per_sq,
          floor,
          unit,
        },
      }),
      invalidatesTags: ["FloorList"],
    }),

    addNewZone: builder.mutation({
      query: ({ name, building, floor, branch }) => ({
        url: `/Property/zones/create`,
        method: "POST",
        body: {
          name,
          building,
          floor,
          branch,
        },
      }),
      invalidatesTags: ["ZoneList"],
    }),

    addNewShop: builder.mutation({
      query: ({
        name,
        status,
        total_area,
        room_type,
        price,
        floor,
        building,
        branch,
        zone,
        property_type,
      }) => ({
        url: `/Property/shops/create`,
        method: "POST",
        body: {
          name,
          status,
          total_area,
          price,
          floor,
          building,
          zone,
          branch,
          property_type,
          room_type,
        },
      }),
      invalidatesTags: ["ShopList"],
    }),

    updateBranch: builder.mutation({
      query: ({ branchId, updatedData }) => ({
        url: `/Property/branchs/update/${branchId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit branch is failed:", error);
      },
      invalidatesTags: ["BranchList"],
    }),

    updateBuilding: builder.mutation({
      query: ({ buildingId, updatedData }) => ({
        url: `/Property/buildings/update/${buildingId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit building name failed:", error);
      },
      invalidatesTags: ["BuildingList"],
    }),

    updateFloor: builder.mutation({
      query: ({ floorId, updatedData }) => ({
        url: `/Property/floors/update/${floorId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit floor is failed:", error);
      },
      invalidatesTags: ["FloorList"],
    }),

    updateManageFloor: builder.mutation({
      query: ({ floorId, updatedData }) => ({
        url: `/Property/floors/property-type/update/${floorId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit floor is failed:", error);
      },
      invalidatesTags: ["FloorList"],
    }),

    updateZone: builder.mutation({
      query: ({ zoneId, updatedData }) => ({
        url: `/Property/zones/update/${zoneId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit zone is failed:", error);
      },
      invalidatesTags: ["ZoneList"],
    }),

    updateShop: builder.mutation({
      query: ({ shopId, updatedData }) => {
        if (!shopId || !updatedData) {
          throw new Error("shopId or updatedData is missing");
        }
        return {
          url: `/Property/shops/update/${shopId}`,
          method: "PATCH",
          body: updatedData,
        };
      },
      onError: (error) => {
        console.error("Edit Shop failed:", error);
      },
      invalidatesTags: ["ShopList"],
    }),    

    deleteBranch: builder.mutation({
      query: ({ branchId }) => ({
        url: `/Property/branchs/delete/${branchId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BranchList"],
    }),

    deleteBuilding: builder.mutation({
      query: ({ buildingId }) => ({
        url: `/Property/buildings/delete/${buildingId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BuildingList"],
    }),

    deleteFloor: builder.mutation({
      query: ({ floorId }) => ({
        url: `/Property/floors/delete/${floorId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FloorList"],
    }),

    deleteZone: builder.mutation({
      query: ({ zoneId }) => ({
        url: `/Property/zones/delete/${zoneId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ZoneList"],
    }),

    deleteShop: builder.mutation({
      query: ({ shopId }) => ({
        url: `/Property/shops/delete/${shopId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShopList"],
    }),

    deleteManageFloor: builder.mutation({
      query: ({ propertyTypeId }) => ({
        url: `/Property/floors/property-type/delete/${propertyTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FloorList"],
    }),

    getBuildingsByBranchId: builder.query({
      query: (branchId) => `common/property-filter?branch=${branchId}`,
      providesTags: ["BuildingList"],
    }),

    getFloorsByBuildingId: builder.query({
      query: (buildingId) => `common/property-filter?building=${buildingId}`,
      providesTags: ["FloorList", "ZoneList"],
    }),

    getZoneByBuildingId: builder.query({
      query: ({ floorId }) => `/common/property-filter?floor=${floorId}`,
      providesTags: ["ZoneList"],
    }),

    getShopByZoneId: builder.query({
      query: ({ zoneId }) => `/common/property-filter?zone=${zoneId}`,
      providesTags: ["ShopList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBuildingQuery,
  useAddNewBuildingMutation,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
  useGetAllFloorListQuery,
  useAddNewFloorMutation,
  useUpdateFloorMutation,
  useDeleteFloorMutation,
  useGetAllZoneQuery,
  useUpdateZoneMutation,
  useDeleteZoneMutation,
  useAddNewZoneMutation,
  useGetAllShopsQuery,
  useAddNewShopMutation,
  useUpdateShopMutation,
  useDeleteShopMutation,
  useGetFloorsByBuildingIdQuery,
  useGetZoneByBuildingIdQuery,
  useGetShopByZoneIdQuery,
  useAddManageFloorMutation,
  useGetAllBranchesQuery,
  useAddNewBranchMutation,
  useDeleteBranchMutation,
  useUpdateBranchMutation,
  useGetBuildingsByBranchIdQuery,
  useUpdateManageFloorMutation,
  useDeleteManageFloorMutation,
} = propertyApi;
