import { Select, Form } from "antd";
import React, { useState } from "react";
import { ShopList } from "../../features";
import { useParams } from "react-router-dom";
import { FilterCard, PageHeader, SearchFilter } from "../../component";
import {
  useGetAllBranchesQuery,
  useGetAllBuildingQuery,
  useGetAllFloorListQuery,
  useGetAllZoneQuery,
} from "../../features/property/propertyApi";
import { useGetAllRoomSettingQuery } from "../../features/setting/roomSettingApi";

const { Option } = Select;
const AddShop = () => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const { data: branchListData, loading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, loading: isLoadingBuildings } =
    useGetAllBuildingQuery();
  const { data: floorListData, loading: isLoadingFloors } =
    useGetAllFloorListQuery();
  const { data: zoneListData, loading: isLoadingZones } = useGetAllZoneQuery();
  const { data: roomTypeData, loading: isLoadingRoomType } =
    useGetAllRoomSettingQuery();
  const { id } = useParams();

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value); // Update selected branch
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
  };

  const handleZoneChange = (value) => {
    setSelectedZone(value);
  };

  const handleRoomTypeChange = (value) => {
    setSelectedRoomType(value);
  };

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    setSelectedBranch(formValues.branch);
    setSelectedBuilding(formValues.building);
    setSelectedFloor(formValues.floor);
    setSelectedZone(formValues.zone);
    setSelectedPropertyType(formValues.propertyType);
    setSelectedStatus(formValues.status);
    setSelectedRoomType(formValues.roomType);
  };

  const handleClearFilters = () => {
    form.resetFields();
    setSearchTerm("");
    setSelectedBranch(null);
    setSelectedBuilding(null);
    setSelectedFloor(null);
    setSelectedZone(null);
    setSelectedPropertyType(null);
    setSelectedStatus(null);
    setSelectedRoomType(null);
  };

  return (
    <>
      <PageHeader
        title="Room / Shop List"
        addBtn="Add New Shop"
        linkTo={`/${id}/property/add-shop/create-shop`}
      />
      <SearchFilter onSearch={handleSearch} searchPlaceHolder="Search..." />
      <div className="filter-card">
        <Form form={form}>
          <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
            <Form.Item name="branch">
              <Select
                placeholder="Branch"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleBranchChange}>
                {isLoadingBranch ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  branchListData?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="building">
              <Select
                placeholder="Building"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleBuildingChange}>
                {isLoadingBuildings ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  buildingListData?.data?.buildings?.map((building) => (
                    <Option key={building?._id} value={building?._id}>
                      {building?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="floor">
              <Select
                placeholder="Floor"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleFloorChange}>
                {isLoadingFloors ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  floorListData?.data?.floors?.map((floor) => (
                    <Option key={floor?._id} value={floor?._id}>
                      {floor?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="zone">
              <Select
                placeholder="Zone"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleZoneChange}>
                {isLoadingZones ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  zoneListData?.data?.zones?.map((zone) => (
                    <Option key={zone?._id} value={zone?._id}>
                      {zone?.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="propertyType">
              <Select
                placeholder="Property Type"
                style={{ width: 150, height: 38 }}
                onChange={setSelectedPropertyType}>
                <Option value={1}>Rent</Option>
                <Option value={2}>Sell</Option>
                <Option value={3}>FOC</Option>
              </Select>
            </Form.Item>
            <Form.Item name="roomType">
              <Select
                placeholder="Room Type"
                style={{
                  width: 150,
                  height: 38,
                }}
                onChange={handleRoomTypeChange}>
                {isLoadingRoomType ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  roomTypeData?.data?.buildings?.map((roomType) => (
                    <Option key={roomType?._id} value={roomType?._id}>
                      {roomType?.room_type}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item name="status">
              <Select
                placeholder="Status"
                style={{ width: 150, height: 38 }}
                onChange={setSelectedStatus}>
                <Option value={1}>Available</Option>
                <Option value={2}>Unavailable</Option>
              </Select>
            </Form.Item>
          </FilterCard>
        </Form>
      </div>
      <ShopList
        selectedBranchFromFilter={selectedBranch}
        searchTerm={searchTerm}
        selectedFloorFromFilter={selectedFloor}
        selectedBuildingFromFilter={selectedBuilding}
        selectedZoneFromFilter={selectedZone}
        selectedRoomTypeFromFilter = { selectedRoomType}
        selectedPropertyTypeFromFilter = {selectedPropertyType}
        selectedStatusFromFilter = {selectedStatus}
      />
    </>
  );
};

export default AddShop;
