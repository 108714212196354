import React, { useState } from "react";
import PageHeader from "../../../component/PageHeader";
import { Button, Card, Form, Input, Modal, message } from "antd";
import { useAddNewBUMutation } from "./buApi";

const BusinessUnitHeader = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addNewBU, { isLoading, error }] = useAddNewBUMutation();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
    console.log("show");
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values) => {
    const payload = {
      bu_name: values.bu_name,
    };

    try {
      await addNewBU(payload).unwrap();
      form.resetFields();
      message.success("Business Unit is added successfully.");
      handleOk();
    } catch (error) {
      const errorMessage = error?.data?.message || "Failed to add Business Unit";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    handleCancel(); // Call parent cancel handler
  };

  return (
    <div>
      <PageHeader
        title="Business Unit List"
        addBtn="Add Business Unit"
        onAdd={showModal}
      />
      <Modal
        className="bu-add-modal"
        width={880}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        closeIcon={false}
      >
        <Card title="Add Business Unit">
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Business Unit"
              name="bu_name"
            >
              <Input placeholder="Business Unit" style={{ height: "44px" }} />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleFormCancel} type="text" className="btn">
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="btn filter"
              >
                Create
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default BusinessUnitHeader;
