import React from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Card,
} from "antd";
import { useAddManageFloorMutation } from "../propertyApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const ManageModal = ({ isOpen, onClose, onOk, currentRecord }) => {
  const token = useSelector((state) => state.authSlice.token2);

  const [form] = Form.useForm();
  const [addManage, { isLoading: isLoadingManage }] = useAddManageFloorMutation();

  const handleSubmit = async (values) => {
    const data = {
      floor: currentRecord?.key,
      property_type: values.propertyType,
      price_per_sq: values.price,
      unit: 1,
    };
    try {
      await addManage(data).unwrap();
      form.resetFields();
      message.success("Property Type is added successfully.");
      onOk();
    } catch (isAddingError) {
      const errorMessage =
        isAddingError?.data?.errors?.[0] || "Failed to add floor";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={880}
      onCancel={handleFormCancel}
      closeIcon={false}
      className="bu-add-modal"
      open={isOpen}
      footer={null}
    >
      <Card title="Manage Price">
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          initialValues={{ unit: "1" }} // Set default value for unit
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Form.Item
              label="Property Type"
              name="propertyType"
              rules={[{ required: true, message: "Please select a property type" }]}
              style={{ width: 400 }}
            >
              <Select
                style={{ height: 44 }}
                placeholder="Select property type"
              >
                <Option value="Rent">Rent</Option>
                <Option value="Sell">Sell</Option>
                <Option value="FOC">Free Of Charge (FOC)</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Unit (sq ft)"
              name="unit"
              style={{ width: 400 }}
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>

            <Form.Item
              label="Price (per sq ft)"
              name="price"
              rules={[{ required: true, message: "Please enter a price" }]}
              style={{ width: 400 }}
            >
              <Input type="number" min={0} style={{ height: 44 }} placeholder="Price" />
            </Form.Item>
          </div>

          <div className="add-branch-btn-gp">
            <Button type="text" className="btn" onClick={handleFormCancel}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="btn filter"
              loading={isLoadingManage}
            >
              Add
            </Button>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default ManageModal;
