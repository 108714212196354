import { Card, Form, Input, Select } from "antd";
import React, { useState } from "react";

const { Option } = Select;

const PaymentCard = ({ onPaymentChange }) => {
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState("MMK");

  const handleValuesChange = (changedValues) => {
    const updatedValues = {
      ...form.getFieldsValue(),
      ...changedValues,
      currency,
    };
    if (onPaymentChange) {
      onPaymentChange(updatedValues); // Notify parent of changes
    }
  };

  const handleCurrencyChange = (value) => {
    setCurrency(value); // Update currency when changed
    const updatedValues = { ...form.getFieldsValue(), currency: value };
    if (onPaymentChange) {
      onPaymentChange(updatedValues); // Notify parent of currency change
    }
  };

  return (
    <div className="payment-card">
      <Form layout="vertical" form={form} onValuesChange={handleValuesChange}>
        <Card
          style={{ marginTop: "16px" }}
          title="Payment Type"
          extra={
            <Form.Item
              name="type"
              noStyle
              rules={[{ required: true, message: "Please select a type" }]}>
              <Select placeholder="MMK" onChange={handleCurrencyChange}>
                <Option value="MMK">MMK</Option>
                <Option value="USD">USD</Option>
              </Select>
            </Form.Item>
          }>
          <div className="add-branch-form">
            <Form.Item
              className="add-branch-input"
              name="totalAmount"
              rules={[{ required: true }]}
              label={`Total Amount (${currency.toUpperCase()})`}>
              <Input
                type="number"
                min={1}
                placeholder={`Enter total amount (${currency.toUpperCase()})`}
                style={{ height: 44 }}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="add-branch-input"
              name="advancedPayment"
              label={`Advanced Payment (${currency.toUpperCase()})`}>
              <Input
                type="number"
                min={1}
                placeholder={`Enter advanced payment (${currency.toUpperCase()})`}
                style={{ height: 44 }}
              />
            </Form.Item>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default PaymentCard;
