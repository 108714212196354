import React, { useEffect } from "react";
import { Card, Form, Input, Button, Breadcrumb, Row, Col, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useGetInvoiceByIdQuery, useUpdatePreviewInvoiceMutation } from "../../features/invoice/invoiceApi";

const EditInvoice = () => {
  const navigate = useNavigate();
  const { id, invoice_id } = useParams();
  const { data, isLoading } = useGetInvoiceByIdQuery({ invoice_id });
  const [form] = Form.useForm();
  const [updatePreviewInvoice, { isLoading: isUpdateLoading }] = useUpdatePreviewInvoiceMutation();

  useEffect(() => {
    if (data?.data) {
      const utilitiesValues = data.data.utilities.reduce((acc, utility) => {
        const nameKey = utility.title.replace(/\s+/g, "_").toLowerCase();

        // Calculate usage for meter utilities
        const usage =
          utility.utilityType === "meter"
            ? (parseFloat(utility.this_month || 0) - parseFloat(utility.previous_month || 0)) || 0
            : "";

        acc[nameKey] = {
          ...utility,
          this_month: utility.this_month || "",
          last_month: utility.previous_month || "",
          usage: usage >= 0 ? usage : 0, // Ensure usage is non-negative
          unit: utility.unit || "",
          qty: utility.qty || "",
          rate: utility.rate || "",
          amount: utility.amount || "",
        };
        return acc;
      }, {});

      form.setFieldsValue({
        ...data.data,
        date: data.data.date ? moment(data.data.date) : moment(),
        ...utilitiesValues,
      });
    }
  }, [data, form]);

  const handleUpdate = async (values) => {
    try {
      // Ensure the date is properly formatted
      const formattedValues = {
        ...values,
        date: values.date ? moment(values.date).format("YYYY-MM-DD") : undefined,
      };
  
      console.log("Updated Values:", formattedValues);
  
      await updatePreviewInvoice({
        params: formattedValues,
        id: invoice_id,
      }).unwrap();
  
      message.success("Preview is updated successfully.");
      navigate(`/${id}/invoice/preview`);
    } catch (error) {
      console.error("Update failed:", error);
  
      // Error message for better debugging
      if (error.message.includes("format")) {
        message.error("Invalid date format. Please check the date field.");
      } else {
        message.error("Unable to update this invoice.");
      }
    }
  };  

  const renderUtilityForm = (utility) => {
    const { utilityType, title } = utility;
    const nameKey = title.replace(/\s+/g, "_").toLowerCase();

    if (utilityType === "meter") {
      return (
        <div key={utility._id}>
          <div className="invoice-sub-header">
            <p>{title}</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="This Month" name={[nameKey, "this_month"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Previous Month" name={[nameKey, "last_month"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Usage" name={[nameKey, "usage"]} className="invoice-input">
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item label="Amount" name={[nameKey, "amount"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
          </div>
        </div>
      );
    } else {
      return (
        <div key={utility._id}>
          <div className="invoice-sub-header">
            <p>{title}</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit" name={[nameKey, "unit"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Qty" name={[nameKey, "qty"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Rate" name={[nameKey, "rate"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Amount" name={[nameKey, "amount"]} className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/invoice/preview`)}>
          Preview Invoice
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Invoice</Breadcrumb.Item>
      </Breadcrumb>
      <Card loading={isLoading} title="Invoice Details">
        <Form
          layout="vertical"
          form={form}
          loading={isLoading}
          onFinish={handleUpdate}
        >
          <Row gutter={[16]}>
            <Col span={4}>
              <p>
                <strong>Tenant Name</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{data?.data?.tenant?.tenant_name}</p>
            </Col>

            <Col span={4}>
              <p>
                <strong>Room / Shop Name</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{data?.data?.shop}</p>
            </Col>
          </Row>

          {data?.data?.utilities?.map(renderUtilityForm)}

          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" className="btn" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button className="btn update" type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default EditInvoice;
