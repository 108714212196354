import React from "react";
import { Button, Card, Form, Input } from "antd";

const PaymentSetting = () => {
  return (
    <div>
      <Card title="Payment Setting">
        <Form layout="vertical">
          <div className="email-setting-form">
            <Form.Item
              label="Client ID"
              name="client_id"
              className="email-setting-input">
              <Input placeholder="Client ID" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              name="client_secret"
              className="email-setting-input">
              <Input placeholder="Client Secret" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Data Key"
              name="data_key"
              className="email-setting-input">
              <Input placeholder="Data Key" style={{ height: 44 }} />
            </Form.Item>
          </div>
          <div className="email-setting-btn-gp">
            <Button type="text" className="btn">
              Cancel
            </Button>
            <Button className="btn filter">Save</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default PaymentSetting;
