import { Breadcrumb, Button, Card, Checkbox, Form, message } from "antd";
import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddDiscountCard,
  ContractInformationCard,
  ContractPolicy,
  InvoicePaymentSettingCard,
  PaymentCard,
  PmfCard,
  TenantInformation,
  ViewContractInfoCard,
} from "../../component";
import dayjs from "dayjs";
import { useAddNewContractTenantMutation } from "./contractApi";
import OwnerPaymentCard from "../../component/contract/OwnerPaymentCard";

const AddNewContract = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    properties = [],
    tenantType,
    newTenantInfo,
    billingInfo,
  } = location.state || {};
  console.log("formState from contract", properties);
  
  const [contractInformation, setContractInformation] = useState({
    contract_name: "",
    contract_duration: "",
    start_date: "",
    end_date: "",
    contract_file: "",
  });
  // State to manage visibility of cards and payment data
  const [contractData, setContractData] = useState({
    isDiscountChecked: false,
    isPmfChecked: false,
    isContractPolicyChecked: false,
    discountData: null,
    pmfList: null,
    contractPolicyData: null,
    properties,
    newTenantInfo,
    tenantType,
    billingInfo,
    contractInformation: {},
    invoicePaymentData: {},
    paymentData: {}, // Store payment data
  });
  
  const [form] = Form.useForm(); // Initialize form here
  const updateContractData = (key, value) => {
    setContractData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [addNewContract, { isLoading: isLoadingNewContract }] =
    useAddNewContractTenantMutation();

  const handlePaymentChange = (paymentData) => {
    updateContractData("paymentData", paymentData); // Update payment data
  };
  const handlePreviousPage = () => {
    navigate(`/${id}/contract/list/new-contract`, {
      state: {
        properties: contractData?.properties,
        newTenantInfo: contractData?.newTenantInfo,
        billingInfo: contractData?.billingInfo,
        tenantType: tenantType,
      },
    });
  };

  const handleCreate = async () => {
    try {
      await form.validateFields();
      const billingContactPerson = {
        contract_person_name: contractData?.billingInfo?.billingName,
        contract_email: contractData?.billingInfo.billingEmail,
        contract_phone_number: contractData.billingInfo.billingPhoneNumber,
        city: contractData.billingInfo.billingCity,
        township: contractData.billingInfo.billingTownship,
        address: contractData.billingInfo.billingAddress,
      };

      const paymentType = {
        payment_type: contractData?.paymentData?.payment_type,
        type: contractData?.paymentData?.type,
        total_amount: contractData?.paymentData?.totalAmount,
        down_payment: contractData?.paymentData?.rateValue,
        down_payment_type: contractData?.paymentData?.rateType,
        advanced_payment: contractData?.paymentData?.advancedPayment,
        remaining_amount: contractData?.paymentData?.remainingAmount,
        payment_duration: contractData?.paymentData?.paymentDuration,
        monthly_payment: contractData?.paymentData?.monthlyPayment,
      };

      const discount = {
        total_amount: contractData?.discountData?.total_amount || 0,
        discount_price:
          contractData?.discountData?.discount_price || 0,
        discount_type: contractData?.discountData?.discount_type || 'flat',
        grand_total_amount:
          contractData?.discountData?.grand_total_amount || 0,
      };

      const contract_policy = contractData?.contractPolicyData?.map(
        (policy) => ({
          policy_type: policy?.policyType,
          start_date: policy?.startDate,
          end_date: policy?.endDate,
          contract_policy_amount: policy?.rateValue,
          contract_policy_amount_type: policy?.rateType,
        })
      );

      const contractInformation = {
        contract_name: contractData?.contractInformation?.contract_name,
        contract_duration: contractData?.contractInformation?.contract_duration,
        contract_file: contractData?.contractInformation?.contract_file,
        start_date: contractData?.contractInformation?.start_date,
        end_date: contractData?.contractInformation?.end_date,
      };

      const pmf = contractData?.pmfList?.map((pmf) => ({
        shop: pmf?.shop,
        total_area: pmf?.totalArea?.toString() || "", // Convert total_area to string
        pmf_price: pmf?.rateValue,
      }));

      const invoice_payment_setting = {
        invoice_setting: contractData?.invoicePaymentData?.paymentMethod,
        invoice_payment: contractData?.invoicePaymentData?.invoicePayment,
      };

      const nrc = `${contractData?.newTenantInfo?.nrc?.region}/${contractData?.newTenantInfo?.nrc?.township}/${contractData?.newTenantInfo?.nrc?.type}/${contractData?.newTenantInfo?.nrc?.number}`;
      const payload = {
        property_type: contractData?.tenantType,
        tenant_category: contractData?.newTenantInfo?.tenant_category,
        tenant_name: contractData?.newTenantInfo?.tenant_name,
        photo: contractData?.newTenantInfo?.profile || "",
        nrc: nrc,
        email: contractData?.newTenantInfo?.email,
        phone_number: contractData?.newTenantInfo?.phoneNumber,
        city: contractData?.newTenantInfo?.city,
        township: contractData?.newTenantInfo?.township,
        address: contractData?.newTenantInfo?.address,
        status: contractData?.newTenantInfo?.status,
        password: contractData?.newTenantInfo?.password,
        billing_contract_person: billingContactPerson,
        property_information: contractData?.properties?.map((property) => ({
          branch: property?.branch,
          building: property?.building,
          floor: property?.floor,
          zone: property?.zone,
          shop: property?.shop,
          room_type: property?.room_type,
          total_area: property?.area?.toString() || "",
          price: property?.price,
        })),
        payment_type: paymentType,
        discount: discount,
        contract_policy: contract_policy,
        contract_information: contractInformation,
        invoice_payment_setting: invoice_payment_setting,
        pmf: pmf,
      };
      await addNewContract(payload).unwrap();
      message.success("New contract is created successfully!");
      navigate(`/${id}/contract/list`);
    } catch (error) {
      message.error(error?.data?.message);
    }
  };

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          { title: <Link to={`/${id}/contract/list`}>Contract List</Link> },
          { title: "Add New Contract" },
        ]}
      />
      <TenantInformation
        name={newTenantInfo?.tenant_name}
        date={dayjs().format("MMM DD, YYYY")}
        tenant_type={tenantType === "lease" ? "Lease" : "Owner"}
        nrc={`${newTenantInfo?.nrc?.region}/ ${newTenantInfo?.nrc?.township}(${newTenantInfo?.nrc?.type})/ ${newTenantInfo?.nrc?.number}`}
        email={newTenantInfo?.email}
        phone_number={newTenantInfo?.phoneNumber}
        address={newTenantInfo?.address}
        no_of_property={properties?.length}
        propertyList={properties}
        tenantCategoryName={newTenantInfo?.tenantCategoryName}
        cityName={newTenantInfo?.cityName}
        townshipName={newTenantInfo?.townshipName}
      />
      {tenantType === "lease" ? (
        <PaymentCard onPaymentChange={handlePaymentChange} />
      ) : (
        <OwnerPaymentCard onPaymentChange={handlePaymentChange} />
      )}
      <div className="extra-card">
        <Card>
          <Checkbox
            onChange={(e) =>
              updateContractData("isDiscountChecked", e.target.checked)
            }>
            <div className="extra-item">Add Discount</div>
          </Checkbox>
          {tenantType !== "lease" && (
            <div className="extra-item">
              <Checkbox
                onChange={(e) =>
                  updateContractData("isPmfChecked", e.target.checked)
                }>
                <div className="extra-item">Add Property Management Facility (PMF)</div>
              </Checkbox>
            </div>
          )}
          <div className="extra-item">
            <Checkbox
              onChange={(e) =>
                updateContractData("isContractPolicyChecked", e.target.checked)
              }>
              <div className="extra-item">Add Contract Policy</div>
            </Checkbox>
          </div>
        </Card>
      </div>
      {contractData.isDiscountChecked && (
        <AddDiscountCard
          discount={contractData.discountData}
          setDiscountData={(discountData) =>
            updateContractData("discountData", discountData)
          }
          totalAmount={contractData.paymentData?.totalAmount}
          currency={contractData.paymentData?.currency}
        />
      )}
      {contractData.isPmfChecked && (
        <PmfCard
          propertyList={contractData.properties}
          setPmf={(pmfData) =>
            updateContractData("pmfList", pmfData)
          }
        />
      )}
      {contractData.isContractPolicyChecked && (
        <ContractPolicy
          setContractPolicyData={(data) =>
            updateContractData("contractPolicyData", data)
          }
        />
      )}
      {tenantType === "lease" ? (
        <ContractInformationCard
          form={form}
          setContractInformation={(data) =>
            updateContractData("contractInformation", data)
          }
        />
      ) : (
        <ViewContractInfoCard
          setContractInformation={(data) =>
            updateContractData("contractInformation", data)
          }
          contract_info={contractInformation}
        />
      )}
      <InvoicePaymentSettingCard
        setInvoicePaymentData={(data) =>
          updateContractData("invoicePaymentData", data)
        }
        onInvoicePaymentChange={(payment) =>
          updateContractData("invoicePayment", payment)
        }
        onPaymentMethodChange={(method) =>
          updateContractData("paymentMethod", method)
        }
        onInvoiceCurrencyChange={(currency) =>
          updateContractData("currency", currency)
        }
      />
      <div className="add-branch-btn-gp">
        <Button className="btn" onClick={handlePreviousPage}>
          Previous
        </Button>
        <Button
          className="btn filter"
          loading={isLoadingNewContract}
          onClick={handleCreate}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default AddNewContract;
