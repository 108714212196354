import { Card, Form, Input, Select, Space } from "antd";
import React from "react";
import { IoWarning } from "react-icons/io5";

const ViewInvoiceSettingCard = ({ record }) => {
  return (
    <div>
      <Card title="View Invoice Setting">
        <Form
          layout="vertical"
          initialValues={{
            invoiceType: record?.invoiceType,
            paddingLeft: record?.paddingLeft,
            prefix: record?.prefix,
            suffix: record?.suffix,
            validity: record?.validity,
            due_validity: record?.due_validity,
            reminder: record?.reminder,
            penalty_fee: record?.penalty_fee,
            penalty_type: record?.penalty_type,
          }}
        >
          <div className="add-branch-form">
            <Form.Item
              name="invoiceType"
              className="add-branch-input"
              label="Invoice Type"
              rules={[{ required: true, message: "Invoice Type is required" }]}
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item
              name="paddingLeft"
              className="add-branch-input"
              label="Invoice Number Padding Left Zeros (Optional)"
              rules={[{ required: true, message: "Invoice Type is required" }]}
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item
              name="prefix"
              className="add-branch-input"
              label="Invoice Number Prefix (Optional)"
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item
              name="suffix"
              className="add-branch-input"
              label="Invoice Number Suffix (Optional)"
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item
              name="validity"
              className="add-branch-input"
              label="Invoice Validity (days) (Optional)"
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item
              name="due_validity"
              className="add-branch-input"
              label="Due Validity (days) (Optional)"
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item
              name="reminder"
              className="add-branch-input"
              label="Reminder (days) (Optional)"
            >
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>
            <Form.Item className="add-branch-input" label="Late Fee (Optional)">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item name="penalty_fee" noStyle>
                  <Input
                    readOnly
                    style={{ height: 44, width: "258px" }}
                  />
                </Form.Item>
                <div className="select-rate-type">
                  <Form.Item name="penalty_type" noStyle>
                    <Select
                      style={{ width: 130, height: 44 }}
                      disabled
                    >
                      <Select.Option value="flat_mmk">Flat (MMK)</Select.Option>
                      <Select.Option value="flat_usd">Flat (USD)</Select.Option>
                      <Select.Option value="percent">Percent (%)</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Space.Compact>
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <p
              style={{
                margin: "0",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                fontWeight: 600,
              }}
            >
              <IoWarning
                style={{ width: "24px", height: "24px", color: "#FFB326" }}
              />
              Reminder(days): Remind to admin before invoice validity.
            </p>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ViewInvoiceSettingCard;
