import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, ConfigProvider, Form, Input, Select, Space } from "antd";
import React from "react";

const { Option } = Select;

const ViewPmfCard = ({ pmf }) => {
  console.log("pmf", pmf);

  return (
    <div className="add-discount-card">
      {pmf.map((pmfItem, index) => (
        <Card
          key={pmfItem._id}
          title={index === 0 ? "Property Management Facility (PMF)" : null}
          style={{
            marginTop: index === 0 ? "15px" : "0px",
            boxShadow: index === 0 ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "none",
          }}
        >
          <Form layout="vertical" initialValues={{ 
            totalArea: pmfItem.total_area,
            rateValue: pmfItem.pmf_price,
            selectProperty: pmfItem.shop?.name,
            rateType: "usd", // Default to USD
          }}>
            <div className="add-branch-form">
              <Form.Item
                name="selectProperty"
                className="add-branch-input"
                label="Select Property"
                rules={[
                  { required: true, message: "Please select a property" },
                ]}
              >
                <Select style={{ height: 44 }} placeholder="Select Property" disabled>
                  <Option key={pmfItem.shop?._id} value={pmfItem.shop?._id}>
                    {pmfItem.shop?.name}
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="totalArea"
                className="add-branch-input"
                label="Total Area (sq ft)"
                rules={[
                  { required: true, message: "Total area is required" },
                ]}
              >
                <Input style={{ height: 44 }} placeholder="Total Area" readOnly />
              </Form.Item>

              <Form.Item className="add-branch-input" label="PMF Fee (USD)">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="rateValue"
                    noStyle
                    rules={[
                      { required: true, message: "PMF value is required" },
                    ]}
                  >
                    <Input style={{ height: 44 }} placeholder="PMF Value" readOnly />
                  </Form.Item>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorTextPlaceholder: "#fff",
                      },
                    }}
                  >
                    <Form.Item name="rateType" noStyle>
                      <Select
                        defaultValue="usd"
                        style={{ width: "110px", height: 44 }}
                        placeholder="PMF Type"
                        disabled
                      >
                        <Option value="usd">USD</Option>
                      </Select>
                    </Form.Item>
                  </ConfigProvider>
                </Space.Compact>
              </Form.Item>
            </div>
          </Form>
        </Card>
      ))}
    </div>
  );
};

export default ViewPmfCard;
