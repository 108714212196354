import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Breadcrumb,
  Upload,
  message,
  Button,
  Spin,
} from "antd";
import ImgCrop from "antd-img-crop";
import "./styles.css";
import {
  useGetAllCitiesListQuery,
  useGetTenantProfileByIdQuery,
  useGetTownshipByCityIdQuery,
  useUpdateTenantDetailMutation,
} from "../../features/tenant/tenantApi";
import { isJPGorPNG } from "../../utils";
const { Option } = Select;
const EditTenant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageObj, setImageObj] = useState(null);
  const [imgFileList, setImgFileList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const location = useLocation();
  const tenantId = location.state?.tenantId;

  const { data: tenantDetail, isLoading: isTenantInfoLoading } =
    useGetTenantProfileByIdQuery({ tenantId });
  const [updateTenantDetail, { isLoading: isUpdateLoading }] =
    useUpdateTenantDetailMutation();

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  // Populate form data and set selected city when tenantDetail is loaded
  useEffect(() => {
    if (tenantDetail) {
      form.setFieldsValue({
        name: tenantDetail.data.tenantName || "",
        nrc: tenantDetail.data.nrc || "",
        phoneNumber: tenantDetail.data.phone_number || "",
        email: tenantDetail.data.email || "",
        status: tenantDetail.data.status || 1,
        city: tenantDetail.data.city._id || "",
        township: tenantDetail.data.township._id || "",
        address: tenantDetail.data.address || "",
      });
      setSelectedCity(tenantDetail.data.city._id);
      if (tenantDetail.data.imageUrl) {
        setImgFileList([
          {
            uid: "-1",
            name: "Profile Image",
            status: "done",
            url: tenantDetail.data.imageUrl,
          },
        ]);
      }
    }
  }, [tenantDetail, form]);

  const handleChange = (info) => {
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
      setImgFileList(info.fileList);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return true;
  };

  const onFinish = async (values) => {
    const isProfileUpdate = Boolean(imgFileList[0]?.originFileObj);
    try {
      const payload = {
        photo: !isProfileUpdate ? imgFileList[0] : null,
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        city: values.city,
        township: values.township,
        address: values.address,
        status: values.status,
      };
      await updateTenantDetail({ params: payload, tenantId });
      message.success("Tenant is updated successfully.");
      navigate(`/${id}/tenant/list`);
    } catch (error) {
      message.error("Unable to update this tenant.");
    }
  };

  const handleShowPreview = () => {
    setShowPreviewModal(true);
  };

  const handlePreviewCancel = () => {
    setShowPreviewModal(false);
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/list`)}>
          Tenant List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Profile</Breadcrumb.Item>
      </Breadcrumb>
      {isTenantInfoLoading ? (
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginLeft: "8px" }}>Edit Tenant Info</span>
              </div>
            }>
            <Form.Item
              rules={[
                { required: true, message: "Please upload tenant profile" },
              ]}>
              <ImgCrop rotationSlider>
                <Upload
                  onPreview={handleShowPreview}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  listType="picture-circle"
                  accept=".png,.jpg,image/png,image/jpeg"
                  multiple={false}
                  maxCount={1}
                  fileList={imgFileList}>
                  {imgFileList.length >= 1 ? null : "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>

            <div className="billing-contact-form">
              <Form.Item
                rules={[
                  { required: true, message: "Please input the tenant name" },
                ]}
                className="tenant-label-group"
                label="Name"
                name="name">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input the tenant name" },
                ]}
                className="tenant-label-group"
                label="NRC"
                name="nrc">
                <Input style={{ height: 44 }} placeholder="NRC" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                className="tenant-label-group"
                name="phoneNumber"
                rules={[
                  { required: true, message: "Please input the phone number" },
                ]}>
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Email"
                rules={[{ required: true, message: "Please input email" }]}
                name="email">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item label="City" name="city"  className="tenant-label-group">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="township"
                label="Township"
                className="tenant-label-group">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: "Please input the address" },
                ]}
                label="Address"
                style={{ width: "100%" }}
                name="address">
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>
            </div>
            <Form.Item
              label="Status"
              className="tenant-label-group"
              rules={[
                {
                  required: true,
                  message: "Please choose the tenant's status",
                },
              ]}
              name="status">
              <Select style={{ height: 44 }} placeholder="Select Status">
                <Option value={1}>Active</Option>
                <Option value={2}>Suspend</Option>
              </Select>
            </Form.Item>
          </Card>

          <Form.Item>
            <div className="add-tenant-btn-group">
              <Button
                onClick={() => navigate(`/${id}/tenant/list`)}
                className="btn">
                Cancel
              </Button>
              <Button type="text" htmlType="submit" className="btn filter">
                Update
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default EditTenant;
