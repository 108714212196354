import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
} from "antd";
import React from "react";
import dayjs from "dayjs";

const { Option } = Select;

const AddContractPolicy = ({ contract_policy }) => {
  const [form] = Form.useForm();
  console.log("contract_policy", contract_policy);
  

  return (
    <div className="add-discount-card">
      {contract_policy?.map((policy, index) => (
        <Card
          key={policy._id}
          title={index === 0 ? "Add Contract Policy" : null} // Only show title for the first card
          style={{
            marginTop: index === 0 ? "15px" : "0px", // Apply margin only to the first card
            boxShadow: index === 0 ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "none", // Shadow only for the first card
            // border: index === 0 ? "1px solid #d9d9d9" : "none" // Border only for the first card
          }}>
          <Form form={form} layout="vertical">
            <div className="add-branch-form">
              <Form.Item
                name={`start_date_${index}`}
                className="add-branch-input"
                label="Start Date">
                <DatePicker
                  defaultValue={dayjs(policy.start_date)}
                  disabled
                  style={{ width: 345, height: 44, backgroundColor: "#F7F8F8" }}
                />
              </Form.Item>

              <Form.Item
                name={`end_date_${index}`}
                className="add-branch-input"
                label="End Date">
                <DatePicker
                  disabled
                  defaultValue={dayjs(policy.end_date)}
                  style={{ width: 345, height: 44, backgroundColor: "#F7F8F8" }}
                />
              </Form.Item>

              <Form.Item className="add-branch-input" label="Amount">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name={`contract_policy_amount_${index}`}
                    noStyle
                    rules={[
                      { required: true, message: "PMF value is required" },
                    ]}>
                    <Input
                      readOnly
                      defaultValue={policy.contract_policy_amount}
                      style={{ height: 44 }}
                      placeholder="PMF Value"
                    />
                  </Form.Item>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorTextPlaceholder: "#fff",
                      },
                    }}>
                    <div className="select-rate-type">
                      <Form.Item
                        name={`contract_policy_amount_type_${index}`}
                        noStyle
                        rules={[
                          { required: true, message: "Rate type is required" },
                        ]}>
                        <Select
                          disabled
                          defaultValue={policy.contract_policy_amount_type}
                          style={{ width: "110px", height: 44 }}
                          placeholder="PMF Type">
                          <Option value="percent">Percent</Option>
                          <Option value="flat">Flat</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </ConfigProvider>
                </Space.Compact>
              </Form.Item>
            </div>
            <div className="add-property-div">
              <Button className="contract-policy-btn">
                {policy.policy_type === "increasement" ? (
                  <PlusOutlined />
                ) : (
                  <MinusOutlined />
                )}
                {policy.policy_type === "increasement" ? "Increasement" : "Decreasement"} 
              </Button>
            </div>
          </Form>
        </Card>
      ))}
    </div>
  );
};

export default AddContractPolicy;
