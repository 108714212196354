import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutAccount, setCredentials } from "../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://tms-b.obs.com.mm/api/v3",
  // baseUrl: "http://localhost:4000/api/v1",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    // Retrieve the token from authSlice
    const token = getState().authSlice.token;
    const token2 = getState().authSlice.token2; // store token2 as token2

    // If token2 exists, use it, otherwise fallback to the original token
    if (token2) {
      headers.set("Authorization", `Bearer ${token2}`);
    } else if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403) {
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logoutAccount());
    }
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "baseApi",
  tagTypes: [
    "Auth",
    "BuildingList",
    "FloorList",
    "ZoneList",
    "ShopList",
    "UtilityList",
    "MaintenanceTypes",
    "AnnouncementList",
    "AdminList",
    "RoleList",
    "PermissionList",
    "TenantList",
    "TenantProfile",
    "TenantPropertyList",
    "SubTenantLists",
    "Admin",
    "CityList",
    "TownshipList",
    "TCList",
    "SubTenantProfile",
    "InvoiceList",
    "SubTenantPropertyList",
    "TenantPropertyPayment",
    "InvoiceSettingList",
    "BranchList",
    "TenantCategoryList",
    "CentralizedAdminList",
    "BUList",
    "CentralizedRoleList",
    "CAdminList",
    "BuData",
    "BranchList",
    "ContractSettingList",
    "RoomSettingList",
    "ExchangeRateList"
  ],
  endpoints: (builder) => ({}),
});
