import { Table, Space, Dropdown, Modal, message, Button } from "antd";
import React, { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  useDeletePropertyMutation,
  useGetTenantPropertyByIdQuery,
} from "../../../features/tenant/tenantApi";
import {
  EyeOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined
} from "@ant-design/icons";

const Property = ({ onEditDetail, onViewDetail, tenantId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);

  const {
    data: tenantProperty,
    isLoading,
    error,
  } = useGetTenantPropertyByIdQuery({ tenantId });

  console.log("Tenant Property", tenantProperty);
  const [deleteProperty] = useDeletePropertyMutation();

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteProperty({ propertyId: currentRecord.key }).unwrap();
        setIsModalOpen(false);
        message.success("Property is deleted successfully.");
      } catch (error) {
        console.error("Failed to delete property:", error);
        message.error("Failed to delete this property.");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const viewDetails = (record) => {
    onViewDetail({
      propertyId: record.key, // Pass the record ID
      type: record.type, // Pass the type (if available)
    });
  };

  const editDetails = (record) => {
    onEditDetail({
      propertyId: record.key,
      type: record.type,
    });
  };

  const items = (record) => [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: () => viewDetails(record),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: () => editDetails(record),
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: () => {
        setSelectedRecord(record);
        showModal(record);
      },
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Outlet Name",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    // {
    //   title: (
    //     <SettingsOutlinedIcon style={{ display: "flex", margin: "auto" }} />
    //   ),
    //   key: "",
    //   align: "center",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Dropdown
    //         menu={{
    //           items: items(record),
    //         }}
    //         placement="bottomRight">
    //         <Button style={{border: '1px solid darkblue'}}>Action <DownOutlined/></Button>
    //       </Dropdown>
    //     </Space>
    //   ),
    // },
  ];

  const tableData = tenantProperty?.data?.flatMap((item) =>
    item.property_information?.map((property) => ({
      key: property._id,
      shopName: property?.shop?.name || "Null",
      building: property?.building?.name,
      buildingId: property?.building?._id,
      floor: property?.floor?.name,
      zone: property?.zone?.name,
      type: item.property_type === "lease" ? "Lease" : "Owner", // Use property_type from item
    }))
  ) || [];  
  
  return (
    <>
      <Table columns={columns} dataSource={tableData}></Table>
      <Modal
        title="Are you sure to delete this property list?"
        open={isModalOpen}
        okText="Confirm"
        okType="danger"
        onOk={handleOk}
        onCancel={handleCancel}></Modal>
    </>
  );
};

export default Property;
