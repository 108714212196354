import {
  Card,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Button,
  message,
  Checkbox,
} from "antd";
import GenerateIcon from "../../assets/icons/refresh.svg";
import React, { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { isJPGorPNG } from "../../utils";
import { useGetAllTenantCategoryQuery } from "../../features/tenant/tenantCategoryApi";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../features/tenant/tenantApi";
import BillingContactPerson from "./BillingContactPerson";
import NrcInput from "../NrcInput";

const { Option } = Select;

const AddNewTenantInfoCard = ({
  tenantInfo,
  setTenantInfo,
  billingInfo,
  setBillingInfo,
  tenantType,
  setTenantType,
  form
}) => {
  const [imageObj, setImageObj] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [formState, setFormState] = useState({
    tenantCategory: null,
    tenantCategoryName: "",
    city: null,
    cityName: "",
    township: null,
    townshipName: "",
    tenantType: null,
    tenantName: "",
    phoneNumber: "",
    email: "",
    address: "",
    password: "",
    status: null,
  });
  // const [form] = Form.useForm();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const handleShowPreview = () => {
    setShowPreviewModal(true);
  };

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          preview: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword });
  };

  const { data: tenantCategoryList, loading: isLoadingTenantCategories } =
    useGetAllTenantCategoryQuery();
  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  useEffect(() => {
    if (tenantInfo?.city) {
      setSelectedCity(tenantInfo.city);
    }
  }, [tenantInfo]);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      const tenantInfo = form.getFieldsValue([
        "tenant_name",
        "phoneNumber",
        "email",
        "city",
        "township",
        "address",
      ]);
      setBillingInfo({
        billingName: tenantInfo.tenant_name,
        billingPhoneNumber: tenantInfo.phoneNumber,
        billingEmail: tenantInfo.email,
        billingCity: tenantInfo.city,
        billingTownship: tenantInfo.township,
        billingAddress: tenantInfo.address,
      });
    } else {
      setBillingInfo({});
    }
  };

  // Handle changes for tenant category, city, and township
  const handleCityChange = (value) => {
    const selectedCity = cityList?.data?.find((city) => city._id === value);
    setSelectedCity(value); // Update selectedCity for township query
    setFormState((prev) => ({
      ...prev,
      city: value,
      cityName: selectedCity?.city_name || "",
    }));
  };

  const handleTownshipChange = (value) => {
    const selectedTownship = townshipList?.data?.find(
      (township) => township._id === value
    );
    setFormState((prev) => ({
      ...prev,
      township: value,
      townshipName: selectedTownship?.township_name || "",
    }));
  };

  const handleTenantCategoryChange = (value) => {
    const selectedCategory = tenantCategoryList?.data?.find(
      (category) => category._id === value
    );
    setFormState((prev) => ({
      ...prev,
      tenantCategory: value,
      tenantCategoryName: selectedCategory?.tenant_category_name || "",
    }));
  };

  // Sync formState to tenantInfo once all changes are collected
  useEffect(() => {
    setTenantInfo(formState);
  }, [formState]);

  const handleTenantTypeChange = (e) => {
    setTenantType(e.target.value); // Update the tenantType in the parent
  };

  const initialFormValues =
    tenantInfo && Object.keys(tenantInfo).length > 0 ? tenantInfo : formState;

  const initialTenantTypeValue = tenantType ? tenantType : formState;
  useEffect(() => {
    // Sync tenantInfo or formState into form fields
    if (tenantInfo && Object.keys(tenantInfo).length > 0) {
      form.setFieldsValue(tenantInfo);
    } else {
      form.setFieldsValue(formState);
    }
  }, [tenantInfo, formState, form]);

  const handleValuesChange = (changedValues, allValues) => {
    setFormState((prev) => ({
      ...prev,
      ...allValues,
    }));
  };
  return (
    <div>
      <Card
        title="Tenant Information"
        extra={
          <div className="tenant-type">
            <Form.Item rules={[{ required: true }]}>
              <Radio.Group
                defaultValue={initialTenantTypeValue}
                onChange={handleTenantTypeChange}
                name="radiogroup">
                <Radio value="lease">Lease</Radio>
                <Radio value="own">Owner</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        }>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialFormValues}
          onValuesChange={handleValuesChange}>
          <Form.Item
            rules={[
              { required: true, message: "Please upload tenant profile" },
            ]}>
            <ImgCrop rotationSlider>
              <Upload
                onPreview={handleShowPreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                listType="picture-circle"
                accept=".png,.jpg,image/png,image/jpeg"
                multiple={false}
                maxCount={1}
                fileList={fileList}>
                {fileList.length >= 1 ? null : "+ Upload"}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <div className="billing-contact-form">
            <Form.Item
              className="tenant-label-group"
              label="Tenant Category"
              rules={[{ required: true }]}
              name="tenant_category">
              <Select
                style={{ height: 44 }}
                placeholder="Tenant Category"
                onChange={handleTenantCategoryChange}
                loading={isLoadingTenantCategories}>
                {isLoadingTenantCategories ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  tenantCategoryList?.data?.map((category) => (
                    <Option key={category._id} value={category._id}>
                      {category?.tenant_category_name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>

            <Form.Item
              label="Tenant Name"
              rules={[{ required: true }]}
              className="tenant-label-group"
              name="tenant_name">
              <Input style={{ height: 44 }} placeholder="Tenant Name" />
            </Form.Item>
            <NrcInput />
            <Form.Item
              label="Phone Number"
              className="tenant-label-group"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  pattern: /^[0-9]{11}$/, // Ensures exactly 11 digits
                  message:
                    "Phone number must be exactly 11 digits long and contain only numbers!",
                },
              ]}>
              <Input
                maxLength={11}
                onKeyDown={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                    e.preventDefault(); // Prevent non-numeric input
                  }
                }}
                style={{ height: 44 }}
                placeholder="Phone Number"
              />
            </Form.Item>

            <Form.Item
              className="tenant-label-group"
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
                { required: true, message: "Email is required!" },
              ]}>
              <Input style={{ height: 44 }} placeholder="Email" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              className="tenant-label-group"
              label="City"
              name="city">
              <Select
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={handleCityChange}
                loading={isCityListLoading}>
                {isCityListLoading ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              className="tenant-label-group"
              label="Township"
              name="township">
              <Select
                style={{ height: 44 }}
                placeholder="Select a township"
                onChange={handleTownshipChange}
                loading={isTownshipListLoading}>
                {isTownshipListLoading ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              label="Address"
              style={{ width: 1050 }}
              name="address">
              <Input style={{ height: 44 }} placeholder="Address" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              label="Status"
              className="tenant-label-group"
              name="status">
              <Select style={{ height: 44 }} placeholder="Select Status">
                <Option value={1}>Active</Option>
                <Option value={2}>Suspend</Option>
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              label="Generate Password"
              name="password"
              style={{ width: 400 }}
              className="tenant-label-group">
              <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                <Form.Item name="password" style={{ flex: 1, margin: 0 }}>
                  <Input.Password
                    style={{ height: 44 }}
                    placeholder="Set Password"
                  />
                </Form.Item>
                <Button
                  style={{ width: 44, height: 44 }}
                  onClick={handleGeneratePassword}>
                  <img
                    style={{ width: 20, height: 20 }}
                    src={GenerateIcon}
                    alt="Generate"
                  />
                </Button>
              </div>
            </Form.Item>
          </div>
          <Form.Item>
            <Checkbox onChange={handleCheckboxChange}>
              Billing contact person same as tenant info.
            </Checkbox>
          </Form.Item>
        </Form>
      </Card>
      <BillingContactPerson billingInfo={billingInfo} />
    </div>
  );
};

export default AddNewTenantInfoCard;
