import {
  Card,
  Form,
  Input,
  Button,
  Select,
  message,
  Breadcrumb,
  ConfigProvider,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetZoneByBuildingIdQuery,
  useUpdateShopMutation,
} from "./propertyApi";
import "../../pages/property/styles.css";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useGetAllRoomSettingQuery } from "../setting/roomSettingApi";

const { Option } = Select;

const EditShop = () => {
  const [form] = Form.useForm();
  const [updateShop, { isLoading }] = useUpdateShopMutation();
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation(); // Access the location object to get the passed state
  const { shop } = location.state;
  
  const [propertyTypeCode, setPropertyTypeCode] = useState(null);
  const [selectedPropertyPrice, setSelectedPropertyPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const { data: branchListData, isLoading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetBuildingsByBranchIdQuery(selectedBranch, { skip: !selectedBranch });
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });
  const { data: zonesData, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      { skip: !selectedFloor }
    );
  const { data: roomType, isLoading: isRoomTypeLoading } =
    useGetAllRoomSettingQuery();

    useEffect(() => {
      if (shop) {
        setSelectedBranch(shop.branchId);
        setSelectedBuilding(shop.buildingId);
        setSelectedFloor(shop.floorId);
    
        let propertyTypeValue;
        switch (shop.propertyType) {
          case "Rent":
            propertyTypeValue = 1;
            break;
          case "Sell":
            propertyTypeValue = 2;
            break;
          case "FOC":
            propertyTypeValue = 3;
            break;
          default:
            propertyTypeValue = null; // Handle unexpected values
        }
        form.setFieldsValue({
          property_type: propertyTypeValue, // Use the mapped value
          branch: shop.branchId,
          building: shop.buildingId,
          floor: shop.floorId,
          zone: shop.zoneId,
          name: shop.name,
          code: shop.code,
          total_area: shop.area,
          room_type: shop.roomTypeId,
          price: shop.price,
          status: shop.status === "Available" ? "1" : "2",
        });
      }
    }, [shop, form]);
    
  const handleZoneChange = (zoneId) => {
    form.setFieldsValue({ zone: zoneId });
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    setSelectedFloor(""); // Clear floor selection when building changes
    form.setFieldsValue({ floor: undefined, zone: undefined });
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
    form.setFieldsValue({ zone: undefined });
  };

  const handleFormCancel = () => {
    form.resetFields();
    navigate(`/${id}/property/add-shop`);
  };

  const handleSubmit = async () => {
    const values = form.getFieldsValue(); // Retrieve form values
    console.log("Form Values:", values); // Debug the form values
  
    if (!shop?.key) {
      message.error("Shop ID is missing. Cannot update shop.");
      console.error("Shop ID is missing:", shop); // Debug shop object
      return;
    }
  
    const payload = {
      building: values.building,
      floor: values.floor,
      zone: values.zone,
      status: values.status,
      total_area: values.total_area,
      property_type: values.property_type, 
      name: values.name,
      branch: values.branch,
      room_type: values.room_type,
      price: values.price ? values.price.replace(/,/g, "") : null, // Remove formatting if necessary
    };
  
    console.log("Payload to Update:", { shopId: shop.key, updatedData: payload }); // Debug payload
  
    try {
      await updateShop({ shopId: shop.key, updatedData: payload }).unwrap(); // Correctly pass shopId and updatedData
      message.success("Shop updated successfully!");
      form.resetFields(); // Reset form after success
      navigate(`/${id}/property/add-shop`);
    } catch (error) {
      message.error("Failed to update the shop.");
      console.error("API Error:", error); // Debug the error
    }
  };  

  const handlePropertyTypeChange = (propertyTypeId) => {
    // If FOC is selected, set price to 0 and return
    if (propertyTypeId === "3") {
      setSelectedPropertyPrice(0);
      setTotalPrice(0);
      form.setFieldsValue({ price: 0 });
      setPropertyTypeCode(3);
      return;
    }
  }

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/property/add-shop`}>Shop List</Link>,
          },
          { title: "Edit Shop" },
        ]}
      />
      <Card title="Edit Shop">
        <ConfigProvider theme={{ token: { colorBgContainer: "#f8f7f7" } }}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="view-detail-form">
              <Form.Item
                className="input-group"
                label="Project / Branch"
                name="branch"
                rules={[{ required: true, message: "Please select a branch" }]}>
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a branch"
                  loading={isLoadingBranch}
                  onChange={(value) => setSelectedBranch(value)}>
                  {branchListData?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Building"
                className="input-group"
                name="building"
                rules={[
                  { required: true, message: "Please select a building" },
                ]}>
                <Select
                  placeholder="Building"
                  style={{ height: 44 }}
                  onChange={handleBuildingChange}
                  loading={isLoadingBuildings}>
                  {buildingListData?.data?.map((building) => (
                    <Option key={building._id} value={building._id}>
                      {building.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Floor"
                className="input-group"
                name="floor"
                rules={[{ required: true, message: "Please select a floor" }]}>
                <Select
                  placeholder="Floor"
                  style={{ height: 44 }}
                  onChange={handleFloorChange}
                  loading={isLoadingFloors}
                  disabled={!selectedBuilding}>
                  {floorListData?.data?.map((floor) => (
                    <Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Zone"
                className="input-group"
                name="zone"
                rules={[{ required: true, message: "Please select a zone" }]}>
                <Select
                  placeholder="Zone"
                  style={{ height: 44 }}
                  onChange={handleZoneChange}
                  loading={isLoadingZones}
                  disabled={!selectedBuilding || !selectedFloor}>
                  {zonesData?.data?.zone?.map((zone) => (
                    <Option key={zone._id} value={zone._id}>
                      {zone.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="input-group"
                label="Room Type"
                name="room_type"
                rules={[
                  { required: true, message: "Please select the room type" },
                ]}>
                <Select style={{ height: 44 }} placeholder="Select Room Type">
                  {isRoomTypeLoading ? (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  ) : (
                    roomType?.data?.buildings?.map((building) => (
                      <Option key={building._id} value={building._id}>
                        {building.room_type}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                className="input-group"
                label="Property Type"
                name="property_type"
                rules={[
                  { required: true, message: "Please select property type" },
                ]}>
                <Select
                  onChange={handlePropertyTypeChange}
                  style={{ height: 44 }}
                  placeholder="Select Property Type">
                  <Option value={1}>Rent</Option>
                  <Option value={2}>Sell</Option>
                  <Option value={3}>Free of Charge (FOC)</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Name"
                className="input-group"
                name="name"
                rules={[{ required: true, message: "Please enter name" }]}>
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                label="Total Area (sq ft)"
                className="input-group"
                name="total_area"
                rules={[
                  { required: true, message: "Please enter the total area" },
                ]}>
                <Input style={{ height: 44 }} placeholder="Total Area" />
              </Form.Item>

              <Form.Item
                label="Price (MMK)"
                className="input-group"
                name="price"
                rules={[{ required: true, message: "Please enter a price" }]}>
                <Input style={{ height: 44 }} placeholder="Price (MMK)" />
              </Form.Item>
              <Form.Item
                className="input-group"
                label="Status"
                name="status"
                rules={[
                  { required: true, message: "Please select property type" },
                ]}>
                <Select style={{ height: 44 }} placeholder="Select Status">
                  <Option value="1">Available</Option>
                  <Option value="2">Unavailable</Option>
                </Select>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}>
              <Button className="btn" type="text" onClick={handleFormCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="btn update">
                Save Changes
              </Button>
            </div>
          </Form>
        </ConfigProvider>
      </Card>
    </>
  );
};

export default EditShop;
