import { Breadcrumb, Button, Card, Form, Input, Select, message } from "antd";
import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../tenant/tenantApi";
import {
  useUpdateBranchMutation,
} from "../../features/property/propertyApi"; // Import the mutation hook

const { Option } = Select;

const EditBranch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateBranch, { isLoading }] = useUpdateBranchMutation(); // Initialize the mutation hook
  const location = useLocation();
  const branchData = location.state?.branch;

  const cityId = branchData.city_id;

  const [selectedCity, setSelectedCity] = useState(cityId);

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

    const handleSubmit = async (values) => {
      const payload = {
        name: values.name,
        contact_person: values.contact_person,
        email: values.email,
        phone_number: values.phone_number,
        city: values.city,
        township: values.township,
        address: values.address,
      };
      try {
        // Ensure `branchData.key` exists and is valid
        if (!branchData?.key) {
          throw new Error("Branch ID is missing.");
        }
    
        await updateBranch({
          branchId: branchData.key, // Pass branchId
          updatedData: payload, // Pass the payload
        }).unwrap();
    
        message.success("Branch updated successfully!");
        navigate(`/${id}/property/add-project`);
      } catch (error) {
        message.error("Failed to update branch. Please try again.");
        console.error("Error updating branch:", error); // Debug error
      }
    };    

  const initialValues = {
    name: branchData?.branch || "",
    contact_person: branchData?.contact_person || "",
    email: branchData?.email || "",
    phone_number: branchData?.phone_number || "",
    city: branchData?.city_id || "",
    township: branchData?.township_id || "", // Keep this as township_id for the form submission
    address: branchData?.address || "", // Assuming address is also part of branchData
  };

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/property/add-project`}>Branches</Link>,
          },
          {
            title: "Edit Branch",
          },
        ]}
      />
      <Card title="Edit Branch">
        <Form
          initialValues={initialValues} // Set initial values for the form
          layout="vertical"
          onFinish={handleSubmit}>
          <div className="add-branch-form">
            <Form.Item
              label="Branch / Project"
              name="name"
              className="add-branch-input">
              <Input placeholder="Branch Name" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Contact Person"
              name="contact_person"
              className="add-branch-input">
              <Input placeholder="Name" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              className="add-branch-input"
              rules={[{ type: "email" }]} // Add validation rule
            >
              <Input placeholder="Email" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              className="add-branch-input">
              <Input placeholder="Phone Number" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="City" name="city" className="add-branch-input">
              <Select
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={(value) => setSelectedCity(value)}
                loading={isCityListLoading}>
                {cityList?.data?.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city?.city_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="township"
              label="Township"
              className="add-branch-input">
              <Select
                style={{ height: 44 }}
                placeholder="Select a township"
                loading={isTownshipListLoading}>
                {townshipList?.data?.map((township) => (
                  <Option key={township._id} value={township._id}>
                    {township?.township_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item label="Address" name="address" style={{ marginTop: 16 }}>
            <Input style={{ height: 44 }} />
          </Form.Item>
          <div className="add-branch-btn-gp">
            <Button
              onClick={() => navigate(`/${id}/property/add-project`)}
              type="text"
              className="btn">
              Cancel
            </Button>
            <Button type="text" htmlType="submit" className="btn filter">
              Update
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default EditBranch;
