import { Card, Form, Input, Button, Modal, Divider } from "antd";
import React, { useState } from "react";
import styles from "../property/styles.css";
import { AddNewContract, ContractSettingList } from "../../features";
import AddIcon from "@mui/icons-material/Add";

const { Search } = Input;

const Contract = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
    <div className="page-new-add">
        <h3 className="page-title">Contract Setting</h3>
        <div className="property-search">
          <Form.Item>
            <Button className="property-add-btn" onClick={showModal}>
              <AddIcon />
              Add Contract
            </Button>
          </Form.Item>
        </div>
      </div>
        <Form>
          <Form.Item>
            <ContractSettingList/>
          </Form.Item>
        </Form>

      <Modal
        width={880}
        title="Add New Contract"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <AddNewContract  handleOk={handleOk} handleCancel={handleCancel}  />
      </Modal>
    </>
  );
};

export default Contract;
