import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Table, message } from "antd";
import React, { useState, useEffect } from "react";
import { DeleteModal, ReusablePagination } from "../../component";
import EditRoomType from "./EditRoomType";
import { useGetAllRoomSettingQuery, useDeleteRoomTypeMutation, useUpdateRoomTypeMutation } from "./roomSettingApi";

const RoomTypeTable = ({ onTotalItems }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);

  const { data: roomListData, isLoading: isLoadingRoom } = useGetAllRoomSettingQuery();
  const [deleteRoomType, { isLoading: isDeleting }] = useDeleteRoomTypeMutation();
  const [updateRoomType] = useUpdateRoomTypeMutation(); // Mutation hook for updating

  const handleEditClick = (record) => {
    setSelectedRecord(record); // Set selected record
    setEditModalVisible(true); // Open edit modal
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setSelectedRecord(null); // Clear selected record on close
  };

  const showDeleteModal = (record) => {
    setCurrentRecord(record); // Set record to delete
    setIsDeleteModalVisible(true); // Show delete modal
  };

  const handleDeleteOk = async () => {
    try {
      await deleteRoomType({ roomTypeId: currentRecord.key }).unwrap();
      message.success("Room type deleted successfully!");
      setIsDeleteModalVisible(false); // Close the modal
      setCurrentRecord(null); // Clear selected record
    } catch (error) {
      message.error("Failed to delete room type.");
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setCurrentRecord(null); // Clear selected record on cancel
  };

  const items = [
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => handleEditClick(record),
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Room Types",
      dataIndex: "roomTypes",
      key: "roomTypes",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: 150,
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];
  
  const dataSource = roomListData?.data?.buildings?.map((roomType, index) => ({
    key: roomType?._id,
    no: index + 1,
    roomTypes: roomType?.room_type,
  }));

  const totalItems = roomListData?.data?.totalBuildings;

  useEffect(() => {
    if (totalItems !== undefined) {
      onTotalItems(totalItems);
    }
  }, [totalItems, onTotalItems]);

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <Table loading={isLoadingRoom || isDeleting} dataSource={paginatedData} pagination={false} columns={columns} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePaginationChange}
      />
      <EditRoomType
        record={selectedRecord}
        visible={isEditModalVisible}
        onClose={handleCloseEditModal}
        updateRoomType={updateRoomType} // Pass updateRoomType mutation to modal
      />
      <DeleteModal
        visible={isDeleteModalVisible}
        onConfirm={handleDeleteOk} // Confirm deletion
        onCancel={handleDeleteCancel}
      />
    </div>
  );
};

export default RoomTypeTable;
