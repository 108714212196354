import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Modal,
  Divider,
  ConfigProvider,
  Button,
} from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import "../main/main.css";
import { logoutAccount } from "../../features/auth/authSlice";
import Logo from "../../assets/Logo -01 1.png";
import { RxDashboard } from "react-icons/rx";
import { FaRegFileAlt } from "react-icons/fa";
import { BsPersonGear } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { GrCodeSandbox } from "react-icons/gr";

const { Header, Content, Sider } = Layout;

const siderStyle = {
  overflow: "auto",
  minHeight: "100vh",
  position: "fixed",
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarColor: "unset",
  transition: "width 0.2s",
};

function getItem(label, key, icon, path, children) {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
  };
}
const items = [
  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Dashboard</span>,
    "1",
    <RxDashboard className="icon-style" />,
    "/"
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Reporting</span>,
    "sub2",
    <FaRegFileAlt className="icon-style" />,
    null,
    [
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>User Logs</span>,
        "2",
        null,
        "/cd/reporting/user-logs"
      ),
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>Audit Logs</span>,
        "3",
        null,
        "/cd/reporting/audit-logs"
      ),
    ]
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Adminstrator</span>,
    "sub1",
    <BsPersonGear className="icon-style" />,
    null,
    [
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Adminstrator List
        </span>,
        "4",
        null,
        "/cd/adminstrator/list"
      ),
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Role & Permissions
        </span>,
        "5",
        null,
        "/cd/adminstrator/role-permission"
      ),
    ]
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Business Unit</span>,
    "sub4",
    <GrCodeSandbox className="icon-style" />,
    null,
    [
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Business Unit List
        </span>,
        "6",
        null,
        "/cd/business-unit/list"
      ),
    ]
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Setting</span>,
    "sub3",
    <IoSettingsOutline className="icon-style" />,
    null,
    [
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          General Setting
        </span>,
        "7",
        null,
        "/cd/setting/general"
      ),
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Email Setting
        </span>,
        "8",
        null,
        "/cd/setting/email"
      ),
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>SMS Setting</span>,
        "9",
        null,
        "/cd/setting/sms"
      ),
      getItem(
        <span style={{ fontSize: "16px", fontWeight: 400 }}>
          Payment Setting
        </span>,
        "10",
        null,
        "/cd/setting/payment"
      ),
    ]
  ),
];

const CentralizedLayout = () => {
  const userName = useSelector((state) => state.authSlice.name);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LogoutModalForm = ({ visible, handleOk, handleCancel }) => (
    <Modal
      title="Are you sure to logout?"
      open={visible}
      okButtonProps={{ type: "danger" }}
      onOk={handleOk}
      onCancel={handleCancel}
      centered></Modal>
  );

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const profileItems = [
    {
      label: <a> Edit Profile</a>,
      key: "edit",
    },
    {
      label: <a onClick={() => setLogoutModalVisible(true)}> Log out</a>,
      key: "logout",
    },
  ];

  const handleLogoutModalClose = () => {
    dispatch(logoutAccount());
    navigate("/sign-in", { replace: true });
  };

  const handleLogoutModalCancel = () => {
    setLogoutModalVisible(false);
  };
  const [selectedKey, setSelectedKey] = useState("1");
  const [openKeys, setOpenKeys] = useState([]);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    const storedOpenKeys = localStorage.getItem("openKeys");
    if (storedOpenKeys) {
      setOpenKeys(JSON.parse(storedOpenKeys));
    }
  }, []);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    const updatedOpenKeys = latestOpenKey ? [latestOpenKey] : [];
    setOpenKeys(updatedOpenKeys);
    localStorage.setItem("openKeys", JSON.stringify(updatedOpenKeys));
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 15,
          },
          components: {
            Layout: {
              headerBg: "box-shadow: 0px 3px 10px 0px #00000033;",
              headerPadding: 0,
              siderBg:
                "linear-gradient(128.88deg, #03045E 1.86%, #0057FF 100%)",
            },
            Menu: {
              itemBg: "transparent",
              itemHoverColor: collapsed ? "black" : "white",
              itemSelectedColor: "black",
              itemColor: collapsed ? "black" : "white",
            },
          },
        }}>
        <Layout hasSider>
          <Sider
            style={siderStyle}
            // collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={280} // Set the width of the sidebar when expanded
            collapsedWidth={80} // Set the width of the sidebar when collapsed
          >
            <div
              style={{
                height: 100,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div style={{ margin: "auto" }}>
                {!collapsed && (
                  <img
                    style={{ width: 120, height: 52 }}
                    src={Logo}
                    alt="Logo"
                  />
                )}
              </div>
              <div>
                <Button
                  style={{
                    border: "1px solid white",
                    width: "15px",
                    height: "50px",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    background: "#FFFFFF",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }}
                  onClick={toggleCollapsed}>
                  <div style={{ margin: "auto" }}>
                    {collapsed ? <RightOutlined /> : <LeftOutlined />}
                  </div>
                </Button>
              </div>
            </div>
            <Divider
              style={{ backgroundColor: "white", margin: 0, marginBottom: 20 }}
            />
            <div className="demo-logo-vertical" />
            <Menu
              selectedKeys={[selectedKey]}
              openKeys={openKeys}
              onClick={handleMenuClick}
              onOpenChange={handleOpenChange}
              mode="inline"
              items={items}
            />
          </Sider>

          <Layout
            style={{
              marginInlineStart: collapsed ? 80 : 280, // Adjust margin when collapsed
            }}>
            <Header
              style={{
                margin: "20px",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <div className="header">
                <div className="setting"></div>
                <div className="user-setting">
                  <Dropdown
                    menu={{ items: profileItems }}
                    trigger={["click"]}
                    placement="bottomRight"
                    arrow>
                    <a onClick={(e) => e.preventDefault()}>
                      <div className="user">
                        <Avatar size="small" icon={<UserOutlined />} />
                        <p className="user-profile" style={{ color: "black" }}>
                          {userName}
                        </p>
                      </div>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content style={{ margin: "0 24px 24px 24px", minHeight: "80vh" }}>
              <Outlet />
            </Content>
          </Layout>
          <LogoutModalForm
            visible={logoutModalVisible}
            handleOk={handleLogoutModalClose}
            handleCancel={handleLogoutModalCancel}
          />
        </Layout>
      </ConfigProvider>
    </>
  );
};
export default CentralizedLayout;
