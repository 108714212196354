import {
  Table,
  message,
  Button,
  Space,
  Dropdown,
  Tag,
  ConfigProvider,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllShopsQuery,
  useDeleteShopMutation
} from "./propertyApi";
import {
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { DeleteModal, ReusablePagination } from "../../component";
import { useNavigate, useParams } from "react-router-dom";
import ViewShop from "./ViewShop";

const ShopList = ({ searchTerm, selectedBranchFromFilter, selectedFloorFromFilter, selectedBuildingFromFilter, selectedZoneFromFilter, selectedRoomTypeFromFilter, selectedPropertyTypeFromFilter, selectedStatusFromFilter}) => {
  const token = useSelector((state) => state.authSlice.token2);
  const { data: shopListData, isLoading: isLoadingShops } =
    useGetAllShopsQuery(token);
  const [deleteShop] = useDeleteShopMutation();
  const navigate = useNavigate();
  const { id } = useParams(); // Accessing the dynamic `id` parameter
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredShop, setFilteredShop] = useState([]);
  
  useEffect(() => {
    if (shopListData) {
      const filteredData = shopListData?.data?.filter((shop) => {
        const matchesSearch = shop.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesBranch = selectedBranchFromFilter
          ? shop.branch?._id === selectedBranchFromFilter
          : true;
        const matchesBuilding = selectedBuildingFromFilter
          ? shop?.building?._id === selectedBuildingFromFilter
          : true;
        const matchesFloor = selectedFloorFromFilter
          ? shop?.floor?._id === selectedFloorFromFilter
          : true;
        const matchesZone = selectedZoneFromFilter
          ? shop?.zone?._id === selectedZoneFromFilter
          : true;
        const matchesPropertyType = selectedPropertyTypeFromFilter
          ? shop?.property_type === selectedPropertyTypeFromFilter
          : true;
        const matchesStatus =
          selectedStatusFromFilter !== null ? shop?.status === selectedStatusFromFilter : true;
        const matchesRoomType = selectedRoomTypeFromFilter
          ? shop?.room_type?._id === selectedRoomTypeFromFilter
          : true;
        return (
          matchesSearch &&
          matchesBranch &&
          matchesBuilding &&
          matchesFloor &&
          matchesZone &&
          matchesPropertyType &&
          matchesStatus &&
          matchesRoomType
        );
      });
      setFilteredShop(filteredData);
    }
  }, [
    searchTerm,
    shopListData,
    selectedBranchFromFilter,
    selectedBuildingFromFilter,
    selectedFloorFromFilter,
    selectedZoneFromFilter,
    selectedPropertyTypeFromFilter,
    selectedStatusFromFilter,
    selectedRoomTypeFromFilter,
  ]);

  const formatPrice = (price) =>
    `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const handleViewDetails = (shop) => {
    setSelectedShop(shop);
    setIsViewModalOpen(true);
  };

  const handleViewCancel = () => {
    setIsViewModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: (shop) => handleViewDetails(shop),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => navigateToEdit(record),
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Project / Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone / View",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Room / Shop Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Total Area (sq ft)",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Property Type",
      dataIndex: "propertyType",
      key: "propertyType",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Available":
            color = "green";
            break;
          case "Unavailable":
            color = "red";
            break;
          default:
            color = "gray"; // Set a default color for any unexpected status
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = filteredShop?.map((shop, index) => ({
    key: shop?._id,
    no: index + 1,
    building: shop?.building?.name,
    floor: shop?.floor?.name,
    area: formatPrice(shop?.total_area),
    price: formatPrice(shop?.price),
    branch: shop?.branch?.name,
    branchId: shop?.branch?._id,
    zone: shop?.zone?.name,
    buildingId: shop?.building?._id,
    floorId: shop?.floor?._id,
    zoneId: shop?.zone?._id,
    name: shop?.name,
    roomTypeId: shop?.room_type?._id,
    roomType: shop?.room_type?.room_type,
    propertyType: shop?.property_type === 1 ? "Rent" : "Sell",
    status: shop.status === 1 ? "Available" : "Unavailable",
  }));

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    try {
      await deleteShop({ shopId: currentRecord.key }).unwrap();
      message.success("Shop deleted successfully!");
    } catch (error) {
      message.error("Failed to delete the shop.");
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const navigateToEdit = (record) => {
    navigate(`/${id}/property/add-shop/edit-shop`, { state: { shop: record } });
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#f8f7f7",
          colorBgContainerDisabled: "rgba(0, 0, 0, 0.08)",
        },
      }}>
      <Table
        columns={columns}
        dataSource={paginatedData}
        loading={isLoadingShops}
        pagination={false}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={shopListData?.data?.length}
        onChange={handlePaginationChange}
      />
      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <ViewShop
        visible={isViewModalOpen}
        onCancel={handleViewCancel}
        selectedShop={selectedShop}
      />
    </ConfigProvider>
  );
};

export default ShopList;
