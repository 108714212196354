import {
  Card,
  Form,
  Input,
  Button,
  Select,
  message,
  Breadcrumb,
  ConfigProvider,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  useAddNewShopMutation,
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
  useGetFloorsByBuildingIdQuery,
  useGetZoneByBuildingIdQuery,
} from "./propertyApi";
import "../../pages/property/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetAllRoomSettingQuery } from "../setting/roomSettingApi";

const { Option } = Select;

const AddNewShop = () => {
  const [form] = Form.useForm();
  const [addNewShop, { isLoading }] = useAddNewShopMutation();
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedPropertyPrice, setSelectedPropertyPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [propertyTypeCode, setPropertyTypeCode] = useState(null);
  const [area, setArea] = useState(0); // Store area value

  const navigate = useNavigate();
  const { id } = useParams();

  const { data: branchListData, isLoading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetBuildingsByBranchIdQuery(selectedBranch, { skip: !selectedBranch });
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });
  const { data: zonesData, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      { skip: !selectedFloor }
    );
  const { data: roomType, isLoading: isRoomTypeLoading } =
    useGetAllRoomSettingQuery();

  const propertyTypeMapping = { Rent: 1, Sell: 2, FOC: 3 };

  const handleZoneChange = (zoneId) => {
    form.setFieldsValue({ zone: zoneId });
    const selectedZone = zonesData?.data?.zone.find(
      (zone) => zone._id === zoneId
    );

    if (selectedZone) {
      setPropertyTypes(zonesData.data.propertyType || []);
      form.setFieldsValue({ property_type: undefined, price: 0 });
      setTotalPrice(0);
    }
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    setSelectedFloor("");
    form.setFieldsValue({ floor: undefined, zone: undefined });
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
    form.setFieldsValue({ zone: undefined });
  };

  const handleFormCancel = () => {
    form.resetFields();
    navigate(`/${id}/property/add-shop`);
  };

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const payload = {
      ...values,
      property_type: propertyTypeCode,
    };

    try {
      await addNewShop(payload).unwrap();
      message.success("Shop added successfully!");
      navigate(`/${id}/property/add-shop`);
      form.resetFields();
    } catch (error) {
      message.error("Failed to add the shop.");
    }
  };

  const handlePropertyTypeChange = (propertyTypeId) => {
    // If FOC is selected, set price to 0 and return
    if (propertyTypeId === "FOC") {
      setSelectedPropertyPrice(0);
      setTotalPrice(0);
      form.setFieldsValue({ price: 0 });
      setPropertyTypeCode(propertyTypeMapping.FOC);
      return;
    }

    // Find the selected type in the available propertyTypes
    const selectedType = propertyTypes.find(
      (type) =>
        type._id === propertyTypeId || type.property_type === propertyTypeId
    );

    if (selectedType) {
      setSelectedPropertyPrice(selectedType.price_per_sq);
      setTotalPrice(selectedType.price_per_sq * area); // Calculate total price based on area and price per sq ft
      form.setFieldsValue({ price: selectedType.price_per_sq * area });
      setPropertyTypeCode(propertyTypeMapping[selectedType.property_type]);
    } else {
      console.warn("Property Type not found for ID:", propertyTypeId);
    }
  };

  const handleAreaChange = (e) => {
    const inputArea = parseFloat(e.target.value) || 0;
    setArea(inputArea); // Update area state
    setTotalPrice(inputArea * selectedPropertyPrice); // Calculate price based on area and property price
    form.setFieldsValue({ price: inputArea * selectedPropertyPrice });
  };

  useEffect(() => {
    form.setFieldsValue({ price: totalPrice });
  }, [totalPrice, form]);

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          { title: <Link to={`/${id}/property/add-shop`}>Shop List</Link> },
          { title: "Create New Shop" },
        ]}
      />
      <Card title="Create New Shop">
        <ConfigProvider theme={{ token: { colorBgContainer: "#f8f7f7" } }}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="view-detail-form">
              {/* Project / Branch Field */}
              <Form.Item
                className="input-group"
                label="Project / Branch"
                name="branch"
                rules={[{ required: true, message: "Please select a branch" }]}>
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a branch"
                  onChange={(value) => setSelectedBranch(value)}>
                  {isLoadingBranch ? (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  ) : (
                    branchListData?.data?.map((branch) => (
                      <Option key={branch._id} value={branch._id}>
                        {branch.name}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Building Field */}
              <Form.Item
                label="Building"
                className="input-group"
                name="building"
                rules={[
                  { required: true, message: "Please select a building" },
                ]}>
                <Select
                  placeholder="Building"
                  style={{ height: 44 }}
                  onChange={handleBuildingChange}
                  disabled={!selectedBranch}>
                  {isLoadingBuildings ? (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  ) : (
                    buildingListData?.data?.map((building) => (
                      <Option key={building._id} value={building._id}>
                        {building.name}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Floor Field */}
              <Form.Item
                label="Floor"
                className="input-group"
                name="floor"
                rules={[{ required: true, message: "Please select a floor" }]}>
                <Select
                  placeholder="Floor"
                  style={{ height: 44 }}
                  onChange={handleFloorChange}
                  disabled={!selectedBuilding}>
                  {isLoadingFloors ? (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  ) : (
                    floorListData?.data?.map((floor) => (
                      <Option key={floor._id} value={floor._id}>
                        {floor.name}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Zone Field */}
              <Form.Item
                label="Zone"
                className="input-group"
                name="zone"
                rules={[{ required: true, message: "Please select a zone" }]}>
                <Select
                  placeholder="Zone"
                  style={{ height: 44 }}
                  onChange={handleZoneChange}
                  disabled={!selectedBuilding || !selectedFloor}>
                  {isLoadingZones ? (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  ) : (
                    zonesData?.data?.zone?.map((zone) => (
                      <Option key={zone._id} value={zone._id}>
                        {zone.name}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Property Type Field */}
              <Form.Item
                className="input-group"
                label="Property Type"
                name="property_type"
                rules={[
                  { required: true, message: "Please select property type" },
                ]}>
                <Select
                  onChange={handlePropertyTypeChange}
                  style={{ height: 44 }}
                  placeholder="Select Property Type">
                  <Option value="Rent">Rent</Option>
                  <Option value="Sell">Sell</Option>
                  <Option value="FOC">Free of Charge (FOC)</Option>
                </Select>
              </Form.Item>

              {/* Room Type Field */}
              <Form.Item
                className="input-group"
                label="Room Type"
                name="room_type"
                rules={[
                  { required: true, message: "Please select the room type" },
                ]}>
                <Select style={{ height: 44 }} placeholder="Select Room Type">
                  {isRoomTypeLoading ? (
                    <Option key="loading" disabled>
                      Loading...
                    </Option>
                  ) : (
                    roomType?.data?.buildings?.map((building) => (
                      <Option key={building._id} value={building._id}>
                        {building.room_type}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Room / Shop Name */}
              <Form.Item
                label="Room / Shop Name"
                className="input-group"
                name="name"
                rules={[
                  { required: true, message: "Please enter room/shop name" },
                ]}>
                <Input style={{ height: 44 }} placeholder="Room / Shop Name" />
              </Form.Item>

              {/* Total Area Field */}
              <Form.Item
                label="Total Area (sq ft)"
                className="input-group"
                name="total_area"
                rules={[
                  { required: true, message: "Please enter the total area" },
                ]}>
                <Input
                type="number"
                min={1}
                  onChange={handleAreaChange}
                  style={{ height: 44 }}
                  placeholder="Total Area"
                />
              </Form.Item>

              {/* Price Field */}
              <Form.Item
                label="Price (MMK)"
                className="input-group"
                name="price"
                rules={[{ required: true, message: "Please enter a price" }]}>
                <Input
                  readOnly
                  value={totalPrice}
                  style={{ height: 44 }}
                  placeholder="Price (MMK)"
                />
              </Form.Item>

              {/* Status Field */}
              <Form.Item
                label="Status"
                className="input-group"
                name="status"
                rules={[
                  { required: true, message: "Please choose the status" },
                ]}>
                <Select style={{ height: 44 }} placeholder="Select Status">
                  <Option value="1" key="1">
                    Available
                  </Option>
                  <Option value="2" key="2">
                    Unavailable
                  </Option>
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}>
              <Button className="btn" type="text" onClick={handleFormCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="btn update">
                CREATE
              </Button>
            </div>
          </Form>
        </ConfigProvider>
      </Card>
    </>
  );
};

export default AddNewShop;
