import React, { useState } from "react";
import {
  AddContractPolicy,
  TenantInformation,
  ViewAddDiscount,
  ViewInvoicePaymentSetting,
  ViewLeaseContract,
  ViewLeasePaymentCard,
  ViewOwnContract,
  ViewOwnPayment,
  ViewPmfCard,
} from "../../component";
import { useLocation } from "react-router-dom";
import { useGetContractByIdQuery } from "./contractApi";
import dayjs from "dayjs";
import { Card, Checkbox } from "antd";

const ViewContract = () => {
  const location = useLocation();
  const contractId = location.state.contract.key;
  const { data: contractData, isLoading: isLoadingContract } =
    useGetContractByIdQuery({ contractId });

  const tenant = contractData?.data?.tenant;
  const contractInfo = contractData?.data?.contract_information;
  const billingContact = contractData?.data?.billing_contract_person;
  const paymentDetails = contractData?.data?.payment_type;
  const discount = contractData?.data?.discount;
  const propertyList = contractData?.data?.property_information;
  const invoicePayment = contractData?.data?.invoice_payment_setting;
  const payment = contractData?.data?.payment_type;
  const pmf = contractData?.data?.pmf;
  const contractPolicy = contractData?.data?.contract_policy;
  console.log("contract", contractData);

  // Initialize states with true to keep checkboxes checked by default
  const [isDiscountChecked, setIsDiscountChecked] = useState(false);
  const [isContractPolicyChecked, setIsContractPolicyChecked] = useState(false);
  const [isPMFChecked, setIsPMFChecked] = useState(false);

  const handleDiscountCheckbox = (e) => {
    setIsDiscountChecked(e.target.checked);
  };

  const handleContractPolicyCheckbox = (e) => {
    setIsContractPolicyChecked(e.target.checked);
  };

  const handlePMFCheckbox = (e) => {
    setIsPMFChecked(e.target.checked);
  };

  return (
    <div>
      <TenantInformation
        name={tenant?.tenant_name}
        date={dayjs(contractInfo?.start_date).format("MMM DD, YYYY")}
        tenant_type={
          contractData?.data?.property_type === "own" ? "Owner" : "Lease"
        }
        tenantCategoryName={tenant?.tenant_category?.tenant_category_name}
        nrc={tenant?.nrc}
        email={tenant?.email}
        phone_number={tenant?.phone_number}
        cityName={billingContact?.city?.city_name}
        townshipName={billingContact?.township?.township_name}
        address={billingContact?.address}
        no_of_property={propertyList?.length}
        paymentDetails={paymentDetails}
        discount={discount}
        propertyList={propertyList}
      />
      {contractData?.data?.property_type === "lease" ? (
        <ViewLeasePaymentCard
          total={payment?.total_amount}
          type={payment?.type}
          advanced={payment?.advanced_payment}
        />
      ) : (
        <ViewOwnPayment payment_info={payment} />
      )}
      <div className="extra-card">
        <Card>
          <div className="extra-item">
            <Checkbox
              checked={isDiscountChecked}
              onChange={handleDiscountCheckbox}
            />
            &nbsp;&nbsp;Add Discount
          </div>
          {contractData?.data?.property_type !== "lease" && (
            <div className="extra-item">
              <Checkbox checked={isPMFChecked} onChange={handlePMFCheckbox}>
                <div className="extra-item">
                  Add Property Management Facility (PMF)
                </div>
              </Checkbox>
            </div>
          )}
           <div className="extra-item">
            <Checkbox
              checked={isContractPolicyChecked}
              onChange={handleContractPolicyCheckbox}
            />
            &nbsp;&nbsp;Add Contract Policy
          </div>
        </Card>
      </div>
      {isDiscountChecked && (
        <ViewAddDiscount currency={payment?.type} discount={discount} />
      )}
      {isPMFChecked && <ViewPmfCard pmf={pmf} />}
      {isContractPolicyChecked && (
        <AddContractPolicy contract_policy={contractPolicy} />
      )}
      {contractData?.data?.property_type === "lease" ? (
        <ViewLeaseContract contract_info={contractInfo} />
      ) : (
        <ViewOwnContract contract_info={contractInfo} />
      )}
      <ViewInvoicePaymentSetting invoicePayment={invoicePayment} />
    </div>
  );
};

export default ViewContract;
