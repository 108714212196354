import { Breadcrumb, Button, Card, Form, Radio, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetAllTenantQuery } from "../tenant/tenantApi";
import PropertyInformation from "../../component/contract/PropertyInformation";

const { Option } = Select;

const ExistingContract = () => {
  const location = useLocation();
  const initialProperties = location.state?.properties || [];
  const initialTenantType = location.state?.tenantType || "lease";
  const initialTenantId = location.state?.tenantId || null;

  const [formState, setFormState] = useState([]);
  const [properties, setProperties] = useState(initialProperties);
  const [tenantType, setTenantType] = useState(initialTenantType);
  const [tenantId, setTenantId] = useState(initialTenantId);
  const [form] = Form.useForm();
  const { id } = useParams();
  const { data: tenantList, loading: isLoadingTenant } = useGetAllTenantQuery();
  const navigate = useNavigate();
  console.log("form State", formState);

  useEffect(() => {
    if (!tenantType) {
      setTenantType("lease");
    }
  }, [tenantType]);

  const handleNextPage = () => {
    form
      .validateFields()
      .then(() => {
        const isFormStateValid = Object.keys(formState).length > 0;

        if (isFormStateValid) {
          const updatedProperties = [...properties, formState];
          setProperties(updatedProperties);

          navigate(`/${id}/contract/list/add-contract`, {
            state: { properties: updatedProperties, tenantType, tenantId },
          });
        } else {
          navigate(`/${id}/contract/list/add-contract`, {
            state: { properties, tenantType, tenantId },
          });
        }
      })
      .catch((errorInfo) => {
        console.error("Validation error:", errorInfo);
        message.error("Please fill out all required fields correctly before proceeding.");
      });
  };

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/contract/list`}>Contract List</Link>,
          },
          {
            title: "Add Property Information",
          },
        ]}
      />
      <Form layout="vertical" form={form}>
        <Card
          title="Tenant Information"
          extra={
            <div className="tenant-type">
              <Form.Item name="tenant_type">
                <Radio.Group
                  defaultValue={initialTenantType}
                  onChange={(e) => setTenantType(e.target.value)} // Set tenant type
                  name="radiogroup">
                  <Radio value="lease">Lease</Radio>
                  <Radio value="own">Owner</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          }>
          <Select
            style={{ height: 44 }}
            defaultValue={initialTenantId}
            className="add-branch-input"
            placeholder="Select Tenant Name"
            loading={isLoadingTenant}
            onChange={(value) => setTenantId(value)} // Set tenant ID
          >
            {tenantList?.data?.map((tenant) => (
              <Option key={tenant._id} value={tenant._id}>
                {tenant?.tenant_name}
              </Option>
            ))}
          </Select>
        </Card>

        <PropertyInformation
          properties={properties}
          setFormState={setFormState}
          initialProperties={initialProperties}
          setProperties={setProperties}
        />

        <div className="add-branch-btn-gp">
          <Button className="btn" type="text">
            Cancel
          </Button>
          <Button onClick={handleNextPage} className="btn filter">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ExistingContract;
