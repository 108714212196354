import { Modal, Button, Form, Select, Input, Card } from "antd";
import React, { useState, useEffect } from "react";
import {
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
  useGetFloorsByBuildingIdQuery,
} from "./propertyApi";

const { Option } = Select;

const EditZoneModal = ({ currentRecord, visible, onOk, onCancel, form }) => {
  const [selectedBranch, setSelectedBranch] = useState(currentRecord?.branchId);
  const [selectedBuilding, setSelectedBuilding] = useState(currentRecord?.buildingId);

  // Fetch branches, buildings, and floors
  const { data: branchListData, isLoading: isLoadingBranch } = useGetAllBranchesQuery();
  const { data: buildingListData, isLoading: isLoadingBuildings } = useGetBuildingsByBranchIdQuery(selectedBranch, { skip: !selectedBranch });
  const { data: floorListData, isLoading: isLoadingFloors } = useGetFloorsByBuildingIdQuery(selectedBuilding, { skip: !selectedBuilding });

  // Set initial form values based on `currentRecord`
  useEffect(() => {
    if (currentRecord) {
      setSelectedBranch(currentRecord.branchId);
      setSelectedBuilding(currentRecord.buildingId);
      form.setFieldsValue({
        branch: currentRecord.branchId,
        building: currentRecord.buildingId,
        floor: currentRecord.floorId,
        zone: currentRecord.zone,
      });
    }
  }, [currentRecord, form]);

  // Handle branch change to clear dependent fields
  const handleBranchChange = (value) => {
    setSelectedBranch(value);
    setSelectedBuilding(null); // Clear building selection
    form.setFieldsValue({
      building: undefined, // Clear building in the form
      floor: undefined, // Clear floor in the form
    });
  };

  // Handle building change to clear the floor field
  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    form.setFieldsValue({ floor: undefined });
  };

  return (
    <Modal
      width={880}
      onCancel={onCancel}
      className="bu-add-modal"
      open={visible}
      closeIcon={false}
      footer={null}
    >
      <Card title="Edit Zone">
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            branch: currentRecord?.branchId,
            building: currentRecord?.buildingId,
            floor: currentRecord?.floorId,
            zone: currentRecord?.zone,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", flexWrap: "wrap" }}>
            <Form.Item label="Project / Branch" name="branch">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a branch"
                loading={isLoadingBranch}
                onChange={handleBranchChange}
              >
                {branchListData?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Building" name="building">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a building"
                loading={isLoadingBuildings}
                onChange={handleBuildingChange}
                disabled={!selectedBranch}
              >
                {buildingListData?.data?.map((building) => (
                  <Option key={building._id} value={building._id}>
                    {building.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Floor Name" name="floor">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a floor"
                loading={isLoadingFloors}
                disabled={!selectedBuilding}
              >
                {floorListData?.data?.map((floor) => (
                  <Option key={floor._id} value={floor._id}>
                    {floor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ width: 400 }} label="Zone / Role" name="zone">
              <Input style={{ height: 44 }} placeholder="Zone / Role" />
            </Form.Item>
          </div>
          <div className="add-branch-btn-gp">
            <Button type="text" className="btn" key="back" onClick={onCancel}>
              Cancel
            </Button>
            <Button className="btn filter" key="submit" onClick={onOk}>
              Update
            </Button>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditZoneModal;
