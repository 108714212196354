import React from "react";
import { Card, List, Spin, Alert, Row, Col, Tag, Button, Avatar } from "antd";
import { useGetTenantProfileByIdQuery } from "../../../features/tenant/tenantApi";
import "./styles.css";
import { useGetSubTenantProfileByIdQuery } from "../../../features/tenant/subTenantApi";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

const Profile = ({ tenantId, subTenantId }) => {
  const tenantProfileQuery = useGetTenantProfileByIdQuery(
    { tenantId },
    { skip: !tenantId }
  );
  const subTenantProfileQuery = useGetSubTenantProfileByIdQuery(
    { subTenantId },
    { skip: !subTenantId }
  );

  const navigate = useNavigate();
  const { id } = useParams();

  const isLoading = tenantId
    ? tenantProfileQuery.isLoading
    : subTenantProfileQuery.isLoading;
  const error = tenantId
    ? tenantProfileQuery.error
    : subTenantProfileQuery.error;
  const tenantProfile = tenantId
    ? tenantProfileQuery.data
    : subTenantProfileQuery.data;

  return (
    <div className="tenant-profile-card">
      <Card>
        {isLoading ? (
          <Spin />
        ) : error ? (
          <Alert message="Error fetching profile" type="error" />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                width: "130px",
              }}>
              <Avatar
                size={100}
                icon={<UserOutlined/>}
                style={{
                  border: "4px solid white",
                  marginTop: "-90px",
                  alignSelf: "center",
                  zIndex: 999,
                }}
              />
            </div>
            <div className="profile-edit-btn">
            <Button
            style={{color: "#0057FF"}}
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                if (tenantId) {
                  // Navigate to tenant edit route
                  navigate(`/${id}/tenant/list/edit-tenant`, {
                    state: { tenantId: tenantId },
                  });
                } else if (subTenantId) {
                  // Navigate to sub-tenant edit route
                  navigate(`/${id}/tenant/sub-list/edit`, {
                    state: { subTenantId: subTenantId },
                  });
                }
              }}
            >Edit</Button>
            </div>
            <Row gutter={[16]}>
              {/* Property Type and Building */}
              <Col span={6}>
                <p>Tenant Name</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>
                  {tenantId
                    ? tenantProfile?.data?.tenantName
                    : tenantProfile?.data?.name}
                </p>
              </Col>

              <Col span={6}>
                <p>Phone Number</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>{tenantProfile?.data?.phone_number}</p>
              </Col>

              <Col span={6}>
                <p>Email Address</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>{tenantProfile?.data?.email}</p>
              </Col>

              <Col span={6}>
                <p>Status</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>
                  <Tag
                    color={tenantProfile?.data?.status === 1 ? "green" : "red"}>
                    {tenantProfile?.data?.status === 1 ? "Active" : "Inactive"}
                  </Tag>
                </p>
              </Col>

              <Col span={6}>
                <p>City</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>{tenantProfile?.data?.city?.city_name}</p>
              </Col>

              <Col span={6}>
                <p>Township</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>{tenantProfile?.data?.township?.township_name}</p>
              </Col>

              <Col span={6}>
                <p>Address</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>{tenantProfile?.data?.address}</p>
              </Col>

              <Col span={6}>
                <p>Number of Shops</p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={12}>
                <p>
                  {tenantId
                    ? tenantProfile?.data?.numberOfShops
                    : tenantProfile?.data?.numberOfSop}
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </div>
  );
};

export default Profile;
