import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const PageHeader = ({ title, totalItems, linkTo, addBtn, totalName, onAdd }) => {
  return (
    <div className="page-new-add">
      <div>
        <h3
          className="page-title"
          style={{ marginBottom: totalItems !== undefined ? "initial" : "0" }}
        >
          {title}
        </h3>
        {totalItems !== undefined && totalName && (
          <p className="total-number">
            Total {totalName}:
            <div className="total-no-display">{totalItems}</div>
          </p>
        )}
      </div>
      <div className="property-search">
        {addBtn && (
          <Link to={linkTo}>
            <Button onClick={onAdd} className="property-add-btn">
              <AddIcon />
              {addBtn}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
