import { Breadcrumb, Card, Col, Row, Table, Tag, ConfigProvider } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import { useGetInvoiceByIdQuery } from "../../features/invoice/invoiceApi";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { id, invoice_id } = useParams();
  const { data } = useGetInvoiceByIdQuery({ invoice_id });
  console.log("data", data);

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Dynamically generate the data source from response
  const generateDataSource = (utilities) =>
    utilities?.map((utility, index) => ({
      key: utility._id || index + 1,
      description: utility.title,
      unit: utility.unit,
      qty: utility.qty,
      rate: utility.rate_type === "percent" ? `${utility.rate}%` : utility.rate,
      amount: formatPrice(utility.amount),
      utilityType: utility.utilityType,
      children:
        utility.utilityType === "meter"
          ? [
              {
                key: `${utility._id}-previous`,
                name: "Previous Month",
                unit: utility.previous_month,
              },
              {
                key: `${utility._id}-this`,
                name: "This Month",
                unit: utility.this_month,
              },
              {
                key: `${utility._id}-usage`,
                name: "Usage",
                unit: utility.this_month - utility.previous_month,
              },
            ]
          : null,
    }));

  const columns = [
    {
      title: "No",
      key: "no",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/invoice/list`)}>
          Invoice List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Invoice Details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="invoice-details">
        <Card title="Invoice Details">
          <Row gutter={[16]}>
            <Col span={4}>
              <p>
                <strong>Tenant Name</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{data?.data?.tenant?.tenant_name}</p>
            </Col>

            <Col span={4}>
              <p>
                <strong>Room / Shop Name</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{data?.data?.shop}</p>
            </Col>

            <Col span={4}>
              <p>
                <strong>Invoice Status</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>
                {data?.data?.status === 1 ? (
                  <Tag color="red">Unpaid</Tag>
                ) : (
                  <Tag color="green">Paid</Tag>
                )}
              </p>
            </Col>
          </Row>
        </Card>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "blue",
              headerColor: "white",
              headerBorderRadius: "0",
            },
          },
        }}
      >
        <Table
          className="custom-table"
          dataSource={generateDataSource(data?.data?.utilities)}
          columns={columns}
          pagination={false}
          summary={() => (
            <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>SUB TOTAL</Table.Summary.Cell>
                  <Table.Summary.Cell>
                  {formatPrice(data?.data?.sub_total)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>PENALTY</Table.Summary.Cell>
                  <Table.Summary.Cell>{formatPrice(0)}</Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>DISCOUNT</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {formatPrice(data?.data?.discount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>
                    GRAND TOTAL
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {formatPrice(data?.data?.grand_total)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
          )}
        />
      </ConfigProvider>
    </>
  );
};

export default InvoiceDetails;
