import { Card } from "antd";
import React from "react";
import LightMode from "../../../assets/light.svg";
import DarkMode from "../../../assets/dark.svg";
import System from "../../../assets/system.svg";
import { FiSun } from "react-icons/fi";
import { AiOutlineMoon } from "react-icons/ai";
import { IoIosGitNetwork } from "react-icons/io";

const GeneralSetting = () => {
  return (
    <div>
      <Card title="General Setting">
        <p style={{ padding: 0, margin: 0, fontWeight: 600, fontSize: "16px" }}>
          Appearance
        </p>
        <div className="general-setting">
        <div>
          <img src={LightMode}></img>
          <p className="general-theme" style={{marginTop:'-7px'}}><FiSun style={{width:'18px', height:'18px'}} />Light Theme</p>
        </div>
        <div style={{padding:'2px'}}>
          <img src={DarkMode}></img>
          <p className="general-theme"><AiOutlineMoon style={{width:'18px', height:'18px'}} />Dark Theme</p>
        </div>
        <div style={{padding:'2px'}}>
          <img src={System}></img>
          <p className="general-theme"><IoIosGitNetwork style={{width:'18px', height:'18px', transform:'rotate(180deg)'}}/>System Theme</p>
        </div>
        </div>
      </Card>
    </div>
  );
};

export default GeneralSetting;
