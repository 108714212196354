import {
  Form,
  Input,
  Button,
  message,
  ConfigProvider,
  Modal,
  Card,
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useUpdateTenantCategoryMutation } from "./tenantCategoryApi";

const { TextArea } = Input;

const EditTenantCategory = ({
  selectedRecord,
  isEditModalOpen,
  handleEditOk,
  handleEditCancel,
}) => {
  const token = useSelector((state) => state.authSlice.token2);
  // API mutation and query
  const [updateTenantCategory, { isLoading: isUpdateLoading }] =
    useUpdateTenantCategoryMutation(token);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue({
        name: selectedRecord?.tenantCategory,
      });
    }
  }, [selectedRecord, form]);

  // Handle form submission
  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        categoryId: selectedRecord?.key,
        updatedData: {
          tenant_category_name: values.name,
        },
      };
      await updateTenantCategory(updatedData).unwrap();
      message.success("Tenant Category is updated successfully.");
      handleEditOk(); // Use the function from parent to close modal
    } catch (isUpdateError) {
      const errorMessage =
        isUpdateError?.data?.errors?.[0] || "Failed to edit tenant category";
      message.error(errorMessage);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#f8f7f7",
        },
      }}>
      <Modal
        width={880}
        className="bu-add-modal"
        open={isEditModalOpen}
        onOk={handleFormSubmit} // Trigger form submission on modal OK button
        closeIcon={null}
        onCancel={handleEditCancel} // Use the function from parent to close modal
        footer={null}>
        <Card title="Edit Tenant Category">
          <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item label="Tenant Category" name="name">
              <Input placeholder="Tenant Category" style={{ height: 44 }} />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="text" className="btn" onClick={handleEditCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn filter"
                loading={isUpdateLoading}>
                Update
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </ConfigProvider>
  );
};

export default EditTenantCategory;
