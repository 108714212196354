import { baseApi } from "../../app/baseApi";

export const invoiceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoicesList: builder.query({
      query: ({ page = 1, limit = 10, status } = {}) => 
        `/Invoice/list?page=${page}&limit=${limit}&status=${status}`,
      providesTags: ["InvoiceList"],
    }),
    createInvoice: builder.mutation({
      query: ({ invoice }) => {      
        return {
          url: `/Invoice/upload/create`,
          method: "POST",
          body: invoice, // Pass the FormData directly
        };
      },
      invalidatesTags: ["InvoiceList"],
    }),  
    getAllExchangeRate: builder.query({
      query: () => `/Invoice/exchange-rate/list`,
      providesTags: ["ExchangeRateList"],
    }),

    getDownloadExcelFile: builder.query({
      query: () => ({
        url: `/Invoice/excel-format-download/tenant`,
        method: 'GET',
        responseType: 'arraybuffer' 
      }),
      providesTags: ["InvoiceList"],
    }),  
    getReport: builder.query({
      query: () => ({
        url: `/Invoice/invoice-report`,
        method: 'GET',
      }),
      providesTags: ["InvoiceList"],
    }),   
    getInvoiceById: builder.query({
      query: ({ invoice_id }) =>
        `/Invoice/preview/edit/${invoice_id}`,
      providesTags: ["InvoiceList"],
    }),
    
    addExchangeRate: builder.mutation({
      query: ({ date, usd, mmk }) => ({
        url: `/Invoice/exchange-rate/create`,
        method: "POST",
        body: {
          date,
          usd,
          mmk,
        },
      }),
      invalidatesTags: ["ExchangeRateList"],
    }),

    updatePreviewInvoice: builder.mutation({
      query: ({ params, id }) => { 
        return {
          url: `/Invoice/preview/update/${id}`,
          method: "PATCH",
          body: JSON.stringify(params),
          headers: {
            // No need to set Content-Type when using FormData; the browser will automatically handle it
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: ["InvoiceList"],
    }),  

    updateInvoice: builder.mutation({
      query: ({ params, id }) => { 
        return {
          url: `/Invoice/payment-receive/${id}`,
          method: "PATCH",
          body: JSON.stringify(params),
          headers: {
            // No need to set Content-Type when using FormData; the browser will automatically handle it
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: ["InvoiceList"],
    }), 
  }),
  overrideExisting: false,
});

export const {
  useGetAllInvoicesListQuery,
  useGetDownloadExcelFileQuery,
  useGetReportQuery,
  useCreateInvoiceMutation,
  useGetInvoiceByIdQuery,
  useUpdatePreviewInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetAllExchangeRateQuery,
  useAddExchangeRateMutation
} = invoiceApi;
