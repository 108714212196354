import { Card, Form, Select } from "antd";
import React, { useState } from "react";
import { IoWarning } from "react-icons/io5";
import { useGetAllInvoiceSettingQuery } from "../../features/setting/invoiceSettingApi";

const { Option } = Select;

const InvoicePaymentSettingCard = ({
  setInvoicePaymentData,
  onInvoicePaymentChange,
  onPaymentMethodChange,
  onInvoiceCurrencyChange,
}) => {
  const [form] = Form.useForm();
  const [selectedSetting, setSelectedSetting] = useState();
  
  const handleFormChange = () => {
    const formData = form.getFieldsValue();
    setInvoicePaymentData({
      ...formData,
    });
  };

  const { data: invoiceSettingList, isLoading: isInvoiceSettingLoading } = useGetAllInvoiceSettingQuery();
  
  return (
    <div>
      <Card title="Invoice Payment Setting" style={{ marginTop: "15px" }}>
        <Form form={form} layout="vertical" initialValues={{}} onValuesChange={handleFormChange}>
          <div className="add-branch-form">
            <Form.Item
              name="paymentMethod"
              className="add-branch-input"
              label="Choose Payment Method">
              <Select
                style={{ height: 44, width: "100%" }}
                placeholder="Choose Payment Method"
                onChange={(value) => {
                  setSelectedSetting(value);
                  form.setFieldValue("paymentMethod", value);
                  onPaymentMethodChange(value); // Ensure this updates the parent state
                }}>
                 {isInvoiceSettingLoading ? (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                ) : (
                  invoiceSettingList?.data?.map((setting) => (
                    <Option key={setting._id} value={setting._id}>
                      {setting?.invoice_type}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="invoicePayment"
              className="add-branch-input"
              label="Invoice Payment">
              <Select
                style={{ height: 44, width: "100%" }}
                placeholder="Select Invoice Payment Frequency"
                onChange={(value) => {
                  form.setFieldValue("invoicePayment", value);
                  onInvoicePaymentChange(value); // Ensure this updates the parent state
                }}>
                <Option value={1}>Monthly</Option>
                <Option value={2}>Quarterly (3 Months)</Option>
                <Option value={3}>Half-Yearly (6 Months)</Option>
                <Option value={4}>Yearly</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
        <p
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            fontWeight: 600,
            marginTop: "16px",
          }}>
          <IoWarning
            style={{ width: "24px", height: "24px", color: "#FFB326" }}
          />
          If you choose MMK, you must pay the rental fee in Myanmar Kyat. If you
          choose USD, you must pay in dollars. But for the utility fee, you must
          pay in MMK.
        </p>
      </Card>
    </div>
  );
};

export default InvoicePaymentSettingCard;
