import { Button, Card, Form, Input, Modal, message } from "antd";
import React, { useEffect } from "react";

const EditRoomType = ({ visible, onClose, record, updateRoomType }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        roomType: record.roomTypes,
      });
    }
  }, [record, form]);

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      // Call the updateRoomType mutation with room type ID and updated data
      await updateRoomType({
        roomTypeId: record.key,
        updatedData: { room_type: values.roomType },
      }).unwrap();

      message.success("Room type updated successfully!");
      form.resetFields();
      onClose(); // Close the modal after successful update
    } catch (error) {
      message.error("Failed to update room type.");
    }
  };

  return (
    <div>
      <Modal
        width={880}
        open={visible}
        onCancel={onClose}
        className="bu-add-modal"
        footer={null}>
        <Card title="Edit Room Type">
          <Form form={form} layout="vertical">
            <Form.Item
              label="Room Type"
              name="roomType"
              rules={[{ required: true, message: "Please enter a room type" }]}>
              <Input style={{ height: 44 }} />
            </Form.Item>
            <div className="add-property-div">
              <Button onClick={onClose} type="cancel" className="btn">
                Cancel
              </Button>
              <Button
                onClick={handleUpdate}
                className="filter btn"
                type="primary">
                UPDATE
              </Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default EditRoomType;
