import { Card, Input, Form, Select, Button } from "antd";
import React from "react";

const EmailSetting = () => {
  return (
    <Card title="E-mail Setting">
      <Form layout="vertical">
        <div className="email-setting-form">
          <Form.Item label="Host" name="host" className="email-setting-input">
            <Input style={{ height: 44 }} />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            className="email-setting-input">
            <Input style={{ height: 44 }} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            className="email-setting-input">
            <Input.Password style={{ height: 44 }} />
          </Form.Item>
          <Form.Item label="Port" name="port" className="email-setting-input">
            <Input style={{ height: 44 }} />
          </Form.Item>
          <Form.Item
            label="Security"
            name="security"
            className="email-setting-input">
            <Select style={{ height: 44 }} />
          </Form.Item>
        </div>
        <div className="email-setting-btn-gp">
          <Button className="email-btn filter">Text Message</Button>
          <Button className="email-btn filter">Update Message</Button>
        </div>
      </Form>
    </Card>
  );
};

export default EmailSetting;
