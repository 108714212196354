import React, { useState } from "react";
import {
  Form,
  Button,
  Table,
  Space,
  Dropdown,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAddNewRoleMutation } from "../../../features/adminstrator/adminApi";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import PageHeader from "../../../component/PageHeader";
import SearchFilter from "../../../component/SearchFilter";
import { DeleteModal, ReusablePagination } from "../../../component";
import { useGetAllCentralizedRoleQuery } from "../../../features/centralized/centralizedAdminApi";

const RolePermission = () => {
  const token = useSelector((state) => state.authSlice.token);
  const {
    data: roleListData,
    isLoading,
    error,
  } = useGetAllCentralizedRoleQuery(token);

  const [addNewRole] = useAddNewRoleMutation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleSubmit = async (values) => {
    const payload = { name: values.name };
    try {
      await addNewRole(payload).unwrap();
      form.resetFields();
      message.success("New Role Added Successfully.");
    } catch (err) {
      console.error("Failed to add new role", err);
      message.error("Failed to add new role.");
    }
  };

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Options",
      key: "option",
      align: "center",
      width: "15%",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EyeOutlined /> View
                    </span>
                  ),
                  onClick: () =>
                    navigate(`/cd/adminstrator/role-permission/view-detail`, {
                      state: { role: record },
                    }),
                },
                {
                  key: "2",
                  label: (
                    <span>
                      <EditOutlined /> Edit
                    </span>
                  ),
                  onClick: () =>
                    navigate(`/cd/adminstrator/role-permission/edit-role`, {
                      state: { role: record },
                    }),
                },
                {
                  key: "3",
                  label: (
                    <span>
                      <DeleteOutlined /> Delete
                    </span>
                  ),
                  onClick: () => showDeleteModal(record),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const tableData = roleListData?.data?.map((role) => ({
    key: role._id,
    role: role.role_name,
  }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <PageHeader
        title="Roles"
        addBtn="Add New Role"
        linkTo="/cd/adminstrator/role-permission/add-new-role"
      />
      <SearchFilter searchPlaceHolder="Search..." />
      {/* <Table
        style={{ marginTop: "16px" }}
        columns={columns}
        dataSource={tableData} // Use filteredData as the dataSource
        loading={isLoading}
        rowKey="key" // Ensure each row has a unique key
      /> */}
      <Table
        pagination={false}
        columns={columns}
        dataSource={paginatedData}
        onChange={onChange}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={roleListData?.paginator?.totalItems}
        onChange={handlePaginationChange}
      />
      <DeleteModal
        visible={isDeleteModalOpen}
        onConfirm={handleDeleteOk}
        onCancel={handleDeleteCancel}
        // confirmLoading={isDeleteLoading}
      />
    </>
  );
};

export default RolePermission;
