import React from "react";
import { Card, Divider, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PieChart } from "@mui/x-charts/PieChart";
import "./styles.css";
import { useGetAllBUListQuery } from "../../features/centralized/businessUnit/buApi";
import { setselectedBu } from "../../features/auth/authSlice";

const DashboardCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: buList, isLoading, isError } = useGetAllBUListQuery();

  const handleGoToDashboard = (buId) => {
    dispatch(setselectedBu(buId));
    navigate(`/${buId}`);
  };

  // Show loading spinner while fetching data
  if (isLoading) {
    return <Spin size="large" />; // Show a spinner during loading
  }

  // Handle error state
  if (isError) {
    return <div>Error loading business units. Please try again later.</div>;
  }

  // Example tenant and property data
  const tenantData = [
    { label: "Owner", value: 400 },
    { label: "Lease", value: 300 },
  ];

  const propertyData = [
    { label: "Available", value: 400 },
    { label: "Unavailable", value: 300 },
  ];

  const tenantColor = ["#9261D2", "#3BC7D8"];
  const propertyColor = ["#28C76F", "#E90A16"];

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        flexDirection: "row",
        flexWrap: "wrap",
      }}>
      {buList.data.map(
        (
          bu // Map over the buList to create a card for each BU
        ) => (
          <Card
            key={bu._id} // Use the unique ID for each card
            title={bu.bu_name} // Use the BU name as the title
            extra={
              <a onClick={() => handleGoToDashboard(bu._id)}>Go to Dashboard</a>
            }
            style={{ width: "350px", marginBottom: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                overflow: "hidden",
                height: "190px",
              }}>
              <span style={{ width: "200px", height: "150px" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "2px",
                  }}>
                  Number of Tenant
                </p>
                <PieChart
                  colors={tenantColor}
                  series={[
                    {
                      startAngle: -90,
                      endAngle: 90,
                      data: tenantData,
                      innerRadius: 30,
                      outerRadius: 65,
                      cx: 65,
                    },
                  ]}
                  height={140}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: { vertical: "bottom", horizontal: "middle" },
                      padding: 0,
                      labelStyle: {
                        fontSize: 10,
                      },
                    },
                  }}
                />
              </span>
              <Divider type="vertical" style={{ height: "120px", margin: 0 }} />
              <span style={{ width: "200px", height: "150px" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "2px",
                  }}>
                  Number of Property
                </p>
                <PieChart
                  colors={propertyColor}
                  series={[
                    {
                      startAngle: -90,
                      endAngle: 90,
                      data: propertyData,
                      innerRadius: 30,
                      outerRadius: 65,
                      cx: 80,
                    },
                  ]}
                  height={140}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: { vertical: "bottom", horizontal: "middle" },
                      padding: 0,
                      labelStyle: {
                        fontSize: 10,
                      },
                    },
                  }}
                />
              </span>
            </div>
            <Divider style={{ margin: 0 }} />
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Invoice</p>
            <div className="dashboard-invoice-card">
              <div
                style={{
                  boxShadow: "1px 1px 1px 1px grey",
                  margin: 0,
                  padding: 10,
                  height: "70px",
                  lineHeight: "7px",
                  width: "150px",
                  borderRadius: 0,
                  alignSelf: "center",
                  marginBottom: "10px",
                }}>
                <p style={{ fontSize: "10px" }}>Outstanding</p>
                <p style={{ fontSize: "16px", fontWeight: 700 }}>
                  1,060,000 MMK
                </p>
              </div>
              <div
                style={{
                  boxShadow: "1px 1px 1px 1px grey",
                  margin: 0,
                  padding: 10,
                  height: "70px",
                  lineHeight: "7px",
                  width: "150px",
                  borderRadius: 0,
                  alignSelf: "center",
                  marginBottom: "10px",
                }}>
                <p style={{ fontSize: "10px" }}>Paid</p>
                <p style={{ fontSize: "16px", fontWeight: 700 }}>860,000 MMK</p>
              </div>
              <div
                style={{
                  boxShadow: "1px 1px 1px 1px grey",
                  margin: 0,
                  padding: 10,
                  height: "70px",
                  lineHeight: "7px",
                  width: "150px",
                  borderRadius: 0,
                  alignSelf: "center",
                  marginBottom: "10px",
                }}>
                <p style={{ fontSize: "10px" }}>Unpaid</p>
                <p style={{ fontSize: "16px", fontWeight: 700 }}>660,000 MMK</p>
              </div>
              <div
                style={{
                  boxShadow: "1px 1px 1px 1px grey",
                  margin: 0,
                  padding: 10,
                  height: "70px",
                  lineHeight: "7px",
                  width: "150px",
                  borderRadius: 0,
                  alignSelf: "center",
                  marginBottom: "10px",
                }}>
                <p style={{ fontSize: "10px" }}>Due</p>
                <p style={{ fontSize: "16px", fontWeight: 700 }}>
                  8,060,000 MMK
                </p>
              </div>
            </div>
            <div>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                Notification
              </p>
              <div className="noti-dashboard-card">
                <div
                  style={{
                    boxShadow: "1px 1px 1px 1px grey",
                    margin: 0,
                    padding: "12px",
                    lineHeight: "7px",
                    borderRadius: 0,
                    marginBottom: "10px",
                  }}>
                  <div className="noti-bar">
                    <p>Invoice Notification</p>
                    <div className="noti-box">
                      <p className="noti-number">5</p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    boxShadow: "1px 1px 1px 1px grey",
                    margin: 0,
                    padding: "12px",
                    lineHeight: "7px",
                    borderRadius: 0,
                    marginBottom: "10px",
                  }}>
                  <div className="noti-bar">
                    <p>Maintenance Notification</p>
                    <div className="noti-box">
                      <p className="noti-number">3</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )
      )}
    </div>
  );
};

export default DashboardCard;
