import {
  Card,
  DatePicker,
  Form,
  Input,
  ConfigProvider,
  Space,
  Select,
  Button,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import dayjs from "dayjs";

const { Option } = Select;

const ContractPolicy = ({ setContractPolicyData }) => {
  const [form] = Form.useForm();
  const [policies, setPolicies] = useState([]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formattedPolicy = {
          ...values,
          startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
        };

        const updatedPolicies = [...policies, formattedPolicy];
        setPolicies(updatedPolicies);
        form.resetFields();
        setContractPolicyData(updatedPolicies);
        message.success("Policy added successfully!");
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };

  const handleEdit = (index) => {
    const policy = policies[index];
    form.setFieldsValue({
      ...policy,
      startDate: dayjs(policy.startDate, "YYYY-MM-DD"),
      endDate: dayjs(policy.endDate, "YYYY-MM-DD"),
    });

    const updatedPolicies = policies.filter((_, i) => i !== index);
    setPolicies(updatedPolicies);
    setContractPolicyData(updatedPolicies);
    message.info("Policy is ready to be edited.");
  };

  const handleDelete = (index) => {
    const updatedPolicies = policies.filter((_, i) => i !== index);
    setPolicies(updatedPolicies);
    setContractPolicyData(updatedPolicies);
    message.success("Policy deleted successfully!");
  };

  const handleAddPolicy = () => {
    if (policies.length >= 2) {
      message.warning("You can only add up to 2 policies.");
      return;
    }
    handleFormSubmit();
  };

  return (
    <div className="add-discount-card">
      <Card title="Add Contract Policy" style={{ marginTop: "15px" }}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="policyType"
            className="add-branch-input"
            label="Increasement (or) Decreasement"
            rules={[{ required: true, message: "Please select a type" }]}>
            <Select style={{ height: 44 }}>
              <Option value="increasement">Increasement</Option>
              <Option value="decreasement">Decreasement</Option>
            </Select>
          </Form.Item>
          <div className="add-branch-form">
            <Form.Item
              name="startDate"
              className="add-branch-input"
              label="Start Date"
              rules={[{ required: true, message: "Start date is required" }]}>
              <DatePicker style={{ height: 44, width: 345 }} />
            </Form.Item>
            <Form.Item
              name="endDate"
              className="add-branch-input"
              label="End Date"
              rules={[{ required: true, message: "End date is required" }]}>
              <DatePicker style={{ height: 44, width: 345 }} />
            </Form.Item>
            <Form.Item className="add-branch-input" label="Amount">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  name="rateValue"
                  noStyle
                  rules={[
                    { required: true, message: "Rate value is required" },
                  ]}>
                  <Input
                    style={{ width: "80%", height: 44 }}
                    placeholder="Enter rate value"
                    type="number"
                    min={1}
                  />
                </Form.Item>
                <div className="select-rate-type">
                  <Form.Item
                    name="rateType"
                    noStyle
                    rules={[
                      { required: true, message: "Rate type is required" },
                    ]}>
                    <Select
                      style={{ width: "104px", height: 44 }}
                      placeholder="Rate Type">
                      <Option value="flat">Flat</Option>
                      <Option value="percent">Percent</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Space.Compact>
            </Form.Item>
          </div>
          <div className="add-branch-btn-gp">
            <Button className="btn filter" onClick={handleAddPolicy}>
              Save
            </Button>
          </div>
        </Form>
      </Card>

      {policies.length > 0 && (
        <div className="property-data-card" style={{ marginTop: "16px" }}>
          {policies.map((policy, index) => (
            <Card key={index} style={{ marginBottom: "10px" }}>
              <Form layout="vertical" initialValues={policy}>
                <div className="add-branch-form">
                  <Form.Item label="Start Date" className="add-branch-input">
                    <DatePicker
                      value={dayjs(policy.startDate)}
                      style={{
                        width: 345,
                        height: 44,
                        backgroundColor: "#F7F8F8",
                      }}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item label="End Date" className="add-branch-input">
                    <DatePicker
                      value={dayjs(policy.endDate)}
                      style={{
                        width: 345,
                        height: 44,
                        backgroundColor: "#F7F8F8",
                      }}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item className="add-branch-input" label="Amount">
                    <Space.Compact style={{ width: "100%" }}>
                      <Input
                        value={policy.rateValue}
                        style={{ height: 44 }}
                        readOnly
                      />
                      <div className="select-rate-type">
                        <Select
                          value={policy.rateType}
                          style={{ width: "110px", height: 44 }}
                          disabled>
                          <Option value="percent">Percent</Option>
                          <Option value="flat">Flat</Option>
                        </Select>
                      </div>
                    </Space.Compact>
                  </Form.Item>
                </div>
                <div className="add-property-div">
                  <Button
                    className="property-btn"
                    onClick={() => handleEdit(index)}>
                    <EditOutlined />
                  </Button>
                  <Button
                    className="property-btn"
                    onClick={() => handleDelete(index)}>
                    <DeleteOutlined />
                  </Button>
                  <Button
                    className="contract-policy-btn"
                    icon={
                      policy.policyType === "increasement" ? (
                        <PlusOutlined />
                      ) : (
                        <MinusOutlined />
                      )
                    }>
                    {policy.policyType === "increasement"
                      ? "Increasement"
                      : "Decreasement"}
                  </Button>
                </div>
              </Form>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContractPolicy;
