import { SettingOutlined } from '@ant-design/icons'
import { Space, Table, Button } from 'antd'
import React, { useState } from 'react'
import { FaEdit } from "react-icons/fa";
import { ReusablePagination } from '../../component';
import EditMaintenanceNoti from './EditMaintenanceNoti';

const MaintenanceTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setEditModalVisible(true);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setSelectedRecord(null);
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
    },
    {
      title: "Template Sample Message",
      dataIndex: "sampleMessage",
      key: "sampleMessage",
    },
    {
      title: <div className="setting-icon"><SettingOutlined/></div>,
      key: "operation",
      align: "center",
      width: 150,
      render: (record) => (
        <Space size="middle">
            <Button onClick={() => handleEditClick(record)} type='text'> 
              <FaEdit style={{color:'#0057FF', width:'18px', height:'18px'}}/>
            </Button>
        </Space>
      ),
    },
  ]

  const dataSource = [
    {
      key: '1',
      event: "Survey Message",
      sampleMessage: "We'll arrive to survey within 2 days."
    }
  ]

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  
  return (
    <div style={{marginTop:'16px'}}>
      <Table columns={columns} dataSource={paginatedData} pagination={false} />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total="20"
        onChange={handlePaginationChange}
      />
      <EditMaintenanceNoti
          record={selectedRecord}
          visible={isEditModalVisible}
          onClose={handleCloseEditModal}
        />
    </div>
  )
}

export default MaintenanceTable