// TenantTable.js
import React from "react";
import { Table, Dropdown, Space, Tag, Button } from "antd";
import { EyeOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const TenantTable = ({ data, onView, onDelete, loading }) => {
  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: onView,
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: onDelete,
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width:'5%',
      render: (text, record, index) => index + 1,
    },
    { title: "Tenant Name", dataIndex: "tenantName", key: "tenantName", width:'15%' },
    { title: "Email", dataIndex: "email", key: "email", width:'15%' },
    { title: "Phone Number", dataIndex: "phNum", key: "phNum", width:'15%' },
    { title: "Created Date", dataIndex: "creDate", key: "creDate", width:'15%' },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width:'15%',
      render: (status) => (
        <Tag color={status === "Active" ? "green" : "orange"}>{status}</Tag>
      ),
    },
    {
      title: "",
      key: "operation",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={data}
      pagination={false} // Disable built-in pagination
    />
  );
};

export default TenantTable;
