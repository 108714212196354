import React, { useEffect } from 'react';
import { Button, Card, Form, Input, Modal, Select } from "antd";

const { Option } = Select;
const { TextArea } = Input;

const EditNotiTemplate = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        event: record.event,
        sampleMessage: record.sampleMessage,
        option: record.option, // Set initial values for options
      });
    }
  }, [record, form]);

  return (
    <div>
      <Modal
        width={880}
        open={visible}
        onCancel={onClose}
        className="bu-add-modal"
        footer={null}>
        <Card title="Edit Notification Template">
          <Form form={form} layout="vertical">
            <Form.Item label="Event" name="event">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Option" name="option">
              <Select mode="multiple" placeholder="Select Options" style={{ width: '100%', height:'44px' }}>
                <Option value="Mobile App">MOBILE APP</Option>
                <Option value="SMS">SMS</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Template Sample Message" name="sampleMessage">
              <TextArea rows={4} />
            </Form.Item>
            <div className="add-property-div">
              <Button onClick={onClose} type="cancel" className="btn">
                Cancel
              </Button>
              <Button className="filter btn">UPDATE</Button>
            </div>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default EditNotiTemplate;
