import { Card, Form, Input, Radio, Select, Space } from "antd";
import React from "react";

const { Option } = Select;

const ViewOwnPayment = ({ payment_info }) => {
  const [form] = Form.useForm();

  // Set the initial values of the form from `payment_info`
  form.setFieldsValue({
    payment_type: payment_info?.payment_type || "Fully",
    type: payment_info?.type || "MMK",
    totalAmount: payment_info?.total_amount || 0,
    downPayment: payment_info?.down_payment || 0,
    downPaymentType: payment_info?.down_payment_type || "percent",
    remainingAmount: payment_info?.remaining_amount || 0,
    paymentDuration: payment_info?.payment_duration || 0,
    monthlyPayment: payment_info?.monthly_payment || 0,
  });

  const currency = payment_info?.type || "MMK"; // Currency from `payment_info`
  const paymentType = payment_info?.payment_type || "Fully"; // Payment type from `payment_info`

  return (
    <div className="payment-card">
      <Form layout="vertical" form={form}>
        <Card
          style={{ marginTop: "16px" }}
          title="Payment Details"
          extra={
            <div style={{ display: "flex" }}>
              <Form.Item name="payment_type">
                <Radio.Group disabled value={payment_info?.payment_type}>
                  <Radio value="Fully">Fully</Radio>
                  <Radio value="Installment">Installment</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="type">
                <Select disabled value={payment_info?.type}>
                  <Option value="MMK">MMK</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </div>
          }>
          {paymentType === "Fully" ? (
            <div className="add-branch-form">
              <Form.Item
                className="add-branch-input"
                name="totalAmount"
                label={`Total Amount (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  readOnly
                  value={payment_info?.total_amount}
                  style={{ height: 44 }}
                />
              </Form.Item>
            </div>
          ) : (
            <div className="add-branch-form">
              <Form.Item
                className="add-branch-input"
                name="totalAmount"
                label={`Total Amount (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  readOnly
                  value={payment_info?.total_amount}
                  style={{ height: 44 }}
                />
              </Form.Item>
              <Form.Item className="add-branch-input" label="Down Payment">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item name="downPayment" noStyle>
                    <Input
                      type="number"
                      readOnly
                      value={payment_info?.down_payment}
                      style={{ height: 44 }}
                    />
                  </Form.Item>
                  <div className="select-rate-type">
                  <Form.Item name="downPaymentType" noStyle>
                    <Select
                      style={{ width: "110px", height: 44 }}
                      value={payment_info?.down_payment_type}
                      disabled>
                      <Option value="flat">Flat</Option>
                      <Option value="percent">Percent</Option>
                    </Select>
                  </Form.Item>
                  </div>
                </Space.Compact>
              </Form.Item>
              <Form.Item
                className="add-branch-input"
                name="remainingAmount"
                label={`Remaining Amount (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  readOnly
                  value={payment_info?.remaining_amount}
                  style={{ height: 44 }}
                />
              </Form.Item>
              <Form.Item
                className="add-branch-input"
                name="paymentDuration"
                label="Payment Duration (months)">
                <Input
                  type="number"
                  readOnly
                  value={payment_info?.payment_duration}
                  style={{ height: 44 }}
                />
              </Form.Item>
              <Form.Item
                className="add-branch-input"
                name="monthlyPayment"
                label={`Monthly Payment (${currency.toUpperCase()})`}>
                <Input
                  type="number"
                  readOnly
                  value={payment_info?.monthly_payment}
                  style={{ height: 44 }}
                />
              </Form.Item>
            </div>
          )}
        </Card>
      </Form>
    </div>
  );
};

export default ViewOwnPayment;
