import {
  EditOutlined,
  EyeOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Dropdown, Space, Table, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useGetAllUtilityQuery } from "./utilityApi";
import { useSelector } from "react-redux";
import { ReusablePagination } from "../../component";

const UtilityTable = ({ onEdit, onView, onDelete, selectedBranch }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const { data: utilityData, isLoading, error } = useGetAllUtilityQuery(token);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredUtility, setFilteredUtility] = useState([]);

  const totalItems = utilityData?.data?.length;
  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (index + 1).toString().padStart(2, "0"),
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Property Type",
      dataIndex: "propertyType",
      key: "propertyType",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span onClick={() => onView(record)}>
                      <EyeOutlined /> View
                    </span>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <span onClick={() => onEdit(record)}>
                      <EditOutlined /> Edit
                    </span>
                  ),
                },
                {
                  key: "3",
                  label: (
                    <span onClick={() => onDelete(record)}>
                      <DeleteOutlined /> Delete
                    </span>
                  ),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const propertyTypeMap = {
    1: "Rent",
    2: "Sell",
    3: "Free Of Charge (FOC)",
  };

  useEffect(() => {
    if (utilityData) {
      const filteredData = utilityData?.data?.filter((utility) => {
        // Check if the selectedBranch matches any of the branches in the utility's branch array
        const matchesBranch = selectedBranch
          ? utility?.branch.some((branchItem) => branchItem._id?._id === selectedBranch)
          : true; // Show all if no branch is selected
  
        return matchesBranch; // Only keep utilities matching the branch filter
      });
  
      setFilteredUtility(filteredData);
    }
  }, [selectedBranch, utilityData]);  

  const tableData = filteredUtility?.map((utility, index) => ({
    key: utility?._id,
    no: index + 1,
    propertyTypeNo: utility?.property_type,
    propertyType: propertyTypeMap[utility?.property_type] || "Unknown",
    utilityType:
      utility?.utilityType.charAt(0).toUpperCase() +
      utility?.utilityType.slice(1),
    rate: `${formatPrice(utility?.rate)} ${
      utility?.rate_type === "%" ? "%" : utility?.rate_type.toUpperCase()
    }`,
    rateValue: utility?.rate,
    rateType: utility?.rate_type,
    // Map over each branch and extract the name, then join them with commas
    branch: utility?.branch
      .map((branchItem) => branchItem._id?.name)
      .join(", "),
    branchId: utility?.branch
      .map((branchItem) => branchItem._id?._id)
      .join(", "),
    unit: utility?.unit,
    title: utility?.title,
  }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const paginatedData = tableData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <Table
        loading={isLoading}
        style={{ marginTop: "15px" }}
        columns={columns}
        pagination={false}
        dataSource={paginatedData}
      />
      <ReusablePagination
        currentPage={currentPage}
        pageSize={pageSize}
        total={totalItems} // Use total items here
        onChange={handlePaginationChange}
      />
    </div>
  );
};

export default UtilityTable;
