import { Breadcrumb, Button, Card, Col, Row, Table, Checkbox } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ViewRole = () => {
  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "40%",
    },
    {
      title: "CREATE",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "READ",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "WRITE",
      dataIndex: "write",
      key: "write",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (text, record) => <Checkbox />,
    },
  ];

  const tableData = [
    {
      key: "1",
      module: "Dashboard",
    },
    {
      key: "2",
      module: "Contract Manage",
    },
    {
      key: "3",
      module: "Tenant",
    },
    {
      key: "4",
      module: "Property",
    },
    {
      key: "5",
      module: "Utility",
    },
    {
      key: "6",
      module: "Invoice",
    },
    {
      key: "7",
      module: "Maintenance",
    },
    {
      key: "8",
      module: "Announcement",
    },
    {
      key: "9",
      module: "Administrator",
    },
    {
      key: "10",
      module: "Reporting",
    },
  ];
  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: (
              <Link to="/cd/adminstrator/role-permission">
                Role & Permissions
              </Link>
            ),
          },
          {
            title: "View Role",
          },
        ]}></Breadcrumb>
      <Card style={{ marginBottom: "16px" }} title="View Role">
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <strong>Role</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={19}>
            <p style={{ margin: 0 }}>Super Admin</p>
          </Col>
          <Col span={4}>
            <strong>Business Unit</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={19}>
            <p style={{ margin: 0 }}>Condo Management</p>
            <p style={{ margin: 0 }}>Shopping Mall Management</p>
          </Col>
        </Row>
      </Card>
      <Card className="permission-card">
        <p className="permission-header">Group of Permissions</p>
        <div className="permission-table">
          <Table
            pagination={false}
            dataSource={tableData}
            columns={columns}></Table>
        </div>
        <div className="permission-btn-group">
          <Button className="btn">
            Cancel
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ViewRole;
