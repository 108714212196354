import {
  Card,
  ConfigProvider,
  Form,
  Input,
  Select,
  Space,
  DatePicker,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import {
  useGetContractByIdQuery,
  useUpdateContractPolicyMutation,
} from "./contractApi";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const AddContractPolicyCard = () => {
  const [form] = Form.useForm();
  const [properties, setProperties] = useState([]);
  const [updateContractPolicy, { isLoading }] =
    useUpdateContractPolicyMutation();
  const location = useLocation();
  const contract = location.state;
  const contractId = contract?.contract?.key;

  const { data: contractData, isLoading: isLoadingContract } =
    useGetContractByIdQuery({ contractId });
  const contract_policy = contractData?.data?.contract_policy;

  useEffect(() => {
    if (contract_policy) {
      const formattedPolicies = contract_policy.map((policy) => ({
        ...policy,
        start_date: dayjs(policy.start_date),
        end_date: dayjs(policy.end_date),
      }));
      setProperties(formattedPolicies);
    }
  }, [contract_policy]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      const newProperty = {
        ...values,
        start_date: values.start_date,
        end_date: values.end_date,
      };
      setProperties([...properties, newProperty]);
      form.resetFields();
    });
  };

  const handleSaveAll = async () => {
    const updatedData = {
      contract_policy: properties.map((property) => ({
        _id: property._id || undefined,
        policy_type: property.policy_type,
        start_date: property.start_date.format("YYYY-MM-DD"),
        end_date: property.end_date.format("YYYY-MM-DD"),
        contract_policy_amount: property.contract_policy_amount,
        contract_policy_amount_type: property.contract_policy_amount_type,
      })),
    };

    try {
      await updateContractPolicy({ contractId, updatedData }).unwrap();
      console.log("Contract policy updated successfully");
    } catch (error) {
      console.error("Failed to update contract policy:", error);
    }
  };

  const handleDeleteProperty = (index) => {
    const updatedProperties = properties.filter((_, i) => i !== index);
    setProperties(updatedProperties);
  };

  const handleEditProperty = (property) => {
    form.setFieldsValue(property);
    handleDeleteProperty(properties.indexOf(property));
  };

  return (
    <>
      <div className="add-discount-card">
        <Card title="Add Contract Policy" style={{ marginTop: "15px" }}>
          <Form form={form} layout="vertical">
            <Form.Item
              name="policy_type"
              className="add-branch-input"
              label="Increasement (or) Decreasement"
              rules={[{ required: true, message: "Please select a type" }]}>
              <Select style={{ height: 44 }}>
                <Option value="increasement">Increasement</Option>
                <Option value="decreasement">Decreasement</Option>
              </Select>
            </Form.Item>
            <div className="add-branch-form">
              <Form.Item
                name="start_date"
                className="add-branch-input"
                label="Start Date"
                rules={[
                  { required: true, message: "Please select a start date" },
                ]}>
                <DatePicker
                  style={{ width: 345, height: 44, backgroundColor: "#F7F8F8" }}
                />
              </Form.Item>

              <Form.Item
                name="end_date"
                className="add-branch-input"
                label="End Date"
                rules={[
                  { required: true, message: "Please select an end date" },
                ]}>
                <DatePicker
                  style={{ width: 345, height: 44, backgroundColor: "#F7F8F8" }}
                />
              </Form.Item>

              <Form.Item className="add-branch-input" label="Amount">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="contract_policy_amount"
                    noStyle
                    rules={[{ required: true, message: "Amount is required" }]}>
                    <Input style={{ height: 44 }} placeholder="Number" />
                  </Form.Item>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorTextPlaceholder: "#fff",
                      },
                    }}>
                       <div className="select-rate-type">
                    <Form.Item
                      name="contract_policy_amount_type"
                      noStyle
                      rules={[{ required: true, message: "Type is required" }]}>
                      <Select
                        style={{ width: "110px", height: 44 }}
                        placeholder="Type">
                        <Option value="percent">Percent</Option>
                        <Option value="flat">Flat</Option>
                      </Select>
                    </Form.Item>
                    </div>
                  </ConfigProvider>
                </Space.Compact>
              </Form.Item>
            </div>
            <div className="add-branch-btn-gp">
              <Button type="text" className="btn">
                Cancel
              </Button>
              <Button
                className="btn filter"
                onClick={handleSave}
                loading={isLoading}>
                Add
              </Button>
            </div>
          </Form>
        </Card>
      </div>

      {properties.length > 0 && (
        <div className="property-data-card" style={{ marginTop: "16px" }}>
          {properties.map((property, index) => (
            <Card key={property._id || index} style={{ marginBottom: "10px" }}>
              <Form layout="vertical" initialValues={property}>
                <div className="add-branch-form">
                  <Form.Item label="Start Date" className="add-branch-input">
                    <DatePicker
                      value={property.start_date}
                      style={{
                        width: 345,
                        height: 44,
                        backgroundColor: "#F7F8F8",
                      }}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item label="End Date" className="add-branch-input">
                    <DatePicker
                      value={property.end_date}
                      style={{
                        width: 345,
                        height: 44,
                        backgroundColor: "#F7F8F8",
                      }}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item label="Amount" className="add-branch-input">
                    <Space.Compact style={{ width: "100%" }}>
                      <Form.Item name="contract_policy_amount" noStyle>
                        <Input
                          value={property.contract_policy_amount}
                          style={{ height: 44 }}
                          readOnly
                        />
                      </Form.Item>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorTextPlaceholder: "#fff",
                          },
                        }}>
                        <div className="select-rate-type">
                          <Form.Item name="contract_policy_amount_type" noStyle>
                            <Select
                              value={property.contract_policy_amount_type}
                              style={{ width: "110px", height: 44 }}
                              disabled>
                              <Option value="percent">Percent</Option>
                              <Option value="flat">Flat</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </ConfigProvider>
                    </Space.Compact>
                  </Form.Item>
                </div>
                <div className="add-property-div">
                  <Button
                    className="property-btn"
                    onClick={() => handleEditProperty(property)}>
                    <EditOutlined />
                  </Button>
                  <Button
                    className="property-btn"
                    onClick={() => handleDeleteProperty(index)}>
                    <DeleteOutlined />
                  </Button>
                  <Button
                    className="contract-policy-btn"
                    icon={
                      property.policy_type === "increasement" ? (
                        <PlusOutlined />
                      ) : (
                        <MinusOutlined />
                      )
                    }>
                    {property.policy_type === "increasement"
                      ? "Increasement"
                      : "Decreasement"}
                  </Button>
                </div>
              </Form>
            </Card>
          ))}
          <div className="add-branch-btn-gp">
            <Button type="text" className="btn">
              Cancel
            </Button>
            <Button
              className="btn filter"
              onClick={handleSaveAll}
              loading={isLoading}>
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddContractPolicyCard;
