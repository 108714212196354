import { Card, ConfigProvider, Form, Input, Select, Space } from "antd";
import React from "react";

const { Option } = Select;

const ViewAddDiscount = ({ discount, currency }) => {
  const [form] = Form.useForm();

  form.setFieldsValue({
    totalAmount: discount?.total_amount,
    rateValue: discount?.discount_price,
    rateType: discount?.discount_type,
    grandTotalAmount: discount?.grand_total_amount,
  });

  return (
    <div className="add-discount-card">
      <Card
        title={`Add Discount (${currency?.toUpperCase()})`}
        style={{ marginTop: "15px" }}>
        <Form form={form} layout="vertical">
          <div className="add-branch-form">
            <Form.Item
              name="totalAmount"
              className="add-branch-input"
              label={`Total Amount (${currency?.toUpperCase()})`}>
              <Input
                readOnly
                style={{ height: 44 }}
                placeholder="Enter total amount"
              />
            </Form.Item>

            <Form.Item className="add-branch-input" label="Rate">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  name="rateValue"
                  noStyle
                  rules={[
                    { required: true, message: "Rate value is required" },
                  ]}>
                  <Input
                    readOnly
                    style={{ height: 44 }}
                    placeholder="Rate Value"
                  />
                </Form.Item>
                <ConfigProvider
                  theme={{
                    token: {
                      colorTextPlaceholder: "#fff",
                    },
                  }}>
                  <div className="select-rate-type">
                    <Form.Item
                      name="rateType"
                      noStyle
                      rules={[
                        { required: true, message: "Rate type is required" },
                      ]}>
                      <Select
                        disabled
                        style={{ width: "110px", height: 44 }}
                        placeholder="Rate Type">
                        <Option value="flat">Flat</Option>
                        <Option value="percent">Percent</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </ConfigProvider>
              </Space.Compact>
            </Form.Item>

            <Form.Item
              name="grandTotalAmount"
              className="add-branch-input"
              label={`Grand Total (${currency?.toUpperCase()})`}>
              <Input
                readOnly
                style={{ height: 44 }}
                placeholder="Grand Total Amount"
              />
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ViewAddDiscount;
