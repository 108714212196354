import {
  Form,
  Input,
  Button,
  Select,
  message,
  ConfigProvider,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  useAddNewZoneMutation,
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
  useGetFloorsByBuildingIdQuery,
} from "./propertyApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddNewZone = ({ handleOk, handleCancel }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  const { data: branchListData, isLoading: isLoadingBranch } =
    useGetAllBranchesQuery();
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetBuildingsByBranchIdQuery(selectedBranch, { skip: !selectedBranch });
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });
  const [addNewZone, { isLoading, error }] = useAddNewZoneMutation(token);

  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const payload = {
      name: values.zone,
      building: values.building,
      floor: values.floor,
      branch: values.branch,
    };
    try {
      await addNewZone(payload).unwrap();
      form.resetFields();
      message.success("Zone is added successfully.");
      handleOk();
    } catch (error) {
      const errorMessage = error?.data?.errors?.[0] || "Failed to add floor";
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ floor: undefined });
  }, [selectedBuilding, form]);

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    handleCancel(); // Call parent cancel handler
  };

  return (
    <>
      <Card title="Add New Zone">
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}>
              <Form.Item
                rules={[{ required: true }]}
                label="Project / Branch"
                name="branch">
                <Select
                  style={{ height: 44, width: 400 }}
                  placeholder="Select a branch"
                  loading={isLoadingBranch}
                  onChange={(value) => setSelectedBranch(value)}>
                  {branchListData?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                label="Building"
                name="building">
                <Select
                  style={{ height: 44, width: 400 }}
                  placeholder="Select a building"
                  loading={isLoadingBuildings}
                  disabled={!selectedBranch}
                  onChange={(value) => setSelectedBuilding(value)}>
                  {buildingListData?.data?.map((building) => (
                    <Option key={building._id} value={building._id}>
                      {building.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                label="Floor Name"
                name="floor">
                <Select
                  style={{ height: 44, width: 400 }}
                  placeholder="Select a floor"
                  disabled={!selectedBuilding}
                  loading={isLoadingFloors}>
                  {floorListData?.data?.map((floor) => (
                    <Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                label="Zone / View"
                name="zone"
                style={{ width: 400 }}>
                <Input style={{ height: 44 }} placeholder="Zone / View" />
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="text" onClick={handleFormCancel} className="btn">
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn filter"
                loading={isLoading}>
                Create
              </Button>
            </div>
          </Form>
        </ConfigProvider>
      </Card>
    </>
  );
};

export default AddNewZone;
