import React from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  ConfigProvider,
  Space,
  Card,
} from "antd";
import { useGetAllBranchesQuery } from "../property/propertyApi";

const { Option } = Select;

const EditUtilityModal = ({
  isEditModalOpen,
  handleEditOk,
  handleEditCancel,
  isViewMode,
  form,
  currentRecord,
  handleUtilityTypeChange,
}) => {
  const { data: branchList, isLoading: isLoadingBranch } =
    useGetAllBranchesQuery();
  console.log(currentRecord);

  return (
    <Modal
      width={880}
      className="bu-add-modal"
      open={isEditModalOpen}
      closeIcon={null}
      onCancel={handleEditCancel}
      footer={null}>
      <Card
        title={isViewMode ? "View Type of Utility" : "Edit Type of Utility"}>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              utilityType: currentRecord?.utilityType,
              title: currentRecord?.title,
              description: currentRecord?.description,
              unit: currentRecord?.unit,
              rateValue: currentRecord?.rateValue,
              rateType: currentRecord?.rateType,
              branch: currentRecord?.branch,
              propertyType: currentRecord?.propertyTypeNo,
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}>
              <Form.Item
                rules={[{ required: true }]}
                style={{ width: 400 }}
                name="branch"
                label="Branch">
                <Select
                  mode="multiple"
                  style={{ height: 44 }}
                  placeholder="Select Branch"
                  disabled={isViewMode}>
                  {branchList?.data?.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                name="propertyType"
                label="Property Type"
                style={{ width: 400 }}>
                <Select
                  style={{ height: 44 }}
                  placeholder="Select Property Type">
                  <Option value={1}>Rent</Option>
                  <Option value={2}>Sell</Option>
                  <Option value={3}>Free of Charge (FOC)</Option>
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                style={{ width: 400 }}
                name="utilityType"
                label="Utility Type">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select Utility Type"
                  onChange={handleUtilityTypeChange}
                  disabled={isViewMode}>
                  <Option value="meter">Meter</Option>
                  <Option value="area">Area</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: true }]}
                style={{ width: 400 }}
                name="title"
                label="Title">
                <Input
                  style={{ height: 44 }}
                  placeholder="Title"
                  readOnly={isViewMode}
                />
              </Form.Item>

              <Form.Item
                rules={[{ required: true }]}
                style={{ width: 400 }}
                name="unit"
                label="Unit">
                <Input
                  type="number"
                  min={1}
                  style={{ height: 44 }}
                  placeholder="Unit"
                  readOnly={isViewMode}
                />
              </Form.Item>

              <Form.Item
                rules={[{ required: true }]}
                style={{ width: 400 }}
                label="Rate">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="rateValue"
                    noStyle
                    rules={[
                      { required: true, message: "Rate value is required" },
                    ]}>
                    <Input
                      type="number"
                      min={1}
                      style={{ width: "80%", height: 44 }}
                      placeholder="Rate Value"
                      readOnly={isViewMode}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rateType"
                    noStyle
                    rules={[
                      { required: true, message: "Rate type is required" },
                    ]}>
                    <Select
                      style={{ width: "30%", height: 44 }}
                      placeholder="Rate Type"
                      disabled={isViewMode}>
                      <Option value="MMK">Flat</Option>
                      <Option value="%">Percent</Option>
                    </Select>
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            </div>

            {!isViewMode && (
              <div className="add-branch-btn-gp">
                <Button
                  type="text"
                  className="btn"
                  key="back"
                  onClick={handleEditCancel}>
                  Cancel
                </Button>
                <Button
                  key="submit"
                  className="btn filter"
                  onClick={handleEditOk}>
                  Update
                </Button>
              </div>
            )}
          </Form>
        </ConfigProvider>
      </Card>
    </Modal>
  );
};

export default EditUtilityModal;
