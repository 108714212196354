import React from 'react'
import { FilterCard, ReportingHeader } from '../../component'
import { Select } from 'antd';

const PropertyReport = () => {
  return (
    <div>
      <ReportingHeader title="Property Report" />
      <FilterCard>
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Branch / Project"
        />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Building"
        />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Floor"
        />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Zone / View"
        />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Room Type"
        />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Property Type"
        />
        <Select
          style={{
            width: 150,
            height: 38,
          }}
          placeholder="Status"
        />
      </FilterCard>
    </div>
  )
}

export default PropertyReport