import { Breadcrumb } from "antd";
import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { ViewInvoiceSettingCard } from "../../features";

const ViewInvoiceSetting = () => {
  const { id } = useParams();
  const { state } = useLocation(); // Access the passed state
  const record = state?.record; // Record data passed from the table

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: "10px", cursor: "pointer" }}
        items={[
          {
            title: <Link to={`/${id}/setting/invoice`}>Invoice Setting</Link>,
          },
          {
            title: "View Invoice Setting",
          },
        ]}
      />
      <ViewInvoiceSettingCard record={record} />
    </div>
  );
};

export default ViewInvoiceSetting;
