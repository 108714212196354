import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Button,
  message,
  Breadcrumb,
  DatePicker,
  Upload,
  ConfigProvider,
} from "antd";
import "../styles.css";
import {
  useGetAllCitiesListQuery,
  useGetAllTenantQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";
import {
  useAddNewSubTenantPropertyMutation,
  useGetShopByTenantIdQuery,
} from "../../../features/tenant/subTenantApi";
import { InboxOutlined } from "@ant-design/icons";
import { useGetAllContractSettingQuery } from "../../../features/contract/contractApi";

const { Option } = Select;
const { Dragger } = Upload;

const AddSubProperty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const subTenantId = location.state?.subTenantId;
  const [contractFileList, setContractFileList] = useState([]);

  const { data: tenantListData, isLoading: isTenantLoading } =
    useGetAllTenantQuery();
  const [selectedTenant, setSelectedTenant] = useState();
  const { data: shopListDataByTenant, isLoaidng: isShopLoading } =
    useGetShopByTenantIdQuery(
      { tenantId: selectedTenant },
      { skip: !selectedTenant }
    );

  const [addNewSubTenantProperty, { isLoading: isAddNewSubTenantProperty }] =
    useAddNewSubTenantPropertyMutation();

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  const { data: contractTypeList, isLoading: isContractTypeLoading } =
    useGetAllContractSettingQuery();

  const onFinish = async (values) => {
    try {
      const payload = {
        tenant: selectedTenant,
        shop: values.shop,
        outlet_name: values.outlet_name,
        billing_contact_person: {
          name: values.billingName || "",
          phoneNumber: values.billingPhoneNumber || "",
          email: values.billingEmail || "",
          city: values.billingCity || "",
          township: values.billingTownship || "",
          address: values.billingAddress || "",
        },
      };
      console.log("Payload", payload);
      await addNewSubTenantProperty({
        data: payload,
        subTenantId: subTenantId,
      }).unwrap();
      message.success("New Property is created successfully.");
      navigate(`/${id}/tenant/sub-list`);
    } catch (error) {
      message.error(`Not available to create new property.`);
    }
  };

  const [selectedOutletName, setSelectedOutletName] = useState("");

  const handleShopChange = (shopId) => {
    const selectedShop = shopListDataByTenant?.data?.find(
      (shopItem) => shopItem.shop._id === shopId
    );

    setSelectedOutletName(selectedShop?.outlet_name || "");
    form.setFieldsValue({
      outlet_name: selectedShop?.outlet_name || "",
      code: selectedShop?.shop?.code || "",
      zone: selectedShop?.shop?.zone?.name || "",
      floor: selectedShop?.shop?.floor?.name || "",
      building: selectedShop?.shop?.building?.name || "",
      area: selectedShop?.shop?.total_area || "",
      price: selectedShop?.shop?.price || "",
    });
  };

  const handleFileChange = ({ fileList }) => {
    setContractFileList(fileList);
  };

  const handleBeforeFileUpload = (file) => {
    return false;
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/sub-list`)}>
          Sub Tenant List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Sub Property</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "8px" }}>Shop Information</span>
            </div>
          }>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item
                name="outlet_name"
                className="tenant-label-group"
                label="Outlet Name">
                <Input
                  style={{ height: 44 }}
                  placeholder="Outlet Name"
                  value={selectedOutletName}
                  readOnly
                />
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Card style={{ marginTop: "20px" }} title="Billing Contact Person">
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item
                className="tenant-label-group"
                name="billingName"
                label="Name">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingPhoneNumber"
                label="Phone Number">
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingEmail"
                label="Email">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingCity"
                label="City">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingTownship"
                label="Township">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="billingAddress"
                label="Address"
                style={{ width: "100%" }}>
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Card title="Property Information" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item
                className="tenant-label-group"
                name="tenant"
                label="Tenant Name">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select tenant name"
                  onChange={(value) => setSelectedTenant(value)}
                  loading={isTenantLoading}>
                  {tenantListData?.data?.map((tenant) => (
                    <Option key={tenant._id} value={tenant._id}>
                      {tenant?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="shop"
                className="tenant-label-group"
                label="Shop Name">
                {/* <Select style={{ height: 44 }} placeholder="Select Shop" /> */}
                <Select
                  style={{ height: 44 }}
                  onChange={handleShopChange}
                  placeholder="Select shop"
                  loading={isShopLoading}>
                  {shopListDataByTenant?.data?.map((shop) => (
                    <Option key={shop.shop._id} value={shop.shop._id}>
                      {shop?.shop?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="code"
                className="tenant-label-group"
                label="Shop Code">
                <Input readOnly style={{ height: 44 }} placeholder="Code" />
              </Form.Item>

              <Form.Item
                name="zone"
                className="tenant-label-group"
                label="Zone / Role">
                <Input
                  readOnly
                  style={{ height: 44 }}
                  placeholder="Zone / Role"
                />
              </Form.Item>

              <Form.Item
                name="floor"
                className="tenant-label-group"
                label="Floor">
                <Input readOnly style={{ height: 44 }} placeholder="Floor" />
              </Form.Item>

              <Form.Item
                name="building"
                className="tenant-label-group"
                label="Building">
                <Input readOnly style={{ height: 44 }} placeholder="Building" />
              </Form.Item>

              <Form.Item
                name="area"
                className="tenant-label-group"
                label="Total Area (Sq ft)">
                <Input
                  readOnly
                  style={{ height: 44 }}
                  placeholder="Total Area"
                />
              </Form.Item>

              <Form.Item
                name="price"
                className="tenant-label-group"
                label="Price (MMK)">
                <Input readOnly style={{ height: 44 }} placeholder="Price" />
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Card title="Contract Information" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="contract-form">
              <Form.Item
                style={{ width: "100%" }}
                className="contract-label-group"
                name="contract_id" // This will store the contract ID
                label="Contract Name">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a contract"
                  loading={isContractTypeLoading}
                  onChange={(value, option) => {
                    form.setFieldsValue({
                      contract_name: option["data-contractname"], // Set the contract name in the form
                    });
                  }}>
                  {contractTypeList?.data?.map((contract) => (
                    <Option
                      key={contract._id}
                      value={contract._id}
                      data-contractname={contract.contractName} // Storing contractName
                    >
                      {contract?.contractName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="contract_name"
                style={{ display: "none" }} // Hidden field to store contract_name
              >
                <Input />
              </Form.Item>

              <Form.Item name="start_date" label="Start Date">
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Start Date"
                />
              </Form.Item>

              <Form.Item name="due_date" label="Due Date">
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Due Date"
                />
              </Form.Item>

              <Form.Item name="contract_file" label="Contract (PDF or JPG)">
                <Dragger
                  onChange={handleFileChange}
                  beforeUpload={handleBeforeFileUpload}
                  multiple={false}
                  maxCount={1}
                  listType="text"
                  accept=".pdf, .jpg, .jpeg, .png"
                  style={{ width: 1060, height: 44 }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single upload.
                  </p>
                </Dragger>
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Form.Item>
          <div className="add-tenant-btn-group">
            <Button className="btn">Cancel</Button>
            <Button
              type="text"
              htmlType="submit"
              loading={isAddNewSubTenantProperty}
              disabled={isAddNewSubTenantProperty}
              className="btn filter">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddSubProperty;
