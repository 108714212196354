import React, { useState } from "react";
import { Button, Table, Dropdown, Space, Tag, Modal } from "antd";
import AddNewReminder from "./AddNewReminder";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
import { PageHeader } from "../../../component";

const Reminder = ({ onViewDetail, onBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showAddNewReminder, setShowAddNewReminder] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const viewDetails = (record) => {
    onViewDetail(record.type);
  };

  const items = (record) => [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: () => viewDetails(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: () => {
        setSelectedRecord(record);
        showModal();
      },
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Shop Name",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags?.map((tag) => {
            let color;
            switch (tag) {
              case "Success":
                color = "green";
                break;
              case "Sending":
                color = "orange";
                break;
              default:
                color = "green";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items(record),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      date: "11-11-2024",
      shopName: "Shop 1",
      description: "lorem",
      tags: ["Success"],
    },
    {
      date: "11-11-2024",
      shopName: "Shop 1",
      description: "lorem",
      tags: ["Sending"],
    },
  ];

  return (
    <>
      {showAddNewReminder ? (
        <AddNewReminder onBack={() => setShowAddNewReminder(false)} />
      ) : (
        <>
          <PageHeader
            title="Reminder"
            addBtn="Send Reminder"
            onAdd={() => setShowAddNewReminder(true)}
          />
          <Table
            style={{ marginTop: "16px" }}
            columns={columns}
            dataSource={dataSource}></Table>
          <Modal
            title="Are you sure to delete this property list?"
            open={isModalOpen}
            okText="Confirm"
            okType="danger"
            onOk={handleOk}
            onCancel={handleCancel}
          />
        </>
      )}
    </>
  );
};

export default Reminder;
