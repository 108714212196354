import React from 'react'
import { BusinessUnitHeader, BUTable } from '../../../features'

const BusinessUnitList = () => {
  return (
    <div>
      <BusinessUnitHeader/>
      <BUTable/>
    </div>
  )
}

export default BusinessUnitList