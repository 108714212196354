import React from "react";
import { PageHeader, SearchFilter } from "../../component";
import { useParams } from "react-router-dom";
import { RoleListTable } from "../../features";

const RolePermission = () => {
  const { id } = useParams();
  return (
    <div>
      <PageHeader
        title="Roles"
        addBtn="Add New Role"
        linkTo={`/${id}/adminstrator/role-permission/add-new-role`}
      />
      <SearchFilter searchPlaceHolder="Search by Role" />
      <RoleListTable />
    </div>
  );
};

export default RolePermission;
