import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Checkbox, Col, Row } from "antd";
import React, { useEffect, useState } from "react";

const TenantInformation = ({
  name,
  date,
  tenant_type,
  tenantCategoryName,
  nrc,
  email,
  phone_number,
  city,
  cityName,
  township,
  townshipName,
  address,
  no_of_property,
  property_information = [],  // Provide default values
  propertyList = [],
}) => {
  const [dropdownStates, setDropdownStates] = useState([]);

  useEffect(() => {
    // Update dropdown states whenever propertyList changes
    setDropdownStates(propertyList.map(() => false));
  }, [propertyList]);

  const toggleDropdown = (index) => {
    console.log("Toggling dropdown for index:", index); // Debug log
    setDropdownStates((prev) =>
      prev.map((isOpen, i) => (i === index ? !isOpen : isOpen))
    );
  };
  
  return (
    <div>
      <Card className="tenant-info-card">
        <div className="tenant-info-group">
          <div className="add-contract-avatar">
            <Avatar
              size={90}
              icon={<UserOutlined />}
              style={{ border: "3px solid white" }}
            />
            <p className="tenant-avatar-name">{name}</p>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <strong>DATE</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{date}</p>
            </Col>
            <Col span={4}>
              <strong>TENANT TYPE</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{tenant_type}</p>
            </Col>
            <Col span={4}>
              <strong>TENANT CATEGORY</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{tenantCategoryName}</p>
            </Col>
            <Col span={4}>
              <strong>TENANT NAME</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{name}</p>
            </Col>
            <Col span={4}>
              <strong>NRC</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{nrc}</p>
            </Col>
            <Col span={4}>
              <strong>EMAIL</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{email}</p>
            </Col>
            <Col span={4}>
              <strong>PHONE NUMBER</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{phone_number}</p>
            </Col>
            <Col span={4}>
              <strong>CITY</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{cityName}</p>
            </Col>
            <Col span={4}>
              <strong>TOWNSHIP</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{townshipName}</p>
            </Col>
            <Col span={4}>
              <strong>ADDRESS</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{address}</p>
            </Col>
            <Col span={4}>
              <strong>NO. OF PROPERTIES</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p style={{ margin: 0 }}>{no_of_property}</p>
            </Col>
          </Row>
          <p style={{ color: "#0057FF", fontWeight: 600 }}>
            <Checkbox checked/> Billing contact person same as tenant info.
          </p>

          <h3 style={{ marginBottom: 8, fontSize: "20px" }}>Properties</h3>
        </div>
        {propertyList?.map((property, index) => (
          <div key={property?.shopName}>
            <Button
              className={
                dropdownStates[index] ? "dropdown-button-open" : "dropdown-button"
              }
              type="link"
              onClick={() => toggleDropdown(index)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "8px",
                }}
              >
                Property {property.shop_name}{" "}
                <div>{dropdownStates[index] ? "▲" : "▼"}</div>
              </div>
            </Button>

            {dropdownStates[index] && (
              <div className="property-info-dropdown">
                <h4>PROPERTY INFORMATION</h4>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <strong>PROJECT / BRANCH</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>
                      {property.branch?.name || property.branchName || "N/A"}
                    </p>
                  </Col>
                  <Col span={8}>
                    <strong>BUILDING</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>
                      {property.building?.name ||
                        property.buildingName ||
                        "N/A"}
                    </p>
                  </Col>
                  <Col span={8}>
                    <strong>FLOOR</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>
                      {property.floor?.name || property.floorName || "N/A"}
                    </p>
                  </Col>
                  <Col span={8}>
                    <strong>ZONE / ROLE</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>
                      {property.zone?.name || property.zoneName || "N/A"}
                    </p>
                  </Col>
                  <Col span={8}>
                    <strong>ROOM TYPE</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>{property.room_type_name || property?.room_type?.room_type}</p>
                  </Col>
                  <Col span={8}>
                    <strong>ROOM / SHOP NAME</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>{property.shopName || property?.shop?.name}</p>
                  </Col>
                  <Col span={8}>
                    <strong>TOTAL AREA</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>
                      {(property.area || property.total_area)?.toString()} sq ft
                    </p>
                  </Col>
                  <Col span={8}>
                    <strong>PRICE</strong>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <p style={{ margin: 0 }}>
                      {property.price
                        ? property.price.toLocaleString()
                        : "N/A"}{" "}
                      MMK
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        ))}
      </Card>
    </div>
  );
};

export default TenantInformation;
