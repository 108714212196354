import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, message, Card } from "antd";
import {
  useGetAllBranchesQuery,
  useGetBuildingsByBranchIdQuery,
} from "../propertyApi";

const { Option } = Select;

const EditFloorModal = ({
  isOpen,
  onClose,
  currentRecord,
  form,
  onUpdate,
  isUpdateLoading,
}) => {
  const [selectedBranch, setSelectedBranch] = useState();

  const { data: BranchListData, isLoading: branchLoading } = useGetAllBranchesQuery();
  const { data: buildingListData, isLoading: isLoadingBuildings } = useGetBuildingsByBranchIdQuery(selectedBranch, {
    skip: !selectedBranch,
  });
  
  // Initialize form and selectedBranch when currentRecord changes
  useEffect(() => {
    if (currentRecord) {
      form.setFieldsValue({
        branch: currentRecord.branchId,
        building: currentRecord.buildingId,
        floorName: currentRecord.floor,
        numberOfShops: currentRecord.numberOfShops,
      });
      setSelectedBranch(currentRecord.branchId);
    }
  }, [currentRecord, form]);

  // Update form field for building when buildingListData changes
  useEffect(() => {
    if (buildingListData && currentRecord) {
      form.setFieldsValue({
        building: currentRecord.buildingId,
      });
    }
  }, [buildingListData, currentRecord, form]);

  const handleEditOk = async () => {
    if (currentRecord) {
      const updatedData = {
        floorId: currentRecord.key,
        updatedData: {
          branch: form.getFieldValue("branch"),
          name: form.getFieldValue("floorName"),
          number_of_shop: form.getFieldValue("numberOfShops"),
          building: form.getFieldValue("building"),
        },
      };

      try {
        await onUpdate(updatedData);
        onClose();
        message.success("Floor updated successfully.");
      } catch (error) {
        const errorMessage = error?.data?.errors?.[0] || "Failed to update floor";
        message.error(errorMessage);
      }
    }
  };

  return (
    <Modal
      width={880}
      onCancel={onClose}
      className="bu-add-modal"
      open={isOpen}
      closeIcon={false}
      footer={null}
    >
      <Card title="Edit Floor">
        <Form layout="vertical" form={form}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Form.Item
              label="Project / Branch"
              name="branch"
              rules={[{ required: true, message: "Please select a branch" }]}
            >
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select Project / Branch"
                onChange={(value) => {
                  setSelectedBranch(value); // Update selectedBranch when user selects a new branch
                  form.setFieldsValue({ building: null }); // Reset building when branch changes
                }}
                loading={branchLoading}
              >
                {BranchListData?.data?.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Building"
              name="building"
              rules={[{ required: true, message: "Please select a building" }]}
            >
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a building"
                loading={isLoadingBuildings}
                disabled={!selectedBranch}
              >
                {buildingListData?.data?.map((building) => (
                  <Option key={building._id} value={building._id}>
                    {building.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Floor Name"
              name="floorName"
              style={{ width: 400 }}
            >
              <Input style={{ height: 44 }} placeholder="Floor Name" />
            </Form.Item>
            <Form.Item
              label="Number of Shops"
              name="numberOfShops"
              style={{ width: 400 }}
              rules={[{ required: true, message: "Please enter number of shops" }]}
            >
              <Input
                min={1}
                type="number"
                style={{ height: 44 }}
                placeholder="Number of Shops"
              />
            </Form.Item>
          </div>
          <div className="add-branch-btn-gp">
            <Button type="text" className="btn" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="btn filter"
              loading={isUpdateLoading}
              onClick={handleEditOk}
            >
              Update
            </Button>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditFloorModal;
