import React from 'react'
import { FilterCard, PageHeader, SearchFilter } from '../../component'
import { Select } from 'antd'

const CustomInvoice = () => {
  return (
    <div>
      <PageHeader title="Custom Invoice List" addBtn="Create Invoice" />
      <SearchFilter searchPlaceHolder="Search By Tenant Name" />
      <FilterCard>
      <Select
          placeholder="Invoice Type"
          style={{
            width: 140,
            height: 38,
          }}
        />
      </FilterCard>
    </div>
  )
}

export default CustomInvoice