import { Card, Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../features/tenant/tenantApi";

const { Option } = Select;

const BillingContactPerson = ({ billingInfo }) => {
  const [form] = Form.useForm();
  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();

  const [selectedCity, setSelectedCity] = useState(null);

  // Fetch the township list based on selected city
  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  // Watch for changes in billingInfo to update selectedCity
  useEffect(() => {
    if (billingInfo?.billingCity) {
      setSelectedCity(billingInfo.billingCity);
    }
  }, [billingInfo]);

  // Set initial form values
  useEffect(() => {
    form.setFieldsValue(billingInfo);
  }, [billingInfo, form]);

  return (
    <div>
      <Card style={{ marginTop: "15px" }} title="Billing Contact Person">
        <Form form={form} layout="vertical">
          <div className="add-branch-form">
            <Form.Item
              rules={[{ required: true }]}
              label="Contact Person Name"
              name="billingName"
              className="add-branch-input">
              <Input style={{ height: 44 }} placeholder="Contact Person Name" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, type: "email" }]}
              label="Email Address"
              name="billingEmail"
              className="add-branch-input">
              <Input style={{ height: 44 }} placeholder="Email Address" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              className="tenant-label-group"
              name="billingPhoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  pattern: /^[0-9]{11}$/, // Ensures exactly 11 digits
                  message:
                    "Phone number must be exactly 11 digits long and contain only numbers!",
                },
              ]}>
              <Input
                maxLength={11}
                onKeyDown={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                    e.preventDefault(); // Prevent non-numeric input
                  }
                }}
                style={{ height: 44 }}
                placeholder="Phone Number"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              className="tenant-label-group"
              label="City"
              name="billingCity">
              <Select
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={(value) => {
                  setSelectedCity(value); // Update selected city
                  form.setFieldsValue({ billingTownship: null }); // Reset township when city changes
                }}
                loading={isCityListLoading}>
                {cityList?.data?.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city?.city_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              rules={[{ required: true }]}
              className="tenant-label-group"
              label="Township"
              name="billingTownship">
              <Select
                style={{ height: 44 }}
                placeholder="Select a township"
                loading={isTownshipListLoading}>
                {townshipList?.data?.map((township) => (
                  <Option key={township._id} value={township._id}>
                    {township?.township_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ marginTop: 16 }}>
            <Form.Item name="billingAddress" label="Address">
              <Input style={{ height: 44 }} placeholder="Address" />
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default BillingContactPerson;
