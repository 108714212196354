import React, { useState } from "react";
import { FilterCard, PageHeader } from "../../component";
import { DatePicker, Form } from "antd";
import { AddExchangeRate, ExchangeRateTable } from "../../features";

const { RangePicker } = DatePicker;
const ExchangeRate = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    setSelectedMonth(formValues.month);
    setSelectedYear(formValues.year);
    setSelectedDateRange(formValues.dateRange); // Save the date range
  };

  const handleClearFilters = () => {
    form.resetFields(); // Resets all form fields
    setSelectedMonth(null); // Clears selected payment filter
    setSelectedDateRange(null); // Clears selected date range
    setSelectedYear(null); // Clears selected tenant filter
  };

  const handleDateRange = (value) => {
    setSelectedDateRange(value); // Update the state with the selected date range
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <PageHeader
        title="Exchange Rate"
        addBtn="Add Type of Issue"
        onAdd={showModal}
      />
      <div className="filter-card">
        <Form form={form}>
          <FilterCard onFilter={handleFilter} onClear={handleClearFilters}>
            <Form.Item name="dateRange">
              <RangePicker
                onChange={handleDateRange}
                style={{ height: 38, width: 233, borderRadius: "25px" }}
              />
            </Form.Item>
            <Form.Item name="year">
              <DatePicker
                onChange={handleYearChange}
                placeholder="Year"
                picker="year"
                style={{
                  width: 140,
                  height: 38,
                  borderRadius: "25px",
                }}></DatePicker>
            </Form.Item>
            <Form.Item name="month">
              <DatePicker
                onChange={handleMonthChange}
                placeholder="Month"
                picker="month"
                style={{
                  width: 140,
                  height: 38,
                  borderRadius: "25px",
                }}></DatePicker>
            </Form.Item>
          </FilterCard>
        </Form>
      </div>
      <ExchangeRateTable
        selectedDateRange={selectedDateRange}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
      />
      <AddExchangeRate visible={isModalVisible} onClose={handleCancel} />
    </div>
  );
};

export default ExchangeRate;
